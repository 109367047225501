@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.tcheckbox {
	width: 36px;
	height: 36px;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url("../../../assets/icon/checkbox_nor.png");
	&.selected {
		background-image: url("../../../assets/icon/checkbox_selected.svg");
	}
	&:focus{
		background-image: url("../../../assets/icon/checked_icon.svg");
	}
}
@__DEV__: true;