@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.tSummary {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:not(.lite) {
    background: #FEFEFE;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    height: 146px;
  }

  .summaryWrap {
    display: flex;

    .summaryItem {
      display: flex;
      padding-left: 50px;
      position: relative;
      width: 100%;
      align-items: center;

      &:not(:last-child)::after {
        content: '';
        border-right: 1px solid #d3d3d3;
        height: 48px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      img {
        display: block;
        margin-right: 24px;
        width: 64px;
        height: 64px;
      }

      .label {
        color: #787776;
        font-size: 26px;
        font-family: 'LGSmartUISB';
        margin-bottom: 2px;
      }

      .value {
        color: @COLOR_GRAY08;
        font-size: 34px;
        font-family: 'LGSmartUISB';
      }
    }
  }

  &.large {
    height: 181px;

    .summaryItem {
      &:not(:last-child)::after {
        height: 60px;
      }

      .title {
        font-size: 28px;
        margin-bottom: 4px;
      }

      .text {
        font-size: 38px;
      }
    }
  }

  &.lite {
    .summaryItem {
      width: auto;
      padding-left: 40px;

      &:first-child {
        padding-left: 0;
      }

      &:not(:last-child)::after {
        content: unset;
      }

      img {
        width: 36px;
        height: 36px;
        margin-right: 12px;
      }

      .text {
        font-size: 30px;
        font-family: 'LGSmartUISB';
      }
    }
  }

  &.center {
    .summaryItem {
      padding-left: 0;
      justify-content: center;
    }
  }
}
@__DEV__: true;