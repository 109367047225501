@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.panel {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.8;
  z-index: 30;
  position: relative;

  >div {
    width: 100%;
    height: 100%;

    >div {
      width: 100%;
      height: 100%;
      padding: 60px 100px 0 100px !important;

      >div {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  .title {
    padding: 0 0 20px 0;
    font-size: 35px;
  }

  .date {
    font-size: 30px;
    padding: 0;
  }

  .tScroller {
    height: 75vh;
    margin: 0;
    padding: 0 50px 0 0;

    .terms {
      font-size: 26px;
      .shadow {
        position: sticky;
        bottom: -1px;
        height: 100px;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), #000);
      }
      .btnBox {
        margin-bottom: 10px;
        text-align: center;
      }
    }
  }
}

// type === half
.halfScroller {
  height: 534px;
  width: 855px;
  margin: 0;
  padding: 0 36px 0 0;
  text-align: left;

  .terms {
    font-size: 26px;
    color: @COLOR_GRAY04;
    font-family: 'LGSmartUIR';
    font-size: 27px;
    line-height: 38px;
    .title {
      font-family: 'LGSmartUISB';
      font-size: 36px;
      line-height: 46px;
    }
    .topShadow {
      position: sticky;
      top: -1px;
      height: 24px;
      background: linear-gradient(to top, rgba(33, 33, 32, 0), #212120);
    }
    .shadow {
      position: sticky;
      bottom: -1px;
      height: 24px;
      background: linear-gradient(to bottom, rgba(33, 33, 32, 0), #212120);
    }
    .btnBox {
      margin-bottom: 10px;
      text-align: center;
    }
  }
}


@__DEV__: true;