@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";


.pointLayer{
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  margin-bottom: 16px;
  .title{
    font-size: 14px;
    height: 16px;
    line-height: 16px;
    color: #494847;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    margin-bottom: 6px;
  }
  .point {
    font-size: 26px;
    height: 25px;
    line-height: 25px;
    color: #212120;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    margin-bottom: 16px;
  }
  .scoreSum{
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    gap: 8px;
    .scoreItem{
      .size(@w: auto, @h: 24px);
      .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
      gap: 4px;
      border: 1px solid #F2EFED;
      border-radius: 30px;
      background-color: #fff;
      padding: 0 12px;
      .text {
        font-size: 14px;
        height: 16px;
        line-height: 16px;
        color: #363533;
        font-family: 'LGSmartUISB'; //font-weight: 600;
      }
      .value {
        font-size: 14px;
        height: 16px;
        line-height: 16px;
        color: #212120;
        font-family: 'LGSmartUIB'; //font-weight: 700;
        &.danger {
          color: #F44B4A;
        }
        &.manage {
          color: #F9B811;
        }
        &.fine {
          color: #44A280;
        }
      }
    }
  }
}

.profileContainer{
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
  margin-bottom: 16px;
  gap: 14px;
  .bodyWrapper{
    .size(@w: 138px, @h: 223px);
    border-radius: 6px;
    border: 1px solid #F2EFED;
    position:relative;

    // border-width: 1apx;
    // border-color: @COLOR_GRAY03;
    // border-style: solid;
    // border-radius: 12px;
    .canvasWrapper{
      .size(@w: 138px, @h: 180px);
      .position(@position: relative, @top: 20px, @right: unset, @bottom: unset, @left: 50%);
      transform: translateX(-50%);
      .canvas {
        .size(@w: 100%, @h: 100%);
        // border-width: 1apx;
        // border-color: @COLOR_GRAY03;
        // border-style: solid;
        // border-radius: 12px;
      }
    }
    .positionGuide{
      .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
      .position(@position: absolute, @top: unset, @right: unset, @bottom: 10px, @left: 10px);
      .size(@w: 18px, @h: 18px);
      border-radius: 50%;
      background-color: @COLOR_GRAY03;
      .font(@fontFamily: "LGSmartUISB", @fontSize: 12px, @color: @COLOR_GRAY01, @fontWeight: 600);
      // line-height: 39px;
      &.rightAlign {
        left: initial;
        right: 10px;
      }
    }
  }
}

.barContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.notice {
  margin-top: 20px;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 14px, @color: @COLOR_GRAY05, @fontWeight: 400);
  line-height: 1.2;
  padding: 0 20px;
  text-align: center;
}

.detailContainer{
  .flex(@display: flex, @justifyCenter: unset, @alignCenter: unset, @direction: column);
  .summaryDetail {
    .size(@w: 288px, @h: 95px);
    font-size: 12px;
    color: #363533;
    font-family: 'LGSmartUIR'; //font-weight: 400;
    line-height: 19px;
    word-break: break-all;
    margin-bottom: 12px;
  }

  .canvasGuide{
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    font-size: 12px;
    height: 15px;
    line-height: 15px;
    color: #787776;
    font-family: 'LGSmartUIR'; //font-weight: 400;
    &::before {
      content: "";
      display: block;
      .size(@w: 15px, @h: 15px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../../../../assets/bodyAlignment/ic_workoutmanager_alret.png");
      margin-right: 4px;
    }
  }

}


@__DEV__: true;