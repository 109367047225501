@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";


.tMediaItem{
	height: 100%;
	width: 100%;
	position: relative;
	padding: 16px;
	box-sizing: border-box;
	color: black;
	font-family: 'LGSmartUIR';

	.image{
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-position: center;
		background-repeat: no-repeat;
		z-index: -1;
	}

	.title{
		font-size: 30px;
		font-weight: bold;
	}
	.brand{
		font-size: 20px;
	}
	.price{
		font-size: 30px;
		font-weight: bold;
		position: absolute;
		left: 16px;
		bottom: 16px;
	}
	.focusCover{
			position: absolute;
			width: 100%;
			height: 100%;
			top:0;
			left:0;
			display: none;
	}
	&:focus {
		color: white;
		.focusCover{
			display: inherit;
			background-color: @fitTv-spotlight-color !important;
			opacity: 0.3;
		}
	}
}
@__DEV__: true;