@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";


.display {
  display: none;
}
.themeImage {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/HomecareProgram/img_homecare_bg.jpg");
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.3;
  }
}
.bgGradient {
  width: 100%;
  height: 298px;
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(79, 66, 60, 0) 0%, #6e5f59 100%);

  &.top {
    bottom: auto;
    top: 0;
    height: 303px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}

.firstPage{
  width: 100%;
  height: ~"calc(100vh - @{fitTv-tHeader-height})";
  position: relative;
}

.secondPage{
  width: 100%;
  height: ~"calc(100vh - @{fitTv-tHeader-height})";
  position: relative;
}
.scrollGuide {
  color: #FEFEFE;
  font-size: 24px;
  height: 28px;
  line-height: 28px;
  font-family: 'LGSmartUIB'; //font-weight: 700;
  display: flex;
  justify-content: left;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 887px;
  transform: translateX(-50%);

}
.scrollGuide::before { // arrow bottom
  content: "";
  display: block;
  background-image: url('../../../assets/HomecareProgram/ic_arrow_down_dark.svg');
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 10px;
}
.contentList{
  margin-bottom: 20px;
}
.mediaListTitle{
  .font(@fontFamily: "LGSmartUIB" , @fontSize: 34px, @color: @COLOR_GRAY08, @fontWeight: 700);
  margin-bottom: 10px;
  color: #212120;
  height: 40px;
  line-height: 40px;
  margin-left: 75px;
}

.panel{
  font-family: "LGSmartUIR";
  width: 100%;
	height: 100%;
	.tBody{
		padding: 0px !important;

    .flexBox{
      display: flex;
      gap: 48px;
      position: absolute;
      top: 615px;
      left: 50%;
      transform: translateX(-50%);
    }
	}
}

@ITEM_WIDTH: 896px;
@ITEM_HEIGHT: 240px;
@ITEM_GAP: 60px;
@ITEM_TOP_BOTTOM_PADDING: 20px;
@ITEM_LEFT_RIGHT_PADDING: 104px;

.scroller {
  width: 100%;
  height: ~"calc(@{ITEM_HEIGHT} + @{ITEM_TOP_BOTTOM_PADDING} * 2)";
}

.container {
  width: ~"calc(@{ITEM_WIDTH} * 2 + @{ITEM_GAP} * 1 )";
  gap: @ITEM_GAP;
  display: flex;
  padding: @ITEM_TOP_BOTTOM_PADDING @ITEM_LEFT_RIGHT_PADDING;

}


.programContainer {
  display: flex;
  align-items: center;
  &:focus {
    .contentImage {
      transform: scale(1.08);
      transition: transform 0.3s ease;
    }
  }
  .contentImage {
    width: 427px;
    height: 240px;
    border-radius: 12px;
    overflow: hidden;
    display: block;
    margin-right: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .contentBox {
    display: flex;
    flex-direction: column;
    width: 429px;
    .contentTitle {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @PRIMARY_COLOR_BROWN02, @fontWeight: 600);
      height: 32px;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: inline-block;
        margin-right: 10px;
        width: 4px;
        height: 23px;
        background-color: @PRIMARY_COLOR_BROWN02;
      }
    }
    .contentSubtitle {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 30px, @color: #222222, @fontWeight: 600);
      margin-bottom: 16px;
    }
    .contentBottomBox {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY06, @fontWeight: 600);
      display: flex;
      align-items: center;

      .contentBottomText {
        display: flex;
        align-items: center;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        > span {
          .font(@fontFamily: "LGSmartUIB", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 700);
        }
        > img {
          width: 36px;
          height: 36px;
          border-radius: 8px;
          display: block;
          margin-right: 6px;
        }
      }
    }
  }
}
@__DEV__: true;