@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.panel {
  position: relative;
  font-family: "LGSmartUIR";
  color: black;
  width: 100%;
  height: 100%;

}

.body {
  display: flex;
  flex-direction: row;
  column-gap: 97px;
  align-items: center;
  justify-content: center;
  margin-top: 108px;

  .vr {
    width: 1px;
    height: 606px;
    background: @COLOR_GRAY04
  }

  .subTitle {
    color: @COLOR_GRAY08;
    font-family: "LGSmartUIB";
    font-size: 36px;
    line-height: normal;
    margin-bottom: 50px;
    max-width: 400px;
  }

  .workoutWeek {
    width: 732px;
    .titleDiv {
      display: flex;
      justify-content: space-between;
      .total {
        height: 63px;
        width: auto;
        max-width: 200px;
        border-radius: 74px;
        color:@COLOR_GRAY08;
        text-align: center;
        font-family: "LGSmartUISB";
        font-size: 33px;
        line-height: normal;
        &.selected{
          box-shadow: 0 0 0 4px @PRIMARY_COLOR_BROWN03 inset;
          background-color: @COLOR_GRAY01;
          .title{
            color: @COLOR_GRAY08;
          }
        }
        &:focus {
          background-color: @PRIMARY_COLOR_BROWN03;
          .title{
            color: @COLOR_GRAY08;
          }
        }
      }
    }
    .week {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 24px;
      row-gap: 24px;
      .day {
        width: 228px;
        height: 144px;
        border-radius: 13px;
        background: @COLOR_GRAY01;
        box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
        position: relative;

        .check {
          display: inline-block;
          position: absolute;
          top: 20px;
          left: 20px;
          width: 38px;
          height: 38px;
          background-size: 38px 38px;
          background-repeat: no-repeat;
          background-image: url("../../../assets/icon/checkbox_nor.png");
          &.select {
            background-image: url("../../../assets/icon/checkbox_focus.png");
          }
        }

        .time {
          color: @COLOR_GRAY04;
          text-align: center;
          font-family: "LGSmartUISB";
          font-size: 27px;
          line-height: normal;
          &.setData {
            color: @PRIMARY_COLOR_GREEN01;
          }
        }

        &.selected{
          box-shadow: 0 0 0 4px @PRIMARY_COLOR_BROWN03 inset;
        }

        &:focus {
          background: @PRIMARY_COLOR_BROWN03;
          .check {
            background-image: url("../../../assets/icon/checkbox_nor.png");
            &.select {
              background-image: url("../../../assets/icon/checkbox_focus.png");
            }
          }

          .time {
            color: @COLOR_GRAY01;
            &.setData {
              color: @COLOR_GRAY01;
            }
          }
        }
      }
    }
  }
  .workoutTime {
    align-self: flex-start;
  }
}

.btnArea {
  margin-top: 75px;
  display: flex;
  column-gap: 24px;
  justify-content: center;
}
@__DEV__: true;