@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

@ARROW_HEIGHT: 5px;

.toolTip {
  .position(@position: absolute, @top: unset, @right: unset, @bottom: ~"calc(100% + 12px)", @left: 50%);
  .size(@w: fit-content, @h: 33px);
  transform: translateX(-50%);
  border-radius: 8px;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #494947;
  z-index: 1;
  transition: bottom 0.3s ease-in-out;
  
  .arrow {
    position: absolute;
    width: 11px;
    height: @ARROW_HEIGHT;
    background-image: url('../../../../../assets/FitnessManager/icon/ic_tooltip_dark_s.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .arrow {
    left: 50%;
    transform: translateX(-50%);
    bottom: -@ARROW_HEIGHT;
  }


  .title {
    .size(@w: auto, @h: 100%);
    font-size: 21px;
    line-height: 33px;
    color: #FEFEFE;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    text-align: center;
  }

}


@__DEV__: true;