@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.buttoncontainer {
  padding: 50px, 70px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 1fr);
  margin: 20px;
  height: auto;
}

@__DEV__: true;