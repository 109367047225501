@import '../../style/CommonStyle.module.less';

.tPopUp{
  width: 1025px !important;
  font-size: 36px !important;
  .checkBoxContainer{
    background: linear-gradient(to top, white, transparent);
    margin-top: 48px;
    .tscroller{
      height: 500px;
      padding: 0 12px 0 0;
      margin-right: 20px;
    }
    > div:first-child{
      margin-top: 0;
    }
    .romSelectContainer{
      display: flex;
      justify-content: space-between;
      .romCheckBox{
        display: flex;
      }
    }
    .totalCheck, .upperCheck, .lowerCheck{
      height: 63px;
      width: fit-content;
      border-radius: 74px;
      font-size: 33px;
      color: @COLOR_GRAY08;
      &:focus{
        background-color: @PRIMARY_COLOR_BROWN03;
        .title{
          color: @COLOR_GRAY08;
        }
      }
      &.selected{
        box-shadow: 0 0 0 4px @PRIMARY_COLOR_BROWN03 inset;
        background-color: @COLOR_GRAY01;
        .title{
          color: @COLOR_GRAY08;
        }
      }
    }
    .eachBox{
      justify-content: flex-start;
      width: 805px;
      height: 116px;
      font-family: 'LGSmartUISB';
      font-size: 32px;
      border-radius: 12px;
      margin: 16px 0;
      background-color: @COLOR_GRAY02;
      overflow: hidden;
      &:focus{
        background-color: @PRIMARY_COLOR_BROWN03;
        color: @COLOR_GRAY01;
        vertical-align: middle;
        .sftMode{
          >span:first-child{ // 의자 이미지
            background-image: url('../../../assets/icon/ic_chair_pre_s.png');
          }
          >span:nth-child(4){ // 덤벨 이미지
            background-image: url('../../../assets/icon/ic_dumbbell_pre_s.png');
          }
        }
      }
      &.selected{
        box-shadow: 0 0 0 4px @PRIMARY_COLOR_BROWN03 inset;
        background-color: @COLOR_GRAY01;
        color: @COLOR_GRAY08;
      }
      .sftMode{
        line-height: 28px;
        .divideBar{
          position: absolute;
          display:inline-block;
          width: 2px;
          height: 32px;
          background-color: @COLOR_GRAY03;
          margin: 0 20px;
        }
        >span:first-child, >span:nth-child(4){
          display: inline-block;
          width: 28px;
          height: 28px;
          background-size: cover;
          background-repeat: no-repeat;
          vertical-align: middle;
          margin-right: 8px;
        }
        >span:first-child{
          background-image: url('../../../assets/icon/ic_chair_nor_s.png');
        }
        >span:nth-child(2){
          right: 40px;
        }
        >span:nth-child(4){
          margin-left: 40px;
          background-image: url('../../../assets/icon/ic_dumbbell_nor_s.png');
        }
      }
    }
  }
}

@__DEV__: true;