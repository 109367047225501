@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

//
.graphBox {
  position: relative;
  .size(@w: 100%, @h: 2.9167rem);
  display: flex;
  align-items: center;
  background-color: #e0ded5;
  border: 1apx solid #fff;
  border-left-style: none;
  //border-radius: 0 2.7917rem 2.7917rem 0;
  > div {
    border: 1apx solid #c9c8c5;
  }

  .graphBg01 {
    max-width: 69.37%;
    background-color: #8b8b8b;
  }
  .graphBg02 {
    max-width: 58.02%;
    background-color: #8b8b8b;
  }
  .graphBg03 {
    max-width: 53.69%;
    background-color: #8b8b8b;
  }
  .graphBg04 {
    max-width: 46.56%;
    background-color: #8b8b8b;
  }
  .graphBg05 {
    max-width: 46.56%;
    background-color: #c04554;
  }
}

.overallGraphBox {
  position: relative;
  .size(@w: 100%, @h: 76px);
  display: flex;
  align-items: center;
  background-color: @COLOR_GRAY02;
  border: 1apx solid #fff;
  border-left-style: none;

  .overallGraphBox1142 {
    width: 1142px;
    height: 100%;
  }
  > div {
    border: 1apx solid transparent;
    border-right: none;
    border-left: none;
  }
  // .graphBg01 {max-width: 40.75rem; background-color: #71B1A9;}
  // .graphBg02 {max-width: 34.916rem; background-color: #EEBB6F;}
  // .graphBg03 {max-width: 32.166rem; background-color: #EEBB6F;}
  // .graphBg04 {max-width: 27.083rem; background-color: #C04554;}
  // .graphBg05 {max-width: 23.5416rem; background-color: #C04554;}
}

//그래프 영역 공통
.commonGraphBg {
  .position(@position: relative, @top: 0, @bottom: null, @right: null, @left: 0);
  border-radius: 0 1.6667rem 1.6667rem 0;
  margin-right: 0.8333rem;
}

.graphInner {
  // position: absolute;
  // right: 0;
  box-sizing: border-box;

  padding: 0 47px;
  width: 100%;
  height: 100%;
  .flex(@display: flex, @justifyCenter: space-between , @alignCenter: center, @direction: row);
  font-family: "LGSmartUIB";
  color: @colorffffff;

  .physicalWord {
    .font(@fontFamily:"LGSmartUIB", @fontSize: 30px, @color: @COLOR_GRAY01, @fontWeight: 700);
    margin-right: 8px;
    line-height: 1.3;
    white-space: nowrap;
    display: inline-block;
    text-transform: capitalize;
    width: 439px;
  }
  .num {
    font-size: 27px;
    text-transform: capitalize;
    font-family: "LGSmartUIB";
    min-width: 89px;
    text-align: center;
  }
}

.gap {
  position: absolute;
  right: -30%;
  top: 50%;
  transform: translateY(-50%);
  .font(@fontFamily: "LGSmartUIB", @fontSize: 1.4583rem, @color: #5b5b5b, @fontWeight: 700);
}

//ani 붙일 경우 그래프 좌->우로 채워짐
.ani {
  animation-name: graph;
  display: block;
  height: inherit;
  animation-duration: 3s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  // animation-timing-function: ease-in-out;
  animation-timing-function: cubic-bezier(0.25, 0.25, -0.2, 0);
}

@keyframes graph {
  0% {
    width: 0;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}

.bodyAverageIcon {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
  width: 10.8333rem;
}

@__DEV__: true;