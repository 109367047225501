@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.panel {
  background: initial !important;
  background-color: @MAIN_COLOR !important;

  .tBody {
    height: 100%;
    padding: 0;

    .tScroller {
      height: calc(100% - @fitTv-tHeader-height);
      color: @COLOR_GRAY08;
    }
  }

  .dateWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 62px 0 60px;
    padding: 0 @fitTv-tBody-padding-leftright;
  }
}

.fullActivityContainer {
  margin-bottom: 60px;
  padding: 0 @fitTv-tBody-padding-leftright;

  .fullActivityTitle {
    font-family: 'LGSmartUIB';
    font-size: 34px;
    margin-bottom: 28px;
  }
}

.topBtn {
  display: block;
  margin: 0 auto 60px !important;
}

.infoBox {
  background: #FEFEFE;
  border-radius: 20px;
  margin-left: 60px;
  padding: 34px 40px;
  width: 100%;

  .infoWrapper {
    display: flex;

    .image {
      width: 338px;
      height: 189px;
      border-radius: 12px;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      .courseImage {
        position: absolute;
        width: 102px;
        height: 68px;
        bottom: 14px;
        right: 14px;
      }
    }

    .info {
      flex: 1;
      margin-left: 60px;

      .category {
        position: relative;
        color: #B59D85;
        font-size: 26px;
        font-family: 'LGSmartUISB';
        margin: 14px 0 12px;
        padding-left: 14px;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 4px;
          height: 23px;
          background-color: #B59D85;
        }
      }

      .title {
        font-size: 36px;
        font-family: 'LGSmartUIB';
        margin-bottom: 40px;
      }
    }
  }

  hr {
    margin: 28px 0 32px;
    border-color: #D9D9D9;

  }
  .desc {
    color: #111111;
    font-size: 28px;
    font-family: 'LGSmartUIR';
    line-height: 42px;
  }
}
@__DEV__: true;