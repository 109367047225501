@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

@wrapWidth: 504px;

.reportSect {
  padding: 40px 40px 0;
  height: 550px;

  .report {
    display: flex;
    justify-content: space-between;
    color: #212120;

    .tit {
      font-family: 'LGSmartUIB';
      font-size: 24px;
      margin-bottom: 12px;
    }

    .wrap {
      position: relative;
      display: inline-block;
      width: ~"calc(@{wrapWidth})";
      // height: 462px;

    }
  }
}

.footTypeWrap {
  margin-bottom: 16px;

  .footTypeComp {
    position: relative;
    height: 140px;
    padding: 30px;
    border: 1px solid #DBD7D4;
    border-radius: 16px;

    .compWrap {
      position: relative;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &::before {
        content: "";
        position: absolute;
        right: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 100%;
        background-color: #b2b0ae;
      }

      >div>div:last-child>div>img {
        width: 28px;
      }
    }
  }
}
.walkingPathComp {
  position: relative;
  height: 200px;
  border: 1px solid #DBD7D4;
  border-radius: 16px;

  .compWrap {
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
      width: 100%;
      border: 1px solid #DBD7D4;
      border-style: dashed;
      border-top-style: none;
    }

    .line {
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      border: 1px solid #DBD7D4;
      border-left-style: none;

      &::before {
        content: "";
        position: absolute;
        right: ~"calc(@{wrapWidth}/4)";
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        border: 1px solid #DBD7D4;
        border-style: dashed;
        border-left-style: none;
      }

      &::after {
        content: "";
        position: absolute;
        left: ~"calc(@{wrapWidth}/4)";
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        border: 1px solid #DBD7D4;
        border-style: dashed;
        border-left-style: none;
      }
    }
  }

}

.bodyBalanceTabWrap {

  .bodyBalanceTabComp {
    position: relative;
    display: inline-block;
    width: 448px;
    height: 406px;
    border-radius: 16px;
    border: 1px solid #DBD7D4;
    padding: 28px;

    .coordinates {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% - 56px);
      height: 100%;
      box-sizing: border-box;
      padding-top: 3px;
    }
  }
}

.coPComp {
  position: relative;
  display: inline-block;
  width: 444px;
  height: 462px;
  padding: 0 30px;
  border-radius: 16px;
  border: 1px solid #DBD7D4;

  .compWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;

    &::before {
      content: "";
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(50%, -50%);
      width: 87%;
      height: 1px;
      background-color: #b2b0ae;
    }
  }
}

.noDataSect {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'LGSmartUISB';
  font-size: 21px;
  color: #494847;
  text-align: center;

  >img {
    width: 24px;
    display: block;
    margin: 0 auto 10PX;
  }
}

// ces
.bodyBalanceTabWrap {
  &.ces {
    margin: 0 auto;
    width: fit-content;

    .bodyBalanceTabComp {
      width: 414px;
      height: 410px;
      padding: 50px;

      .coordinates {
        height: calc(100% - 50px);
      }
    }
  }
}

.responseBox {
  &.ces {
    position: absolute;
    top: 40px;
    width: calc(100% - 80px);

    >div {
      justify-content: space-between;
    }
  }
}

.footTypeWrap {
  &.ces {
    position: absolute;
    width: calc(100% - 80px);
    top: 361px;
    margin-bottom: 0;

    .compWrap {
      display: flex;
      justify-content: space-between;
      width: calc(100% - 37px);
      margin: 0 auto;

      >div {
        width: 455px !important;

        >div:first-child {
          font-size: 30px !important;
	  margin-bottom: 22px !important;
        }
        >div:last-child {
          font-size: 21px !important;
          line-height: 1.7;
        }
      }

      &::before {
        display: none;
      }
    }
  }
}
@__DEV__: true;