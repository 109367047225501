@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

@ITEM_WIDTH: 427px;
@ITEM_GAP: 25px;
@BODY_PADDING_TOP: 40px;
@CONTAINER_PADDING: 75px;

.panel{
  font-family: "LGSmartUIR";
  background: @BG_COLOR_02;
  .tBody {
    padding: @BODY_PADDING_TOP 0 0 0;
    position: relative;
    color: @COLOR_GRAY08;
    .scroll {
      padding-right: 0;
      width: 100%;
      height: calc(100% - @BODY_PADDING_TOP);
    }
    .container{
      padding-bottom: @CONTAINER_PADDING;
      .header {
        display: flex;
        position: relative;
        margin-bottom: 52px;
        min-height: 222px;
        column-gap: 40px;
        padding-left: @CONTAINER_PADDING;
        .infoLayer{
          .title {
            font-size: 60px;
            font-family: 'LGSmartUIB';
            line-height: 1;
          }
          .features {
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            margin-top: 6px;
            align-items: center;
            > span {
              // hr
              display: inline-block;
              width: 4px;
              height: 23px;
              background: @PRIMARY_COLOR_BROWN02;
            }
            .feature {
              text-align: center;
              color: @PRIMARY_COLOR_BROWN02;
              font-family: 'LGSmartUISB';
              font-size: 27px;
              > span {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: @PRIMARY_COLOR_BROWN02;
                margin-right: 10px;
                vertical-align: middle;
              }
            }
          }
          .introduction {
            width: 1229px;
            margin-top: 20px;
            margin-bottom: 32px;
            line-height: 1.4;
            color: @COLOR_GRAY06;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          .button {
            width: auto;
            > div:nth-child(2) {
              width: auto;
            }
            border-radius: 12px;
            margin-right: 16px;
          }
        }
        .logo {
          width: 395px;
          height: 222px;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          border-radius: 12px;
        }
      }
      // .hr {
      //   width: 1634px;
      //   height: 1px;
      //   background-color: @COLOR_GRAY04;
      //   margin-top: 70px;
      // }
      // .programTitle {
      //   font-size: 32px;
      //   font-family: 'LGSmartUISB';
      //   margin-top: 40px;
      // }
      // .programIntroduction {
      //   line-height: 1.4;
      //   margin-top: 16px;
      //   text-overflow: ellipsis;
      //   display: -webkit-box;
      //   -webkit-line-clamp: 2;
      //   -webkit-box-orient: vertical;
      //   overflow: hidden;
      // }
      // .scroll {
      //   // width: 1664px;
      // }
      // .images {
      //   width: 1739px;
      // }
      // .images {
      //   display: flex;
      //   margin-top: 28px;
      //   flex-direction: row;
      //   column-gap: @ITEM_GAP;
      //   .imageCard {
      //     display: flex;
      //     flex-direction: column;
      //     row-gap: 25px;
      //     width: @ITEM_WIDTH;
      //     align-items: center;
      //     &:focus {
      //       .image {
      //         transform: scale(1.08);
      //         box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
      //         border-radius: 12px;
      //       }
      //     }
      //     .image {
      //       width: 395px;
      //       height: 222px;
      //       object-fit: cover;
      //       border-radius: 12px;
      //       transition: transform 0.3s;
      //       transform: scale(1);
      //       transform-origin: center center;
      //     }
      //     .imgTitle {
      //       font-size: 28px;
      //       font-family: 'LGSmartUIR';
      //       color: @COLOR_GRAY07;
      //       width: 100%;
      //       > div {
      //         //marquee
      //         text-align: center;
      //       }
      //       // text-overflow: ellipsis;
      //       // display: -webkit-box;
      //       // -webkit-line-clamp: 1;
      //       // -webkit-box-orient: vertical;
      //       // overflow: hidden;
      //       // word-break: break-all;
      //     }
      //   }
      // }

      .register {
        color: @COLOR_GRAY08;
        font-family: "LGSmartUIB";
        font-size: 36px;
        line-height: normal;
        display: flex;
        column-gap: 16px;
        margin-bottom: 40px;
        padding-left: @CONTAINER_PADDING;
        > span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          // vertical-align: middle;
          align-self: center;
          background-color: @COLOR_GRAY04;
        }
        > div:nth-child(3) {
          color: @PRIMARY_COLOR_BROWN03;
          font-family: "LGSmartUISB";
          font-size: 30px;
          align-self: center;
        }
      }

      .programLists {
        margin-bottom: 60px;
        color: @COLOR_GRAY08;
        .programTitle {
          font-size: 32px;
          font-family: 'LGSmartUISB';
          font-size: 36px;
          line-height: normal;
          display: flex;
          justify-content: space-between;
          margin: 40px 75px 30px 0;
          align-items: center;
          padding-left: @CONTAINER_PADDING;
          > div {
            span {
              display: inline-block;
              padding: 4px 13px;
              border-radius: 8px;
              background: @PRIMARY_COLOR_GREEN02;
              line-height: 40px;
              font-family: 'LGSmartUIB';
              color: @COLOR_GRAY01;
              font-size: 27px;
            }
            .title {
              display: inline-block;
              margin-bottom: 15px;
            }
            .programIntroduction {
              font-family: 'LGSmartUIR';
              color: #313131;
              font-size: 27px;
              line-height: 35px;
              width: 1232px;
            }
          }
        }

        .programDetail {
          display: flex;
          column-gap: 12px;
          margin-bottom: 50px;
          padding-left: @CONTAINER_PADDING;
          .icon {
            width: 36px;
            // height: 36px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &.update {
              background-image: url(../../../assets/HomecareProgram/ic_bodycheck_upload.svg);
            }
            &.features {
              background-image: url(../../../assets/HomecareProgram/ic_bodycheck_workout.svg);
            }
            &.age {
              background-image: url(../../../assets/HomecareProgram/ic_bodycheck_senior.svg);
            }
            &.playTime {
              background-image: url(../../../assets/HomecareProgram/ic_bodycheck_time.svg);
            }
            &.level {
              background-image: url(../../../assets/HomecareProgram/ic_bodycheck_beginner.svg);
            }
            &.strength {
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background-color: @COLOR_GRAY05;
              align-self: center;
              margin-left: -12px;
            }
          }
          .iconTitle {
            margin-right: 12px;
            font-family: 'LGSmartUISB';
            color: @COLOR_GRAY08;
            font-size: 27px;
            line-height: 42px;
          }
        }

        .images {
          // width: 1762px;
          margin-bottom: 60px;
          width: calc(100% + @CONTAINER_PADDING);
          transform: translateX(-75px);
        }
        .hr {
          width: 1634px;
          height: 1px;
          background-color: @COLOR_GRAY04;
          margin-left: @CONTAINER_PADDING;
        }
      }
    }
  }
}
@__DEV__: true;