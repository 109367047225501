@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.panel {
  font-family: 'LGSmartUIR';

  .tBody {
    height: auto;
    padding: 60px 65px 75px 75px;
  }

}

.topLayer {
  display: flex;
  column-gap: 80px;
  align-items: center;
  margin-bottom: 60px;
  .imgBox {
    width: 559px;
    height: 316px;
    border-radius: 16px;
    background-color: #D9D9D9;
  }
  .textBox {
    .title{
      color: @COLOR_GRAY01;
      font-size: 36px;
      font-family: 'LGSmartUIB';
      margin-bottom: 12px;
      line-height: normal;
    }
    .subTitle {
      color: @COLOR_GRAY04;
      font-size: 27px;
      line-height: 35px;
      margin-bottom: 40px;
      line-height: normal;
    }
  }
}

.contentsLayer {
  display: flex;
  column-gap: 32px;
  .leftBox {
    width: 1169px;
    height: 399px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.10);
    background-color: rgba(255, 255, 255, 0.03);
    padding: 40px 0 0 40px;
    .totalScoreText {
      color: @COLOR_GRAY03;
      font-family: 'LGSmartUISB';
      font-size: 36px;
      line-height: normal;
      margin-right: 30px;
      vertical-align: middle;
    }
    .totalScoreNumber {
      color: #B59D85;
      font-family: 'LGSmartUIB';
      font-size: 45px;
      line-height: normal;
      vertical-align: middle;
    }
    .hr {
      width: 1089px;
      height: 2px;
      background-color: @COLOR_GRAY07;
      margin-top: 28px;
      margin-bottom: 40px;
    }
    .perfect {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 20px;
      .icon {
        width: 150px;
        height: 150px;
        background: url(../../../../assets/icon/ic_report_alert_check.svg);
      }
      .text {
        color: @COLOR_GRAY01;
        text-align: center;
        font-family: 'LGSmartUIB';
        font-size: 36px;
        line-height: normal;
      }
      .desc {
        color: @COLOR_GRAY01;
        font-size: 27px;
        line-height: normal;
      }
    }
    .contentScroller{
      height: 274px;
      .minusList {
        display: flex;
        column-gap: 30px;
        margin-bottom: 40px;
        .imgBox {
          width: 294px;
          height: 164px;
          border-radius: 16px;
        }
        .textBox {
          .timeScore {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            .timeIcon {
              width: 28px;
              height: 28px;
              background: url(../../../../assets/icon/ic_workoutmanager_clock.svg) no-repeat center;
              margin-right: 6px;
            }
            .time {
              margin-right: 24px;
              color: @COLOR_GRAY03;
              font-family: 'LGSmartUISB';
              font-size: 27px;
            }
            .minusScore {
              padding: 4px 16px;
              border-radius: 4px;
              background: @COLOR_GRAY07;
              line-height: 36px;
              text-align: center;
              font-family: "LGSmartUIB";
              color: #F44B4A;
              font-size: 24px;
              margin: 0 16px auto 0;
            }
            .feedback {
              color: @COLOR_GRAY04;
              font-size: 27px;
            }
          }
        }
      }
    }
  }

  .rightBox {
    width: 579px;
    height: 399px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.10);
    background-color: rgba(255, 255, 255, 0.03);
    padding: 40px 0 0 40px;
    .topRank {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .crownIcon {
        width: 46px;
        height: 46px;
        background: url(../../../../assets/icon/ic_workoutmanager_ranking.svg);
        margin-right: 12px;
      }
      .crownText {
        color: @COLOR_GRAY01;
        font-family: "LGSmartUIB";
        font-size: 36px;
      }
    }
    .rankingScroller{
      height: 311px;
      .ranking {
        display: flex;
        align-items: center;
        width: 486px;
        height: 122px;
        border-radius: 20px;
        padding-left: 10px;
        margin-bottom: 16px;
        .rankIndex {
          color: @COLOR_GRAY01;
          text-align: center;
          font-family: "LGSmartUISB";
          font-size: 36px;
          line-height: normal;
          margin-right: 10px;
          min-width: 60px;
          &.top{
            color: @PRIMARY_COLOR_GREEN03;
          }
        }
        .profileImg {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin-right: 26px;
          background-color: #fff;
        }
        .rankInfo {
          .score {
            color: @PRIMARY_COLOR_BROWN01;
            font-family: "LGSmartUIB";
            font-size: 36px;
            line-height: normal;
            margin-bottom: 4px;
          }
          .profileNick {
            width: 236px;
            color: @COLOR_GRAY04;
            font-size: 27px;
            line-height: normal;
          }
        }
        &.myRank {
          background: rgba(255, 255, 255, 0.10);
        }
      }
    }
  }
}
@__DEV__: true;