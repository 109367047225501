@FONT_SIZE: 50px;
@FONT_COLOR: black;
@FONT_FAMILY: 'LGSmartUIR';
@FONT_WEIGHT: bold;

.container {
	display: flex;
	align-items: center;
}

.timePickerWrapper {
	display: flex;
	align-items: center;
	height: 370px;
	.timePicker {
		width: 200px;
		margin: 0;
		height: 273px;
		span {
			transform: scale(1) !important;
		}
	}
	.text {
		width: 200px;
		display: flex;
    align-items: center;
    justify-content: center;
		margin-left: auto;
		margin-right: auto;
		font-family: @FONT_FAMILY;
		color: @FONT_COLOR;
		font-size: @FONT_SIZE;
		font-weight: @FONT_WEIGHT;
	}
	div[role="spinbutton"]:first-of-type {
		height: 153px;  /* picker height - 60*2 */
		line-height: 153px;
		font-family: @FONT_FAMILY;
    color: @FONT_COLOR;
    font-size: @FONT_SIZE;
		font-weight: @FONT_WEIGHT;
	}
	:focus {
		div[role="spinbutton"]:first-of-type{
			color: #ffffff;
		}
	}
}
@__DEV__: true;