@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@DIEMETER: 14px;
@GAP: 4px;

.stepper {
  --length: 0;
  --count: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .fixedBar {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bar {
      background-position: center;
    }
  }

  .stepBar {
    position: absolute;
    background-color: @PRIMARY_COLOR_GREEN03;
    transition: 0.3s ease;
  }

  .step {
    position: relative;

    .circle {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      background-color: @COLOR_GRAY01;
      width: @DIEMETER;
      height: @DIEMETER;
      border-radius: 50%;
      transition: 0.3s ease;
    }
    // &:last-child .circle {
    //   width: 30px;
    //   height: 30px;
    //   border-radius: 50%;
    //   background-position: center;
    //   background-repeat: no-repeat;
    //   background-size: contain;
    //   background-image: url("../../../assets/TStepper/ic_progressbar_finish.svg");
    // }

    .text {
      position: absolute;
      color: @COLOR_GRAY01;
      font-size: 24px;
      font-family: 'LGSmartUISB';
    }

    &.active {
      .circle {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        .font(@fontFamily: "LGSmartUIB", @fontSize: 22px, @color: @COLOR_GRAY08, @fontWeight: 700);
        text-align: center;
        line-height: 30px;
        background-color: @PRIMARY_COLOR_GREEN03;
        // background-image: url("../../../assets/TStepper/ic_progressbar_complete.svg");
      }
    }
  }
}

.horizontal .stepper {
  top: 67px; // default size
  width: 1454px;
  height: 4px;
  margin: 0 auto;

  .fixedBar {
    top: 50%;
    left: ~"calc(@{DIEMETER} / 2 + @{GAP})";
    width: ~"calc(100% - (@{DIEMETER} / 2 + @{GAP}) * 2)";
    transform: translateY(-50%);
    height: inherit;

    .bar {
      background-image: url("../../../assets/TStepper/dashed_border.svg");
      height: inherit;
      width: ~"calc(((100% - ((@{DIEMETER} + @{GAP} * 2) * (var(--length) - 2)))) / (var(--length) - 1))";
    }
  }

  .stepBar {
    top: 50%;
    transform: translateY(-50%);
    height: inherit;
    width: ~"calc(100% / (var(--length) - 1) * var(--number))";

    &.percent {
      width: var(--percent);
    }
  }

  .step {
    .text {
      top: 21px;
      left: 50%;
      transform: translateX(-50%);
      width: 120px;
      height: 84px;
      line-height: 28px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.vertical .stepper {
  flex-direction: column;
  left: 65px;
  width: 4px;
  height: 100%;

  .fixedBar {
    flex-direction: column;
    top: ~"calc(@{DIEMETER} / 2 + @{GAP})";
    left: 50%;
    transform: translateX(-50%);
    width: inherit;
    height: ~"calc(100% - (@{DIEMETER} / 2 + @{GAP}) * 2)";

    .bar {
      background-image: url("../../../assets/TStepper/dashed_border_height.svg");
      width: inherit;
      height: ~"calc(((100% - ((@{DIEMETER} + @{GAP} * 2) * (var(--length) - 2)))) / (var(--length) - 1))";
    }
  }

  .stepBar {
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: ~"calc(100% / (var(--length) - 1) * var(--number))";;

    &.percent {
      height: var(--percent);
    }
  }

  .step {
    display: flex;
    align-items: center;

    .text {
      top: 50%;
      left: ~"calc(@{DIEMETER} + 10px)";
      transform: translateY(-50%);
      white-space: nowrap;
    }
  }
}

.bg {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 180px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  &.vertical {
    width: 262px;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.20) 59%, rgba(0, 0, 0, 0) 100%);
    padding: 54px 0 52px;
    box-sizing: border-box;
  }
}

.reverse {
  &.horizontal {
    .stepBar {
      right: 0;
    }
  }

  &.vertical {
    .stepBar {
      bottom: 0;
    }
  }
}

.startFinish {
  .step {
    &.active {
      .circle.reward {
        width: 24px;
        height: 24px;
        background-image: url("../../../assets/TStepper/ic_progressbar_reward.svg");
      }
    }

    &.start {
      .circle {
        background-image: url("../../../assets/TStepper/ic_progressbar_complete.svg");
      }
    }

    &.finish {
      .circle {
        width: 30px;
        height: 30px;
        background-image: url("../../../assets/TStepper/ic_progressbar_finish.svg");
      }

      &.active {
        .circle {
          background-image: url("../../../assets/TStepper/ic_progressbar_finish_success.svg");
        }
      }
    }
  }
}
@__DEV__: true;