@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.timePicker {
	> div {
		margin: 0 !important;
	}
	> div:nth-child(1) > div {
		// focus 영역
		width: 216px;
		height: 326px;
		> div > div:nth-child(2) > div {
			// 화살표
			color: @COLOR_GRAY08;
			font-size: 60px ;
			font-family: 'LGSmartUISB' !important;
		}
	}
	span {
		// :
		color: @COLOR_GRAY06 !important;
		font-family: "LGSmartUIR" !important;
		font-size: 60px !important;
	}
	> div:nth-child(1) > div {
		&::before {
			background-color: @COLOR_GRAY01 !important;
			opacity: 1 !important;
			box-shadow: unset !important;
		}
		&:focus {
			&::before {
				background-color: @PRIMARY_COLOR_BROWN03 !important;
			}
			span {
				color: @COLOR_GRAY01 !important;
			}
			> div > div:nth-child(2) > div {
				color: @COLOR_GRAY01 !important;
			}
		}
	}
	&.disabled {
		> div:nth-child(1) > div {
			opacity: 0.3 !important;
		}
	}
}
@__DEV__: true;