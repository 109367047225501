@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.title {
  margin-bottom: 8px;
}

.pointContainer {
  .size(@w: 100%, @h: auto);
  margin-bottom: 20px;
}

.exerciseContainer {
  .size(@w: 100%, @h: auto);
  .mediaItem {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  strong {
    font-family: "LGSmartUISB";
  }
}

.bpmContainer {
  margin-top: 20px;
  .size(@w: 100%, @h: auto);
  .bpmFeedback {
    .font(@fontFamily: "LGSmartUISB", @fontSize: 14px, @color: @COLOR_GRAY08, @fontWeight: 600);
    line-height: 18px;
  }
}
.guideText {
  .font(@fontFamily: "LGSmartUISB", @fontSize: 14px, @color: @COLOR_GRAY05, @fontWeight: 600);
  line-height: 18px;
  margin-bottom: 8px;
  width: 100%;
}

@__DEV__: true;