@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@ITEM_PADDING: 32px;
.item{
  width: ~"calc(100% - @{ITEM_PADDING} * 2)";
  position: relative;
  display: flex;
  justify-content: space-around;
  height: 130px;
  align-items: center;
  padding-left: @ITEM_PADDING;
  padding-right: @ITEM_PADDING;
  grid-gap: 20px;
  font-family: "LGSmartUIR";
  .checkbox{
    pointer-events: none;
  }
  >div{
    flex-shrink: 0;
  }
  .title{
    max-width: fit-content;
    flex-grow: 1;
    flex-shrink: 1;
    font-size: 32px;
    color: @COLOR_GRAY08;
    overflow: hidden;
  }
  .textContainer{
    display: flex;
    flex-direction: column;
    .subText{
    font-size: 28px;
    color: @COLOR_GRAY05;
    }
  }
  &.inline{
    justify-content: flex-start;
    width: 905px;
    .title{
      flex-grow: 0;
    }
  }
  &.block {
    .checkbox{
      width: 36px;
      height: 36px;
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url("../../../assets/icon/checkbox_nor.png");
      &.selected {
        // background-image: url("../../../assets/icon/checkbox_selected.svg");
        background-image: url("../../../assets/icon/checked_icon.svg");
        .title{
          color: @COLOR_GRAY01;
        }
      }
    }
    &:focus{
      .checkbox {
        // background-image: url("../../../assets/icon/checkbox_focus.png");
        background-image: url("../../../assets/icon/checkbox_nor.png");
        &.selected{
          background: url(../../../assets/icon/checked_icon.svg);
        }
      }
      .title{
        color: @COLOR_GRAY01;
      }
    }
  }

  &.isDark {
    color: @COLOR_GRAY04;
    .title{
      color: @COLOR_GRAY04;
    }
    .checkbox {
      background-image: url(../../../assets/icon/ic_check_dark_nor.svg);
    }
    &.focus {
      box-shadow: 0 0 0 4px @COLOR_GRAY01 inset;
      border-radius: 12px;
      .checkbox {
        background-image: url(../../../assets/icon/ic_check_dark_nor.svg);
      }
      .title{
        color: @COLOR_GRAY01;
        font-family: 'LGSmartUIB';
      }
    }
    &.selected {
      border-radius: 12px;
      background-color: rgba(255, 255, 255, 0.08);
      .checkbox {
        background-image: url(../../../assets/icon/ic_check_dark_sel.svg);
      }
      .title{
        color: @COLOR_GRAY01;
      }
    }
  }
}

&.subTextVisible {
  flex-direction: column;
}
@__DEV__: true;