@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tIcon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-position: center;

  &.small {
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
  }
}
.dayActive {
  .imgElement(@backgroundW: 32px, @backgroundH: 32px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_day_active.svg");
}
.dayNor {
  .imgElement(@backgroundW: 22px, @backgroundH: 22px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_day_nor.svg");
}

.calorieActive {
  .imgElement(@backgroundW: 32px, @backgroundH: 32px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_calorie_active.svg");
  &.isDark {
    background-image: url("../../../assets/icon/ic_calories_dark.svg");
  }
}
.calorieNor {
  .imgElement(@backgroundW: 22px, @backgroundH: 22px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_calorie_nor.svg");
  &.isDark {
    background-image: url("../../../assets/icon/ic_calories_dark.svg");
  }
}

.timeActive {
  .imgElement(@backgroundW: 32px, @backgroundH: 32px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_time_active.svg");
  &.isDark {
    background-image: url("../../../assets/icon/ic_time_dark.svg");
  }
}
.timeNor {
  .imgElement(@backgroundW: 22px, @backgroundH: 22px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_time_nor.svg");
  &.isDark {
    background-image: url("../../../assets/icon/ic_time_dark.svg");
  }
}

.stepActive {
  .imgElement(@backgroundW: 32px, @backgroundH: 32px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_step_nor.svg");
}
.stepNor {
  .imgElement(@backgroundW: 22px, @backgroundH: 22px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_step_nor.svg");
}

.kmActive {
  .imgElement(@backgroundW: 32px, @backgroundH: 32px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_time_active.svg");
  &.isDark {
    background-image: url("../../../assets/icon/ic_time_dark.svg");
  }
}
.kmNor {
  .imgElement(@backgroundW: 22px, @backgroundH: 22px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/ic_time_nor.svg");
  &.isDark {
    background-image: url("../../../assets/icon/ic_time_dark.svg");
  }
}

@__DEV__: true;