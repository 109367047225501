@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.header {
  .size(@w: 100%, @h: 54px);
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  background-color: #fff;
  font-size: 20px;
  color: #212120;
  font-family: 'LGSmartUIB'; //font-weight: 700;
  margin-bottom: 20px;
}
@__DEV__: true;