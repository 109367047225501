@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.tBody {
  height: 874px;
  padding-top: 0;
  padding-bottom: 67px;
}

.summaryReportSect {
  font-family: 'LGSmartUIR';
  color: @COLOR_GRAY07;
  position: relative;
  background-color: #fff;
  height: 710px;
  border-radius: 30px;
  padding: 80px 60px;
}

.medicalCenter {
  position: absolute;
  width: 181px;
  height: 38px;
  right: 92px;
  bottom: 18px;
  background-image: url('../../../../../assets/images/medicalCenterMark.svg');
}
@__DEV__: true;