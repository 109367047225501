@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

.tPopup {
  .scroller {
    height: 500px;
  }
  .scrollerInnerContainer {
    padding: 0 40px;
    .title {
      .size(@w: 100%, @h: auto);
      font-size: 39px;
      line-height: 46px;
      color: #363533;
      font-family: 'LGSmartUIB'; //font-weight: 700;
      text-align: center;
      margin-bottom: 30px;
    }

    .text {
      .size(@w: 100%, @h: auto);
      font-size: 27px;
      line-height: 38px;
      color: #363533;
      font-family: 'LGSmartUIR'; //font-weight: 400;
      text-align: justify;
      >span {
        font-family: 'LGSmartUIB'; //font-weight: 700;
      }
    }
    &.isDark {
      .title, .text {
        color: @COLOR_GRAY04;
      }
    }
  }

}
@__DEV__: true;