@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.tBody {
  height: 874px;
  padding-top: 0;
  padding-bottom: 67px;
}

.summaryReportSect {
  font-family: 'LGSmartUIR';
  color: @COLOR_GRAY07;
  position: relative;
  background-color: #fff;
  height: 710px;
  border-radius: 30px;
  padding: 80px 60px;
}

.notification {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  left: 75px;
  bottom: 13px;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 400);
  line-height: 25px;
  .guideText::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
  }
}
@__DEV__: true;