@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@TOOLTIP_HEIGHT: 58px;
@TOOLTIP_GAP: 4px;
@ARROW_HEIGHT: 8px;

.default-tooltip-size() {
  .size(@w: max-content, @h: @TOOLTIP_HEIGHT);
  line-height: @TOOLTIP_HEIGHT;
  border-radius: 68px;
  padding: 0 24px;
  font-size: 26px;
}

.toolTip {
  .default-tooltip-size();
  display: none;
  position: absolute;
  text-align: center;
  font-family: 'LGSmartUISB';
  color: #FEFEFE;
  background-color: @PRIMARY_COLOR_GREEN02;
  z-index: 1;
  .arrow {
    position: absolute;
    width: 17px;
    height: @ARROW_HEIGHT;
    background-image: url('../../../assets/Ttooltip/ic_tooltip_green.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.bottom {
    left: 50%;
    transform: translateX(-50%);
    top: ~"calc(100% + @{ARROW_HEIGHT} + @{TOOLTIP_GAP})";
    .arrow {
      left: 50%;
      transform: translateX(-50%);
      top: -@ARROW_HEIGHT;
    }
  }

  &.top {
    left: 50%;
    transform: translateX(-50%);
    bottom: ~"calc(100% + @{ARROW_HEIGHT} + @{TOOLTIP_GAP})";
    .arrow {
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      bottom: -@ARROW_HEIGHT;
    }
  }

  &.right {
    top: 50%;
    transform: translateY(-50%);
    left: ~"calc(100% + @{ARROW_HEIGHT} + @{TOOLTIP_GAP})";
    .arrow {
      top: 50%;
      transform: translateY(-50%) rotate(270deg);
      left: ~"calc(-@{ARROW_HEIGHT} - @{TOOLTIP_GAP} + 1px)";
    }
  }

  &.left {
    top: 50%;
    transform: translateY(-50%);
    right: ~"calc(100% + @{ARROW_HEIGHT} + @{TOOLTIP_GAP})";
    .arrow {
      top: 50%;
      transform: translateY(-50%) rotate(90deg);
      right: ~"calc(-@{ARROW_HEIGHT} - @{TOOLTIP_GAP} + 1px)";
    }
  }
  &.isDark {
    background-color: @PRIMARY_COLOR_BROWN01;
    color: @COLOR_GRAY08;
    .arrow {
      background-image: url('../../../assets/Theader/ic_tooltip_dark.svg');
      top: -7px;
    }
  }

  &.square {
    border-radius: 8px;
    background-color: @PRIMARY_COLOR_BROWN02;
    .font(@fontFamily:"LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY01, @fontWeight: 600);
    padding: 12px 24px;
    height: auto;
    line-height: 1.2;
    .arrow {
      background-image: url("../../../assets/Theader/ic_tooltip_brown.svg");
    }
  }
}

.green{
  background-color: @PRIMARY_COLOR_GREEN02;
  color: @COLOR_GRAY01;
  .arrow {
    background-image: url('../../../assets/icon/tooltip.png');
    transform: rotate(0deg) !important;
  }
}

.mineralGreen {
  background-color: @PRIMARY_COLOR_GREEN02;
  color: @COLOR_GRAY01;
  .arrow {
    width: @ARROW_HEIGHT;
    height: 17px;
    background-image: url("../../../assets/Theader/ic_gnb_mr_foc_tooltip.svg");
  }

  &.bottom {
    .arrow {
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      top: ~"calc(-@{ARROW_HEIGHT} - 2px)";
    }
  }

  &.top {
    .arrow {
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      bottom: ~"calc(-@{ARROW_HEIGHT} - 2px)";
    }
  }

  &.right {
    .arrow {
      top: 50%;
      transform: translateY(-50%);
      left: ~"calc(-@{ARROW_HEIGHT} + @{TOOLTIP_GAP} - 2px)";
    }
  }

  &.left {
    .arrow {
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
      right: ~"calc(-@{ARROW_HEIGHT} + @{TOOLTIP_GAP} - 2px)";
    }
  }

  &.isDark {
    background-color: @PRIMARY_COLOR_BROWN01;
    color: @COLOR_GRAY08;
    .arrow {
      background-image: url('../../../assets/Theader/ic_gnb_mr_foc_tooltip_dark.svg');
    }
  }
}
@__DEV__: true;