@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";


.description {
  height: 37px;
  line-height: 37px;
  font-size: 32px;
  color: #FEFEFE;
  font-family: 'LGSmartUISB';
  position: absolute;
  top: 319px;
  left: 50%;
  transform: translateX(-50%);
}


.iconButtonArea {
  display: flex;
  gap: 106px;
  position: absolute;
  top: 396px;
  left: 50%;
  transform: translateX(-50%);

}

.iconButton{
  display: flex;
  flex-direction: column;
  align-items: center;

  .image{
    background: rgba(255, 255, 255, 0.03);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.1) inset;
    border-radius: 48px;
    margin-bottom: 20px;
    .size(@w: 306px, @h: 306px);
    background-position: center;
    background-repeat: no-repeat;
  }
  .title {
    height: 37px;
    line-height: 37px;
    font-size: 30px;
    color: #B2B0AE;
    font-family: 'LGSmartUISB';
  }

  &:hover, &:focus {
    transition: all 200ms ease;
    transform: scale(1.1);
  }

  &.selected{
    .image {
      box-shadow: 0 0 0 4px #FEFEFE inset;
    }
    .title {
      color: #FEFEFE;
      font-family: 'LGSmartUIB';

    }

    
  }
}

.buttonArea {
  display: flex;
  gap: 24px;
  position: absolute;
  top: 819px;
  left: 50%;
  transform: translateX(-50%);
}
@__DEV__: true;