@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";
@CONTENT_PADDING:  75px;

.scroller{
  height: inherit;
  width: 1664px;
  color: @COLOR_GRAY08;
  padding: 0;
  border-radius: 0 0 12px 0 ;
  > div {
    padding-top: 50px;
  }
  .content {
    height: 100%;
    padding-bottom: @CONTENT_PADDING;
  }
}

.topLayer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1514px;
  padding-left: @CONTENT_PADDING;
  .partScroller{
    width: 1514px;
    margin-bottom: 49px;
    .partLayer {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      //button
      > div {
        min-width: unset;
        max-width: unset;
        font-size: 21px;
        &:hover,
        &:focus {
          background-color: @PRIMARY_COLOR_BROWN03;
          color: @COLOR_GRAY01;
          transform: scale(1);
        }
        &.pressed {
          opacity: 1;
        }
        &.selected {
          transform: scale(1);
        }
      }
    }

  }
  .mediaItem > img {
    object-fit: contain;
  }
}


.barContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 46px 40px;
  .info {
    display: flex;
    align-items: center;

    .title {
      width: 204px;
      padding: 25px 20px;
      box-sizing: border-box;
      font-family: 'LGSmartUIB';
      font-size: 21px;
      border-radius: 12px;
      text-align: center;
      border: 2px solid @COLOR_GRAY03;
      color: @PRIMARY_COLOR_BROWN03;
      margin-right: 20px;
      line-height: normal;
      .lastDate {
        color: @COLOR_GRAY05;
        font-size: 21px;
        font-family: 'LGSmartUIL';
      }
    }
    .bars {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 54px; // bar+gap+text
      &.notScore {
        height: unset;
      }
    }
  }
}

.mediaLayer {
  margin-top: 62px;
  .levelTitle {
    display: flex;
    column-gap: 12px;
    color: @COLOR_GRAY08;
    font-family: 'LGSmartUIB';
    font-size: 36px;
    line-height: normal;
    padding-left: @CONTENT_PADDING;
    .level {
      padding: 4px 13px;
      border-radius: 8px;
      background: @PRIMARY_COLOR_BROWN01;
      color: @COLOR_GRAY01;
      font-size: 30px;
      // height: 43px;
    }
  }
}

@__DEV__: true;