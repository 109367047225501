@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

@ITEM_WIDTH: 264px;
@ITEM_HEIGHT: 137px;
@ITEM_GAP: 12px;
@ITEM_TOP_BOTTOM_PADDING: 45px;

.scroller {
    position: absolute;
    width: 100%;
    height: ~"calc(@{ITEM_HEIGHT} + @{ITEM_TOP_BOTTOM_PADDING} * 2)";
}
.container {
    width: ~"calc(@{ITEM_WIDTH} * 9 + @{ITEM_GAP} * 7 )";
    gap: @ITEM_GAP;
    display: flex;
    padding: @ITEM_TOP_BOTTOM_PADDING 75px;
}
.item {
  padding: 0 30px;
  text-align: center;
}

@__DEV__: true;