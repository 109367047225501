@import "../../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../../style/utils.module.less";

.verticalBarItem {
  .size(@w: 20px, @h: 0);
  position: relative;
  border-radius: 10px 10px 0 0;
  transition: height 0.3s ease-in-out;

}
@__DEV__: true;