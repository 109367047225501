@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.activity {
  .size(@w: 536px, @h: auto);
  padding: 40px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(255, 255, 255, 0.10);
  border-radius: 20px;


  .dataContainer {
    .flex(@display: flex, @justifyCenter:flex-start , @alignCenter: center);
    .graphBox {
      position: relative;
      margin-right: 30px;
      .icon {
        .size(@w: 36px, @h: 36px);
        .position(@position: absolute, @top: 50%, @right: unset, @bottom: unset, @left: 50%);
        transform: translate(-50%, -50%);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .info {
      .flex(@display: flex, @justifyCenter: center, @alignCenter: normal, @direction: column);
      .title {
        display: flex;
        align-items: center;
        font-size: 27px;
        font-family: 'LGSmartUISB';
        height: 32px;
        line-height: 32px;
        color: #DBD7D4;
        margin-bottom: 7px;
        // &::before {
        //   content: "";
        //   display: block;
        //   .size(@w: 36px, @h: 36px);
        //   background-size: contain;
        //   background-position: center;
        //   background-repeat: no-repeat;
        //   background-image: url("../../../../../assets/FitnessManager/ic_workoutmanager_day.png");
        //   margin-right: 6px;
        // }
      }
      .data {
        height: 46px;
        line-height: 46px;
        font-size: 39px;
        color: #FEFEFE;
        font-family: 'LGSmartUIB';

      }
    }
  }

  .hr {
    height: 1px;
    background-color: #787776;
    margin: 32px 0 30px 0;
  }
  .tip {
    display: flex;
    align-items: center;
    .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @PRIMARY_COLOR_BROWN01, @fontWeight: 600);
    line-height: 28px;
    border-radius: 10px;
    margin-bottom: 12px;
    &::before {
      content: "";
      display: inline-block;
      .size(@w: 28px, @h: 28px);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../../../assets/icon/ic_loading_page_tip.svg");
      margin-right: 8px;
    }
  }
  .commentTitle {
    line-height: 35px;
    font-size: 30px;
    color: #F2EFED;
    font-family: 'LGSmartUIB';
    margin-bottom: 16px;
  }
  .comment {
    line-height: 38px;
    font-size: 27px;
    color: #DBD7D4;
    font-family: 'LGSmartUIR';
  }
}
@__DEV__: true;