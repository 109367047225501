

@check-size: 125px;
@check-border-width: 10px;
@checkmark-width: 60px;
@checkmark-height: 38px;
@checkmark-left: calc(@checkmark-width / 2);
@checkmark-top: 50%;
@checkmark-color: #B59D85;

.checkmarkWrapper {
   width: @check-size;
   height: @check-size;
   position: relative;
   margin-left: auto;
   margin-right: auto;

}

.checkmarkWrapper::after {
   content: "";
   position: absolute;
   inset: 0;
   border: @check-border-width solid transparent;
   width: 100%;
   height: 100%;
   border-radius: 50%;
   display: block;
   z-index: 0;
}

.checkmarkWrapper::before {
   content: "";
   position: absolute;
   inset: 0;
   border: @check-border-width solid transparent;
   // border-left-color: var(@checkmark-color);
   width: 100%;
   height: 100%;
   border-radius: 50%;
   display: block;
   z-index: 1;
   animation: circle linear forwards 0.35s;
}

.checkmark {
height: @checkmark-height;
width: @checkmark-width;
position: absolute;
opacity: 0;
left: @checkmark-left;
top: @checkmark-top;
display: block;
// border-left: var(@check-border-width) solid var(@checkmark-color);
// border-bottom: var(@check-border-width) solid var(@checkmark-color);
transform-origin: left top;
transform: rotate(-45deg);
animation: checkmark linear both 0.35s;
left: 30%;
    top: 53%;
}

.short {
   position: absolute;
   top: 0;
   left: 0;
   width: 10px;
   height: 100%;
   background-color: #B59D85;
   border-radius: 8px;
}

.long {
   position: absolute;
   bottom: 0;
   left: 0;
   width: 100%;
   height: 10px;
   background-color: #B59D85;
   border-radius: 8px;
}

@keyframes checkmark {
   0% {
       height: 0;
       width: 0;
       opacity: 0;
     }
     80% {
       height: 0;
       width: 0;
       opacity: 0;  
     }
     90% {
       height: @checkmark-height;
       width: 0;
       opacity: 1;
     }
     100% {
       height: @checkmark-height;
       width: @checkmark-width;
       opacity: 1;
     }
}

@keyframes circle {
   0% {
       border-color: transparent;
       border-left-color: @checkmark-color;
   }
   // 90% {
   //     transform: rotate(-360deg);
   //     border-color: transparent;
   //     border-left-color: var(@checkmark-color);
   // }
   100% {
       transform: rotate(-360deg);
       border-color: @checkmark-color;
   }
}
@__DEV__: true;