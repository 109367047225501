@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.container{
  font-family: "LGSmartUIR";
  // .listHeader{
  //   padding-bottom: 0px;
  // }
  .connectList{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 25px;
    border-bottom: 1px solid black;
  }
}


.searchDevices{
  font-size: 24px;
  margin: 0 !important;
  &.selected, &:focus{
    font-size: 24px;
  }
}
.searchFailed{
  margin: 130px 5px;
  font-family: "LGSmartUIR";
  font-size: 28px;
  text-align: center;
  color: #707070;
}


.setting:not(:last-child) {
  border-bottom: 1px solid #494847;
  .subText {
    display: flex;
    align-items: center;
    >span:nth-child(1) {
      color: #CFBF8C;
      order: 1;
    }
    &::before { //border
      content: "";
      display: block;
      width: 1px;
      height: 15px;
      margin: 0 10px;
      opacity: 0.1;
      background: #FFF;
      order: 2;

    }
    &::after { // battery icon
      content: "";
      display: block;
      background-image: url('../../../../assets/Theader/ic_battery_dark.svg');
      width: 24px;
      height: 24px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 4px;
      order: 3;
    }
    >span:nth-child(2) {
      order: 4;
    }
  }
}



@__DEV__: true;