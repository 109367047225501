@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

@ITEM_WIDTH: 427px;
@ITEM_HEIGHT: 240px;
@ITEM_GAP: 24px;
@ITEM_TOP_BOTTOM_PADDING: 28px;
@ITEM_LEFT_RIGHT_PADDING: 115px;

.mediaList {
    position: relative;
    .lButtonIcon { // left button
        .position(@position: absolute, @top: ~"calc(@{ITEM_TOP_BOTTOM_PADDING} + @{ITEM_HEIGHT} / 2)", @right: unset, @bottom: unset, @left: 0);
        transform: translateY(-50%);
        .size(@w: 44px, @h: 100px);
        background-image: url("../../../assets/landingPage/icon/ic_mrcu_left_nor.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        z-index: 1;
    }
    .rButtonIcon { // right button
        .position(@position: absolute, @top: ~"calc(@{ITEM_TOP_BOTTOM_PADDING} + @{ITEM_HEIGHT} / 2)", @right: 0, @bottom: unset, @left: unset);
        transform: translateY(-50%);
        .size(@w: 44px, @h: 100px);
        background-image: url("../../../assets/landingPage/icon/ic_mrcu_right_nor.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        pointer-events: none;
        z-index: 1;
    }

    .scroller {
        width: 100%;
    }
    
    .container {
        --itemCount: 5;
        width: ~"calc(@{ITEM_WIDTH} * var(--itemCount) + @{ITEM_GAP} * (var(--itemCount) - 1) )";
        display: flex;
        gap: @ITEM_GAP;
        padding: @ITEM_TOP_BOTTOM_PADDING @ITEM_LEFT_RIGHT_PADDING;
    }

    @SMALL_ITEM_WIDTH: 296px;
    @SMALL_ITEM_HEIGHT: 166px;
    @SMALL_ITEM_GAP: 20px;
    &.small{
        .lButtonIcon {
            .position(@position: absolute, @top: ~"calc(@{ITEM_TOP_BOTTOM_PADDING} + @{SMALL_ITEM_HEIGHT} / 2)", @right: unset, @bottom: unset, @left: 0);
        }
        .rButtonIcon {
            .position(@position: absolute, @top: ~"calc(@{ITEM_TOP_BOTTOM_PADDING} + @{SMALL_ITEM_HEIGHT} / 2)", @right: 0, @bottom: unset, @left: unset);
        }
        .container {
            width: ~"calc(@{SMALL_ITEM_WIDTH} * var(--itemCount) + @{SMALL_ITEM_GAP} * (var(--itemCount) - 1) )";
            gap: @SMALL_ITEM_GAP;
        }
    }
}




.swiperContainer {
    padding: @ITEM_TOP_BOTTOM_PADDING @ITEM_LEFT_RIGHT_PADDING;
}
@__DEV__: true;