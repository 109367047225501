@import "../../style/CommonStyle.module.less";

@MAIN_COLLAPSED_WIDTH: 106px;
@MAIN_EXTENDED_WIDTH: 500px;
@SUB_EXTENDED_WIDTH: 500px;

@SECOND_DEPTH_LAYOUT_PADDING_TOP: 106px;

.expandedRootContainer {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  // background: #0000005e;
  z-index: 1;
  &.hide {
    width: auto;
    z-index: 0;
  }
}

.tabWrap {
  background: linear-gradient(270deg, #86796d 0%, #96887c 100%);
  width: @MAIN_COLLAPSED_WIDTH;
  // height: 100%;
  height: calc((100% - 208px));
  display: flex;
  flex-direction: column;
  justify-content: center;
  // justify-content: space-evenly;
  padding-top: 104px;
  padding-bottom: 104px;

  z-index: 1;
  flex-grow: 0;
  transition: width 0.5s ease;
  &.expanded {
    width: @MAIN_EXTENDED_WIDTH;
  }
  &.secondDepthLayout {
    width: @SUB_EXTENDED_WIDTH;
    height: calc((100% - @SECOND_DEPTH_LAYOUT_PADDING_TOP));
    left: @MAIN_EXTENDED_WIDTH;
    background-color: @BG_COLOR_05;
    box-shadow: 8px 0 36px rgba(33, 33, 32, 0.08);
    padding-top: @SECOND_DEPTH_LAYOUT_PADDING_TOP; //custom
    padding-bottom: unset;
    justify-content: flex-start;

    &.isDark {
      background-color: @COLOR_GRAY08;
    }
  }
  &.extraArea {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0);
    transition: initial;
  }
  &.hide {
    width: 0px;
  }

  // dark mode
  &.isDark {
    background: #302F2F;
  }
  &.isTransparent {
    background: rgba(0, 0, 0, 0.2) !important;
  }
}

@__DEV__: true;