@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.popup {
  //enact popup reset
  margin-bottom: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  height: 100%;
  // z-index: 300;
  > div {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;

      > div {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.cl_modalDimmer {
  background: @COLOR_GRAY09;
  opacity: 0.9;
  z-index: -1;
}

.finisiContainer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	width: 100%;
	height: 100%;
  .container {
    color: @COLOR_GRAY01;
    text-align: center;
    line-height: normal;
    margin-top: 150px;
    img {
      vertical-align: middle;
    }
    .textContainer{
      display: inline-block;
      vertical-align: middle;
      text-align: start;
      .title {
        font-family: "LGSmartUIB";
        font-size: 48px;
      }
      .desc {
        font-family: "LGSmartUIL";
        font-size: 28px;
        line-height: 38px;
        margin-top: 16px;
      }
      .hr {
        width: 663px;
        height: 1px;
        background-color: @COLOR_GRAY06;
        margin-top: 40px;
      }

      .heartContainer {
        .heartInfo {
          margin-top: 40px;
          img {
            width: 28px;
            height: 28px;
            margin-right: 8px;
          }
          .heartDesc {
            display: inline-block;
            font-family: "LGSmartUIL";
            font-size: 26px;
          }
        }
        > div:nth-child(2) {
          border: 1px solid @COLOR_GRAY03;
          background-color: transparent;
          margin-top: 20px;
          &:focus {
            color: @COLOR_GRAY03;
          }
        }
      }
    }
    .buttonContainer {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      justify-content: center;
      margin-top: 60px;
    }
  }
}

@__DEV__: true;