@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";
.panel{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 0px;
  background-color: #f0ece4;
  section{
    padding: 0px !important;
    .loginBG{
      width: 25rem;
      height: 18.5417rem;
      position: absolute;
      top: 5.5417rem;
      left: 50%;
      transform: translateX(-50%);
      background-image: url(../../../assets/images/login_bg.png);
    }
    .inputLayer{
      position:absolute;
      top: 24.0833rem;
      left: 50%;
      width: 20.4167rem;
      transform: translateX(-50%);
      .inputItem{
        display: inline-block;
        margin-bottom: 0.8333rem;
        > * {
          display: inline-block;
        }
        .genderBtn{
          width: 8rem;
          max-width: 8rem;
          text-align: center;
          margin-left: 1.25rem;
          margin-right: 0.4167rem;
          margin-top: 0.4167rem;
          > div {
            line-height: 2.1667rem;
          }
        }
      }
    }
    .inputTitle{
      font-family: "LGSmartUIR";
      width: 4.4583rem;
      height: 3.0833rem;
      line-height: 3.0833rem;
      font-size: 1.5rem;
      color: #6c6c6c;
    }
    .inputBox{
      font-family: "LGSmartUIR";
      .inputField{
          margin-right: 0rem;
          margin-left: 0rem;
          padding:0 !important;
          font-size: 1.5rem;
          color: #403E3D;
          letter-spacing: 0.03em;
          >div{
              display:none;
          }
          >input{
              font-size: 1.5rem;
              width: 13.75rem !important;
              height: 3.0833rem !important;
              background: #FFFFFF;
              box-shadow: 0rem 0rem 0.625rem rgb(0 0 0 / 10%);
              border-radius: 0.4167rem;
              padding-left: 0.8333rem;
              padding-right: 0.8333rem;
              &:focus,
              &:hover{
                border: 0.2083rem solid #5BE8D5;
                box-shadow: 0rem 0.1667rem 0.4167rem rgba(0, 0, 0, 0.15);
              }
          }
      }
    }
  }
}

.loginButton {
  position: absolute;
  bottom: 4.1667rem;
  left: 50%;
  transform: translateX(-50%) !important;
}
@__DEV__: true;