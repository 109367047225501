@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

@DETAIL_CARD_WIDTH: 434px;
@CANVAS_WIDTH: 272px;
@CANVAS_HEIGHT: 400px;

.silhouetteContainer {
  width: 100%;
  padding: 0 55px;
  box-sizing: border-box;
  display: flex;
  margin-top: 14px;
  justify-content: space-between;
  &.cesShowMode {
    margin-top: 34px;
  }

  .detailCardsContainer {
    width: @DETAIL_CARD_WIDTH;
    height: @CANVAS_HEIGHT;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .profileContainer {
    position: relative;
    width: 634px;
    height: @CANVAS_HEIGHT;
    display: flex;
    justify-content: space-between;

    .mirrorMode {
      position: absolute;
      .font(@fontFamily: "LGSmartUIR", @fontSize: 20px, @color: @COLOR_GRAY05, @fontWeight: 600);
      bottom: -30px;
      right: 0;
    }
    .bodyWrapper {
      width: 305px;
      height: @CANVAS_HEIGHT;
      position: relative;
      .grid {
        position: absolute;
        top: 0;
        width: 100%;
        height: 347px;
        background-image: url("../../../../../../assets/bodyAlignment/img_overall_grid.png");
      }
      // border-width: 1apx;
      // border-color: @COLOR_GRAY03;
      // border-style: solid;
      // border-radius: 12px;
      .canvasWrapper {
        width: 100%; //366:530 비율로 계산.
        height: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .canvas {
          width: 100%;
          height: 100%;
          border-width: 1apx;
          border-color: @COLOR_GRAY03;
          border-style: solid;
          border-radius: 12px;
          box-sizing: border-box;
        }
      }
      .positionGuide {
        position: absolute;
        left: 20px;
        bottom: 20px;
        width: 40px;
        height: 40px;
        text-align: center;
        vertical-align: middle;
        border-radius: 50%;
        background-color: @COLOR_GRAY03;
        .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @COLOR_GRAY01, @fontWeight: 600);
        line-height: 39px;
        &.rightAlign {
          left: initial;
          right: 20px;
        }
      }
    }
  }
}

.detailContainer {
  position: relative;
  width: 100%;
  // height: 150px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-all;
  &.cesShowMode {
    padding-top: 40px;
  }

  .totalScore {
    display: flex;
    align-items: center;
    gap: 16px;
    .title {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 42px, @color: @COLOR_GRAY06, @fontWeight: 600);
      line-height: 49px;
    }
    .pointStr {
      display: flex;
      justify-content: center;
      height: 67px;
      line-height: 67px;
      margin-top: -3px;
      .point {
        .font(@fontFamily: "LGSmartUIB", @fontSize: 57px, @color: black, @fontWeight: 700);
      }
    }
  }
  .scoreSum {
    margin-top: 6px;
    display: flex;
    gap: 16px;
    .scoreItem {
      text-align: center;
      line-height: 1;
      padding: 10px 26px;
      min-width: 121px;
      height: 48px;
      border: 3px solid transparent;
      border-radius: 50px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: @COLOR_GRAY05;
      background-color: @COLOR_GRAY02;

      &.selected {
        background-color: transparent;
        color: @COLOR_GRAY08;
        border: 3px solid @PRIMARY_COLOR_BROWN03;
        .value {
          &.fine {
            color: #44a280;
          }
          &.manage {
            color: #f9b811;
          }
          &.danger {
            color: #f44b4a;
          }
        }
      }

      .title {
        .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @COLOR_GRAY07, @fontWeight: 600);
        margin-right: 14px;
        height: 28px;
        line-height: 1.2;
        color: inherit;
      }
      .value {
        .font(@fontFamily: "LGSmartUIB", @fontSize: 24px, @color: @COLOR_GRAY08, @fontWeight: 700);
        height: 28px;
        line-height: 1.2;
        color: inherit;
      }
    }
  }
}

.canvasGuide {
  padding-left: 34px;
  position: relative;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 400);
  line-height: normal;
  &::before {
    content: "";
    width: 26px;
    height: 26px;
    position: absolute;
    left: 0%;
    top: 0%;
    background-image: url("../../../../../../assets/bodyAlignment/ic_workoutmanager_alret.png");
  }
}
.summaryDetail {
  margin-top: 33px;
  margin-bottom: 28px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
  line-height: 38px;
}

.detailCard {
  position: relative;
  width: @DETAIL_CARD_WIDTH;
  height: 90px;
  border: 1px solid @COLOR_GRAY03;
  border-radius: 12px;
  padding: 0 24px;
  box-sizing: border-box;
  background-color: @COLOR_GRAY01;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .font(@fontFamily: "LGSmartUISB", @fontSize: 20px, @color: @COLOR_GRAY07, @fontWeight: 600);
  line-height: 1.2;

  &.choose {
    background-color: @COLOR_GRAY02;
  }

  &:focus {
    border: 1px solid @PRIMARY_COLOR_BROWN03;
  }

  .bodyPartLayer {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .partName {
      // display: flex;
      // align-items: center;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 18px;
      > b {
        font-family: "LGSmartUIB";
        font-weight: 700;
      }
      .infoIcon {
        display: inline-block;
        width: 22px;
        height: 22px;
        background-image: url("../../../../../../assets/icon/ic_alert_s.svg");
        margin-right: 8px;
      }
    }

    .partDetail {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 21px, @color: @COLOR_GRAY07, @fontWeight: 600);
      line-height: 25px;
    }
  }

  .bodyScoreLayer {
    display: flex;
    align-items: center;

    font-size: 20px;
    &.LEFT {
      margin-left: 8px;
    }
    &.RIGHT {
      margin-right: 8px;
    }
    &.bigFont {
      font-size: 24px;
      line-height: 28px;
    }

    &.fine {
      color: #44a280;
    }
    &.manage {
      color: #f9b811;
    }
    &.danger {
      color: #f44b4a;
    }
    .rightIcon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url("../../../../../../assets/icon/ic_right_20.svg");
      margin-left: 7px;
    }
  }
}

.dottedLine {
  position: absolute;
  bottom: 45px;
  z-index: 1;
  width: 0;
  overflow: hidden;
  transition: width 0.3s ease;
  &.LEFT {
    left: @DETAIL_CARD_WIDTH;
    background-position: left;
  }
  &.RIGHT {
    right: @DETAIL_CARD_WIDTH;
    background-position: right;
  }
  &.classIndex0 {
    bottom: auto;
    top: 45px;
    height: 26px;
  }
  &.classIndex1 {
    height: 61px;
  }
  &.classIndex2 {
    height: 71px;
  }
  &.classIndex3 {
    height: 102px;
  }
  &.selected {
    width: 108px;
  }

  background-repeat: no-repeat;
  background-size: cover;
}
@__DEV__: true;