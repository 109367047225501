@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

@BELLYPOINT_SIZE: 17px;

.base{
  width: 100%;
  height: 100%;
  position: relative;
  --infoComponentsOffset: 0;

  @keyframes hideAnimation {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes bellyPoint {
    0% {
      opacity: 0;
    }
    30%{
      opacity: 1;
    }
    70%{
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  .imageContainer {
    position: absolute;
    //origin 513X1200
    width: 256px;
    height: 600px;
    // margin-bottom: -84px;
    opacity: 0;
    // margin-bottom: -100px;
    // transform: scale(1.08);
  }
  .image_scan{
    --scanimageMaxHeight: 200px;
    @keyframes expandContractTopToBottom {
      0% {
        height: var(--scanimageMaxHeight);
        opacity: 1;
      }
      60% {
        opacity: 1;
        height: 200px; /* 최종 높이 */
      }
      80% {
        opacity: 1;
        height: 200px; /* 최종 높이 */
      }
      100% {
        opacity: 0;
        height: 200px; /* 최종 높이 */
      }
    }
    @keyframes expandContract {
      0% {
        height: 200px; /* 초기 높이 */
        opacity: 1;
      }
      60% {
        opacity: 1;
        height: var(--scanimageMaxHeight); /* 최종 높이 */
      }
      80% {
        opacity: 1;
        height: var(--scanimageMaxHeight); /* 최종 높이 */
      }
      100% {
        opacity: 0;
        height: var(--scanimageMaxHeight); /* 최종 높이 */
      }
    }

    width: 335px;
    height: 200px;
    position: absolute;
    background-image: url("../../../../../assets/bodyScan/img_scan.svg");
    // object-fit:fill;
    overflow: hidden; /* 넘치는 부분 숨김 */
    background-position: top center;
    background-repeat: no-repeat;
    animation: expandContractTopToBottom 5s linear;
    animation-fill-mode: forwards;
  }
  .image_scan_bottom{
    width: 363px;
    height: 63px;
    position: absolute;
    background-image: url("../../../../../assets/bodyScan/img_scan_bottom.png");
    transform-origin: center center;
    animation: hideAnimation 2s linear;
    animation-fill-mode: forwards;
  }
  .bellyPoint {
    width: @BELLYPOINT_SIZE;
    height: @BELLYPOINT_SIZE;
    opacity: 0;
    margin-left: calc((-1) * (@BELLYPOINT_SIZE / 2));
    margin-top: calc((-1) * (@BELLYPOINT_SIZE / 2));
    position: absolute;
    animation: bellyPoint 2s linear;
    animation-fill-mode: forwards;
  }
}
@__DEV__: true;