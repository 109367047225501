@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

.wrapper{
  position:absolute;
  width:100%;
  height: 100%;
  top:0;
  left:0;
  &.missmatched::before {
    content: "";
    display: inline-block;
    position: absolute;
    background-color: #A42A29;
    opacity: 0.3;
    width: 100%;
    height: 100%;
  }

  .matlayout{
    position: relative;
    top: 326px;
    left: 94px;
    width: 771px;
    height: 428px;
    background-image: url('../../../../../assets/bodyBalance/img_test_camera_off_foot_mat.png');
    .leftFootLayout, .rightFootLayout{
      position : absolute;
      width: 273px;
      height: 273px;
      top: 78px;
      border-radius: 14px;
      background-image: url('../../../../../assets/bodyBalance/real_foot_mat.png');
      background-color: rgba(255, 255, 255, 0.06);
    }
    .leftFootLayout{
      left: 77px;
    }
    .rightFootLayout{
      right: 77px;
    }
    .leftFoot, .rightFoot{
      position: absolute;
      width: 91px;
      height: 220px;
      margin-left: -45px;
      margin-top: -110px;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0;
    }
    .leftFoot{
      background-image: url('../../../../../assets/bodyBalance/realUserFootLeft.png');
    }
    .rightFoot{
      background-image: url('../../../../../assets/bodyBalance/realUserFootRight.png');
    }
  }
  &.missmatched .matlayout{
    background-repeat: no-repeat;
    background-image: url('../../../../../assets/bodyBalance/img_test_camera_off_foot_mat_mismatch.png');
  }
  &.matched .matlayout{
    background-image: url('../../../../../assets/bodyBalance/img_test_camera_off_foot_mat_match.png');
  }
}
@__DEV__: true;