@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";


.calendar {
  position: absolute;
  left: 227px;
  top: 170px;
  padding: 36px 40px;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.10);
}

.inputSection {
  position: absolute;
  left: 803px;
  top: 170px;
  width: 784px;
  height: 655px;
  padding: 60px;
  box-sizing: border-box;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.03);

  .title {
    color: #DBD7D4;
    font-size: 28px;
    height: 33px;
    line-height: 33px;
    font-family: 'LGSmartUISB';
    margin-bottom: 20px;
  }

  .date {
    color: #B59D85;
    font-size: 34px;
    height: 40px;
    line-height: 40px;
    font-family: 'LGSmartUIB';
  }

  .hr {
    width: 664px;
    height: 1px;
    margin: 52px auto;
    background-color: #FFF;
    opacity: 0.2;
  }
  .inputTitle{
    color: #DBD7D4;
    font-size: 28px;
    height: 33px;
    line-height: 33px;
    font-family: 'LGSmartUISB';
    margin-bottom: 36px;
  }
  .input {
    width: 650px;
    margin-bottom: 28px

  }
}



.buttonArea {
  display: flex;
  gap: 24px;
  position: absolute;
  top: 888px; 
  left: 615px;
}
@__DEV__: true;