@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_WIDTH: 820px;

.panel {
  font-family: "LGSmartUIR";
  width: 100%;
  height: 100%;
}

.tBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 129px);
}

.inputBox {
  font-family: "LGSmartUIB";
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  height: calc(100% - 300px);

  .inputField {
    margin-right: 0rem;
    margin-left: 0rem;
    padding: 0 !important;
    font-size: 1.5rem;
    color: #403e3d;
    letter-spacing: 0.03em;
    > div {
      display: none;
    }
    > input {
      text-align: right;
      font-size: 1.5rem;
      width: 5rem;
      background: transparent;
      border-bottom: 1px solid @color222222;
    }
  }

  .inputLabel {
    color: @color222222;
    font-size: 1.5rem;
  }
}

.buttonBox {
  display: flex;
  gap: 20px;
  height: 300px;
}

@__DEV__: true;