@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.tabArea {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .tabTitle {
    position: relative;
    width: 210px;
    max-width: 200px;
    // height: 33px;
    padding: 10px 24px;
    color: @COLOR_GRAY07;
    font-size: 27px;
    font-family: "LGSmartUISB";
    line-height: 1.1;
    text-transform: capitalize;
    text-align: center;
    transition: all 0.2s ease;
    border-radius: 60px;
    &.small {
      min-width: 126px;
      width: auto;
      padding: 10px 20px;
    }

    .tabTitleText {
      opacity: 0.8;
    }

    &.pressed {
      position: relative;
      overflow: hidden;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: @COLOR_GRAY08;
        border-radius: inherit;
        opacity: 0.2;
      }
    }

    &:focus {
      color: @COLOR_GRAY01;
      font-family: "LGSmartUIB";
      background-color: @PRIMARY_COLOR_BROWN03;
      .tabTitleText {
        opacity: 1;
      }
    }

    &.active {
      font-family: "LGSmartUIB";
      .tabTitleText {
        opacity: 1;
      }
    }
  }
  .devider {
    display: inline-block;
    width: 2px;
    height: 23px;
    background-color: @COLOR_GRAY04;
    margin: 0 4px;
  }
}

@__DEV__: true;