@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

.wrapping {
  position: relative;
  top: 0;
  margin: 0 auto;
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .idealposture {
    display: "flex";
    flex-direction: "column";
    width: 334px;
    padding: 12px 12px 0 12px;
    border-radius: 16px;
    height: 460px;
    box-sizing: border-box;
    background-color: @COLOR_GRAY03;
    margin-left: 87px;
  }
  .swiperInnerImg {
    position: relative;
    width: 310px;
    height: 374px;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: 100%;
      // border: 1px solid @COLOR_GRAY03;
    }
  }
  .recordDate {
    .font(@fontFamily: "LGSmartUISB", @fontSize: 30px, @color: @COLOR_GRAY01, @fontWeight: 600);
    text-align: center;
    height: 35px;
    line-height: 35px;
    padding-top: 20px;
    padding-bottom: 19px;
  }
}
@__DEV__: true;