@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_PADDING: 50px;

.body {
  margin-top: 50px;

  .item {
    width:~"calc(100% - @{ITEM_PADDING} * 2)";
    position: relative;
    display: flex;
    justify-content: space-between;
    height: 130px;
    align-items: center;
    padding-left: @ITEM_PADDING;
    padding-right: @ITEM_PADDING;
    grid-gap: 20px;
    border-bottom: 1px solid black;

    &:focus {
      background-color: @fitTv-spotlight-color;
      color: @fitTv-spotlight-text-color;
    }
  }
}
@__DEV__: true;