@import '../../style/CommonStyle.module.less';

.tDrapdown{
  margin-left: 0px !important;
  margin-right: 0px !important;

  [role=button] { //dropdown
    height: 72px !important;
    border-radius: 12px !important;
    padding: 18px 26px !important;
    margin: 0px !important;
    font-size: 27px !important;
    font-family: "LGSmartUISB" !important;
    background-color: @BG_COLOR_03 !important;
    color: @COLOR_GRAY07 !important;

    > div:first-child { //dropdown shadow
      background-color: transparent !important;
      box-shadow: none !important;
    }

    > div:nth-child(2) >div:nth-child(1) { //icon
        margin-right: 0px !important;
        margin-left: 10px !important;
        font-size: 24px !important;
        width: 24px !important;
        color: @COLOR_GRAY07 !important
    }

    &:focus{
      background-color: @PRIMARY_COLOR_BROWN03 !important;
      color: @COLOR_GRAY01 !important;
      > div:nth-child(2) > div:nth-child(1) { // icon
        color: @COLOR_GRAY01 !important;
      }
    }
  }

  // &.pressed [role=button] {
  //   position: relative;
  //   &::before {
  //     content: "";
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     background-color: #000000;
  //     border-radius: inherit;
  //     opacity: 0.3;
  //   }
  // }

  // color
  &.isDark {
    [role=button] { //dropdown
      background-color: #363533 !important;
      color: #FEFEFE !important;

      > div:nth-child(2) >div:nth-child(1) { //icon
        color: #FEFEFE !important
      }

      &:focus{
        background-color: #FEFEFE !important;
        color:#363533 !important;
        > div:nth-child(2) > div:nth-child(1) { // icon
          color: #363533 !important;
        }
      }
    }
  }

  &.isDark.transparent {
    [role=button] { //dropdown
      background-color: transparent !important;

      &:focus{
        background-color: transparent !important;
        color: #FEFEFE !important;
        box-shadow: 0 0 0 2px #FEFEFE inset;
        > div:nth-child(2) > div:nth-child(1) { // icon
          color: #FEFEFE !important;
        }
      }
    }
  }

  //size
  &.large {
    [role=button] {
      height: 81px !important;
      font-size: 32px !important;
      border-radius: 8px !important;
    }
  }
}

[id=floatLayer]{
  --list-background-color: @COLOR_GRAY01;
  --list-item-font-color: @COLOR_GRAY08;
  --list-item-focus-background-color: @PRIMARY_COLOR_BROWN03;
  --list-item-focus-font-color: @COLOR_GRAY01;
  --list-item-select-border-color: @PRIMARY_COLOR_BROWN03;
  --scroll-track-color: @COLOR_GRAY03;
  --scroll-bar-color: @COLOR_GRAY04;

  >div[id]{
    >div:nth-child(2)>div:nth-child(1){ //list layout
      padding: 0 !important;
      margin-top: 12px !important;
      box-shadow: none !important;
      background-color: var(--list-background-color) !important;
      border-radius: 8px !important;
      >div{
        padding: 16px !important;
        height: auto !important;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
        >div:nth-child(2)>div { //scroll track
          background-color: var(--scroll-track-color) !important;
          width: 4px !important;
          border-radius: 20px !important;
          >div{ //scroll bar
            background: var(--scroll-bar-color) !important;
            width: 100% !important;
            border-radius: inherit !important;
          }
        }
      }
  }
  >div:nth-child(2)>div:nth-child(1)>div>div>div>div>div:nth-child(1){ //list item
      font-family: 'LGSmartUIR' !important;
      color: var(--list-item-font-color) !important;
      font-size: 30px !important;
      font-weight: 400 !important;
      margin: 0px !important;
      padding: 12px 20px !important;
      height: 61px !important;
      line-height: 61px !important;
      -webkit-margin-start: 0px !important;
      margin-inline-start: 0px !important;
      > div:first-child { //list item shadow
          background-color: transparent !important;
          box-shadow: none !important;
      }
      > div:nth-child(3) { //check icon
          display: none !important;
      }
      &:focus{
          font-family: 'LGSmartUISB' !important;
          background-color: var(--list-item-focus-background-color) !important;
          color: var(--list-item-focus-font-color) !important;
          border-radius: 8px !important;
      }
      &[data-selected="true"] { //selected item
        font-weight: 600 !important;
        box-shadow: 0 0 0 2px inset var(--list-item-select-border-color);
        border-radius: 8px;
      }
    }
  }
}


@__DEV__: true;