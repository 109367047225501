@import '../../style/CommonStyle.module.less';


.starlayer {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: center;
  .star {
    display: inline-block;
    width: 64px;
    height: 64px;
    background: url(../../../assets/icon/unChked_star_icon.svg) no-repeat 0 0;
    &.select {
      background: url(../../../assets/icon/chked_star_icon.svg) no-repeat 0 0;
    }
    &:focus {
      background: url(../../../assets/icon/chked_star_icon.svg) no-repeat 0 0;
    }
  }
}
@__DEV__: true;