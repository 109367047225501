@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.container{
  margin-top: 50px;
  text-align: center;
  > .innerContainer {

    margin-bottom: 16px;
  }
  .detail {
    text-decoration: underline;
    padding: 10px 24px;
    &:focus {
      color: @COLOR_GRAY01;
      font-family: 'LGSmartUIB';
      border-radius: 30px;
      background: rgba(254, 254, 254, 0.10);
    }
    &.pressed {
      color: @COLOR_GRAY01;
      font-family: 'LGSmartUIB';
      background: rgba(254, 254, 254, 0.15);
    }
  }
}
@__DEV__: true;