@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";




.chips {
  margin-bottom: 28px;
  padding: 0 60px 0 75px;
}

.graphWrapper {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
  gap: 28px;  
}
@__DEV__: true;