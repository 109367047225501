@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.bodyAlignmentReport {
  .size(@w: 100%, @h: auto);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);

  .tabButtonWrapper {
    padding: 16px;
  }

}


@__DEV__: true;