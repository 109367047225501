.popup {
    margin-bottom: 0 !important;
    width: 100%;
    height: 100%;
    > div > div {
      height: 100% !important;
      > div {
        max-width: unset !important;
        height: 100% !important;
        max-height: 80% !important;
      }
    }
    .text {
      text-align: center;
      margin-top: 50px;
      margin-bottom: 120px;
    }
    .Container {
      display: flex;
      height: auto;
      flex-wrap: wrap;
      justify-content: space-evenly;
      row-gap: 30px;
      margin-bottom: 120px;

      .switch {
        width: 550px;
        height: 350px !important;
        .switchImg {
          width: 435px;
        }
      }
    }
    .startButton {
      display: flex;
      align-items: center;
      column-gap: 20px;
      justify-content: center;
    }
  }
@__DEV__: true;