@import "../../style/CommonStyle.module.less";
@import "./TabLayout.module.less";

@ICON_SIZE: 74px;
@ICON_MARGIN: ~"calc( (@{MAIN_COLLAPSED_WIDTH} - @{ICON_SIZE}) / 2 )";
.tabitem {
  position: relative;
  font-family: "LGSmartUISB";
  font-size: 36px;
  height: @ICON_SIZE;
  border-radius: 12px;
  display: flex;
  align-items: center;
  color: @COLOR_GRAY06;
  // margin: 6px 16px;
  margin: 6px 0;
  padding: 0 16px;

  &.selected {
    font-family: "LGSmartUIB";
    color: @PRIMARY_COLOR_GREEN01;
    .icon {
      background-color: #ffffff54;
    }
  }
  &.focused {
    font-family: "LGSmartUIB";
    color: @COLOR_GRAY01;
    border-radius: 12px;
    .icon {
      background-color: @PRIMARY_COLOR_BROWN03;
    }
  }
  &.pressed {
    position: relative;
    .icon {
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.3;
        border-radius: 12px;
      }
    }
  }
  &.AllExpanded {
    color: @COLOR_GRAY06;
  }
  &.isDark {
    color: @COLOR_GRAY02;
    &.selected {
      color: #cfbf8c;
      .icon {
        background-color: @COLOR_GRAY06;
      }
    }
    &.focused {
      color: @COLOR_GRAY06;
      .icon {
        background-color: @COLOR_GRAY01;
      }
    }
    &.pressed {
      .icon {
        &::after {
          background-color: #212120;
        }
      }
    }
  }
  &.isTransparent {
    &.selected {
      color: #cfbf8c;
      .icon {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
    &.focused {
      color: @COLOR_GRAY06;
      .icon {
        background-color: @PRIMARY_COLOR_BROWN03;
      }
    }
    &.pressed {
      .icon {
        &::after {
          background-color: #212120;
        }
      }
    }
  }

  .icon {
    position: relative;
    border-radius: 12px;
    min-width: @ICON_SIZE;
    height: @ICON_SIZE;
    // margin-left: @ICON_MARGIN;
    // margin-right: @ICON_MARGIN;
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    > svg {
      position: absolute;
      width: 54px;
      height: 54px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .marqueeWrap {
    width: 100%;
  }

  .text {
    width: 100%;
    line-height: calc((@ICON_SIZE + 12px));
    margin-left: @ICON_MARGIN;
    padding-right: @ICON_MARGIN;

    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;

    &.subItem {
      // margin-left: @ICON_MARGIN;
      margin-left: 40px;
    }
  }
  &.dividerBox {
    margin-top: 61px;
    margin-bottom: 61px;
  }
  .divider {
    width: 32px;
    height: 1px;
    margin: 0 auto;
    background-color: @COLOR_GRAY04;
  }
  &.expanded {
    height: calc((@ICON_SIZE + 12px));
    margin: 6px 16px;

    .divider {
      width: ~"calc(100% - @{ICON_MARGIN} * 2)";
      background-color: @COLOR_GRAY04;
    }
  }

  .toolTip {
    // left: calc(@MAIN_COLLAPSED_WIDTH - 16px + 4px) !important;
    left: calc(@MAIN_COLLAPSED_WIDTH + 4px) !important;
  }
}

@__DEV__: true;