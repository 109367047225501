@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.graphContainer {
  @W_GAP: 41px;
  @GRAPH_WIDTH: 196px;
  width: calc(@GRAPH_WIDTH + 82px);
  // padding: 0 @W_GAP;
  position: absolute;
  top: 50px;
  right: 96px;
  padding: 41px 41px 36px 41px;
  box-sizing: border-box;
  > div {
    > canvas {
      width: 196px;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .goalPercentage {
      position: absolute;
      top: 71px;
      height: 53px;
      line-height: 53px;
      left: 50%;
      transform: translateX(-50%);
      .font(@fontFamily: "LGSmartUIB" , @fontSize: 45px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 700);
      > span {
        .font(@fontFamily: "LGSmartUISB" , @fontSize: 27px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 600);
      }
    }
  }

  .textWrapper {
    width: max-content;
    margin-top: 16px;
    text-align: center;
    .font(@fontFamily: "LGSmartUISB" , @fontSize: 27px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 600);
    .typeName {
      margin: 0;
      height: 32px;
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      &::after {
        content: "";
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-left: 8px;
        background-image: url("../../../../assets/landingPage/icon/ic_arrow_round_26.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transform: scale(1);
        transition: transform 0.3s ease;
      }
    }
    .goalContainer {
      height: 32px;
      text-align: center;
      > span:last-child {
        margin-left: 4px;
        color: @COLOR_GRAY05;
      }
    }
  }

  &:focus {
    .typeName::after {
      transform: scale(1.08);
    }
    .tooltip {
      display: block;
    }
  }
  &.pressed {
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 20px;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
@__DEV__: true;