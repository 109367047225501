@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.body {
  margin-bottom: 36px;
}

.tabArea {
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center);
  height: 63px;
}

.titleContainer {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center);
  .title {
    font-size: 33px;
    height: 39px;
    line-height: 39px;
    color: #F2EFED;
    font-family: 'LGSmartUISB'; 
  }
  .vr {
    width: 1px;
    height: 25px;
    opacity: 0.3;
    background: #FFF;
    margin: 0 16px;
  }
}


@__DEV__: true;