@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.goalContainer {
  .size(@w: 536px, @h: auto);
  padding: 35px 40px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.03);
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.10) inset;
  border-radius: 18px;

  .dataContainer {
    .flex(@display: flex, @justifyCenter:flex-start , @alignCenter: center, @direction: row);
    .graphBox {
      position: relative;
      margin-right: 30px;
      .icon {
        .size(@w: 36px, @h: 36px);
        .position(@position: absolute, @top: 50%, @right: unset, @bottom: unset, @left: 50%);
        transform: translate(-50%, -50%);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .goalBox {
      .flex(@display: flex, @justifyCenter: center, @alignCenter: normal, @direction: column);
      .title {
        font-size: 30px;
        // height: 35px;
        line-height: 35px;
        font-family: 'LGSmartUISB'; //font-weight: 600;
        color: #FEFEFE;
        margin-bottom: 20px;
      }
      .data {
        // height: 42px;
        line-height: 42px;
        font-size: 36px;
        font-family: 'LGSmartUISB'; //font-weight: 600;
        color: #FEFEFE;
        >span:nth-child(2) {
          color: #B2B0AE;
        }
      }
    }
  }
  .hr {
    height: 1px;
    background-color: #787776;
    margin: 35px 0 28px 0;
  }

  .infoBox {
    .flex(@display: flex, @justifyCenter: space-between, @alignCenter: flex-start, @direction: row);
    margin-bottom: 17px;
    &:last-child {
      margin-bottom: 0;
    }
    .title {
      font-size: 27px;
      // height: 32px;
      line-height: 32px;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      color: #DBD7D4;
      max-width: 270px;
      word-wrap: break-word;
    }
    .data {
      font-size: 27px;
      // height: 32px;
      line-height: 32px;
      font-family: 'LGSmartUIB'; //font-weight: 700;
      color: #FEFEFE;
    }
  }
}
@__DEV__: true;