@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

@TEXT_LINE_HEIGHT: 19px;
.listItem {
  .flex(@display: flex, @justifyCenter: unset, @alignCenter: unset, @direction: row);

  .dot {
    .size(@w: 3px, @h: @TEXT_LINE_HEIGHT);
    .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
    margin-right: 6px;
    &::before {
      content: "";
      .size(@w: 3px, @h: 3px);
      background-color: #363533;
      border-radius: 50%;
    }
  }
  
  .text {
    .size(@w: auto, @h: auto);
    line-height: @TEXT_LINE_HEIGHT;
    font-size: 14px;
    color: #363533;
    font-family: 'LGSmartUIR'; //font-weight: 400;
  }
}
@__DEV__: true;