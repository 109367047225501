@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.TTermsPopup {
  > div > div > div > {
    div:nth-child(1) > div:nth-child(1) {
      row-gap: 10px;
      div:nth-child(1) {
        margin-bottom: 42px;
      }
      div:nth-child(2), div:nth-child(3) {
        margin-bottom: 0px;
      }
    }
  }
}

.TLoginPopup {
  > div > div > div > {
    div:nth-child(1) > div:nth-child(1) {
      row-gap: 10px;
      div:nth-child(1) {
        margin-bottom: 42px;
      }
      div:nth-child(2), div:nth-child(3) {
        margin-bottom: 0px;
      }
    }
  }
}
@__DEV__: true;