@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

.panel{
  font-family: "LGSmartUIR";
  width: 100%;
  color: black;
  .tBody{
    padding: 0px;
    .title{
      // color: #222222;
      // font-size: 32px;
      position: absolute;
      top: 910px;
      .font(@fontFamily: "LGSmartUISB", @fontSize: 30px, @color: @PRIMARY_COLOR_BROWN02, @fontWeight: 600);
      line-height: 35px;
      margin-top: 20px;
      padding: 0 75px;
      width: calc(100% - 150px);
      text-align: center;
    }
    // .flexBox{
    //   display: flex;
    //   justify-content: space-evenly;
    //   margin-top: 120px;
    // }
    // .btnStyle{
    //   padding-top: 30px;
    //   padding-left: 30px;
    // }
    // .date{
    //   width: auto;
    // }

    .sectionContainer {
      position: absolute;
      bottom: 174px;
      height: 653px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }
  
    .buttonInnerContainer {
      display: flex;
    }
  
    .mainSectionBody {
      transition: margin 400ms;
    }
  
    .mainSectionBody > div {
      left: calc((100vw - (20px * 2)) * 0.075);
    }
  
    .mainSectionContainer {
      position:relative;
      left: -7%;
      width: 50%;
      display: flex;
      transition: margin 400ms;
    }
  }
}


.tPopup {
  .scroller {
    height: 500px;
  }
  .title {
    .size(@w: 100%, @h: auto);
    font-size: 39px;
    line-height: 46px;
    color: #363533;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    text-align: center;
    margin-bottom: 30px;

  }

  .text {
    .size(@w: 100%, @h: auto);
    font-size: 27px;
    line-height: 38px;
    color: #363533;
    font-family: 'LGSmartUIR'; //font-weight: 400;
  }
}
@__DEV__: true;