@import '../../../style/CommonStyle.module.less';
@import "../../../style/utils.module.less";

.basic {
  .size(@w: 1520px, @h: 425px);
  position: relative;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.03);
  >div {
    position: absolute;
    &:nth-child(1){
      top: 130px;
      left: 263px;
    }
    &:nth-child(2){
      top: 130px;
      left: 838px;
    }
    &:nth-child(3){
      top: 241px;
      left: 263px;
    }
    &:nth-child(4){
      top: 241px;
      left: 838px;
    }
  }
  &::after {  // border
    content: "";
    .size(@w: 2px, @h: 219px);
    .position(@position: absolute, @top: 95px, @right: unset, @bottom: unset, @left: 724px);
    background-color: #B2B0AE;
    opacity: 0.3;
  }
}

.body, 
.exercise, 
.life {
  .size(@w: 100%, @h: auto);
  display: flex;
  flex-direction: column;
  gap: 40px;
}
@__DEV__: true;