@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.timeLineContainer {
  position: initial;
  margin: 0;
  padding: 0;

  .timeWrapper {
    display: flex;
    align-items: center;
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .time {
      position: relative;
      color: #63544B;
      font-size: 32px;
      font-family: 'LGSmartUISB';
      text-align: center;

      img {
        margin-bottom: 10px;
      }
    }

    &:not(:last-child) .time .bar {
      width: 4px;
      background: #B59D85;
      opacity: 0.20;
      border-radius: 28px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      margin: 24px 0;
    }

    .infoBox {
      background: #FEFEFE;
      border-radius: 20px;
      margin-left: 60px;
      padding: 34px 40px;
      width: 100%;

      .infoWrapper {
        display: flex;

        .image {
          width: 338px;
          height: 189px;
          border-radius: 12px;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;

          .courseImage {
            position: absolute;
            width: 102px;
            height: 68px;
            bottom: 14px;
            right: 14px;
          }
        }

        .info {
          flex: 1;
          margin-left: 60px;

          .category {
            position: relative;
            color: #B59D85;
            font-size: 26px;
            font-family: 'LGSmartUISB';
            margin: 14px 0 12px;
            padding-left: 14px;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 4px;
              height: 23px;
              background-color: #B59D85;
            }
          }

          .title {
            font-size: 36px;
            font-family: 'LGSmartUIB';
            margin-bottom: 40px;
          }
        }
      }

      hr {
        margin: 28px 0 32px;
        border-color: #D9D9D9;

      }
      .desc {
        color: #111111;
        font-size: 28px;
        font-family: 'LGSmartUIR';
        line-height: 42px;
      }
    }
  }
}
@__DEV__: true;