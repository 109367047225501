@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.panel {
  background: linear-gradient(
    to bottom,
    @BG_COLOR_03 0%,
    @BG_COLOR_03 241px,
    @MAIN_COLOR 3.5%,
    @MAIN_COLOR 100%
  );
}
.container {
  width: 100%;
  height: 839px;
}
.body {
  padding-top: 23px;
  padding-right: 0;
}
.tab {
  height: 89px !important;
  // > div {
  //   padding: 26px 46px;
  //   height: 37px;
  // }

  > div > div > div {
    font-size: 32px !important;
  }
}

@__DEV__: true;