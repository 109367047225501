@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.box {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: 97px;
}

.logo {
  width: 220px;
  height: 124px;
  border-radius: 12px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #ffffff;
}

.container {
  height: 359px;
  padding: 40px;
  border-radius: 20px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.03);
  border: 2px solid rgba(255, 255, 255, 0.10);
}

.textArea {
  display: flex;
  flex-direction: column;
  gap: 17px;
  justify-content: center;

  .title {
    height: 39px;
    line-height: 39px;
    font-size: 36px;
    color: #FEFEFE;
    font-family: 'LGSmartUIR'; //font-weight: 400;
  }

  .subTitle {
    height: 32px;
    line-height: 32px;
    font-size: 27px;
    color: #F2EFED;
    font-family: 'LGSmartUIR'; //font-weight: 400;
  }
}

.bar {
  width: 1569px;
}
@__DEV__: true;