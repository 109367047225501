@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

@ARROW_HEIGHT: 8px;

.default-tooltip-size() {
  .size(@w: 604px, @h: auto);
  border-radius: 12px;
  padding: 24px;
  box-sizing: border-box;
}

.toolTip {
  .default-tooltip-size();
  position: absolute;
  background-color: #494947;
  z-index: 1;
  display: none;

  .arrow {
    position: absolute;
    width: 22px;
    height: @ARROW_HEIGHT;
    background-image: url('../../../../../../assets/myPanel/icon/ic_tooltip_dark.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.bottom {
    left: 50%;
    transform: translateX(-50%);
    .arrow {
      left: 50%;
      transform: translateX(-50%);
      top: -@ARROW_HEIGHT;
    }
  }
  &.top {
    left: 50%;
    transform: translateX(-50%);
    .arrow {
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
      bottom: -@ARROW_HEIGHT;
    }
  }

  .title {
    font-size: 27px;
    height: 32px;
    line-height: 32px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    text-align: center;
    margin-bottom: 8px;
  }
  .description {
    font-size: 24px;
    line-height: 36px;
    color:#FFF;
    font-family: 'LGSmartUIR'; //font-weight: 400;
  }
}


@__DEV__: true;