@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.50);

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &.readyPanel {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0) 100%);

    .box {
      width: 100%;
      z-index: 1;

      .title {
        color: white;
        font-size: 48px;
        font-family: 'LGSmartUIB';
        line-height: 56px;
      }
      .countdown {
        color: white;
        font-size: 260px;
        font-family: 'LGSmartUIB';
        line-height: 304px;
      }
    }
  }
}

.borderBox {
  background: rgba(33, 32.54, 32, 0.80);
  border-radius: 16px;
  padding: 24px 0;
}

.contentInfo {
  position: absolute;
  left: 54px;
  bottom: 54px;
  max-width: 316px;

  .contentInfoBox {
    padding: 0 24px;
    .title {
      color: @COLOR_GRAY01;
      font-size: 30px;
      font-family: 'LGSmartUIB';
      margin-bottom: 8px;
    }
    .distance {
      margin-bottom: 8px;
      .curDistance {
        color: @COLOR_GRAY01;
        font-size: 27px;
        font-family: 'LGSmartUIB';
      }
      .totalDistance {
        color: @COLOR_GRAY04;
        font-size: 27px;
        font-family: 'LGSmartUISB';
      }
    }
    .curSpeed {
      margin-top: 15px;
      color: #F4CCA4;
      font-size: 36px;
      font-family: 'LGSmartUIB';
      text-align: center;
    }
  }

  hr {
    margin: 16px 0 20px;
    border-color: #D9D9D933;
  }

  .guideSpeed {
    // margin: 6px 0 24px 0;
    margin-top: 6px;
    color: @COLOR_GRAY03;
    font-size: 24px;
    font-family: 'LGSmartUISB';
    text-align: center;
    >span{
      color: @COLOR_GRAY04;
    }
  }

  .controlVelocity{
    display: flex;
    font-size: 24px;
    padding: 0 24px;
    font-family: 'LGSmartUISB';
    justify-content: space-between;
    align-items: center;
    color: @COLOR_GRAY03;
    text-align: center;
  }

  &.hiking {
    .contentInfoBox {
      .title {
        margin-bottom: 16px;
      }

      .distance {
        margin-top: 16px;
        margin-bottom: 0;
        text-align: center;
        .curDistance {
          color: #F4CCA4;
          font-size: 27px;
          font-family: 'LGSmartUIB';
        }
        .totalDistance {
          font-size: 24px;
        }
      }
    }
  }
}

.bodyInfo {
  position: absolute;
  right: 52px;
  bottom: 54px;

  > .borderBox:not(:last-child) {
    margin-bottom: 20px;
  }

  .bpm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 0;

    .bpmLayer {
      color: @COLOR_GRAY02;
      font-size: 26px;
      font-family: "LGSmartUIR";
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      img {
        margin-right: 8px;
      }

      span {
        color: @COLOR_GRAY01;
        font-size: 38px;
        font-family: "LGSmartUIB";
        line-height: 40px;
      }
    }
    .bpmStatus {
      font-size: 20px;
    }
  }

  .footprint {
    display: flex;
    padding: 0 65px;
    justify-content: center;

    .footImg {
      position: relative;
      margin: 24px 0;
      width: 45px;
      height: 107px;

      img {
        position: absolute;
        top: 0;
        left: 0;

        &.active {
          opacity: 0;
          transition: opacity 250ms ease-in-out;
        }
      }
    }

    .footBorder {
      width: 2px;
      height: 100%;
      margin: 0 46px;
    }
  }

  .etc {
    padding: 25px 24px;
    text-align: center;

    .step {
      color: @COLOR_GRAY02;
      font-size: 26px;
      font-family: 'LGSmartUIR';
      display: flex;
      align-items: center;
      justify-content: center;

      .stepNum {
        color: @COLOR_GRAY01;
        font-size: 44px;
        font-family: 'LGSmartUIB';
        line-height: 50px;
        margin-right: 8px;
      }
    }

    hr {
      margin: 19px 0 16px;
      border-color: #D9D9D94D;
    }

    .etcBottom {
      display: flex;
      justify-content: center;

      img {
        margin-right: 8px;
      }

      span {
        color: @COLOR_GRAY02;
        font-size: 24px;
        font-family: 'LGSmartUISB';
      }

      .kcal {
        margin-right: 16px;
      }

      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  .userBox {
    width: 316px;
    height: 465px;
    padding: 0;
    position: relative;

    &.warning {
      outline: 4px #F44B4A solid;
    }

    .user {
      position: relative;
      width: 100%;
      height: 100%;

      video {
        border-radius: 16px;
      }

      .baselineGuideMsg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-family: 'LGSmartUISB';
        color: @COLOR_GRAY01;
        padding: 8px 0;
        // text-shadow: 2px 2px @COLOR_GRAY08;
        text-shadow: 0 0 6px @COLOR_GRAY08;
      }
    }

    .stepBox {
      position: absolute;
      bottom: 24px;
      width: 100%;
      overflow: hidden;

      .step {
        color: @COLOR_GRAY02;
        font-size: 27px;
        font-family: 'LGSmartUIR';
        display: flex;
        align-items: center;
        justify-content: center;

        .stepNum {
          color: @COLOR_GRAY01;
          font-size: 36px;
          font-family: 'LGSmartUIB';
          line-height: 42px;
          margin-right: 8px;
        }
      }

      hr {
        margin: 16px 22px;
        opacity: 0.10;
        background: white;
      }

      .totalCount {
        color: @COLOR_GRAY04;
        font-size: 24px;
        font-family: 'LGSmartUIB';
        text-align: center;

        span {
          font-family: 'LGSmartUISB';
        }
      }
    }
  }
}

.sceneryContainer {
  .spotBtnBottomBg {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 180px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0) 100%);

    .title {
      position: absolute;
      left: 52px;
      bottom: 67px;
      color: @COLOR_GRAY01;
      font-size: 45px;
      font-family: 'LGSmartUISB';
      line-height: normal;
    }

    .btnWrapper {
      position: absolute;
      right: 52px;
      bottom: 52px;
      z-index: 1;

      > *:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}

.hidden {
  visibility: hidden;
  height: 0 !important;
  margin: 0 !important;
}

.playerContainer.hiking {
  height: 100%;
  .contentInfo {
    left: 204px;
    max-width: 278px;
  }
}
.sceneryPlayer {
  height: 100%;

  > video {
    height: 100%;
  }
}

.selectAngle {
  span {
    color: @PRIMARY_COLOR_GREEN03;
    margin-left: 10px;
  }
}

.settingButtons {
  position: absolute;
  top: 52px;
  right: 52px;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  .selectAngle {
    position: relative;
    min-width: unset;
    width: auto !important;
    background-color: unset;
    font-size: 27px;
    font-family: 'LGSmartUIB';
    height: 81px;
    line-height: 81px;
    color: @COLOR_GRAY01;

    div:last-child {
      width: unset !important;
      max-width: unset !important;
    }
  }

  > div {
    background: rgba(33, 33, 32, 0.70) !important;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .workoutToggle:focus {
    background-color: @COLOR_GRAY01;
  }

  &.isDark {
    > div:focus {
      background: @COLOR_GRAY02 !important;
      color: @COLOR_GRAY06;
    }
  }
}

.finishBtn {
  color: @COLOR_GRAY01;
  background: rgba(33, 33, 32, 0.70) !important;
}

.btn {
  max-width: 280px !important;
}
@__DEV__: true;