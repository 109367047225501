@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";


.shaka-player{
  position: absolute;
  width: auto;
  height: 700px;
  right: 10px;
}
.buttoncontainer{
  display: flex;
}

.box{
  display: flex;
  position: absolute;
  bottom: 10px;
  // margin-top: 100px;
  >button{
    width: 80px;
    height: 45px;
    font-size: 16px;
    margin-right: 15px;
  }
}
@__DEV__: true;