@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.container {
  padding: 64px 80px 36px 80px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  justify-content: space-between;

  .imgBox {
    padding-top: 2px;
    padding-bottom: 83px;
    width: 533px;
    height: 405px;

    > img {
      display: block;
      width: 533px;
      height: 405px;
    }
  }

  .textBox {
    p {
      margin: 0;
      color: @COLOR_GRAY07;
      word-wrap: break-word;
    }
    margin-right: 21px;
    width: 894px;
    height: 100%;

    .title {
      font-size: 36px;
      font-family: "LGSmartUIB";
      line-height: 46px;
      margin-bottom: 21px;
    }
    .desc {
      font-size: 28px;
      font-family: "LGSmartUIR";
      line-height: 40px;
      margin-bottom: 12px;
    }
    .testText {
      display: flex;
      display: flex;
      align-items: center;
      color: @PRIMARY_COLOR_BROWN02;
      font-size: 28px;
      font-family: "LGSmartUISB";
      line-height: 38px;
      word-wrap: break-word;
      margin-bottom: 20px;

      > img {
        display: block;
        width: 28px;
        height: 28px;
        margin-right: 12px;
      }
    }
  }
}

@__DEV__: true;