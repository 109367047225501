@import "../../style/CommonStyle.module.less";

.panel {
  section {
    padding: 0 !important;
  }

  .tBody {
    padding-top: 52px;
    position: relative;
    z-index: 1;
  }

  .bgImg > img {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.imageContainer {
  position: fixed;
  height: 839px;
  top: 120px;
  right: 140px;
}


.contentStartArea {
  width: 70%;

  .category {
    position: relative;
    color: @COLOR_GRAY01;
    font-size: 27px;
    font-family: 'LGSmartUISB';
    padding-left: 14px;
    margin-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 4px;
      height: 23px;
      background-color: @COLOR_GRAY01;
    }
  }

  .contentStartTitle {
    font-family: 'LGSmartUIB';
    font-size: 62px;
    color: @COLOR_GRAY01;
    line-height: 85px;
    margin-bottom: 24px;
  }

  .tagWrapper {
    margin-bottom: 14px;

    .tag {
      display: inline-block;
      color: #B2B0AE;
      font-size: 26px;
      font-family: 'LGSmartUIR';
      padding: 8px 14px;
      border: 1px #494847 solid;
      border-radius: 6px;

      &:not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .iconInfoWrapper {
    margin-bottom: 32px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .iconInfo {
      display: inline-flex;
      align-items: center;
      color: #DBD7D4;
      font-size: 28px;
      font-family: 'LGSmartUIR';
      line-height: 42px;

      &:not(:last-child) {
        margin-right: 32px;
      }

      .iconInfoImg {
        width: 36px;
        height: 36px;
        margin-right: 9px;
        text-align: center;
      }
    }
  }

  .infoWrapper {
    margin-bottom: 20px;

    .info {
      display: flex;
      color: #DBD7D4;
      font-size: 28px;
      font-family: 'LGSmartUIR';
      line-height: 42px;

      .infoTitle {
        color: #FEFEFE;
        font-size: 27px;
        font-family: 'LGSmartUISB';
        margin-right: 24px;
        white-space: nowrap;
      }

      >div>span{
        font-size: 27px;
      }
    }
  }

  .caution {
    color: @COLOR_GRAY03;
    font-size: 21px;
    font-family: 'LGSmartUIR';
    display: flex;
    align-items: center;

    .cautionImg {
      width: 26px;
      height: 26px;
      margin-right: 8px;
    }
  }

  .contentStartDes {
    color: #DBD7D4;
    font-size: 28px;
    font-family: 'LGSmartUIR';
    line-height: 42px;
    margin-bottom: 59px;
  }

  .btnWrap {
    display: flex;
    align-items: center;
    margin-top: 52px;

    > *:not(:last-child) {
      margin-right: 24px;
    }

    .likeBtnContainer {
      color: #DBD7D4;
      font-size: 27px;
      font-family: 'LGSmartUISB';
      text-align: center;

      .likeBtn {
        margin-bottom: 4px;
      }
    }
  }

  .trackContainer {
    position: fixed;
    left: 75px;
    bottom: 103px;
    display: flex;
    align-items: center;
    .track {
      margin-right: 40px;
    }
    .trackInfo {
      padding-left: 40px;
      border-left: 2px solid rgba(255, 255, 255, 0.20);
      .curDistance {
        color: #F4CCA4;
        font-size: 44px;
        font-family: 'LGSmartUIB';
        line-height: 51px;
        margin-bottom: 4px;
      }
      .totalDistance {
        color: #B2B0AE;
        font-size: 26px;
        font-family: 'LGSmartUIR';
      }
    }
  }
}

.bar {
  position: relative;
  padding-right: 20px;

  &::after {
    content: '';
    display: inline-block;
    width: 2px;
    height: 22px;
    background-color: @COLOR_GRAY06;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}
@__DEV__: true;