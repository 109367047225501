@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";


.shaka-player{
  position: absolute;
  width: 700px;
  height: 700px;
  right: 10px;
}
.buttoncontainer{
  display: flex;
}
@__DEV__: true;