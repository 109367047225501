@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

//상단 가이드 Tit=====================================================
.guideTitWrap {
  position: absolute;
  z-index: 2;
  .flex(@display: flex, @justifyCenter: flex-end, @alignCenter: center);
  .size(@w: null, @h: 50px);
  line-height: 60px;
  padding: 30px 15px;
  column-gap: 30px;
  top: 52px;
  right: 52px;
  .guideTit {
    .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
    .size(@w: 90%, @h: null);
    span {
      padding: 0 20px;
      box-sizing: border-box;
      color: #36383b;
      background-color: #cadcdd;
      border-radius: 25px;
    }
  }
}
//End :: guideTitWrap=================================================

//option2 class
.container {
  height: 100%;
  width: 100%;
  position: relative;

  .workoutTitleContainer {
    .position(@position: absolute, @top:35%, @right: null, @bottom: null, @left: 50%);
    // z-index: 5;
    transform: translateX(-50%);
    .flex(@justifyCenter:space-between, @direction: column);
    height: 200px;
    &.hidden {
      visibility: hidden;
    }
  }

  .workoutTitle {
    background-color: #fff;
    padding: 10px 15px;
    color: #2068a1;
    border-radius: 24px;
    margin-bottom: 5px;
    .size(@w: 150px, @h: 30px)
  }

  .excerciseName {
    .font(@fontFamily: "LGSmartUIR", @fontSize: 8rem, @color: #fff, @fontWeight: 600);
    height: 75px;
  }

  .frontTit {
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .size(@w: 100%, @h: 100%);
    // z-index: 5;
    & div {
      .size(@w: 100%, @h: 100%);
      .flex(@display: flex, @justifyCenter: center, @alignCenter: center);
      .font (@fontFamily: "NotoSans", @fontSize: 175px, @color: #fff, @fontWeight: 900);;
    }
  }

  .cl_bgVideoType {
    // z-index: 0;
    // .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .size(@w: 100%, @h: 100%);

    .playSection {
      // z-index: -1;
      // .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
      background-color: @COLOR_GRAY09;
      .size(100%, 100%);
      & div {
        position: absolute;
        .size (@w : null, @h: 100%);
        top: 0;
        transform: translateY(0);
      }
      .avatar {
        position: relative;
        // padding-bottom: 56.25%;
        .size(@w:50%, @h: null);
        .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
        background-color: none;

        .shaka-player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          > video{
            object-fit: cover;
          }
        }
        // .avatarInner {
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;

        //   & > div {
        //     width: 100%;
        //   }
        // }
      }
      .user {
        overflow: hidden;
        right: 0;
        .size(@w:50%, @h: 100%);
        background-color: black;
        &.transparent {
          background-color: transparent;
        }
        &.hidden {
          visibility: hidden !important;
        }
      }
    }
  }
}

.boundingBox {
  position: absolute;
  box-shadow: 0 0 0 2px #fff inset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

// sk===============================================================
.Tpanel {
  .container {
    .headerBg {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 7.5rem;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    }
    .guideTitWrap {
      .back {
        position: absolute;
        top: 0;
        right: 1730px;
      }
      .bgBtn {
        min-width: 400px;
        width: auto;
        div:last-child {
          width: unset !important;
          max-width: unset !important;
        }
      }
      .ratioBtn {
        width: 284px;
        div:last-child {
          width: unset;
        }
      }
    }

    .playContainer {
      // z-index: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      .cl_bgVideoType {
        position: relative;
        .size(@w: 100%, @h: 100%);
      }

      .countLayer {
        width: 100%;
        height: 100%;
        background-color: @COLOR_GRAY09;
        opacity: 0.3;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: "LGSmartUIB";
        position: absolute;
        top: 0;
        left: 0;

        .ready {
          color: @colorffffff;
          text-align: center;
          font-size: 48px;
          line-height: normal;
        }
        .start {
          color: @colorffffff;
          text-align: center;
          font-size: 160px;
          line-height: 304px;
        }
      }
    }
    .bpmContainer {
      pointer-events: none;
      position: absolute;
      right: 52px;
      bottom: 175px;
      width: 315px;
      height: 269px;
      border-radius: 16px;
      background-color: rgba(33, 33, 32, 0.8);
      z-index: 0;
      .bpmBox {
        padding: 24px;
        margin: 28px 20px 20px 20px;
        height: 74px;
      }
      .bodyInfo {
        margin: 0 20px 10px 20px;
        padding-top: 10px;
        border-top: 1px solid @COLOR_GRAY05;
        display: flex;
        flex-direction: column;
        align-items: center;
        .bpmLayer {
          margin-bottom: 4px;
          img {
            vertical-align: middle;
            width: 30px;
            height: 30px;
            margin-right: 8px;
          }
          .bpm {
            display: inline-block;
            font-family: "LGSmartUIR";
            font-size: 26px;
            color: @COLOR_GRAY01;
            vertical-align: middle;
            span {
              font-family: "LGSmartUIB";
              font-size: 38px;
              line-height: 40px;
            }
          }
        }
        .bpmStatus {
          font-size: 20px;
        }
      }
    }
    .finishBtn {
      position: absolute;
      z-index: 1;
      right: 70px;
      bottom: 80px;
    }
  }

  // portrait
  // &.portrait {
  //   .playContainer {
  //     height: 100%;
  //     top: 0;
  //     .avatar {
  //       .shaka-player {
  //         position: absolute;
  //         top: 0;
  //         left: 0;
  //         .size(@w:100%, @h: 100%);
  //         > video {
  //           position: absolute;
  //           width: 100vw;
  //           left: -25vw;
  //         }
  //       }
  //       &.full {
  //         width: 100%;
  //         .shaka-player {
  //           > video {
  //             position: absolute;
  //             width: 100vw;
  //             left: 0vw;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // // landscape
  // &.landscape {
  //   .playContainer {
  //     height: 540px;
  //     top: 270px;
  //     .avatar {
  //       .shaka-player {
  //         > video {
  //           object-fit: contain;
  //         }
  //       }
  //     }
  //   }
  // }
  &.portrait55 {
    .playContainer {
      .avatar {
        width: 50%;
        .shaka-player {
          position: absolute;
          top: 0;
          left: 0;
          .size(@w:100%, @h: 100%);
          > video {
            position: absolute;
            width: 100vw;
            left: -25vw;
          }
        }
        &.full {
          width: 100%;
          .shaka-player {
            > video {
              position: absolute;
              width: 100vw;
              left: 0vw;
            }
          }
        }
      }
      .user {
        width: 50%;
      }
    }
    &.alignLeft {
      .playContainer {
        .avatar {
          .shaka-player {
            > video {
              left: 0vw; // cesShowMode 0vw
            }
          }
        }
      }
    }
  }
  &.portrait73 {
    .playContainer {
      .avatar {
        width: 70%;
        .shaka-player {
          position: absolute;
          top: 0;
          left: 0;
          .size(@w:100%, @h: 100%);
          > video {
            position: absolute;
            object-fit: contain;
            width: 70vw;
            left: 0vw;
          }
        }
      }
      .user {
        width: 30%;
        margin-top: 15vh;
        > div {
          height: 70%;
        }
      }
    }
  }
  // landscape
  &.landscape {
    .playContainer {
      .avatar {
        .shaka-player {
          > video {
            object-fit: contain;
          }
        }
      }
      .user {
        height: 50vh;
        margin-top: 25vh;
      }
    }
  }
  &.fullscreen {
    .avatar {
      width: 100% !important;
    }
  }
}

.forceMsg {
  transform: translateX(-50%) translateY(150px) !important;
}

.degreeContainer {
  position: absolute;
  right: 70px;
  bottom: 176px;
  width: 263px;
  height: 80px;
  z-index: 0;
  border-radius: 16px;
  background: rgba(134,121,109, 0.80);
  line-height: 80px;
  font-size: 38px;
  color: @COLOR_GRAY01;
  text-align: center;
  > span {
    display: inline-block;
    margin-right: 5px;
    width: 44px;
    height: 44px;
    vertical-align: sub;
    background-image: url("../../../../assets/icon/angle_icon.png");
    background-repeat: no-repeat;
    background-size: contain;
  }
  &.maxdegree {
    bottom: 270px;
    height: 140px;
    line-height: normal;
    background: rgba(33, 33, 32, 0.80);
    padding: 20px 0;
    .gradeWrapper{
      width: 100%;
      position: relative;
      display: inline-flex;
      justify-content: center;
      .userTryCount{
        font-family: "LGSmartUIR";
        font-size: 22px;
        background: rgba(134,121,109, 1.0);
        border-radius: 28px;
        margin-left: 10px;
        margin-bottom: 0px;
        width: 30px;
        height: 30px;
        line-height: 28px;
        margin-top: 4px;
      }
      .subGrade{
        font-family: "LGSmartUIR";
        padding: 4px 14px;
        width: fit-content;
        height: 28px;
        line-height: 28px;
        border-radius: 40px;
        font-size: 24px;
        margin-bottom: 10px;
        margin-left: 10px;
        margin-bottom: 10px;
        &.danger{ // Try more
          // background-color: @COLOR_GRAY01;
          background-color: rgba(236, 236, 6, 1.0);
          color: @COLOR_GRAY08;
        }
        &.good{ // Good
          background-color: #44A280;
        }
      }
    }
    .subTitle{
      font-family: "LGSmartUIR";
      font-size: 24px;
      margin-top: 20px;
    }
  }
}

.aiCoachingGuidePopup {
  text-align: center;
  font-family: "LGSmartUIR";
  margin-bottom: 0 !important;
  width: 100%;
  height: 100%;
  > div > div {
    height: 100% !important;
    > div {
      max-width: unset !important;
      height: 100% !important;
      max-height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .container {
    display: flex;
    font-size: 40px;
    flex-direction: column;
    justify-content: flex-end;
    row-gap: 50px;
    align-items: center;
    > img {
      width: 400px;
    }
    .startBtn{
      margin-top: 105px;
      margin-bottom: 125px;
    }
  }
}
@__DEV__: true;