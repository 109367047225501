@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

//상단 가이드 Tit=====================================================
.guideTitWrap {
  position: relative;
  z-index: 500;
  .flex(@display: flex, @justifyCenter: flex-end, @alignCenter: center);
  .size(@w: null, @h: 50px);
  line-height: 60px;
  padding: 30px 15px;
  .guideTit {
    .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
    .size(@w: 90%, @h: null);
    span {
      padding: 0 20px;
      box-sizing: border-box;
      color: #36383b;
      background-color: #cadcdd;
      border-radius: 25px;
    }
  }
}
//End :: guideTitWrap=================================================

.container {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;

  .stepperHorizon{
    // position: absolute;
    // top: 67px;
    // margin-left: 50%;
    // transform: translateX(-50%);
    // width: 1454px;
    z-index: 100;
  }

  .cl_bgVideoType {
    z-index: 0;
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .size(@w: 100%, @h: 100%);

    section {
      display: flex;
      z-index: -1;
      .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
      .size(100%, 100%);

      >div {
        position: absolute;
        .size (@w : null, @h: 100%);
        // top: 0;
        transform: translateY(0);
      }

      .avatar {
        position: relative;
        // padding-bottom: 56.25%;
        .size(@w:50%, @h: null);
        .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
        background-color: none;

        .shaka-player {
          position: absolute;
          top: 0;
          left: 0;
          .size(@w:100%, @h: 100%);
          >video{
            position: absolute;
            width: 75vw;
            left: -25%;
          }
        }
        .counterContainer {
          position: absolute;
          bottom: 188px;
          left: 52px;
          z-index: 100;
          width: 210px;
          height: 210px;
          .flex(@direction: column, @justifyCenter: space-between);
        }
        // .avatarInner {
        // position: absolute;
        // top: 0;
        // left: 0;
        // width: 100%;
        // height: 100%;

        //   & > div {
        //     width: 100%;
        //   }
        // }
        .labelContainer {
          position: absolute;
          top: 200px;
          left: 0;
          width: 100%;
          height: 60px;

          .label {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.08);
            .font(@fontFamily: "LGSmartUIB", @fontSize: 30px, @color: @COLOR_GRAY06, @fontWeight: 700);
            left: 52px;
            &.isRight {
              left: auto;
              right: 52px;
            }
          }
        }
      }
      .user {
        position: relative;
        overflow: hidden;
        right: 0;
        .size(@w:50%, @h: 100%);

        // img {
        //   .position(@position: absolute, @top: 0, @right: 0, @bottom: null, @left: null);
        //   display: block;
        //   .size(@w: 100%, @h: 100%);
        // }

      }
    }
  }

  .instructionSign {
    position: absolute;
    color: #222222;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    border-radius: 55px;
    padding: 13px 25px;
    font-family: LGSmartUI;
    margin-top: 25px;
    left: 50%;
    transform: translate(-50%);
  }

  .testType {
    // opacity: 1;
    .font(@fontFamily: "LGSmartUIB", @fontSize: 140px, @color: #FFFFFF, @fontWeight: 700);
    text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
    text-align: center;
    position: absolute;
    font-size: 100px;
    color: #fff;
    font-family: LGSmartUI;
    height: 164px;
    line-height: normal;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // animation: hideFrontSign 0.5s linear 2s;
    // animation-fill-mode: forwards;
  }

  .pauseBtn {
    position: absolute;
    right: 52px;
    bottom: 75px;
    z-index: 100;
  }
}

@keyframes hideFrontSign {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.boundingBox {
  position: absolute;
  box-shadow: 0 0 0 2px #fff inset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

.takePicture {
  scale: 1;
  background-color: white;
  &:focus {
    scale: 1.3;
    background-color: orange;
  }
}

@keyframes bellyPoint {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.bellyPoint {
  width: 1.875rem;
  height: 1.875rem;
  margin-left: -0.9375rem;
  margin-top: -0.9375rem;
  position: absolute;
  z-index: 100;
  transform: scale(1);
  animation: bellyPoint linear infinite 0.8s;
  -webkit-animation: bellyPoint linear infinite 0.8s;
  transition-property: left, top;
  transition-duration: 100ms;
  transition-timing-function: linear;
  &.hide{
    opacity: 0;
  }
}

.scanBox{
  position: fixed;
  right: 52px;
  bottom: 75px;
  z-index: 100;
  .skipBtn{
    position: relative;
    width: 263px;
    height: 81px;
    line-height: 81px;
    background-color: rgba(33, 33, 32, 0.70) !important;
    color: @COLOR_GRAY01;
  }
}
.videoSkip{
  position: fixed;
  left: 52px;
  bottom: 75px;
  z-index: 100;
  .skipBtn{
    position: relative;
    width: 263px;
    height: 81px;
    line-height: 81px;
    background-color: rgba(33, 33, 32, 0.70) !important;
    color: @COLOR_GRAY01;
    // >div>span{
    //   display: inline-block;
    //   width: 29px;
    //   height: 29px;
    //   margin: 0 10px;
    //   vertical-align: middle;
    //   background-image: url('../../../../../assets/icon/skip_btn.png');
    //   background-repeat: no-repeat;
    // }
    // &:hover, &:focus{
    //   >div>span{
    //     background-image: url('../../../../../assets/icon/skip_btn_revert.png');
    //   }
    // }
  }
}

.hide{
  opacity: 0;
}
@__DEV__: true;