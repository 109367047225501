@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_WIDTH: 253px;
@ITEM_HEIGHT: 322px;

.item {
  position: relative;
  width: @ITEM_WIDTH;
  height: @ITEM_HEIGHT;
  border-radius: 23px;
  background: #96887C;

  .title {
    .position(@position: absolute, @top: 24px, @right: unset, @bottom: unset, @left: 24px);
    font-family: 'LGSmartUIB'; //font-weight: 700;
    color: #FEFEFE;
    font-size: 30px;
    height: 35px;
    line-height: 35px;
    z-index: 1;
    
  }
  .image {
    .size(@w: @ITEM_WIDTH, @h: @ITEM_HEIGHT);
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:focus {
    transition: all 0.3s ease;
    transform: scale(1.08);
    box-shadow: @MAIN_BOX_SHADOW;
  }

  &.pressed::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: inherit;
    opacity: 0.3;
  }
}




@__DEV__: true;