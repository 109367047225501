@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.container {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background: #000;
  z-index: 1;
  .stepper{
    position: absolute;
    top: 67px;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 1454px;
  }
  .workoutTitleContainer {
    font-family: 'LGSmartUIB';
    .position(@position: absolute, @top:36%, @right: null, @bottom: null, @left: 50%);
    z-index: 5;
    transform: translateX(-50%);
    .flex(@justifyCenter:space-between, @direction: column);
    height: 215px;
    padding: auto;
    text-align:center;
    .testNo{
      display: inline-flex;
      padding: 12px 28px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 60px;
      background: @COLOR_GRAY01;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
      color: @COLOR_GRAY08;
      text-align: center;
      font-size: 36px;
      margin-bottom: 30px;
    }
    .noDisplay{
      display: none;
    }
    .guideText{
      text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
      font-size: 140px;
      line-height: 140px;
    }
  }
  section{
    display: flex;
    z-index: -1;
    .size(@w:100%, @h: 100%);
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .avatar {
      position: absolute;
      .size(@w:50%, @h: 100%);
      .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
      background-color: none;
      .avatarImg{
        width: 100%;
        height: 100%;
        position: absolute;
      }
      .shaka-player {
        position: absolute;
        top: 0;
        left: 0;
        .size(@w:100%, @h: 100%);
        >video{
          position: absolute;
          width: 75vw;
          background-color: black;
        }
      }
      .miniFootCorrectionSign, .matchedMiniFootSign{
        position: absolute;
        left: 52px;
        bottom: 176px;
        width: 316px;
        height: 175px;
        background-image: url(../../../../../assets/bodyBalance/img_test_camera_on_foot_mat.png);
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 11;
        .leftFoot, .rightFoot{
          position: absolute;
          top: 30%;
          width: 29px;
        }
        .leftFoot{
          left: 22%;
        }
        .rightFoot{
          right: 22%;
        }
      }
      .matchedMiniFootSign{
        background-image: url(../../../../../assets/bodyBalance/img_test_camera_on_foot_mat_match.png);
      }
      .labelContainer {
        position: absolute;
        top: 200px;
        left: 0;
        width: 100%;
        height: 60px;
        z-index: 10;

        .label {
          position: absolute;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.08);
          .font(@fontFamily: "LGSmartUIB", @fontSize: 30px, @color: @COLOR_GRAY06, @fontWeight: 700);
          left: 52px;
          &.isRight {
            left: auto;
            right: 52px;
          }
        }
      }
    }
    .user {
      position: absolute;
      overflow: hidden;
      right: 0;
      background-color: #363533;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      .size(@w:50%, @h: 100%);
      .realFootprint{
        position: absolute;
        width: 772px;
        height: 428px;
        margin: auto;
        border-radius: 32px;
        border : 3px solid @COLOR_GRAY04;
        z-index: 0;
        &::before, &::after{
          content: "";
          position: absolute;
          top: 56px;
          width: 113px;
          height: 269px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
        &::before{
          left: 154px;
          background-image: url('../../../../../assets/bodyBalance/ic_foot_guide_left.png');
        }
        &::after{
          right: 154px;
          background-image: url('../../../../../assets/bodyBalance/ic_foot_guide_right.png');
        }
        >span{
          display: inline-block;
          position: absolute;
          left: 50%;
          width: 2px;
          height: 380px;
          border-left: 2px solid transparent;
          border-image: repeating-linear-gradient(to bottom, @COLOR_GRAY04, @COLOR_GRAY04 4px, transparent 4px, transparent 8px);
          opacity: 0.5;
        }
      }
      .point{
        position: absolute;
        z-index: 100;
      }
    }
  }

  .retryTestContainer{
    position: absolute;
    left: 52px;
    bottom: 75px;
    z-index: 100;
    .retryBtn{
      width: 314px;
      height: 81px;
      line-height: 81px;
      font-size: 28px;
      color: @COLOR_GRAY01;
      background: rgba(33, 33, 32, 0.70) !important;
    }
  }
  .videoSkip, .changePosition{
    position: fixed;
    left: 386px;
    bottom: 75px;
    z-index: 100;
    .skipBtn{
      position: relative;
      width: 263px;
      height: 81px;
      line-height: 81px;
      background-color: rgba(33, 33, 32, 0.70) !important;
      color: @COLOR_GRAY01;
      // >div>span{
      //   display: inline-block;
      //   width: 29px;
      //   height: 29px;
      //   margin: 0 10px;
      //   vertical-align: middle;
      //   background-image: url('../../../../../assets/icon/skip_btn.png');
      //   background-repeat: no-repeat;
      // }
      // &:hover, &:focus{
      //   >div>span{
      //     background-image: url('../../../../../assets/icon/skip_btn_revert.png');
      //   }
      // }
    }
  }
  .changePosition{
    left: 52px;
  }
  .testSkip{
    position: fixed;
    right: 52px;
    bottom: 75px;
    z-index: 100;
    .skipBtn{
      position: relative;
      width: 263px;
      height: 81px;
      line-height: 81px;
      background-color: rgba(33, 33, 32, 0.70) !important;
      color: @COLOR_GRAY01;
      // >div>span{
      //   display: inline-block;
      //   width: 29px;
      //   height: 29px;
      //   margin: 0 10px;
      //   vertical-align: middle;
      //   background-image: url('../../../../../assets/icon/skip_btn_revert.png');
      //   background-repeat: no-repeat;
      // }
      // &:hover, &:focus{
      //   >div>span{
      //     background-image: url('../../../../../assets/icon/skip_btn_revert.png');
      //   }
      // }
    }
  }

  .pauseBtn {
    position: absolute;
    right: 52px;
    bottom: 75px;
    z-index: 100;
  }
  .movePosition{
    position: absolute;
    right: 335px;
    bottom: 75px;
    z-index: 100;
  }
}

.hide {
  opacity: 0;
}
@__DEV__: true;