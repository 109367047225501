@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_WIDTH: 253px;
@ITEM_HEIGHT: 322px;
@ITEM_GAP: 20px;
@ITEM_TOP_BOTTOM_PADDING: 28px;
@ITEM_LEFT_RIGHT_PADDING: 115px;

.physicalTest {
  height: auto;
}

.scroller {
    width: 100%;
}
.container {
    width: ~"calc(@{ITEM_WIDTH} * 6 + @{ITEM_GAP} * 4 )";
    gap: @ITEM_GAP;
    display: flex;
    padding: @ITEM_TOP_BOTTOM_PADDING @ITEM_LEFT_RIGHT_PADDING;
}

.title {    
    padding: 0 @ITEM_LEFT_RIGHT_PADDING;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    color: #000;
    font-size: 36px;
    height: 42px;
    line-height: 42px;
}
@__DEV__: true;