@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.panel {
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/inHomeOutdoorActivity/img_walk_bg.png");
  background-repeat: no-repeat;
  background-position: top;

  &.contentPanel {
    background-image: unset;
    background-color: @BG_COLOR_04;
  }

  .tScroller {
    height: calc(100% - @fitTv-tHeader-height);
  }

  .tBody {
    padding: 0;
    height: 100%;

    .banner {
      margin: 0 @fitTv-tBody-padding-leftright 60px;

      height: 217px;
      background: @BG_COLOR_03;
      border-radius: 31px;
      color: black;
      font-size: 28px;
      font-family: 'LGSmartUISB';
      opacity: 0.50;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .contentContainer {
      padding: 48px 0 75px;
      min-height: calc(100% - @fitTv-tHeader-height);

      .themeContainer {
        margin-left: 75px;
        margin-bottom: 33px;
      }

      .listContainer {
        .listTitle {
          font-size: 34px;
          font-family: 'LGSmartUIB';
          margin-bottom: 28px;
          margin-left: 75px;
          color: @COLOR_GRAY08;
        }
        &:not(:last-child) {
          margin-bottom: 48px;
        }
      }
    }
  }
}

@__DEV__: true;