@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

[id="floatLayer"] {
  //common layout
  > div:not([id]) > div:nth-child(2) {
    bottom: 50%;
    transform: translateY(50%);
  }
}

.tPopUp {
  //enact popup reset
  margin-bottom: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  > div {
    padding: 0 !important;
    > div > div {
      width: unset !important;
      margin: 0 !important;
    }
  }
}

.default-style() {
  .info {
    background: @COLOR_GRAY01;
    color: @COLOR_GRAY07;
    display: flex;
    flex-direction: column;
    padding: 60px 40px;
    border-radius: 30px;
    font-family: "LGSmartUIR";

    // &:focus {
    //   background-color: darkgray !important;
    //   box-shadow: 0 0 0 4px @fitTv-spotlight-color inset;
    //   .title {
    //     color: @fitTv-spotlight-text-color;
    //   }
    // }
    &.isDark {
      background: @COLOR_GRAY08 !important;
      color: @COLOR_GRAY01 !important;
    }
  }
  .textLayer {
    width: 100%;
    row-gap: 20px;

    .title {
      text-align: center;
      font-size: 39px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .text {
      max-height: 100%;
      height: auto;
      font-size: 27px;
      // margin-bottom: 28px;
      text-align: center;
    }

    .subText {
      font-size: 26px;
      color: @COLOR_GRAY05;
    }
  }

  img {
    width: 465px;
    height: auto;
  }

  .buttonLayer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 52px;
    width: 100%;
  }
}

.calendarPopUp {
  .default-style();
  .title {
    font-size: 40px;
    margin-bottom: 48px;
  }
  .buttonLayer {
    margin-top: 50px;
  }
}

.textPopUp {
  .default-style();
  .textLayer {
    display: flex;
    flex-direction: column;
    ol {
      margin: 0px;
    }
    .subText {
      font-size: 30px;
      font-weight: 600;
      color: @COLOR_GRAY08;
      margin-bottom: 29px;
    }
    li {
      &.subText{
        list-style: auto;
      }
    }
  }
  .buttonLayer {
    justify-content: center;
    gap: 16px;
    .button1,
    .button2 {
      max-width: none;
    }
  }
}

.imagePopUp {
  .default-style();
  .info {
    align-items: unset;
  }
  .title {
    margin-bottom: 20px;
  }
  .text {
    margin-bottom: 48px;
  }
}

.smartMatPopUp {
  .default-style();
  width: 1005px !important;
  .title {
    margin-bottom: 20px !important;
    font-size: 39px !important;
  }
  .text {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    font-size: 27px !important;
  }
  .text1 {
    font-size: 27px !important;
    margin-top: 0px !important;
  }
  .buttonLayer {
    margin-top: 50px;
    width: 75%;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  img {
    margin-top: 32px;
    width: 925px;
    height: 358px;
  }
}

.connectPopup {
  .default-style();
  font-family: "LGSmartUIR";
  .connectedText {
    margin-top: 20px;
    font-size: 24px !important;
  }
}

.testSelectPopup {
  .default-style();

  .buttonLayer {
    margin-top: 50px;
    justify-content: center;
    gap: 16px;
    .button1,
    .button2 {
      max-width: none;
      width: 304px;
      height: 87px;
      line-height: 87px;
    }
  }
}

.checkArea {
  margin-top: 33px;
  .check {
    font-size: 28px;
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      width: 36px;
      height: 36px;
      background: url(../../../assets/icon/unChked_icon.svg) no-repeat 0 0;
      background-size: 36px;
      margin-right: 12px;
      vertical-align: bottom;
    }
    &.onCheck {
      &:before {
        background: url(../../../assets/icon/checked_icon.svg) no-repeat 0 0;
      }
    }
    &:focus {
      &:before {
        // background: url(../../../assets/icon/checked_icon.svg) no-repeat 0 0;
        transition: all 200ms ease;
        transform: scale(1.08);
      }
    }
  }
}

.starratingPopup {
  .default-style();
  .title {
    margin-bottom: 24px;
  }
  .text {
    margin-bottom: 24px;
  }
  .buttonLayer {
    margin-top: 50px;
  }
}
@__DEV__: true;