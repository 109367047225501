
@import '../../../style/CommonStyle.module.less';

.container {
  padding: 52px 75px 60px;

  .scroller {
    height: 593px;
    color: black;
  }

  .dateWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
  }

  .filterSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 28px;
  }

  .contentContainer {
    color: @COLOR_GRAY02;
    background-color: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.10);
    font-family: "LGSmartUIR";
    margin-bottom: 28px;
    border-radius: 20px;
    padding: 40px 32px 47px 40px;

    .dateContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-family: "LGSmartUIB";
      font-size: 36px;
      margin-bottom: 32px;

      .dateText {
        font-weight: bold;
      }
      .datailBtn {
        padding: 0 20px;

        > div {
          // custom
          display: flex;
          align-items: center;
        }

        .icon {
          width: 28px;
          height: 28px;
          margin-left: 4px;
          background-image: url("../../../../assets/inHomeOutdoorActivity/ic_right_28_dark.svg");
        }
        &:focus {
          .icon {
            background-image: url("../../../../assets/inHomeOutdoorActivity/ic_right_28_gray.svg");
          }
        }
      }
    }

    .mainContainer {
      display: flex;

      .mainTextContainer {
        display: inline-flex;
        width: 100%;
        height: auto;
      }
      .imgBox {
        width: 480px;
        height: 269px;
        margin-right: 40px;
      }
      .textBox {
        margin-right: 120px;
        width: 737px;

        .title {
          color: @COLOR_GRAY01;
          font-family: "LGSmartUISB";
          font-size: 36px;
          line-height: 39px;
          margin-bottom: 16px;
          line-height: normal;
        }
        .subTitle {
          color: @COLOR_GRAY04;
          text-align: justify;
          font-size: 27px;
          margin-bottom: 32px;
          line-height: normal;
        }
      }
      .scoreBox {
        display: flex;
        margin-bottom: 12px;
        .score {
          padding: 4px 16px;
          border-radius: 4px;
          background: @COLOR_GRAY07;
          line-height: 40px;
          text-align: center;
          font-family: "LGSmartUIB";
          font-size: 27px;
          margin: 0 16px auto 0;
          &.red {
            color: #F44B4A;
          }
          &.yellow {
            color: #F9B811;
          }
        }
        .scoreText {
          color: @COLOR_GRAY03;
          font-size: 27px;
          line-height: 40px;
        }
      }
      .totalScore {
        text-align: center;
        align-self: center;

        .totalScoreNumber {
          color: @PRIMARY_COLOR_BROWN01;
          font-family: "LGSmartUIB";
          text-align: center;
          font-size: 66px;
          line-height: normal;
          margin-bottom: 12px;
        }
        .totalScoreText {
          color: @COLOR_GRAY01;
          font-size: 27px;
          font-family: "LGSmartUISB";
          line-height: 52px;
          border-radius: 50px;
          background-color: @PRIMARY_COLOR_BROWN03;
          padding: 10px 20px;
        }
      }
    }
  }
}

@__DEV__: true;