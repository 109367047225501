@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.tabLayout {
  position: relative;
  width: 1664px;
  height: 695px;
  background-color: white;
  border-radius: 0 0 12px 12px;
}

.top {
  padding-top: 0;
}

.medicalCenter {
  position: absolute;
  width: 181px;
  height: 38px;
  right: 92px;
  bottom: 18px;
  background-image: url("../../../../../assets/images/medicalCenterMark.svg");
}

@__DEV__: true;