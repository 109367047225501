@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.container {
  font-family: "LGSmartUIB";
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  background: #363533;
  z-index: 1;
  // .stepperHorizon{
  //   // position: absolute;
  //   // top: 67px;
  //   // margin-left: 50%;
  //   // transform: translateX(-50%);
  //   // width: 1454px;
  // }
  .stepperTitle{
    position: absolute;
    top: 150px;
    left: 3%;
    color: black;
  }
  .stepperVertical{
    position: absolute;
    top: 200px;
    left: 3%;
    // height: 35% !important;
  }
  .workoutTitleContainer {
    .position(@position: absolute, @top:36%, @right: null, @bottom: null, @left: 50%);
    z-index: 5;
    transform: translateX(-50%);
    .flex(@justifyCenter:space-between, @direction: column);
    height: 215px;
    padding: auto;
    text-align:center;
    .testNo{
      display: inline-flex;
      padding: 12px 28px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 60px;
      background: @COLOR_GRAY01;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
      color: @COLOR_GRAY08;
      text-align: center;
      font-size: 36px;
      margin-bottom: 30px;
    }
    .guideText{
      text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
      font-size: 140px;
      line-height: 140px;
    }
  }
  section{
    display: flex;
    z-index: -1;
    .size(@w:100%, @h: 100%);
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .avatar {
      position: absolute;
      .size(@w:50%, @h: 100%);
      .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
      background-color: none;
      .avatarImg{
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: #000;
      }
      .shaka-player {
        position: absolute;
        top: 0;
        left: 0;
        .size(@w:100%, @h: 100%);
      }
      .counterContainer {
        position: absolute;
        bottom: 188px;
        left: 52px;
        z-index: 100;
        .flex(@direction: column, @justifyCenter: space-between);
      }
      .degreeContainer{
        position: absolute;
        left: 645px;
        bottom: 176px;
        width: 263px;
        height: 80px;
        z-index: 110;
        border-radius: 16px;
        background: rgba(33, 33, 32, 0.80);
        line-height: 80px;
        font-size: 38px;
        color: @COLOR_GRAY01;
        text-align: center;
        >span{
          display: inline-block;
          margin-right: 5px;
          width: 44px;
          height: 44px;
          vertical-align: sub;
          background-image: url("../../../../../assets/icon/angle_icon.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .labelContainer {
        position: absolute;
        top: 200px;
        left: 0;
        width: 100%;
        height: 60px;

        .label {
          position: absolute;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.08);
          .font(@fontFamily: "LGSmartUIB", @fontSize: 30px, @color: @COLOR_GRAY06, @fontWeight: 700);
          left: 52px;
          &.isRight {
            left: auto;
            right: 52px;
          }
        }
      }
    }
    .user {
      position: absolute;
      overflow: hidden;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #000;
      z-index: 90;
      .size(@w:50%, @h: 100%);
      .point{
        position: absolute;
        z-index: 110;
      }
      .realAngleContainer{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        right: 52px;
        bottom: 176px;
        width: 263px;
        height: 122px;
        z-index: 110;
        border-radius: 16px;
        background: rgba(33, 33, 32, 0.80);
        font-size: 38px;
        color: @COLOR_GRAY01;
        text-align: center;
        .subTitle{
          font-size: 24px;
        }
        div>span{
          display: inline-block;
          margin-right: 5px;
          width: 44px;
          height: 44px;
          vertical-align: sub;
          background-image: url("../../../../../assets/icon/angle_icon.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .averageRomScore{
        position:absolute;
        bottom: 550px;
        right: 320px;
        &.knee{
          bottom: 365px;
          right: 320px;
        }
        .grade{
          position: absolute;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .flexBox{
            margin-top: 20px;
            display: flex;
            // font-size: 26px;
            width: 227px;
            height: 130px;
            padding: 18px;
            border-radius: 16px;
            background: rgba(33, 33, 32, 0.8);
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            .subGrade{
              padding: 4px 14px;
              // width: 40px;
              height: 28px;
              line-height: 28px;
              border-radius: 40px;
              font-size: 24px;
            }
            .danger{ // 위험
              background-color: #F63C23;
            }
            .manage{ // 관리
              background-color: #FFBD3E;
            }
            .prevention{ // 예방
              background-color: #44A280;
            }
            .title{
              font-family: "LGSmartUIR";
              font-size: 24px;
            }
            .info {
              font-family: "LGSmartUIB";
              display: flex;
              align-items: center;
              .value{
                font-size: 36px;
                >span {
                  display: inline-block;
                  margin-right: 5px;
                  width: 44px;
                  height: 44px;
                  vertical-align: sub;
                  background-image: url("../../../../../assets/icon/angle_icon.png");
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-image: url("../../../../../assets/icon/angle_icon.png");
                }
              }
            }
          }
          // &:after{
          //   content: "";
          //   position: absolute;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          //   width: 100%;
          //   height: 1px;
          //   background: #AFAFAF;
          // }
          // &.knee{
          //   line-height: 90px;
          //   &:after{
          //     opacity: 0;
          //   }
          // }
        }
                  
        >div>span{
          display: inline-block;
          margin-right: 5px;
          width: 44px;
          height: 44px;
          vertical-align: sub;
          background-image: url("../../../../../assets/icon/angle_icon.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }
}


.pauseBtn {
  position: absolute;
  right: 335px;
  bottom: 75px;
  z-index: 100;
}
.movePosition{
  right: 52px;
  bottom: 75px;
}
.retryTestContainer{
  position: absolute;
  left: 52px;
  bottom: 75px;
  z-index: 100;
  .retryBtn{
    width: 314px;
    height: 81px;
    line-height: 81px;
    font-size: 28px;
    color: @COLOR_GRAY01;
    background: rgba(33, 33, 32, 0.70) !important;
  }
}
.testSkip{
  position: fixed;
  right: 52px;
  bottom: 75px;
  z-index: 100;
  .skipBtn{
    position: relative;
    width: 263px;
    height: 81px;
    line-height: 81px;
    background-color: rgba(33, 33, 32, 0.70) !important;
    color: @COLOR_GRAY01;
    // >div>span{
    //   display: inline-block;
    //   width: 29px;
    //   height: 29px;
    //   margin: 0 10px;
    //   vertical-align: middle;
    //   background-image: url('../../../../../assets/icon/skip_btn_revert.png');
    //   background-repeat: no-repeat;
    // }
    // &:hover, &:focus{
    //   >div>span{
    //     background-image: url('../../../../../assets/icon/skip_btn_revert.png');
    //   }
    // }
  }
}


@keyframes bellyPoint {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.bellyPoint {
  width: 1.875rem;
  height: 1.875rem;
  margin-left: -0.9375rem;
  margin-top: -0.9375rem;
  position: absolute;
  z-index: 110;
  transform: scale(1);
  animation: bellyPoint linear infinite 0.8s;
  -webkit-animation: bellyPoint linear infinite 0.8s;
  transition-property: left, top;
  transition-duration: 100ms;
  transition-timing-function: linear;
  &.hide{
    opacity: 0;
  }
}

.hide{
  opacity: 0;
}
@__DEV__: true;