@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";


.tMediaItem{
    height: auto;
    background-color:bisque;

    .image{
        width: 100%;
        object-fit: cover;
    }
    &.large .image{
        height: 236px;
    }
    &.medium .image{
        height: 200px;
    }
    &.small .image{
        height: 130px;
    }
    .title{
        font-size: 30px;
        color: black;
        margin-left: 10px;
        margin-right: 10px;
    }
    .subTitle{
        font-size: 20px;
    }
    .focusCover{
        position: absolute;
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        display: none;
    }
    &:focus {
        background-color: @fitTv-spotlight-color !important;
        .title{
            color: white;
        }
        .focusCover{
            display: inherit;
            background-color: @fitTv-spotlight-color !important;
            opacity: 0.3;
        }
    }
}
@__DEV__: true;