
@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_WIDTH: 432px;
@ITEM_HEIGHT: 320px;

.infoSection {
  position: relative;
  .size(@w: @ITEM_WIDTH, @h: @ITEM_HEIGHT);
  border-radius: 18px;
  transition: transform 300ms ease;
  .text {
    .position(@position: absolute, @top: 38px, @right: unset, @bottom: unset, @left: 38px);
    font-size: 36px;
    height: 47px; 
    line-height: 47px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
  }

  .date {
    .position(@position: absolute, @top: 164px, @right: unset, @bottom: unset, @left: 40px);
    font-size: 27px;
    height: 32px; 
    line-height: 32px;
    color: #DBD7D4;
    font-family: 'LGSmartUISB'; //font-weight: 600;
  }

  &::after { // background image
    content: "";
    .position(@position: absolute, @top: 0, @right: 0, @bottom: unset, @left: unset);
    .size(@w: 180px, @h: 177px);
    background-image: url("../../../../assets/report/img_report_gate_category_bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    transform: scale(1.08);
  }
  
  &.pressed::before {
    content: "";
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .size(@w: 100%, @h: 100%);
    background-color: #000;
    border-radius: inherit;
    opacity: 0.3;
  }

  &.bodyalignment{
    background-color: #2D628B;
  }
  &.rom{
    background-color: #599F9A;
  }
  &.bodybalance{
    background-color: #E49F52;
  }
  &.physicaltest{
    background-color: #DB6C94;
  }
}
@__DEV__: true;