@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.body{
  display: flex;
  .logcontainer{
    width: 50%;
    color: black;
  }
  .workerscontainer{
    width: 50%;
    color: black;
  }
}
@__DEV__: true;