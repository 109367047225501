@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.bleTestFlex{
  display: flex;
  justify-content: space-between;
}
.bleTestFlexItem{
  flex-basis: 50%;
}

.bleList {
  margin-top: 20px;
  color: #000;
}
.connectSuccess{
  color: black;
  margin-top: 50px;
  margin-left: 20px;
  font-size: 30px;
}
.connectFailure{
  color: red;
  margin-top: 50px;
  margin-left: 20px;
  font-size: 30px;
}
.matData{
  position: absolute;
  bottom: 80px;
  left: 350px;
  text-align: center;
  margin-top: 10px;
  padding: 10px 0;
  width: 500px;
  height: 330px;
  border-radius: 0.83333rem;
  border-color: #000;
  color: black;
  border: 1px solid black;
  >div{
    padding: 5px 0;
  }
  .byteData{
    position:relative;
    display: inline-block;
    text-align: left;
    padding: 10px;
    >div{
      position: relative;
      display: inline-block;
    }
  }
}
.infoBox{
  display: flex;
  color: black;
  padding: 30px 20px 0;
}

.playerBox {
  margin-top: 30px;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .shaka-player {
    width: auto;
    height: 500px;
  }
  .videoSelect{
    margin-top:20px;
    .tDropDown{
      margin: 0;
    }
  }
  .buttonContainer {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
@__DEV__: true;