@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";
@SCALE: 1.08;
@BODYALIGNMENT_ANISIZE: 700px;
@ROM_ANISIZE: 260px;
@BODYBALANCE_ANISIZE: 366px;
@PHYSICALTEST_ANISIZE: 366px;
.main {
    position: relative;
    background-repeat: no-repeat;
    background-position: center center;
    transform-origin: right bottom;
    transition: all 0.6s;
    &.focused{
        transform : scale(@SCALE);
        z-index: 1;
    }
    .imageContainer{
        position: absolute;
        transform-origin: center bottom;
        transition: transform 300ms ease;
    }
}
.bodyalignment{
    .size(@w: 615px, @h: 605px);
    background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_alignment_bg_nor.png");
    background-size: 615px 605px;
    margin-left: 75px;
    &.focused{
        //확대 되면서 우측 사선부분 보정 필요함.
        transform-origin: 480px bottom;
    }
    &.pressed{
        background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_alignment_bg_pre.png");
    }
    .imageContainer{
        right: 27px;
        bottom: 0;
        height: @BODYALIGNMENT_ANISIZE;
        z-index: 2;
        transition: transform 1s ease;
    }
    .prepare{
        position: absolute;
    background-image: url("../../../../assets/icon/camera_black.png");
        .size(@w: 44px, @h:44px);
        background-size: 44px 44px;
        left: 278px;
    bottom: 20px;
        z-index: 3;
    }
}
.rom,
.bodybalance,
.physicaltest{
    .size(@w: 455px, @h: 605px);
    background-size: 455px 605px;
    margin-left: -120px;
    transform-origin: left bottom;
    &.focused{
        margin-right: ~"calc((605px * @{SCALE} - 605px)/2)";
    }
    .prepare{
        position: absolute;
    background-image: url("../../../../assets/icon/camera_black.png");
        .size(@w: 44px, @h:44px);
        background-size: 44px 44px;
        left: 39px;
        bottom: 20px;
    }
}

.rom{
    // background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_nor.png");
    // &.pressed{
    //     background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_rom_bg_pre.png");
    // }
    // .imageContainer{
    //     right: 67px;
    //     bottom: 98px;
    //     height: @ROM_ANISIZE;
    // }
}
.bodybalance{
    background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_balance_bg_nor.png");
    &.pressed{
        background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_balance_bg_pre.png");
    }
    .imageContainer{
        right: 13px;
        bottom: 0;
        height: @BODYBALANCE_ANISIZE;
    }
    .prepare{
    .size(@w: 44px, @h:44px);
    background-size: 44px 44px;
    background-image: url("../../../../assets/icon/mat_black.png");
    }
}
.physicaltest{
    background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_physical_bg_nor.png");
    &.pressed{
        background-image: url("../../../../assets/bodycheckup/mainSections/img_bodycheck_gate_physical_bg_pre.png");
    }
    .imageContainer{
        right: 23px;
        bottom: 0;
        height: @PHYSICALTEST_ANISIZE;
    }
  .sectionText {
    width: 200px;
    left: 198px;
  }
}

.bodyAlignmentText {
    .position(@position: absolute, @top: 90px, @right: inherit, @bottom: inherit, @left: 170px);
  width: 230px;
    z-index: 2;
    .title{
        width: 100%;
        line-height: 47px;
        .font(@fontFamily: "LGSmartUIB", @fontSize: 39px, @color: #FEFEFE, @fontWeight: 700 );
        word-break: keep-all;

    }
    .subtext{
        width: 100%;
        margin-top: 8px;
        .font(@fontFamily: 'LGSmartUI', @fontSize: 22px, @color: #FEFEFE, @fontWeight: 500 );
        line-height: 26px;
        word-break: keep-all;
    }
}
.sectionText {
    .position(@position: absolute, @top: 90px, @right: inherit, @bottom: inherit, @left: 189px);
    width: 218px;
    .title{
        width: 100%;
        text-align: center;
        line-height: 47px;
        .font(@fontFamily: 'LGSmartUIB', @fontSize: 37px, @color: #FEFEFE, @fontWeight: 700 );
        word-break: keep-all;
    }
    .subtext{
        width: 100%;
        text-align: center;
        margin-top: 8px;
        .font(@fontFamily: 'LGSmartUI', @fontSize: 22px, @color: #FEFEFE, @fontWeight: 500 );
        line-height: 26px;
        word-break: keep-all;
    }
}

@__DEV__: true;