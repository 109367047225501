@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

@BAR_HEIGHT: 22px;
@MARKER_GAP: 6px;
@ARROW_HEIGHT: 8px;
@BORER_RADIUS: 37px;

.bar {
  position: relative;
  .size(@w: 100%, @h: @BAR_HEIGHT);
  border-radius: @BORER_RADIUS;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .fixedBar {
    .size(@w: 100%, @h: 100%);
    position: absolute;
    left: 0;
    border-radius: inherit;
    background-color: #494847;
  }

  .weekList {
    position: absolute;
    left: 0;
    .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center);
    width: 100%;
  }
  .weekList .item {
    position: relative;
  }

  .weekList .item .divider {
    .size(@w: 3px, @h: 22px);
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: #212120;
    z-index: 1;
  }

  .weekList .item .none {
    background-color: transparent;
  }
  
  .weekList .item .title {
    .size(@w: max-content, @h: 28px);
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    margin-top: ~"calc(@{BAR_HEIGHT} / 2 + 8px)";
    line-height: 28px;
    font-size: 24px;
    color: #B2B0AE;
    font-family: 'LGSmartUIR';
  }
  .title:first-child {
    transform: translateX(0);
  }
  .title:last-child {
    transform: translateX(-100%);
  }

  .weekList .item .complete {
    color: #FEFEFE;
  }


}



.circle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #F2EFED;
}

.myBar {
  position: absolute;
  left: 0;
  width: 33%;
  height: 100%;
  border-radius: @BORER_RADIUS 0 0 @BORER_RADIUS;
  background: linear-gradient(270deg, #B59D85 -39.74%, rgba(181, 157, 133, 0.30) 100%);
  transition: 1s ease;
  &.border {
    border-radius: @BORER_RADIUS;
  }
}
.programBar {
  position: absolute;
  left: 0;
  width: 33%;
  height: 100%;
  border-radius: @BORER_RADIUS 0 0 @BORER_RADIUS;
  // background: linear-gradient(270deg, #8A6F5E 0%, rgba(138, 111, 94, 0.30) 100%);
  background-color: @COLOR_GRAY05;
  transition: 1s ease;
  &.border {
    border-radius: @BORER_RADIUS;
  }
}


.marker {  //marker
  position: absolute;
  bottom: ~"calc(@{BAR_HEIGHT} + @{MARKER_GAP} + @{ARROW_HEIGHT})";
  right: 0;
  transform: translateX(50%);
  font-size: 27px;
  font-family: 'LGSmartUISB';
  height: 60px;
  border-radius: 68px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  > span:nth-child(1) {
    order: 1;
  }
  &::before { //border
    content: "";
    display: block;
    .size(@w: 1px, @h: 20px);
    margin: 0 10px;
    order: 2;
  }
  > span:nth-child(2) {
    order: 3;
  }

  &::after { //arrow
    content: "";
    display: block;
    position: absolute;
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    .size(@w: 22px, @h: @ARROW_HEIGHT);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.myMarker {  //my
    color: #363533;
    background-color: #F2EFED;
    &::before { //border
      background: #787776;
    }
    &::after { //arrow
      background-image: url('../../../../../../assets/Ttooltip/ic_tooltip_white.png');
    }
  }
  &.programMarker {  //program
    color: #FEFEFE;
    background-color: #494847;
    &::before { //border
      background: #787776;
    }
    &::after { //arrow
      background-image: url('../../../../../../assets/Ttooltip/ic_tooltip_darkgray.png');
    }
  }
}

.dotStart,
.dotEnd {
  position: absolute;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  z-index: 1;
}
.dotStart {
  background-color: @COLOR_GRAY02;
  left: 4px;
}
.dotEnd {
  background-color: @COLOR_GRAY07;
  right: 4px;
}

@__DEV__: true;