@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.tTimeProgressBar {
  margin: 3px !important;
  > div {
    background-color: unset !important;
    //total
    div:nth-child(1) {
      background-color: @COLOR_GRAY02 !important;
    }
    //progress
    div:nth-child(2) {
      background-color: @PRIMARY_COLOR_BROWN03 !important;
    }
  }
}
@__DEV__: true;