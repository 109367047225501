@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@WITH_LABEL: 80px;
@GRAPH_PADDING: 0 34px;
@WITH_LABEL_PADDING: 100px;

.container {
  width: 100%;
  height: 100%;

  .graphArea {
    position: relative;
    width: 100%;
    height: 380px;
    display: flex;
    align-items: flex-end;
    left: 50%;
    transform: translateX(-50%);
    &.withLabel {
      width: 95%;
      left: @WITH_LABEL;
      transform: unset;
    }

    .graphLine {
      position: absolute;
      width: 100%;
      height: 1px;
      background: repeating-linear-gradient(to right, @COLOR_GRAY03, @COLOR_GRAY03 3px, transparent 3px, transparent 6px);
      &.solid {
        background: repeating-linear-gradient(to right, @COLOR_GRAY03, @COLOR_GRAY03 3px);
      }
    }
    .yLabel {
      width: 68px;
      height: 30px;
      color: @COLOR_GRAY03;
      text-align: right;
      font-family: "LGSmartUISB";
      font-size: 24px;
      line-height: normal;
      position: absolute;
      left: -85px;
    }
    .scoreContainer {
      width: 100%;
      padding: @GRAPH_PADDING;
      max-height: 220px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 32px;
      .scoreBox {
        padding: 0 33px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .score {
          position: relative;
          width: 16px;
          height: 0%;
          background-color: @PRIMARY_COLOR_BROWN03;
          border-radius: 8px 8px 0 0;
          transition: height 1s ease-in-out;

          .scoreTextBox {
            position: absolute;
            min-width: 44px;
            height: 26px;
            padding: 4px 12px;
            border-radius: 8px;
            background-color: @PRIMARY_COLOR_BROWN01;
            top: -51px;
            left: 50%;
            transform: translateX(-50%);

            color: #ffffff;
            font-size: 22px;
            font-family: "LGSmartUIR";
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            line-height: 1.1;

            > img {
              position: absolute;
              top: 34px;
              left: 50%;
              transform: translateX(-50%);
              width: 11px;
              height: 5px;
            }
          }
        }
      }
    }
  }
  .labelArea {
    margin-top: 16px;
    padding: @GRAPH_PADDING;
    display: flex;
    justify-content: space-between;
    &.withLabel {
      padding-left: @WITH_LABEL_PADDING;
    }
    .label {
      width: 82px;
      height: 25px;
      .font(@fontFamily: "LGSmartUISB", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 600 );
      word-wrap: break-word;
      text-align: center;
      line-height: 1;
    }
  }
}

.graphContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  bottom: 0;

  .lineGraphBox {
    position: relative;
    > svg {
      g:nth-child(1) {
        stroke: @PRIMARY_COLOR_BROWN01;
        path {
          stroke: @PRIMARY_COLOR_BROWN01;
        }
      }
      g:last-child {
        stroke: @PRIMARY_COLOR_BROWN01;
        circle {
          stroke: @PRIMARY_COLOR_BROWN01;
        }
      }
    }

    .ordinator {
      position: absolute;
      top: 0;
      left: 0;
      > div {
        position: relative;

        .textBox {
          position: absolute;
          min-width: 44px;
          height: 26px;
          padding: 4px 12px;
          border-radius: 8px;
          background-color: @COLOR_GRAY06;
          top: -59px;
          left: 50%;
          transform: translateX(-50%);

          color: #ffffff;
          font-size: 21px;
          font-family: "LGSmartUISB";
          word-wrap: break-word;
          text-align: center;
          line-height: 26px;
          white-space: nowrap;

          > img {
            position: absolute;
            top: 34px;
            left: 50%;
            transform: translateX(-50%);
            width: 11px;
            height: 5px;
          }
        }
      }
    }
  }
}

.notTest {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  bottom: 0;
  color: @COLOR_GRAY04;
  font-family: "LGSmartUISB";
  font-size: 24px;
  text-align: center;
  width: 100%;
  height: 70px;
  div:first-child {
    font-size: 27px;
  }
}
@__DEV__: true;