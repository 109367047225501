@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";
@SCALE: 1.08;
@ANISIZE: 550px;
@ITEM_WIDTH: 382px;
@ITEM_HEIGHT: 653px;
@GAP: 12px;
.dummyBox {
  display: inline-block;
  &:first-child {
    padding-left: 334px;
  }
  &:last-child {
    padding-right: calc(75px - @GAP);
  }
  &:focus {
    .main {
      box-shadow: @MAIN_BOX_SHADOW;
      transform: scale(@SCALE);
      z-index: 1;
    }
  }
}
.main {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    transition: transform 0.6s linear;
    border-radius: 24px;
    .size(@w: @ITEM_WIDTH, @h: @ITEM_HEIGHT);
    background-color: @COLOR_GRAY01;
    display: inline-block;
  margin: 0 @GAP;

    > * {
        pointer-events: none;
    }
    &:focus{
        box-shadow: @MAIN_BOX_SHADOW;
        transform : scale(@SCALE);
        z-index: 1;
    }
    .imageContainer{
        position: absolute;
        transform-origin: center bottom;
        transition: transform 300ms ease;
        right: -68px;
        bottom: 230px;
        height: @ANISIZE;
    }
    .label {
        position: absolute;
        left: 32px;
        bottom: 92px;
        background: rgba(0, 0, 0, 0.70);
        border-radius: 12px;
        padding: 12px 20px;
        color: @COLOR_GRAY01;
        font-size: 26px;
        font-family: 'LGSmartUISB';
    }
    .prepare{
        position: absolute;
        background-image: url("../../../../assets/icon/mat_white.png");
        .size(@w: 44px, @h:44px);
        background-size: 44px 44px;
        left: 32px;
        bottom: 32px;
    }
    .pressed{
        position: absolute;
        .size(@w: 100%, @h: 100%);
        border-radius: 24px;
        top:0;
        left:0;
        background-color: #21212033;
    }
}
.walking{
    background-image: url("../../../../assets/mainSections/img_outdoor_gate_jogging.png");
}
.hiking{
    background-image: url("../../../../assets/mainSections/img_outdoor_gate_climbing.png");
    .prepare{
        background-image: url("../../../../assets/icon/mat_camera_white.png");
        .size(@w: 101px, @h:44px);
        background-size: 101px 44px;
    }
}
.trainingCoaching{
    background-image: url("../../../../assets/mainSections/img_outdoor_gate_training.png");
}
.golfCoaching{
    background-image: url("../../../../assets/mainSections/img_outdoor_gate_golf.png");
}
.sectionText {
    .position(@position: absolute, @top: 44px, @right: inherit, @bottom: inherit, @left: 32px);
    width: 394px;
    line-height: 42px;
    .font(@fontFamily: 'LGSmartUIB', @fontSize: 36px, @color: @COLOR_GRAY08, @fontWeight: 700 );
    word-break: keep-all;
}
.subTitle{
    .position(@position: absolute, @top: 102px, @right: inherit, @bottom: inherit, @left: 33px);
    width: 394px;
    line-height: 38px;
    .font(@fontFamily: 'LGSmartUIR', @fontSize: 28px, @color: @COLOR_GRAY06, @fontWeight: 500 );
    word-break: keep-all;
    width: 320px;
    height: 76px;
    white-space: normal;
}

.imageContainer {
  position: absolute;
  transition: transform 0.5s ease-in-out;
  width: 398px;
  left: 0;
  bottom: 40px;
  overflow: hidden;

  z-index: 1;
  height: 700px;
  > div {
    margin-top: 100px;
  }
  > div > div {
    position: absolute !important;
    left: -30px;
    transform: scale(1.4) !important;
  }
}

@__DEV__: true;