@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.popupPanel {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0.7;
  z-index: 2;

  .container {
    height: 100%;
    padding: 0 52px;

    .progressBar {
      display: block;
      width: 100%;
      height: 12px;
      margin: 0;
      padding: 0;
      margin-top: 642px;
      > div > div:nth-child(1) {
        background-color: rgba(254, 254, 254, 0.5) !important;
        border-radius: 10px;
        > div:nth-child(2) {
          background-color: @PRIMARY_COLOR_GREEN03 !important;
          opacity: 1;
        }
      }
      // knob
      > div > div:nth-child(2)::before {
        height: 30px;
        width: 30px;
        background-color: @PRIMARY_COLOR_GREEN03 !important;
        box-shadow: inset 0 0 0 5px @COLOR_GRAY02;
        border: unset;
        border-radius: 50%;
        transform: translate(-50%, -50%) !important;
      }

      &:focus {
        // knob
        >div>div:nth-child(2)::before {
          background-color: @COLOR_GRAY02 !important;
          box-shadow: unset;
        }
      }
    }

    .timeContainer{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 16px;
      color: @COLOR_GRAY02;
      div {
        margin: 0;
      }
    }
    .bottomButtons {
      position: relative;
      display: flex;
      justify-content: center;
      margin-top: 46px;

      > div {
        position: relative;
        min-width: unset;
        width: auto;
        background-color: unset;
        font-size: 27px;
        font-family: 'LGSmartUIB';
        height: 81px;
        line-height: 81px;

        div:last-child {
          width: unset !important;
          max-width: unset !important;
        }
        &:focus {
          background-color: #F2EFED;
        }
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }
  }

  .playIcon, .pauseIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
    height: 140px;
    background-size: 140px 140px;
    background-image: url(../../../../assets/icon/ic_play_nor.svg);

    &.pauseIcon {
      background-image: url(../../../../assets/icon/ic_pause_nor.svg);
    }

    &:focus, &:hover {
      background-image: url(../../../../assets/icon/ic_play_foc.svg);

      &.pauseIcon {
        background-image: url(../../../../assets/icon/ic_pause_foc.svg);
      }
    }
  }
}
@__DEV__: true;