@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@bgImageFocus: linear-gradient(
  50deg,
  rgba(90, 111, 146, 0.6),
  rgba(90, 111, 146, 0.77),
  rgba(112, 77, 116, 0.77),
  rgba(112, 77, 116, 0.6)
);
@fillPerImage: linear-gradient(
  46deg,
  rgba(91, 113, 200, 40%),
  rgba(171, 130, 238, 40%)
);

//하단부 버튼 hover및 focus 컬러 지정
//colorE0DED6

.tButton {
  //common style
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  vertical-align: middle;
  font-family: "LGSmartUISB";
  background-color: @COLOR_GRAY02;
  color: @COLOR_GRAY08;

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }

  .marquee {
    width: 100%;
    > div > div {
      text-align: center;
    }
  }

  // button size
  &.test {
    height: 72px;
    background-color: @BG_COLOR_04;
    border-radius: 72px;
    padding: 16px 38px;
    font-size: 33px;
    min-width: 304px;
    max-width: 450px;
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
    }
    &.pressed {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: @COLOR_GRAY09;
        border-radius: inherit;
        opacity: 0.3;
      }
    }
  }

  &.popup {
    // width: 304px;
    height: 87px;
    background-color: @BG_COLOR_04;
    border-radius: 72px;
    padding: 16px 38px;
    font-size: 33px;
    min-width: 280px;
    max-width: 450px;
    .text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
    }
    &.pressed {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: @COLOR_GRAY09;
        border-radius: inherit;
        opacity: 0.3;
      }
    }
  }

  &.xLarge {
    width: auto;
    height: 83px;
    line-height: 83px;
    border-radius: 56px;
    padding: 0 30px;
    font-size: 30px;
    min-width: 280px;
    max-width: 450px;
  }

  &.large {
    width: auto;
    height: 73px;
    line-height: 73px;
    border-radius: 56px;
    padding: 0 32px;
    font-size: 28px;
    min-width: 280px;
    max-width: 450px;
  }

  &.medium {
    width: auto;
    height: 66px;
    line-height: 66px;
    border-radius: 74px;
    padding: 0 24px;
    font-size: 28px;
    min-width: 150px;
    max-width: 300px;
  }

  &.small {
    width: auto;
    height: 63px;
    line-height: 63px;
    border-radius: 80px;
    padding: 0 30px;
    font-size: 30px;
    min-width: 150px;
    max-width: 300px;
  }

  &.tiny {
    width: auto;
    height: 54px;
    line-height: 54px;
    border-radius: 48px;
    padding: 0 24px;
    font-size: 28px;
    min-width: 117px;
  }

  &:focus {
    background-color: @COLOR_GRAY08;
    color: @COLOR_GRAY01;
    transition: all 200ms ease;
    transform: scale(1.08);
  }
  &.selected {
    box-shadow: 0 0 0 3px #000 inset;
    // background-color: @COLOR_GRAY01;
  }
  &.disabled {
    opacity: 0.3;
  }
  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: @BTN_COLOR_FONT;
      border-radius: inherit;
      opacity: 0.15;
    }
  }

  //button color
  &.black {
    background-color: @COLOR_GRAY07;
    color: @COLOR_GRAY01;
    &:focus {
      background-color: @COLOR_GRAY07;
      color: @COLOR_GRAY01;
    }
    &.pressed::before {
      background-color: @BTN_COLOR_FONT;
    }
  }

  &.green {
    background-color: @PRIMARY_COLOR_GREEN01;
    color: @COLOR_GRAY01;
    // &:hover,
    // &:focus {
    //   background: linear-gradient(
    //       0deg,
    //       rgba(255, 255, 255, 0.15) 0%,
    //       rgba(255, 255, 255, 0.15) 100%
    //     ),
    //     #436755;
    // }
    &.pressed::before {
      background: @COLOR_GRAY08;
      opacity: 0.2;
    }
  }

  &.yellow {
    background-color: #cfbf8c;
    color: @COLOR_GRAY01;
    &:focus {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2) 0%,
          rgba(255, 255, 255, 0.2) 100%
        ),
        #cfbf8c;
    }
    &.pressed::before {
      background: @COLOR_GRAY08;
      opacity: 0.3;
    }
  }

  &.brown {
    background-color: @BG_COLOR_04;
    color: @COLOR_GRAY08;
    &:focus {
      background: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
    }
    &.pressed::before {
      background: @COLOR_GRAY08;
      opacity: 0.3;
    }
    .selected {
      border-radius: 72px;
      box-shadow: 0 0 0 2px inset @COLOR_GRAY08;
      background: @COLOR_GRAY01;
    }
  }

  &.transparent {
    background-color: transparent;
    color: @COLOR_GRAY02;
  }

  // homecare week
  &.square {
    width: auto;
    height: auto;
    border-radius: 16px;
    padding: 20px;
    font-size: 27px;
    min-width: 208px;
    background-color: unset;

    &:focus {
      background: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
    }
    &.pressed {
      opacity: 0.3;
      background: @COLOR_GRAY09;
    }
    &.selected {
      box-shadow: 0 0 0 3px @PRIMARY_COLOR_BROWN03 inset;
    }
  }
}

.reportOnlyIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 63px !important;
  height: 63px !important;
  padding: 0 !important;
  min-width: auto !important;
  &.medium {
    @ICON_SIZE: 40px;
    .icon {
      .size(@w: @ICON_SIZE, @h: @ICON_SIZE);
    }
  }
  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:focus,
  &.pressed {
    .note {
      background-image: url("../../../assets/icon/ic_text_focused.png");
    }
    .sound {
      background-image: url("../../../assets/icon/ic_sound_focused.png");
    }
    .soundMute {
      background-image: url("../../../assets/icon/ic_mute_focused.png");
    }
  }

  &:focus {
    background-color: @PRIMARY_COLOR_BROWN03;
    transform: scale(1);
  }
  &.pressed {
    opacity: 1;
  }
}

.withIcon,
.report {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.xLarge {
    @ICON_SIZE: 34px;
    @ICON_MARGIN_RIGHT: 8px;
    .icon {
      margin-right: @ICON_MARGIN_RIGHT;
      .size(@w: @ICON_SIZE, @h: @ICON_SIZE);
    }
    .marquee {
      width: ~"calc(100% - @{ICON_SIZE} - @{ICON_MARGIN_RIGHT})";
    }
  }

  &.medium {
    width: 170px;
    column-gap: 8px;
    @ICON_SIZE: 40px;
    @ICON_MARGIN_RIGHT: 8px;
    .icon {
      .size(@w: @ICON_SIZE, @h: @ICON_SIZE);
    }
    .text {
      width: 74px;
    }
    .marquee {
      width: ~"calc(100% - @{ICON_SIZE} - @{ICON_MARGIN_RIGHT})";
    }
  }
  &.small {
    @ICON_SIZE: 24px;
    @ICON_MARGIN_RIGHT: 8px;
    column-gap: 8px;
    .icon {
      .size(@w: @ICON_SIZE, @h: @ICON_SIZE);
    }
    .marquee {
      width: ~"calc(100% - @{ICON_SIZE} - @{ICON_MARGIN_RIGHT})";
    }
  }

  &.tiny {
    @ICON_SIZE: 26px;
    @ICON_MARGIN_RIGHT: 10px;
    .icon {
      margin-right: @ICON_MARGIN_RIGHT;
      .size(@w: @ICON_SIZE, @h: @ICON_SIZE);
    }
    .marquee {
      width: ~"calc(100% - @{ICON_SIZE} - @{ICON_MARGIN_RIGHT})";
    }
  }


  .icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &.weak {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #F44B4A;
      border-radius: 50%;
    }
    &.borderline {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #F9B811;
      border-radius: 50%;
    }
    &.good {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #44A280;
      border-radius: 50%;
    }
  }

  &.pressed {
    opacity: 0.3;
  }
  &:focus,
  &.pressed {
    .bellW {
      background-image: url("../../../assets/icon/ic_homecare_subscribe_foc.png");
    }
    .note {
      background-image: url("../../../assets/icon/ic_text_focused.png");
    }
    .sound {
      background-image: url("../../../assets/icon/ic_sound_focused.png");
    }
    .soundMute {
      background-image: url("../../../assets/icon/ic_mute_focused.png");
    }
    .photo {
      background-image: url("../../../assets/icon/ic_photo_focused.png");
    }
    .pose {
      background-image: url("../../../assets/icon/ic_pose_focused.png");
    }
    .landscape {
      background-image: url("../../../assets/icon/ic_landscape_foc.svg");
    }
    .portrait55 {
      background-image: url("../../../assets/icon/ic_portrait55_foc.svg");
    }
    .portrait73 {
      background-image: url("../../../assets/icon/ic_portrait73_foc.svg");
    }
    .fullscreen {
      background-image: url("../../../assets/icon/ic_fullscreen_foc.svg");
    }
    .bgsyncup {
      background-image: url("../../../assets/icon/ic_bgsyncup_foc.svg");
    }
    .show {
      background-image: url("../../../assets/myPanel/icon/ic_my_show_foc.png");
    }
    .hidden {
      background-image: url("../../../assets/myPanel/icon/ic_my_hidden_foc.png");
    }
    .refresh {
      background-image: url("../../../assets/TSettingSection/ic_setting_refresh_foc.png");
    }
    .refresh_nor {
      background-image: url("../../../assets/TSettingSection/ic_setting_refresh.png");
    }
    .edit {
      background-image: url("../../../assets/icon/ic_homecare_edit_foc.svg");
    }
    .autoPtzOff {
      background-image: url("../../../assets/icon/ic_autoptz_off_foc.svg");
    }
    .autoPtzOn {
      background-image: url("../../../assets/icon/ic_autoptz_foc.svg");
      &+div {
        color: @COLOR_GRAY08;
      }
    }
    .musicOff {
      background-image: url("../../../assets/icon/ic_bgmusic_off_foc.svg");
    }
    .musicOn {
      background-image: url("../../../assets/icon/ic_bgmusic_foc.svg");
      &+div {
        color: @COLOR_GRAY08;
      }
    }
    .plus {
      background-image: url("../../../assets/FitnessManager/icon/ic_homecare_plan_foc.png");
    }
    .plusW {
      background-image: url("../../../assets/FitnessManager/icon/ic_homecare_plan_nor.png");
    }
    .hiking {
      background-image: url("../../../assets/icon/ic_inhome_hiking_course_foc.svg");
    }
    // .setting {
    //   background-image: url("../../../assets/icon/ic_inhome_hiking_setting_foc.svg");
    // }
    .heart {
      background-image: url("../../../assets/icon/ic_inhome_like_sel.svg");
    }
    .heartH {
      background-image: url("../../../assets/icon/ic_inhome_like_foc.svg");
    }
    .share {
      background-image: url("../../../assets/report/icon/ic_report_gate_share_foc.png");
    }
  }
  &.report {
    color: @COLOR_GRAY06;

    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
      transform: scale(1);
    }
    &.pressed {
      opacity: 1;
    }
  }
}
.bell {
  background-image: url("../../../assets/bell.png");
}
.bellW {
  background-image: url("../../../assets/icon/ic_homecare_time_26.png");
}
.sound {
  background-image: url("../../../assets/icon/ic_sound_nor.png");
}
.soundMute {
  background-image: url("../../../assets/icon/ic_mute_nor.png");
}
.pose {
  background-image: url("../../../assets/icon/ic_pose_nor.png");
}
.photo {
  background-image: url("../../../assets/icon/ic_photo_nor.png");
}
.note {
  background-image: url("../../../assets/icon/ic_text_nor.png");
}
.check {
  background-image: url("../../../assets/survey/ic_check_24.png");
}
.landscape {
  background-image: url("../../../assets/icon/ic_landscape_nor.svg");
}
.portrait55 {
  background-image: url("../../../assets/icon/ic_portrait55_nor.svg");
}
.portrait73 {
  background-image: url("../../../assets/icon/ic_portrait73_nor.svg");
}
.fullscreen {
  background-image: url("../../../assets/icon/ic_fullscreen_nor.svg");
}
.bgsyncup {
  background-image: url("../../../assets/icon/ic_bgsyncup_nor.svg");
}
.show {
  background-image: url("../../../assets/myPanel/icon/ic_my_show.png");
}
.hidden {
  background-image: url("../../../assets/myPanel/icon/ic_my_hidden.png");
}
.loading {
  background-image: url("../../../assets/TSettingSection/ic_setting_loading.png");
}
.refresh {
  background-image: url("../../../assets/TSettingSection/ic_setting_refresh.png");
}
.refresh_nor {
  background-image: url("../../../assets/TSettingSection/ic_setting_refresh_foc.png");
}
.edit {
  background-image: url("../../../assets/icon/ic_homecare_edit_nor.png");
}
.autoPtzOff {
  background-image: url("../../../assets/icon/ic_autoptz_off_nor.svg");
}
.autoPtzOn {
  background-image: url("../../../assets/icon/ic_autoptz_on_nor.svg");
  &+div {
    color: @PRIMARY_COLOR_GREEN03;
  }
}
.musicOff {
  background-image: url("../../../assets/icon/ic_bgmusic_off_nor.svg");
}
.musicOn {
  background-image: url("../../../assets/icon/ic_bgmusic_on_nor.svg");
  &+div {
    color: @PRIMARY_COLOR_GREEN03;
  }
}
.plus {
  background-image: url("../../../assets/FitnessManager/icon/ic_homecare_plan_nor.png");
}
.plusW {
  background-image: url("../../../assets/FitnessManager/icon/ic_homecare_plan_foc.png");
}
.hiking {
  background-image: url("../../../assets/icon/ic_inhome_hiking_course.svg");
}
.setting {
  background-image: url("../../../assets/icon/ic_inhome_hiking_setting.svg");
}
.heart {
  background-image: url("../../../assets/icon/ic_inhome_like_black.svg");
}
.heartH {
  background-image: url("../../../assets/icon/ic_inhome_like.svg");
}
.share {
  background-image: url("../../../assets/report/icon/ic_report_gate_share_nor.png");
}

// darkMode
.isDark {
  background: rgba(254, 254, 254, 0.1);
  color: @COLOR_GRAY01;
  &.pressed::before {
    background: @COLOR_GRAY08;
  }
  &:focus {
    background: @COLOR_GRAY02;
    color: @COLOR_GRAY06;
  }
  &.selected {
    box-shadow: 0 0 0 2px @COLOR_GRAY03 inset;
    &.none {
      box-shadow: unset;
    }
    &.transparent {
      background-color: rgba(255, 255, 255, 0.10);
      &:focus {
        color: #FEFEFE;
      }
    }
    // background-color: unset;
  }
}

.noBg {
  color: #787776;
  font-family: 'LGSmartUISB';
  background-color: unset;
  border-radius: 56px;

  &.tiny {
    font-size: 27px;
  }

  &:focus,
  &.pressed {
    background: @PRIMARY_COLOR_BROWN03;
    color: @COLOR_GRAY01;
  }

  &.pressed::before {
    opacity: 0.30;
  }

  &.isDark {
    color: @COLOR_GRAY01;
    &.pressed::before {
      background: @COLOR_GRAY08;
    }
    &:focus {
      background: @COLOR_GRAY02;
      color: @COLOR_GRAY06;
    }
    &.selected {
      box-shadow: 0 0 0 2px @COLOR_GRAY03 inset;
      // background-color: unset;
    }
  }
}

.isDark.withIcon {
  &:focus,
  &.pressed {
    .setting {
      background-image: url("../../../assets/icon/ic_inhome_hiking_setting_foc.svg");
    }
  }
}
@__DEV__: true;