@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

@BORDER_WIDTH: 2px;

.tItem {
  height: ~"calc(100% - @{BORDER_WIDTH} * 2)";
  width: auto;
  min-width: 100px;
  max-width: 500px;
  display: inline-block;
  // border-width: @BORDER_WIDTH;
  // border-style: solid;
  // border-color: black;
  background-color: lightgray;
  position: relative;
  vertical-align: top;
  font-family: "LGSmartUIR";
  box-sizing: border-box;
  &:not(:first-child) {
    border-left-width: ~"calc(@{BORDER_WIDTH} / 2)";
  }
  .title {
    font-size: 28px !important;
    height: 100%;
    color: @fitTv-normal-text-color;
    display: flex;
    justify-content: center;
    flex-direction: column;
    // margin-left: 10px;
    // margin-right: 10px;
    text-align: center;
  }
  &.large .title {
    font-size: 30px;
  }
  &.selected {
    background-color: @PRIMARY_COLOR_BROWN03;
    .title {
      font-family: 'LGSmartUISB';
    }
  }
  &:focus {
    background-color: @PRIMARY_COLOR_BROWN03 !important;
    box-shadow: 0 0 0 4px @fitTv-spotlight-color inset;
    .title {
      color: @COLOR_GRAY01;
      font-family: 'LGSmartUISB';
    }
  }
  &.medium {
    height: 61px;
    line-height: 61px;
    border-radius: 8px;
    padding: 0 25px;
    font-size: 27px;
    min-width: 176px;
  }

  &.small {
    width: auto;
    height: auto;
    min-width: unset;
    border-radius: 12px;
    padding: 10px 36px;
    background-color: @BG_COLOR_03;
    border: unset;
    .title {
      font-size: 28px;
      margin: 0px;
      color: @COLOR_GRAY08;
      font-weight: 600;
    }
    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03 !important;
      box-shadow: unset;
      .title {
        color: @COLOR_GRAY01 !important;
      }
    }
    &.selected {
      background-color: @COLOR_GRAY01;
      .title {
        color: @COLOR_GRAY08;
      }
    }
    &.pressed {
      opacity: 0.2;
    }
  }
  &.smallReport {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 112px;
    height: 45px;
    min-width: unset;
    border-radius: 12px;
    padding: 10px 36px;
    background-color: transparent;
    border: 1px solid @COLOR_GRAY03;
    box-sizing: border-box;
    .title {
      height: 26px;
      line-height: 1.2;
      font-family: "LGSmartUISB";
      font-size: 22px !important;
      margin: 0px;
      color: @COLOR_GRAY06;
      text-transform: capitalize;
      word-wrap: break-word;
      //   > div > div {
      //     height: 26px;
      //   }
    }
    &.selected {
      border: 2apx solid @PRIMARY_COLOR_BROWN03;
      padding: 8px 34px;
    }
    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03 !important;
      box-shadow: unset;
      .title {
        color: @COLOR_GRAY01 !important;
      }
    }
    &.pressed {
      position: relative;
      &::after {
        content: "";
        position: absolute;

        width: 100%;
        height: 100%;
        background-color: #ffffff;
        opacity: 0.15;
        border-radius: 12px;
      }
    }
  }
}

.tItem.isDark {
  background-color: transparent;
  .title {
    color: #DBD7D4;
  }
  &.selected {
    background-color: rgba(254, 254, 254, 0.10);
    .title {
      color: #FEFEFE!important;
    }
  }
  &:focus {
    box-shadow: unset;
    background-color: #FEFEFE !important;
    .title {
      color: #212120 !important;
    }
  }
  &.pressed {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: inherit;
      opacity: 0.3;
    }
  }
}

&.header {
  --maxItemCount: 5;
  min-width: initial;
  max-width: none;
  height: 100%;
  width: ~"calc(100%  / var(--maxItemCount))";
  border: none;
  background-color: @BG_COLOR_03;
  padding: 0 46px;
  box-sizing: border-box;
  .title {
    color: @PRIMARY_COLOR_BROWN03;
  }

  &.selected {
    background-color: @BG_COLOR_03;
    box-shadow: 0 -5px 0 -1px @PRIMARY_COLOR_BROWN03 inset;
    .title {
      color: @PRIMARY_COLOR_BROWN03;
    }
  }
  &:focus {
    box-shadow: none;
    .title {
      color: @COLOR_GRAY01;
    }
  }
}

&.header.isDark {
  background-color: #212120;
  // box-shadow: 0 -5px 0 -1px rgba(255, 255, 255, 0.1) inset;
  .title {
    color:#B2B0AE;
  }
  &.selected {
    background-color: #212120;
    box-shadow: 0 -5px 0 -1px #FEFEFE inset;
  }
}




&.report {
  font-family: "LGSmartUIR";
  --maxItemCount: 6;
  min-width: initial;
  max-width: none;
  height: 100%;
  width: ~"calc((100% - ((var(--maxItemCount) - 1) * 2px)) / var(--maxItemCount))";
  border: none;
  background-color: #d4c8bd;
  border-radius: 12px 12px 0 0;
  margin-right: 2px;
  .title {
    color: @COLOR_GRAY06;
  }
  &:last-child {
    margin-right: 0px;
  }
  &.selected {
    background-color: @COLOR_GRAY01;
    .title {
      color: @COLOR_GRAY08;
    }
  }
  &:focus {
    box-shadow: none;
    .title {
      color: @COLOR_GRAY01;
    }
  }
}

&.detailTest {
  width: auto;
  height: 53px;
  min-width: unset;
  border-radius: 35px;
  padding: 10px 26px;
  background-color: @COLOR_GRAY02;
  box-sizing: border-box;
  text-align: center;
  .title {
    .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY05, @fontWeight: 600);
    font-size: 26px !important;
    line-height: 33px;
    margin: 0px;
  }

  &.selected {
    border: 2apx solid @COLOR_GRAY08;
    padding: 8px 24px;
    background-color: @COLOR_GRAY01;
    line-height: 33px;
    .title {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY08, @fontWeight: 600);
    }
  }
  &:focus {
    background-color: @PRIMARY_COLOR_BROWN03;
    box-shadow: unset;
    .title {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY01, @fontWeight: 600);
    }
  }

  &.pressed {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.3;
      border-radius: 35px;
    }
  }
}

&.leftRightTab {
  font-size: 27px;
  width: 299px !important;
  line-height: 1;
  font-family: "LGSmartUISB";
  color: @COLOR_GRAY04;
  background-color: @BTN_COLOR_FONT;
  .title {
    .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY05, @fontWeight: 600);
    margin: 0px;
    opacity: 0.5;
  }

  &:focus {
    background-color: @PRIMARY_COLOR_BROWN03;
    box-shadow: unset;

    .title {
      color: @COLOR_GRAY01 !important;
      opacity: 1;
    }
  }
  &.selected {
    // .title {
    // border: 1px solid @COLOR_GRAY04 !important;

    background-color: @BTN_COLOR_FONT;
    box-shadow: unset;

    .title {
      .font(@fontFamily: "LGSmartUIB", @fontSize: 27px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 700);
      opacity: 1;
    }
    // }
  }
  &.pressed {
    position: relative;
    &::after {
      content: "" !important;
      position: absolute !important;
      left: 0;
      top: 0 !important;
      margin: 0 !important;
      width: 100% !important;
      height: 100% !important;
      background-color: #000000 !important;
      opacity: 0.3 !important;
    }
  }
}

&.disabled {
  opacity: 0.3;
}

@__DEV__: true;