@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@LAYER_HEIGHT: 782px;
@CONTANTLAYER_VERTICAL_PADDING: 46px;
@CALENDERLAYER_VERTICAL_PADDING: 35px;

.panel {
  font-family: "LGSmartUIR";
  color: black;
  width: 100%;
  height: 100%;

  .body {
    display: flex;
    column-gap: 28px;
    padding-top: 0;
  }
}

.leftContainer {
  width: 1088px;
  margin-top: 14px;
  .toplayer {
    display: flex;
    justify-content: space-between;
  }

  .contantLayer {
    height: calc(@LAYER_HEIGHT - (@CONTANTLAYER_VERTICAL_PADDING * 2));
    background-color: @COLOR_GRAY01;
    border-radius: 20px;
    // padding: @CONTANTLAYER_VERTICAL_PADDING 40px;
    padding: 40px 0 ;
    margin-top: 21px;
    overflow: hidden;
    .centerInfo {
      display: flex;
      column-gap: 25px;
      padding-left: @CONTANTLAYER_VERTICAL_PADDING;
      .logo {
        width: 142px;
        height: 142px;
        border-radius: 16px;
        // border: 1px solid @COLOR_GRAY05;
        background-color: @COLOR_GRAY01;
      }
      .info {
        font-family: "LGSmartUIB";
        .title {
          color: @COLOR_GRAY07;
          text-align: justify;
          font-size: 36px;
          display: inline-block;
          max-width: unset;
          .arrow {
            display: inline-block;
            width: 34px;
            height: 34px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../../assets/HomecareProgram/right_icon.png);
            vertical-align: middle;
          }
          &:focus {
            color: @COLOR_GRAY01;
            .arrow {
              background-image: url(../../../../assets/HomecareProgram/ic_right_28_dark.png)
            }
          }
        }
        .week {
          color: @COLOR_GRAY07;
          font-size: 27px;
          line-height: normal;
          margin-top: 2px;
          padding-left: 24px;
          > span {
            // progress state
            color: @COLOR_GRAY06;
            font-family: "LGSmartUISB";
          }
        }
        .alarmLayer {
          display: inline-block;
          padding-left: 24px;
          margin-top: 6px;
          color: @COLOR_GRAY06;
          font-family: "LGSmartUISB";
          font-size: 27px;
          line-height: normal;
          margin-right: 18px;
          .alarmimg {
            display: inline-block;
            width: 26px;
            height: 26px;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../../../../assets/HomecareProgram/ic_homecare_time_26.png);
            vertical-align: text-top;
            margin-right: 6px;
          }
          .alarTime {
            display: inline-block;
            margin-right: 16px;
            .hr {
              display: inline-block;
              width: 1px;
              height: 17px;
              background-color: @COLOR_GRAY04;
              margin: 0 12px;
              vertical-align: middle;
            }
            .time {
              display: inline-block;
              color: @PRIMARY_COLOR_GREEN02;
              font-size: 27px;
              line-height: normal;
            }
          }
        }
      }
    }

    .hr {
      width: 1008px;
      height: 1px;
      background: #D9D9D9;
      margin: 46px 0 30px @CONTANTLAYER_VERTICAL_PADDING;
    }

    .mediaLayer {
      // width: 1048px;
      // height: 292px;
      width: calc(100% + 80px);
      transform: translateX(-40px);
    }

    .buttonLayer {
      margin-top: 45px;
      display: flex;
      justify-content: center;
    }

    .noWokrout {
      color: @COLOR_GRAY07;
      font-family: "LGSmartUISB";
      padding: 46px;
      text-align: center;
    }
  }
}


.rightLayer {
  width: 552px;
  .tooltip {
    padding: 14px 24px;
    text-align: center;
    border-radius: 68px;
    background: #494947;
    color: @COLOR_GRAY01;
    position: relative;
    font-size: 27px;
    span {
      color: @PRIMARY_COLOR_GREEN03;
      font-family: "LGSmartUISB";
      // line-height: normal;
    }
    &::after {
      content: '';
      position: absolute;
      width: 21px;
      height: 8px;
      background: url(../../../../assets/HomecareProgram/ic_tooltip.png);
      top: 92px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .calenderLayer {
    height: calc(@LAYER_HEIGHT - (@CALENDERLAYER_VERTICAL_PADDING * 2));
    text-align: center;
    border-radius: 20px;
    background: @COLOR_GRAY01;
    margin-top: 6px;
    padding: @CALENDERLAYER_VERTICAL_PADDING 25px;
    .calender {
      border-radius: 20px;
      margin: 0 auto;

      > div:nth-child(1) {
        margin-bottom: 32px;

        >div:nth-child(1) {
          width: 205px;
        }
      }
    }
    .todayTimeLayer {
      margin-top: 45px;
      .titleLayer {
        display: flex;
        justify-content: space-between;
        .title {
          color: @COLOR_GRAY07;
          font-family: "LGSmartUISB";
          font-size: 24px;
          span {
            display: inline-block;
            width: 26px;
            height: 26px;
            background: url(../../../../assets/HomecareProgram/ic_calander_workout_time.svg) no-repeat center;
            margin-right: 6px;
            vertical-align: middle;
          }
          .title {
            display: inline-block;
          }
        }
        .percent {
          color: @COLOR_GRAY07;
          font-family: "LGSmartUIB";
          font-size: 24px;
        }
      }
      .hr {
        width: 498px;
        height: 1px;
        background: #D9D9D9;
        margin: 20px 0 18px;
      }

      .timeProgressBar {
        width: 498px;
        height: 12px;
        > div {
          border-radius: 30px;
          div:nth-child(1) {
            border-radius: inherit;
          }
          div:nth-child(2) {
            border-radius: inherit;
          }
        }
      }

      .timeInfo {
        display: flex;
        column-gap: 6px;
        font-family: "LGSmartUIB";
        height: 25px;
        // progress
        > div:nth-child(1) {
          color: @PRIMARY_COLOR_GREEN01;
          font-size: 21px;
          margin: 0;
        }
        // total
        > div {
          color: @COLOR_GRAY05;
          font-family: "LGSmartUISB";
          font-size: 21px;
          margin: 0;
        }
      }
    }
  }
}
@__DEV__: true;