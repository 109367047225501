.filterSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}

.tMediaList {
  display: flex;
  justify-content: center;
}

.tScroller {
  color: #000;
  height: 500px;
}
@__DEV__: true;