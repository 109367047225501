@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";


.tScroller {
  height: 660px;
  padding: 0 50px 0 0;
  overflow: hidden;
}

.item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
@__DEV__: true;