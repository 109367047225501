@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";


.fitIcon {
  .position(@position: absolute, @top: 246px, @right: unset, @bottom: unset, @left: 186px);
  .size(@w: 176px, @h: 46px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}


.jimmyImage {
  .position(@position: absolute, @top: 208px, @right: unset, @bottom: unset, @left: 427px);
  .size(@w: 251px, @h: 251px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.infoContainer {
  .position(@position: absolute, @top: 461px, @right: unset, @bottom: unset, @left: 175px);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  .size(@w: 522px, @h: 516px);
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 16px;
  .infoArea {
    padding: 42px 54px;
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: flex-start, @direction: column);
    gap: 24px;
    .infoBox {
      .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
      .qBox {
        .size(@w: 134px, @h: 32px);
        font-size: 27px;
        line-height: 32px;
        color: #B59D85;
        font-family: 'LGSmartUISB'; //font-weight: 600;
      }
      .aBox {
        .size(@w: 279px, @h: 32px);
        font-size: 27px;
        line-height: 32px;
        color: #F2EFED;
        font-family: 'LGSmartUISB'; //font-weight: 600;
        .elip(1);
      }
    }
  }
  .vr {
    .size(@w: 442px, @h: 1px);
    background-color: #494847;
    margin-bottom: 16px;
  }
}

@ITEM_WIDTH: 432px;
@ITEM_HEIGHT: 320px;
@SPACING: 34px;

.bodyCheckUpContainer {
  .position(@position: absolute, @top: 303px, @right: unset, @bottom: unset, @left: 741px);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
  flex-wrap: wrap;
  gap: @SPACING;
  width: ~"calc(@{ITEM_WIDTH} * 2 + @{SPACING})";
}


@__DEV__: true;