@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.scroller{
  height:650px;
}
.settingLayer{
  width: 100%;
  height: auto;
  padding-bottom: 30px;
}
.titleArea {
  position: relative;
  display: block;
  color: black;
  font-size: 1.1667rem;
  .titleBox {
      position: relative;
      display: inline-block;
      width: 17.5rem;
      height: auto;
      left: 3.125rem;
      top: 0rem;
      .text {
        position: relative;
        top: 0rem;
        text-align: start;
        margin-top: 0.5417rem;
        font-weight: bold;
      }
  }
  .textArea {
      position: relative;
      display: inline-block;
      width: 41.6667rem;
      height: auto;
      left: 2.5rem;
      background-color: rgba(174, 0, 255, 0.0);
      .text {
        position: relative;
        color: rgb(252, 252, 252);
        text-align: start;
        margin-top: 0.5417rem;
        color: black;
      }
  }
  .title {
      position: relative;
      width:100%;
      text-align: center;
      font-size: 1.5rem;
      font-weight: 'bold';
  }
  .switchs {
    position: relative;
    display: inline-flex;
    width: 100%;
    .switchTitle {
      position: relative;
      width: 660px;
      height: 3rem;
      line-height: 3rem;
      margin-left: 3.125rem;
      font-size: 1.25rem;
      font-weight: bold;
      color: black;
      background-color: bisque;
    }
    .switch{
      width: 10.4167rem;
      color: black;
      margin-bottom: 0px;
    }

  }

  .urlInputSect {

    .input {
      width: 84%;
      height: 56px !important;
      margin: 10px 10px 10px 75px;
      font-size: 22px;
      padding: 0 14px;
      border-radius: 6px;
      border-color: @COLOR_GRAY04;
      box-sizing: border-box;

      >input {
        color: black !important;
      }
    }
  }
}
.important{
  color: red !important;
}
@__DEV__: true;