@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";

.setting {
  margin: 60px 0 30px 0;
}
.avatar {
  margin-bottom: 46px;
}

.buttonContainer {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  gap: 24px;

}
@__DEV__: true;