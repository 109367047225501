@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.bar {
  position: relative;
  width: 100%;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .fixedBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 12px;
    background-color: #fff;
    > div > span {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @COLOR_GRAY06, @fontWeight: 600);
      line-height: 1.1;
      position: absolute;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      height: 28px;
    }
  }

  .fixedBarSection {
    position: relative;
    width: 192px;
    height: 100%;
    background-color: #fff;
  }

  .fixedBarGreen {
    background-color: @COLOR_GRAY03;
    &::before {
      content: "";
      position: absolute;
      right: 0;
      top: -43px;
      width: 2px;
      height: 43px;
      background-color: @COLOR_GRAY03;
    }
  }

  .fixedBarYellow {
    background-color: @COLOR_GRAY04;
    &::before {
      content: "";
      position: absolute;
      left: -1px;
      top: -43px;
      width: 2px;
      height: 43px;
      background-color: @COLOR_GRAY03;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: -43px;
      width: 2px;
      height: 43px;
      background-color: @COLOR_GRAY03;
    }
  }

  .fixedBarRed {
    background-color: @COLOR_GRAY05;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: -43px;
      width: 2px;
      height: 43px;
      background-color: @COLOR_GRAY03;
    }
  }
  .divider {
    width: 1px;
    height: 12px;
    background-color: #fefefe;
    z-index: 1;
  }
}

.marker {
  position: absolute;
  width: 38px !important;
  height: 38px !important;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 2;
  transition: left 0.8s ease-in-out;
}

.degree {
  display: flex;
  justify-content: space-between;
  position: absolute;
  font-size: 20px;
  width: 100%;
  color: @COLOR_GRAY05;
  bottom: -47px;
}

.fixedBar.SFT {
  .fixedBarSection {
    width: 88px;
  }
}

@__DEV__: true;