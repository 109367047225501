@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";
.panel{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  padding: 0px;
  background-color: #f0ece4;
  section{
    padding: 0px !important;
  }
  &.launching {
    background-color: @BG_COLOR_02;
  }
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  .flex(@direction: column);
}
.fullpageImage{
  // position: absolute;
  width: auto;
  height: auto;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  margin-bottom: 16px;
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.spriteCommon {
  height: 215px;
  &.launching{
    height: 16.6667rem;
  }
}

.loadingSign {
  color: @COLOR_GRAY07;
  font-size: 36px;
  font-family: "LGSmartUISB";
  line-height: 42px;
  &.tips{
    font-family: 'LGSmartUISB';
    // position: absolute;
    // top: 6.0833rem;
    color: @COLOR_GRAY07;
    line-height: normal;
    font-size: 36px;
    margin-top: 16px;
  }
  &.launching{
    font-family: 'LGSmartUIR';
    color: #706F69;
    line-height: normal;
    margin-top: 220px;
    font-size: 36px;
    z-index: 1;
  }
}

@TIP_BOX_PADDING_WIDTH : 36px;
@TIP_BOX_PADDING_HEIGHTT : 80px;
.tipText {
  width: calc(1406px - (@TIP_BOX_PADDING_WIDTH * 2));
  border-radius: 20px;
  background: @COLOR_GRAY01;
  padding: 36px 80px;
  text-align: center;
  margin-top: 103px;
  .today {
    position: relative;
    display: inline-block;
    margin: 0 auto;
    color: @PRIMARY_COLOR_BROWN02;
    font-family: 'LGSmartUIB';
    font-size: 30px;
    line-height: normal;
    &::before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      top: 6px;
      left: -34px;
      background-image: url(../../../assets/icon/ic_loading_page_tip.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .text {
    color: @COLOR_GRAY07;
    font-family: 'LGSmartUISB';
    font-size: 27px;
    line-height: 38px;
  }
}

.text1{
  font-family: "fluxNumberL";
  opacity: 0
}
.text2{
  font-family: "fluxNumberR";
  opacity: 0
}
.text3{
  font-family: "fluxNumberB";
  opacity: 0
}
.text4{
  font-family: "LGSmartUIL";
  opacity: 0
}
.text5{
  font-family: "LGSmartUIR";
  opacity: 0
}
.text6{
  font-family: "LGSmartUISB";
  opacity: 0
}
.text7{
  font-family: "LGSmartUIB";
  opacity: 0
}

@__DEV__: true;