@BIG_SIZE_WIDTH: 100%;
@BIG_SIZE_HEIGHT: 100%;
@MEDIUM_SIZE_WIDTH: 100%;
@MEDIUM_SIZE_HEIGHT: 100%;
// @SMALL_SIZE_WIDTH: 525px;
// @SMALL_SIZE_HEIGHT: 900px;
@SMALL_SIZE_WIDTH: 50vw;
@SMALL_SIZE_HEIGHT: 100%;
@TINY_SIZE_WIDTH: 30vw;
@TINY_SIZE_HEIGHT: 100%;
@CAMERA_INNER_MARGIN: 0px;
@CAMERA_BORDER_GAP: 0px;
@CAMERA_BORDER_RADIUS: 15px;

@LEFTRIGHT: 15px;
@TOPBOTTOM: 15px;

// .covercam {
  // width: 1000px;
  // height: calc(100vh - 20%);
  // border: 2px solid red;
  // position: absolute ;
  // border-radius: @CAMERA_INNER_MARGIN + @CAMERA_BORDER_GAP;
  // border: @CAMERA_BORDER_GAP solid rgba(220, 220, 220, 0.7);
  // background-color: transparent;

  // .camera {
  // position: absolute !important;
  // right: @CAMERA_INNER_MARGIN/2;
  // top: @CAMERA_INNER_MARGIN/2;
  // border-radius: @CAMERA_INNER_MARGIN;
  // transform: rotateY(180deg);
  // -webkit-transform:rotateY(180deg); /* Safari and Chrome */
  //   width: 100%;
  //   height: 100%;
  //   object-fit: cover;
  // }
// }

.calculateWidth (@width, @height) {
  // width: @width;
  height: @height;
  .camera {
    width: (@width  - @CAMERA_INNER_MARGIN) !important;
    height: @height - @CAMERA_INNER_MARGIN !important;
    // border-radius: @CAMERA_BORDER_RADIUS;
    object-fit: cover;
    transform: rotateY(180deg);
  }
  .noMirror{
    transform: initial;
  }
}
.big {
  .calculateWidth(@BIG_SIZE_WIDTH, @BIG_SIZE_HEIGHT );
}
.medium {
  .calculateWidth(@MEDIUM_SIZE_WIDTH, @MEDIUM_SIZE_HEIGHT );
  // width: 100% !important
  // .covercam {
  //   width: 100% !important;
  //   height: 100%;
  // };
}
.small {
  .calculateWidth(@SMALL_SIZE_WIDTH, @SMALL_SIZE_HEIGHT );
}
.tiny {
  .calculateWidth(@TINY_SIZE_WIDTH, @TINY_SIZE_HEIGHT );
}

.hide {
  opacity: 0;
}

@__DEV__: true;