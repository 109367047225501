@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.avatarContainer {
  .size(@w: 1664px, @h: 368px);
  position: relative;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);

  .image {
    .position(@position: absolute, @top: unset, @right: unset, @bottom: 0, @left: 148px);
    .size(@w: 268px, @h: 328px);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &::after { //border
    content: "";
    .position(@position: absolute, @top: 40px, @right: unset, @bottom: unset, @left: 564px);
    .size(@w: 1px, @h: 288px);
    display: block;
    background-color: #494847;
  }

  .colorContainer {
    .position(@position: absolute, @top: 113px, @right: unset, @bottom: unset, @left: 709px);
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    gap: 48px;
    .box {
      .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
    }
    .color {
      .size(@w: 94px, @h: 94px);
      border-radius: 50%;
      margin-bottom: 20px;
      &:focus {
        transform: scale(1.08);
      }
      &.selected {
        box-shadow: 0 0 0 4px #FFF inset;
      }

      &.white {
        background-color:#FFF;
      }
      &.red {
        background-color:#F00;
      }
      &.green {
        background-color:#44A280;
      }
      &.yellow {
        background-color:#FFBD3E;
      }
      &.blue {
        background-color:#06F;
      }
      &.black {
        background-color:#373736;
      }
    }
    .title {
      font-size: 27px;
      height: 32px;
      line-height: 32px;
      color: #FEFEFE;
      font-family: 'LGSmartUIR'; //font-weight: 400;
    }
  }

}



@__DEV__: true;