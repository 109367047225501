@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.coordinatePlaneWrap {
  margin: 31px auto;
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
}

.coordinatePlane {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 88px;
    height: 88px;
    border: 1px solid #787776;
    border-style: dashed;
    border-radius: 50%;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 169px;
    height: 169px;
    border: 1px solid #787776;
    border-style: dashed;
    border-radius: 50%;
  }
}

.xAxis {
  position: absolute;
  bottom: 50%;
  left: 0;
  width: 100%;
  border: 1px solid #787776;
  border-style: dashed;
  border-left: none;
  border-right: none;
  border-bottom: none;

  .directionRight {
    position: absolute;
    top: 50%;
    left: 278px;
    transform: translateY(-50%);

    .valueRight {
      position: absolute;
      bottom: -35%;
      right: 50%;
      transform: translate(50%, 50%);
    }
  }

  .directionLeft {
    position: absolute;
    top: 50%;
    right: 278px;
    transform: translateY(-50%);

    .valueLeft {
      position: absolute;
      bottom: -35%;
      left: 50%;
      transform: translate(-50%, 50%);
    }
  }
}

.yAxis {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border: 1px solid #787776;
  border-style: dashed;
  border-top: none;
  border-bottom: none;
  border-right: none;

  .directionForward {
    position: absolute;
    top: -20px;
    transform: translate(-50%, -50%);
  }

  .directionBack {
    position: absolute;
    bottom: -20px;
    transform: translate(-50%, 50%);
  }
  .valueFB {
    position: absolute;
    top: 0;
    right: -5px;
    width: 0;
  }
}

.direction {
  font-family: 'LGSmartUISB';
  font-size: 22px;
  color: #787776;
}

.value {
  font-family: 'LGSmartUIB';
  font-size: 24px;
  color: #212120;
}

.point {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #63544B;
  border: 5px solid @PRIMARY_COLOR_BROWN01;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;

  .pointText {
    font-family: "LGSmartUISB";
    font-size: 22px;
    color: #FEFEFE;
    padding: 4px 12px;
    background-color: #11A27E;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: -42px;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }
}

/* 삼각형 스타일을 point 클래스 바깥에서 정의 */
.pointText::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 11px;
  height: 5px;
  background: url('../../../assets/icon/inverted_triangle.png') no-repeat;
}

.infoWrap {
  width: inherit;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
  line-height: 1;

  .info {
    font-family: "LGSmartUIB";
    font-size: inherit;
    color: #363533;
    margin-left: 8px;

    >span {
      color: #11A27E;
    }
  }
}
@__DEV__: true;