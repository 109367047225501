@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.timeLineContainer {
  position: initial;
  margin: 0;
  padding: 0;

  &:last-child {
    .timeWrapper {
      margin-bottom: 0;

      .infoBox {

        .infoWrapper {
          &:after {
            border: none;
          }
        }
      }
    }

    .bar {
      display: none;
    }
  }

  .timeWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 72px;

    .time {
      position: relative;
      min-width: 225px;
      color: #63544B;
      font-size: 27px;
      font-family: 'LGSmartUISB';
      text-align: center;

      .calendar {
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        background-color: #F2EFED;
        margin: 0 auto 10px;

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .bar {
        width: 4px;
        height: 100%;
        background: #B59D85;
        opacity: 0.20;
        border-radius: 28px;
        position: absolute;
        left: 50%;
        bottom: -90%;
        transform: translate(-50%, 50%);
      }
    }

    .infoBox {
      background: #FEFEFE;
      border-radius: 20px;
      margin-left: 46px;
      // padding: 34px 40px;
      width: 100%;

      .infoWrapper {
        position: relative;
        display: flex;
        align-items: center;

        &:after {
          content: '';
          position: absolute;
          border-bottom: 1px solid #D9D9D9;
          width: 100%;
          bottom: -36px;
        }

        .image {
          width: 338px;
          height: 189px;
          border-radius: 12px;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
        }

        .info {
          flex: 1;
          margin-left: 60px;

          .expandCategory {
            position: relative;
            color: #B59D85;
            font-family: 'LGSmartUISB';
            font-size: 27px;
            margin-bottom: 12px;
            padding-left: 14px;

            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 4px;
              height: 23px;
              background-color: #B59D85;
            }
          }

          .expandTitle {
            font-size: 36px;
            font-family: 'LGSmartUIB';
            margin-bottom: 32px;
          }

          .tSummary {

          }

          .progressBarWrap {
            margin-top: 24px;
            font-family: 'LGSmartUISB';
            font-size: 27px;

            .progressBarInfo {
              margin-bottom: 16px;

              >span {
                color: #44A280;
              }
            }
          }
        }
      }

      hr {
        margin: 28px 0 32px;
        border-color: #D9D9D9;

      }
      .desc {
        color: #111111;
        font-size: 28px;
        font-family: 'LGSmartUIR';
        line-height: 42px;
      }
    }
  }
}

.tSummary {
  display: flex;
}

.timeProgressBar {
  width: 800px;
  height: 14px;
  > div {
    div:nth-child(1) {
      border-radius: 10px;
      background-color: #F2EFED !important;
    }
    div:nth-child(2) {
      border-radius: 10px;
      background-color: #44A280 !important;
    }
  }
}
@__DEV__: true;