@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.item {
  .contentContainer {
    font-size: 24px;
    height: auto;
    display: flex;
    color: black;
    padding: 60px 30px;
    align-items: center;

    .arrowIcon {
      align-self: flex-start;
      width: 24px;
      height: 24px;
      background: url(../../../assets/HomecareProgram/ic_arrow_down.svg);
      margin-left: auto;
      &.close {
        background: url(../../../assets/HomecareProgram/ic_arrow_down.svg);
      }
    }

    .dateContainer {
      margin-right: 80px;
      .dateIton {
        width: 64px;
        height: 64px;
        background: url(../../../assets/icon/ic_workoutmanager_record_date.svg);
        margin: 0 auto 10px;
      }
      .dateText {
        font-weight: bold;
      }
    }

    .mainContainer {
      max-width: 1100px;
      .title {
        font-weight: bold;
        font-size: 28px;
      }
    }
  }

  &.isDark {
    background-color: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.10);
    font-family: "LGSmartUIR";
    margin-bottom: 28px;
    border-radius: 20px;
    .arrowIcon {
      width: 24px;
      height: 24px;
      background: url(../../../assets/icon/ic_arrow_down_dark_nor.svg);
      &.close {
        background: url(../../../assets/icon/ic_arrow_down_dark_nor.svg);
        transform: rotateX(180deg);
      }
    }

    .dateContainer {
      .dateText {
        color: @COLOR_GRAY01;
        font-family: "LGSmartUISB";
        font-size: 27px;
        margin-bottom: 10px;
      }
    }

    .mainContainer {
      .title {
        color: @COLOR_GRAY01;
        font-family: "LGSmartUIB";
        font-size: 36px;
        margin-bottom: 24px;
        &.brown {
          color: @PRIMARY_COLOR_BROWN01;
          font-size: 45px;
        }
      }
      .subTitle {
        color: @COLOR_GRAY03;
        font-family: "LGSmartUIR";
        font-size: 27px;
        line-height: 42px;
        margin-bottom: 32px;
      }
      .dataContainer {
        display: flex;

        .dataIcon {
          width: 36px;
          height: 36px;
          margin-right: 12px;
        }
        .time {
          background: url(../../../assets/icon/ic_workoutmanager_record_workout_time.svg);
        }
        .bodyPart {
          background: url(../../../assets/icon/ic_workoutmanager_body.svg);
        }
        .painScore {
          background: url(../../../assets/icon/ic_workoutmanager_plus.svg);
        }
        .data {
          color: @COLOR_GRAY04;
          font-family: "LGSmartUISB";
          font-size: 27px;
          margin-right: 40px;
        }
      }
    }
    &:focus {
      background-color: @COLOR_GRAY01;
      .contentContainer {
        .arrowIcon {
          background-size: 24px 24px !important;
          background: url(../../../assets/HomecareProgram/ic_arrow_down.svg);
          &.close {
            background: url(../../../assets/HomecareProgram/ic_arrow_down.svg);
          }
        }
        .dateContainer {
          .dateText {
            color: @COLOR_GRAY07;
          }
        }
        .mainContainer {
          .title {
            color: @COLOR_GRAY08;
            &.brown {
              color: @COLOR_GRAY08;
            }
          }
          .subTitle {
            color: @COLOR_GRAY06;
          }
          .dataContainer {
            .data {
              color: @COLOR_GRAY06;
            }
          }
        }
      }
    }
  }
  .pressed {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

@__DEV__: true;