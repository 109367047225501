@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

.healthBall {
  .size(@w: 760px, @h: 547px);
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  box-sizing: border-box;

  .titleContainer {
    .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
    padding: 32px 40px 0 40px;
    margin-bottom: 40px;
    .titleArea {
      .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
      .title {
        font-size: 33px;
        color: #FEFEFE;
        height: 39px;
        line-height: 39px;
        font-family: 'LGSmartUIB';
        margin-right: 6px;
      }
      .icon {
        position: relative;
        .size(@w: 36px, @h: 36px);
        background-image: url("../../../../../assets/myPanel/icon/ic_my_profile_tip.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        .tooltip{
          top: ~"calc( 100% + 21px )";
        }
        &:focus {
          background-image: url("../../../../../assets/myPanel/icon/ic_my_profile_tip_foc.png");
          .tooltip {
            display: block;
          }
        }
      }
    }
    .button {
      min-width: 160px;
    }

  }

  .ball {
    .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: column);
    height: 331px;
    margin-bottom: 41px;
    .image{
      .size(@w: 280px, @h: 280px);
      background-size: 254px 254px;
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 30px;
      margin-bottom: 9px;
      position: relative;
      .tooltip{
        bottom: ~"calc( 100% + 19px )";
      }
      &:focus {
        box-shadow: 0 0 0 6px #FEFEFE inset;
        .tooltip {
          display: block;
        }
      }
    }
    .text {
      height: 42px;
      line-height: 42px;
      font-size: 36px;
      color: #FEFEFE;
      font-family: 'LGSmartUIB';
    }
  }

  .message {
    .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: column);
    height: 331px;
    margin-bottom: 41px;
    > span:nth-child(1) {
      display: block;
      margin-bottom: 12px;
      font-size: 33px;
      color: #F2EFED;
      height: 39px;
      line-height: 39px;
      font-family: 'LGSmartUISB';
    }
    > span:nth-child(2) {
      display: block;
      font-size: 27px;
      color: #DBD7D4;
      height: 32px;
      line-height: 32px;
      font-family: 'LGSmartUIR';
    }
  }

  .reference{
    .flex(@display: flex, @justifyCenter: center, @alignCenter: center);
    font-size: 21px;
    color: #B2B0AE;
    height: 25px;
    line-height: 25px;
    font-family: 'LGSmartUIR';
    &::before {
      content: "";
      display: block;
      .size(@w: 26px, @h: 26px);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../../../assets/myPanel/icon/ic_workoutmanager_alret.png");
      margin-right: 6px;
    }
  }
}
@__DEV__: true;