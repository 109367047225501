@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";
@SCALE: 1.06;
@SECTION_WIDTH: 380px;
@SECTION_HEIGHT: 440px;
@BODYCHECKUP_ANISIZE: 440px;
@BG_IMG_SIZE: 330px;
.main {
    position: relative;
    transition-property: transform, width;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    border-radius: 24px;

    .size(@w: @SECTION_WIDTH, @h: @SECTION_HEIGHT);
    .bgimage{
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: @BG_IMG_SIZE;
        border-radius: inherit;
    }
    &:focus{
        transform : scale(@SCALE);
        box-shadow: @MAIN_BOX_SHADOW;
    }
    .pressed{
        .size(@w: 100%, @h: 100%);
        position: absolute;
        top:0;
        left:0;
        border-radius: inherit;
        background-color: #000000;
        opacity: 0.3;
    }
}
.bodycheckup{
    background-color: @PRIMARY_COLOR_BROWN01;
    .imageContainer{
        position: absolute;
        left: 0;
        bottom: 0;
        height: @BODYCHECKUP_ANISIZE;
    }
}
.homecare{
    background-color: @PRIMARY_COLOR_BROWN02;

}
.inhomeoutdoor{
    width: 757px;
    .bgimage {
        object-fit: cover;
        width: 100% !important;
        height: 100%;
        border-radius: 24px;
    }
}
.sectionText {
    .position(@position: absolute, @top: 40px, @right: inherit, @bottom: inherit, @left: 40px);
    width: fit-content;
    line-height: 54px;
    .font(@fontFamily: 'LGSmartUIB', @fontSize: 42px, @color: @COLOR_GRAY01, @fontWeight: 700 );
    word-break: keep-all;
}

.dayText {
    position: absolute;
    left: 40px;
    bottom: 39px;
    height: 63px;
    line-height: 63px;
    padding: 0 20px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.2);
    color: @COLOR_GRAY01;
    font-size: 33px;
    font-family: 'LGSmartUISB';
    opacity: 0.8;
}

.sectionInfo {
  width: max-content;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translate(-50%, 0);
  color: @PRIMARY_COLOR_BROWN02;
  font-size: 24px;
}
@__DEV__: true;