@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.collection {
  height: 524px;
  position: relative;
  background: linear-gradient(
    180deg,
    #c9bdb1 0%,
    rgba(146, 132, 121, 0.2) 100%
  );

  &::before {
    // background
    .position(@position: absolute, @top: unset, @right: unset, @bottom: 0, @left: 51px);
    content: "";
    .size(@w: 615px, @h: 316px);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../../../assets/landingPage/contentCollection/img_home_bg.png");
  }
}

.logo {
  .position(@position: absolute, @top: 114px, @right: unset, @bottom: unset, @left: 115px);
  .size(@w: 144px, @h: 38px);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../../assets/landingPage/contentCollection/img_logo_s.png");
}

.title {
  .position(@position: absolute, @top: 182px, @right: unset, @bottom: unset, @left: 115px);
  font-size: 45px;
  height: 106px;
  line-height: 53px;
  font-family: "LGSmartUIB"; //font-weight: 700;
  color: #000;
}

.description {
  .position(@position: absolute, @top: 304px, @right: unset, @bottom: unset, @left: 115px);
  font-size: 30px;
  width: 550px;
  line-height: 35px;
  font-family: "LGSmartUISB"; //font-weight: 600;
  color: #494847;
}

@ITEM_WIDTH: 598px;
@ITEM_HEIGHT: 336px;

.swiperContainer {
  .position(@position: absolute, @top: 65px, @right: auto, @bottom: unset, @left: 774px);
  position: absolute !important;
  width: 1040px;
  padding: 20px 24px 20px 30px !important;
  box-sizing: border-box;
  overflow: hidden;

  .swiperSlide {
    width: @ITEM_WIDTH !important;
    height: @ITEM_HEIGHT;
    border-radius: 20px;
    transform: scale(1);
    overflow: hidden;
    transition: transform 0.3s ease;
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    &::before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000000;
      opacity: 0.3;
    }
    &.focusedImg {
      transform: scale(1.08);
      &::before {
        opacity: 0;
      }
    }
  }
}

.rButtonIcon {
  .position(@position: absolute, @top: 207px, @right: 0, @bottom: unset, @left: unset);
  .size(@w: 44px, @h: 100px);
  background-image: url("../../../../assets/landingPage/icon/ic_mrcu_right_nor.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
}

.paginationContainer {
  position: absolute;
  left: 1030px;
  bottom: 40px;
  text-align: center;
  pointer-events: none;

  .pagination {
    display: inline-block;
    margin-top: 40px;
    padding: 10px 18px;
    border-radius: 30px;
    line-height: 0;

    .bullet {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #96887c;
      &:not(:last-child) {
        margin-right: 10px;
      }

      &.active {
        width: 45px;
        border-radius: 20px;
        background-color: @PRIMARY_COLOR_BROWN03;
      }
    }

    &.focus {
      background-color: @PRIMARY_COLOR_BROWN03;

      .bullet {
        &.active {
          background-color: @COLOR_GRAY01;
        }

        // &:focus {
        //   background-color: @COLOR_GRAY01;
        // }
      }
    }
  }
}

@__DEV__: true;