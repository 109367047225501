@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";


.scroller {
  height: 815px;
}

.innerContainer {
  padding: 60px 75px 75px 75px;
}

.desciption {
  font-size: 30px;
  height: 84px;
  line-height: 42px;
  font-family: 'LGSmartUIR'; //font-weight: 400;
  color: #FEFEFE;
  margin-bottom: 63px;
}

.goalContainer {
  margin-bottom: 60px;
  .titleArea {
    .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
    margin-bottom: 36px;
  }
  .title {
    font-size: 36px;
    height: 42px;
    line-height: 42px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
  }
  .button {
    transform: none !important;
  }
  .list {
    display: flex;
    gap: 28px;
  }
}

.survey {
  margin-bottom: 60px;
}
  

.buttonContainer {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  gap: 24px;
}


@__DEV__: true;