@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

.gaitResultsSect {
  height: 100%;

  .gaitFootDirection {
    position: absolute;
    top: 20px;
    width: 36px;
    height: 36px;
    background-color: #DBD7D4;
    border-radius: 50%;
    color: #FEFEFE;
    font-size: 21px;
    font-family: 'LGSmartUISB';
    text-align: center;
    line-height: 36px;

    &.left {
      left: 20px;
    }

    &.right {
      right: 20px;
    }
  }

  .gaitImgWrap {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      width: 61px;
      // position: absolute;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
  }

  .gaitInfoWrap {
    position: absolute;
    bottom: 20px;
    min-width: 67px;
    height: 33px;
    padding: 3px 16px;
    border: 1px solid #dbd7d4;
    border-radius: 20px;
    color: #4F423C;
    font-size: 21px;
    font-family: 'LGSmartUISB';
    text-align: center;
    line-height: 25px;
    box-sizing: border-box;

    &.pronate {
      left: 20px;
    }

    &.supinate {
      right: 20px;
    }
  }
}

@__DEV__: true;