@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.activityTodayContainer {
  margin-bottom: 60px;
  padding: 0 @fitTv-tBody-padding-leftright;
  min-height: 263px;

  .activityTodaySummary {
    margin-top: 16px;

    .activityTodayTitle {
      font-family: 'LGSmartUISB';
      font-size: 36px;
      margin-bottom: 16px;
      line-height: 36px;
      color: @COLOR_GRAY08;
    }

    .exerciseStatusStr {
      color: @COLOR_GRAY07;
      font-family: 'LGSmartUIR';
      margin-bottom: 66px;

      > span {
        font-family: 'LGSmartUISB';
      }
    }
  }

  .summaryBox {
    height: auto;
    padding: 32px 0 33px;

    .summaryTop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 8px 26px 50px;

      .datePicker {
        column-gap: 24px;

        div:first-child {
          width: 268px;
          height: 42px;
          line-height: 42px;

          > div {
            font-size: 36px;
          }
        }

        div:last-child > div {
          width: 36px;
          height: 36px;
          background-size: 36px;

          &:first-child {
            margin-right: 12px;
          }
        }
      }

      .fullBtn {
        padding: 0 20px;

        > div {
          // custom
          display: flex;
          align-items: center;
        }

        .icon {
          width: 28px;
          height: 28px;
          margin-left: 4px;
          background-image: url("../../../../../assets/inHomeOutdoorActivity/ic_right_28_gray.svg");
        }
        &:hover,
        &:focus {
          .icon {
            background-image: url("../../../../../assets/inHomeOutdoorActivity/ic_right_28_dark.svg");
          }
        }
      }
    }
  }

  .activityTodayEmpty {
    font-size: 34px;
    font-family: 'LGSmartUIR';
    line-height: 50px;
    padding-top: 70px;
    color: @COLOR_GRAY08;

    span {
      font-family: 'LGSmartUISB';
    }
  }
}
@__DEV__: true;