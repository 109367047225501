@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.popupPanel {
  width: 100%;
  height: 100%;
  background: black;
  // opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 300;

  > div {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    > div {
      width: 100%;
      height: 100%;

      > div {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
      }
    }
  }


  .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .size(@w: 140px, @h: 140px);
    .flex();
    z-index: 2;

    .pause, .play {
      display: inline-block;
      width: 140px;
      height: 140px;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
    }

    .pause {
      background-image: url(../../../assets/icon/ic_pause_nor.svg);
    }
    .play {
      background-image: url(../../../assets/icon/ic_play_nor.svg);
    }

    &:focus, &:hover {
      .pause {
        background-image: url(../../../assets/icon/ic_pause_foc.svg);
      }
      .play {
        background-image: url(../../../assets/icon/ic_play_foc.svg);
      }
    }
    .pressed {
      &::after{
        content: '';
        width: 140px;
        height: 140px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #212120;
        border-radius: 50%;
        opacity: 0.2;
      }
    }
  }
}
@__DEV__: true;