@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

.wrapper{
  position:absolute;
  width:100%;
  height: 100%;
  top:0;
  left:0;
}
.instructionSign {
  .flex();
  position: absolute;
  color: #222;
  padding: 13px 25px;
  min-width: 155px !important;
  height: 49px !important;
  border-radius: 55px;
  left: 50%;
  bottom: 30%;
  transform: translateX(-50%) !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, rgba(87, 187, 183, 0.9) 0%, rgba(99, 226, 198, 0.9) 100%);
  z-index: 100;
}

.bodyOutline {
  position: absolute;
  scale: 1;
  left: 50%;
  top: 50%;
  width: 460px;
  height: 882px;
  transform: translate(-50%, -50%);
  border-style: solid;
  border-width: 300px;
  border-color: rgba(33,33,32,0.3);
  border-radius: 320px;
  &.notMatch{
    border-color: rgba(164, 42, 41, 0.3);
  }
}
.guideFootLocation{
  position: absolute;
  z-index: 100;
  left: 50%;
  margin-bottom: -70px;//vertical cross bottom
  transform: translate(-50%);
}
.guideArrow {
  position: absolute;
  width: 114px;
  height: 72px;
  top: 50%;
  background-size: 100% 100%;
  transform: translateY(-50%);
  background-image: url("../../../../../assets/bodyScan/img_arrow_mismatch.svg");
  &.left{
    left: 12%;
  }
  &.right{
    right: 12%;
    transform: scaleX(-1) translateY(-50%);
  }
}


@__DEV__: true;