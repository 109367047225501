@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

.base{
  width: 100%;
  height: 100%;

  --infoComponentsOffset: 0;

  .image_scan{
    --scanimageMaxHeight: 200px;
    @keyframes expandContract {
      0% {
        top: 326px;/* 초기 위치 */
        opacity: 1;
      }
      60% {
        opacity: 1;
        top: 583px;
      }
      100% {
        opacity: 0;
        top: 754px;
      }
    }

    width: 859px;
    height: 132px;
    position: absolute;
    left: 55px;
    // left: 50%;
    // transform: translateX(-50%);
    top: 326px;
    background-image: url("../../../../../assets/bodyBalance/ic_mat_scan.png");
    // object-fit:fill;
    overflow: hidden; /* 넘치는 부분 숨김 */
    background-position: top center;
    background-repeat: no-repeat;
    animation: expandContract 3.5s linear;
    animation-fill-mode: forwards;
    // animation-iteration-count: infinite; /* 무한 반복 */
  }
}
@__DEV__: true;