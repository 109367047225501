@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_WIDTH: 300px;
@ITEM_HEIGHT: 160px;

.item {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  position: relative;
  width: @ITEM_WIDTH;
  height: @ITEM_HEIGHT;
  border-radius: 20px;
  background: #96887C;
  

  .title {
    font-family: 'LGSmartUIB'; //font-weight: 700;
    color: #FEFEFE;
    font-size: 27px;
    height: 32px;
    line-height: 32px;
    text-shadow: 0px 0px 9px rgba(0, 0, 0, 0.25);
    z-index: 1;
    
  }
  .image {
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .size(@w: inherit, @h: inherit);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
  transition: transform 0.3s ease;
  &:focus {
    transform: scale(1.08);
    box-shadow: @MAIN_BOX_SHADOW;
  }

  &.pressed::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: inherit;
    opacity: 0.3;
  }
}


@__DEV__: true;