@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

@CONTAINER_SPACING: 42px;
.container {
  padding: 0 @CONTAINER_SPACING;
  width: calc(100% - @CONTAINER_SPACING * 2);
  height: 100%;
  display: flex;
  justify-content: space-between;

  .subTabSummary {
    width: 894px;
    height: 518px;
    border: 1px solid @COLOR_GRAY03;
    border-radius: 12px;
    padding: 43px 50px 44px 50px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }

  .imgBox {
    width: 300px;
    height: 429px;
    overflow: hidden;

    // Temporarily processing images
    position: relative;
    .scoreImg {
      display: block;
      max-width: none;
      max-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .gradeContainer {
    width: 440px;
    height: 100%;
    border-radius: 12px;
    box-sizing: border-box;
    padding-top: 25px;
    padding-bottom: 19px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .gradeLayer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .gradeBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .tooltipBox {
          padding: 8px 20px;
          border-radius: 57px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: @COLOR_GRAY01;
          font-size: 27px;
          font-family: "LGSmartUISB";

          > span {
            font-family: "LGSmartUIB";
          }
        }

        .currentScore {
          height: 62px;
          color: @COLOR_GRAY08;
          font-size: 53px;
          font-family: "LGSmartUIB";
          text-align: center;
          text-transform: capitalize;
          word-wrap: break-word;
          line-height: 1.2;
          margin: 6px 0;
        }
      }

      .textArea {
        color: @COLOR_GRAY08;
        font-family: "LGSmartUISB";
        font-size: 30px;
        line-height: 38px;
        word-wrap: break-word;
        margin-bottom: 44px;

        .text {
          font-size: 27px;
          font-family: "LGSmartUIR";
          font-weight: 400;
          color: @COLOR_GRAY07;
          word-wrap: break-word;
        }
        > p {
          margin: 0;
          text-align: center;
          // &:first-child {
          //   margin-bottom: 12px;
          // }
        }

        .subtitle {
          font-family: "LGSmartUIR";
          font-weight: 400;
          color: black;
        }
      }
    }
  }
}

.ratingBox {
  margin-bottom: 40px;
  .rating {
    width: 440px;
    margin-bottom: 65px;
    display: flex;
    justify-content: space-between;
    .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY09, @fontWeight: 600);
    &:last-child {
      .font(@fontFamily: "LGSmartUIB", @fontSize: 27px, @color: @COLOR_GRAY09, @fontWeight: 700);
    }
    > div > span {
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      background-image: url("../../../../../assets/rom/ic_bodycheck_rom_measure.png");
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 8px;
    }
  }
}
.tBar {
  width: 440px; // 80 * 5
}

@__DEV__: true;