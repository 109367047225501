@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.title {
  .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
  .size(@w: 100%, @h: 19px);
  .text {
    .size(@w: auto, @h: 100%);
    line-height: 19px;
    letter-spacing: -0.5px;
    font-size: 14px;
    color: #212120;
    font-family: 'LGSmartUIB'; //font-weight: 700;
  }
}

.title.withIcon {
  .icon {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    &.check {
      .size(@w: 13px, @h: 13px);
      background-image: url("../../../../../assets/preview/icon/ic_bodycheck_check.png");
      margin-right: 4px;
    }
  }
}
@__DEV__: true;