@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.body{
  display: flex;
  >div {
    margin-left: 10px;
    margin-right: 10px;
  }
}
@__DEV__: true;