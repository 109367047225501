@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

.scroller {
  padding-right: 0;

  .infoBox {
    padding: 60px;
    padding-bottom: 30px;
    .info {
      border: 1px solid @COLOR_GRAY03;
      box-sizing: border-box;
      border-radius: 16px;
      width: 1652px;
      height: 425px;
      padding: 78px 90px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      > img {
        width: 240px;
        height: 266px;
      }

      .bodyBalanceTabInfo {
        width: 1125px;
      }
    }
  }

  .diseasesListBox {
    padding: 30px 0;
    .diseasesList {
      margin: 0 60px;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .divider {
        position: absolute;
        left: 549px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #d9d9d9;
        &.second {
          left: 1099px;
        }
      }
      .desc {
        margin-bottom: 16px;
        width: 490px;
        margin-left: 60px;
        &:first-child,
        &:nth-child(4) {
          margin-left: 0;
          width: 550px;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          vertical-align: middle;
          background-color: #000000;
          margin-right: 8px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .title {
    .font(@fontFamily: "LGSmartUIB", @fontSize: 36px, @color: @COLOR_GRAY07, @fontWeight: 700);
    line-height: 46px;
    word-wrap: break-word;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    .icon {
      background-image: url("../../../../../../assets/icon/alert_circle_subheading.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 26px;
      height: 26px;
      margin-right: 12px;
    }
  }
  .desc {
    .font(@fontFamily: "LGSmartUIR", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
    line-height: 38px;
    word-wrap: break-word;
    > span {
      .font(@fontFamily: "LGSmartUIB",  @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 700);
    }
  }
  .source {
    .font(@fontFamily: "LGSmartUIR", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 400);
    line-height: 25px;
    margin-top: 21px;
  }
}

.dotPoint {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  min-width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 50%;
  margin-top: 14px;
  margin-right: 9px;
}

.exerciseLayer {
  padding-top: 30px;
  .pictogramLayer {
    padding: 0 60px;
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .pictogram {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY06, @fontWeight: 600);
      padding: 10px 24px;
      border-radius: 10px;
      border: 1px solid @COLOR_GRAY03;
      display: flex;
      align-items: center;
      height: 56px;
      box-sizing: border-box;

      .picIcon {
        height: 32px;
        margin-right: 6px;
      }

      &.selected {
        border: 2apx solid @PRIMARY_COLOR_BROWN03;
        background-color: rgba(0, 0, 0, 0);
        color: @COLOR_GRAY06;
        padding: 9px 23px;
      }
      &:focus {
        background-color: @PRIMARY_COLOR_BROWN03;
        color: @COLOR_GRAY01;
      }
    }
  }

  .exerciseFeedbackLayer {
    margin-bottom: 16px;
    padding: 0 60px;
    .font(@fontFamily: "LGSmartUIR", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
    list-style: 38px;

    .exerciseBox {
      min-height: 38px;
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
      .dotPoint {
        margin-top: 13px;
        margin-right: 8px;
      }
    }
  }
}

.mediaList {
  width: auto;
  height: 360px;
  > div {
    width: 1770px;
    height: 360px;
    position: absolute;
    left: 0;
    & > div {
      height: 360px;
    }
    & > div > div {
      padding-top: 28px;
    }
  }
}

.bottomButtonLayer {
  display: flex;
  justify-content: center;
  margin-top: 46px;
  padding-bottom: 60px;
  .button {
    margin-left: 10px;
    margin-right: 10px;
    height: 71px;
    min-width: 304px;
    font-size: 33px;
  }
}

@__DEV__: true;