.flexBox{
  display: flex;
  justify-content: space-between;
  .selectAllBtn{
    &:focus{
      transform: none;
      box-shadow: none;
    }
  }
}
.tscroller{
  width: 100%;
  margin-top: 20px;
  height: 650px;
}
.bottomLayout{
  position: absolute;
  bottom: 100px;
  left: 0;
  margin-left: 50%;
  transform: translateX(-50%);
}
@__DEV__: true;