
@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

@ITEM_WIDTH: 160px;
@ITEM_HEIGHT: 160px;

.infoSection {
  position: relative;
  .size(@w: @ITEM_WIDTH, @h: @ITEM_HEIGHT);
  border-radius: 18px;
  transition: transform 300ms ease;
  .text {
    .position(@position: absolute, @top: 16px, @right: unset, @bottom: unset, @left: 16px);
    font-size: 18px;
    height: 22px; 
    line-height: 22px;
    color: #FFF;
    font-family: 'LGSmartUIB'; //font-weight: 700;
  }

  .date {
    .position(@position: absolute, @top: unset, @right: unset, @bottom: 16px, @left: 16px);
    font-size: 12px;
    height: 26px; 
    line-height: 26px;
    color: #F2EFED;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    padding: 0 12px;
    background-color: rgba(0, 0, 0, 0.20);
    border-radius: 50px;
  }

  &::after { // background image
    content: "";
    .position(@position: absolute, @top: 0, @right: 0, @bottom: unset, @left: unset);
    .size(@w: 72px, @h: 70px);
    background-image: url("../../../../../assets/preview/img_report_gate_category_bg.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &:focus {
    transform: scale(1.08);
  }
  
  &.pressed::before {
    content: "";
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .size(@w: 100%, @h: 100%);
    background-color: #000;
    border-radius: inherit;
    opacity: 0.3;
  }

  &.bodyalignment{
    background-color: #45768E;
  }
  &.rom{
    background-color: #819270;
  }
  &.bodybalance{
    background-color: #DCA15E;
  }
  &.physicaltest{
    background-color: #DA92AC;
  }
}
@__DEV__: true;