@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tListTitle{
    color: black;
}
.tMediaList{
    width: 100%;
    //do not set height
}
@__DEV__: true;