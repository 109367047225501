.tScroller {
  color: #000;
  height: 814px;
}

.gridLayout {
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1.5fr 2fr;
	grid-template-rows: repeat(2, 250px);
  margin-bottom: 30px;
  .stage  {
    grid-row: 1/3;
    grid-column: 1/2;
    background-color: tomato;
    padding: 30px;
    position: relative;
    .title {
      font-size: 33px;
      font-weight: bold;
    }
    .graph {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background-color: darkgray;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .bodyType {
    grid-row: 1/3;
    grid-column: 2/3;
    background-color: tomato;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .image {
      background-position: center;
      background-repeat: no-repeat;
      width: 336px;
      height: 422px;
      position: absolute;
      right: 0;
      bottom: 0;
    }
    .title{
      font-size: 33px;
      font-weight: bold;
    }
  }
  .data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: tomato;
    .title {
      margin-bottom: 20px;
    }
    .text {
      font-weight: bold;
      font-size: 60px;
    }
  }
}

.dateWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.table {
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;

  .th {
    background-color: '#f2f2f2';
    border: 1px solid #ddd;
    padding: 8px;  
    background-color: #ddd;
  };

  .td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  };

}
@__DEV__: true;