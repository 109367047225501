@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

.wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.overallContainer {
  width: 100%;
  height: 511px;
  display: flex;
  .imageBox {
    position: relative;
    margin-left: 40px;
    margin-top: 0;
    width: 424px;
    height: 509px;
    border: 1px solid @COLOR_GRAY03;
    border-radius: 16px;
    background-image: url("../../../../../../assets/rom/img_bg_overall.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    flex-shrink: 0;
    .overallImage {
      position: absolute;
      bottom: 0;
      left: 102px;
      width: 390px;
      height: 508px;
      background-image: url("../../../../../../assets/rom/img_bodycheck_silhouette_01.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    .labelBox {
      position: absolute;
      bottom: 28px;
      border: 1px solid @COLOR_GRAY03;
      border-radius: 48px;
      padding: 4px 44px;
      height: 26px;
      font-family: "LGSmartUISB";
      font-size: 22px;
      word-wrap: break-word;
      line-height: 1.2;
      color: @COLOR_GRAY05;
      &.leftLabel {
        left: 40px;
      }
      &.rightLabel {
        right: 40px;
      }
    }
    .dotBox {
      position: absolute;
      width: 7px;
      height: 7px;
      .dotAbs {
        position: relative;
        > div {
          position: absolute;
          top: -13px;
          display: flex;
          align-items: center;
        }
      }

      &.dotNeck {
        top: 76px;
        left: 294px;
        .dotLine {
          margin-left: 10px;
          width: 64px;
        }
      }
      &.dotShoulderL {
        top: 106px;
        left: 245px;
        .dotLine {
          margin-right: 10px;
          width: 21px;
        }
        .dotAbs > div {
          left: auto;
          right: 0;
        }
      }
      &.dotShoulderR {
        top: 106px;
        left: 342px;
      }
      &.dotTrunk {
        top: 215px;
        left: 294px;
        .dotLine {
          margin-right: 10px;
          width: 59px;
        }
        .dotAbs > div {
          left: auto;
          right: 0;
        }
      }
      &.dotHipL {
        top: 246px;
        left: 262px;
      }
      &.dotHipR {
        top: 246px;
        left: 326px;
        .dotLine {
          margin-left: 10px;
          width: 43px;
        }
      }
      &.dotKneeL {
        top: 375px;
        left: 269px;
      }
      &.dotKneeR {
        top: 375px;
        left: 320px;
        .dotLine {
          margin-left: 10px;
          width: 45px;
        }
      }
    }
    .dot {
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: @COLOR_GRAY04; // default color
      transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
      z-index: 100;

      &.selected {
        width: 15px;
        height: 15px;
        border: 2apx solid #ffffff;
        &.good {
          background-color: #44a280;
        }
        &.borderline {
          background-color: #f9b811;
        }
        &.weak {
          background-color: #f44b4a;
        }
      }
    }
  }
}
.scroller {
  height: 100%;
  margin: 0;
  padding-right: 40px;
  > div:nth-child(2) {
    padding: 0;
    height: 100%;
  }
  .bodyContainer {
    .eachContainer {
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1119px;
        > div > span {
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-left: 16px;
          margin-right: 12px;
          background-repeat: no-repeat;
          background-size: contain;
          vertical-align: middle;
        }
        .mainTitle {
          padding-top: 40px;
          font-size: 26px;
          font-family: "LGSmartUISB";
          font-weight: 600;
          color: @COLOR_GRAY08;
          line-height: 36px;
        }
        .tIconBtn {
          margin-top: 40px;
          div:nth-child(1) {
            margin-right: 12px;
          }
          .withMargin {
            width: 36px;
            height: 36px;
            border-radius: 6px;
          }
        }
        .section {
          margin: 0 16px;
          .title {
            font-size: 22px;
            font-family: "LGSmartUIR";
            font-weight: 600;
            color: @COLOR_GRAY07;
          }
          .bodyImage {
            display: flex;
            margin-top: 14px;
            .eachImg {
              width: 284px;
              height: 170px;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}
.flexBox {
  display: flex;
  margin-top: 27px;
}

.clickBox {
  position: absolute;
  top: 58px;
  left: 36px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .clickItem {
    position: relative;
    width: 132px;
    height: 44px;

    .bodyPart.selected {
      border: 2apx solid @COLOR_GRAY08;
      background-color: @COLOR_GRAY01;
      line-height: 1;
    }
    .bodyPart:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
      transform: scale(1.08);
    }
    .bodyPart:focus + .dotLine,
    .bodyPart.selected + .dotLine {
      width: 100%;
    }

    .bodyPart {
      position: absolute;
      left: 0;
      right: 0;
      display: block;
      min-width: auto;
      width: 132px;
      height: 44px;
      box-sizing: border-box;
      padding: 8px 16px;
      border-radius: 8px;
      background-color: @COLOR_GRAY02;
      color: @COLOR_GRAY08;
      font-family: "LGSmartUISB";
      font-size: 24px;
      word-wrap: break-word;
      line-height: 1.1;
      text-align: center;
      transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out,
        color 0.3s ease-in-out;
      z-index: 1;
    }

    .dotLine {
      position: absolute;
      bottom: 21px;
      left: 135px;
      transition: width 0.3s ease-in-out;
      width: 0%;
      background-repeat: no-repeat;
      background-size: cover;
      &.dotLineNeck {
        max-width: 124px;
        height: 1px;
      }
      &.dotLineShoulder {
        max-width: 78px;
        height: 37px;
      }
      &.dotLineTrunk {
        bottom: auto;
        top: 22px;
        max-width: 128px;
        height: 12px;
      }
      &.dotLineHip {
        max-width: 96px;
        height: 24px;
      }
      &.dotLineKnee {
        bottom: auto;
        top: 22px;
        max-width: 102px;
        height: 43px;
      }
    }
  }
}

.testBtnWrap {
  display: flex;
  margin-left: 680px;
  padding: 28px 0 40px 0;
  .testBtn {
    width: 304px !important;
    height: 72px !important;
  }
}

@__DEV__: true;