@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.dummyImage {
  .size(@w: 328px, @h: 479px);
  background-image: url("../../../../../assets/preview/dummyImage.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

}
@__DEV__: true;