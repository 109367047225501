@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";
.bodycheckupCountdownTimer {
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .size(@w: 100%, @h: 100%);
    z-index: 5;
    & div {
      .size(@w: 100%, @h: 100%);
      .flex(@display: flex, @justifyCenter: center, @alignCenter: center);
    //   .font (@fontFamily: "NotoSans", @fontSize: 175px, @color: #fff, @fontWeight: 900);
    }
  }
@__DEV__: true;