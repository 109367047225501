@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.chips {
  width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
}

@__DEV__: true;