@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.barItem {
  display: flex;
  align-items: center;
}


.name {
  min-width: 146px;
  font-family: 'LGSmartUISB';
  font-size: 22px;
  height: 26px;
  line-height: 26px;
  margin-right: 16px;
}


.bars {
  .size(@w: 510px, @h: 17px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  .barContainer{
    height: 100%;

    > span {
      color: @COLOR_GRAY05;
      text-align: center;
      font-family: "LGSmartUIL";
      font-size: 21px;
      line-height: 48px;
      .emoji {
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: text-top;
        margin-right: 4px;
        &.weak {
          background: url(../../../../../assets/TBarItem/fluent_emoji_red.svg) no-repeat center;
          background-size: contain;
        }
        &.borderline {
          background: url(../../../../../assets/TBarItem/fluent_emoji_yellow.svg) no-repeat center;
          background-size: contain;
        }
        &.good {
          background: url(../../../../../assets/TBarItem/fluent_emoji_green.svg) no-repeat center;
          background-size: contain;
        }
      }
    }

    .weak {
      .size(@w: 169px, @h: inherit);
      border-radius: 11px 1px 1px 11px;
      background-color: @COLOR_GRAY03;
      &.active {
        background-color: @PRIMARY_COLOR_BROWN03;
      }
    }

    .borderline {
      .size(@w: 169px, @h: inherit);
      border-radius: 2px;
      background-color: @COLOR_GRAY03;
      &.active {
        background-color: @PRIMARY_COLOR_BROWN03;
      }
    }

    .good {
      .size(@w: 169px, @h: inherit);
      border-radius: 1px 11px 11px 1px;
      background-color: @COLOR_GRAY03;
      &.active {
        background-color: @PRIMARY_COLOR_BROWN03;
      }
    }
  }

  .notScore {
    text-align: center;
    color: @COLOR_GRAY05;
    text-align: center;
    font-family: "LGSmartUISB";
    font-size: 21px;
    width: 100%;
    margin: 0 auto;
    .alert {
      display: inline-block;
      width: 24px;
      height: 24px;
      background: url(../../../../../assets/icon/ic_alert_line_m.png) no-repeat center;
      vertical-align: middle;
      margin-right: 4px;
    }
  }
}



@__DEV__: true;