@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.panel {
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.95;
  z-index: 300;
  font-family: "LGSmartUIR";

  >div {
    width: 100%;
    height: 100%;

    >div {
      width: 100%;
      height: 100%;

      >div {
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  .container{
    >img{
      width: 564px;
      height: 460px;
    }
    .title{
      font-family: 'LGSmartUIB';
      font-size: 48px;
      color: @COLOR_GRAY01;
      text-align: center;
    }
    .countdown{
      margin-top: 24px;
      // width: 546px;
      height: 76px;
      line-height: 76px;
      border-radius: 60px;
      background: rgba(33, 33, 32, 0.80);
      font-family: 'LGSmartUISB';
      font-size: 32px;
      text-align: center;
      >span{
        color: @PRIMARY_COLOR_GREEN03;
        margin-right: 5px;
      }
    }
    .imageContainer {
      position: absolute;
      top: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 820px;
    }
  }
  .retryBox{
    position: fixed;
    right: 50px;
    bottom: 50px;
    .retryBtn{
      position: relative;
      width: 314px;
      height: 81px;
      max-width: 314px !important;
      line-height: 81px;
    }
  } 
}
@__DEV__: true;