@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.button {
  .size(@w: 798px, @h: 236px);
  .flex(@display: flex, @justifyCenter: left, @alignCenter: flex-start, @direction: row);
  position: relative;
  padding: 28px;
  border-radius: 36px;
  border: 1px solid rgba(255, 255, 255, 0.40);
  background: rgba(228, 219, 210, 0.30);
  backdrop-filter: blur(20px);
  box-sizing: border-box;

  .image {
    .size(@w: 180px, @h: 180px);
    margin-right: 36px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

  }
  .box {
    // display: flex;
    // flex-direction: column;
    // gap: 16px;
    width: 480px;
  }
  .title {
    .flex(@display: flex, @justifyCenter: left, @alignCenter: center, @direction: row);
    font-family: 'LGSmartUIB'; //font-weight: 700;
    color: #FEFEFE;
    font-size: 36px;
    height: 42px;
    line-height: 42px;
    margin-bottom: 16px;
  }
  .title::after { // arrow icon
    content: "";
    .size(@w: 34px, @h: 34px);
    display: block;
    background-image: url('../../../../assets/HomecareProgram/ic_right_dark.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: 8px;
  }

  .description {
    font-family: 'LGSmartUIR'; //font-weight: 400;
    color: #FEFEFE;
    font-size: 27px;
    // height: 76px;
    line-height: 38px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &:focus {
    transition: all 0.3s ease;
    transform: scale(1.08);
    box-shadow: 0 10px 20px #0000001a;
    background: @COLOR_GRAY01;
    .title,
    .description {
      color: @COLOR_GRAY08;
    }
    .title::after {
      background-image: url("../../../../assets/HomecareProgram/ic_right.svg");
    }
  }
  &.pressed::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: inherit;
    opacity: 0.2;
  }
}
@__DEV__: true;