@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@bgImageFocus: linear-gradient(
  50deg,
  rgba(90, 111, 146, 0.6),
  rgba(90, 111, 146, 0.77),
  rgba(112, 77, 116, 0.77),
  rgba(112, 77, 116, 0.6)
);
@fillPerImage: linear-gradient(
  46deg,
  rgba(91, 113, 200, 40%),
  rgba(171, 130, 238, 40%)
);

.container {
  border-radius: 74px;
  padding: 0 16px;
  height: 63px;
  width: 113px;
  background-color: @COLOR_GRAY02;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .divider {
    width: 1px;
    height: 32px;
    background-color: black;
    opacity: 0.1;
    margin: 0 8px;
  }

  .tButton {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    opacity: 0.3;

    width: 48px;
    height: 48px;

    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
      transition: background-color 200ms ease;
    }
  }
}

//하단부 버튼 hover및 focus 컬러 지정
//colorE0DED6

.tButton {
  //common style
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  vertical-align: middle;
  font-family: "LGSmartUISB";
  background-color: transparent;
  color: @COLOR_GRAY08;
  border-radius: 50%;

  // type === pose
  &.pose {
    background-image: url("../../../assets/icon/ic_bodycheck_pose_on.svg");
  }
  &.photo {
    background-image: url("../../../assets/icon/ic_bodycheck_photo_on.svg");
  }

  // type === activity
  &.walking {
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/icon/ic_activity_walking_on.svg");
  }
  &.hiking {
    background-size: 36px 36px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../assets/icon/ic_activity_hiking_on.svg");
  }

  &:focus,
  &.selected {
    opacity: 1;
  }

  &:focus {
    background-color: @COLOR_GRAY08;
    color: @COLOR_GRAY01;
    transition: all 200ms ease;

    &.pose {
      background-image: url("../../../assets/icon/ic_bodycheck_pose_foc.svg");
    }
    &.photo {
      background-image: url("../../../assets/icon/ic_bodycheck_photo_foc.svg");
    }
    &.walking {
      background-image: url("../../../assets/icon/ic_activity_walking_foc.svg");
    }
    &.hiking {
      background-image: url("../../../assets/icon/ic_activity_hiking_foc.svg");
    }
  }
  &.selected {
    // background-color: @COLOR_GRAY01;
  }
  &.disabled {
    opacity: 0.3;
  }
  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      border-radius: inherit;
      opacity: 0.3;
    }
  }
}

.isDark {
  background-color: rgba(33, 33, 32, 0.7) !important;
  .tButton {
    &:focus {
      background-color: #cfbf8c;
      transition: background-color 200ms ease;
    }
  }
  .divider {
    background-color: white;
    opacity: 0.3;
  }
}

@__DEV__: true;