@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";

@PADDING_TOP: 40px;

.body {
  padding: @PADDING_TOP 0 0 0;
}
.scroller {
  height: calc(100% - @PADDING_TOP);
  // height: ~"calc(1080px - @{PADDING_TOP} - @{fitTv-tHeader-height})";
  // 911
  // height: 500px;
}
.innerContainer {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  padding: 0 75px 75px 75px;
  height: auto;
  .pageInfo {
    margin-bottom: 80px;
  }

  .buttonArea {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    gap: 40px;
  }
}

@__DEV__: true;