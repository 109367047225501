@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.endContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin: 137px 0 46px;

  img {
    width: 456px;
    height: 424px;
    margin-right: 51px;
  }

  .title {
    color: @COLOR_GRAY01;
    font-size: 48px;
    font-family: 'LGSmartUISB';
    font-weight: 600;
    margin-bottom: 16px;

    span {
      font-family: 'LGSmartUIB';
    }
  }

  .text {
    color: @COLOR_GRAY01;
    font-size: 28px;
    font-family: 'LGSmartUIR';
    line-height: 38px;
  }
}

.finishedContainer {
  margin: 87px 0 76px;

  .box {
    .title {
      color: @COLOR_GRAY02;
      font-size: 28px;
      font-family: 'LGSmartUIB';
      margin-bottom: 38px;
    }

    &:not(:last-child) {
      margin-bottom: 66px;
    }

    &.goalRecord {
      .goalHistory {
        position: initial;
        margin: 0 auto;
        column-gap: 121px;
      }
    }

    &.myRecord {
      .recordInfo {
        display: flex;
        padding: 32px 64px;
        background: @COLOR_GRAY08;
        opacity: 0.80;
        border-radius: 20px;
        backdrop-filter: blur(78px);

        .record {
          text-align: center;

          &:not(:last-child) {
            margin-right: 44px;
          }

          .label {
            color: @COLOR_GRAY03;
            font-size: 26px;
            font-family: 'LGSmartUIR';
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              margin-right: 8px;
            }
          }

          .value {
            color: @COLOR_GRAY01;
            font-size: 28px;
            font-family: 'LGSmartUISB';
          }
        }
      }
    }
  }
}

.sceneryContainer {
  margin: 120px 0 83px;
  text-align: center;

  img {
    margin-bottom: 50px;
  }

  .title {
    color: @COLOR_GRAY01;
    font-size: 45px;
    font-family: 'LGSmartUISB';
    margin-bottom: 24px;

    span {
      font-family: 'LGSmartUIB';
    }
  }

  .text {
    color: @COLOR_GRAY03;
    font-size: 27px;
    font-family: 'LGSmartUIR';
    line-height: 38px;
  }
}

.gestureContainer {
  .alert {
    color: @PRIMARY_COLOR_BROWN02;
    font-size: 27px;
    font-family: 'LGSmartUIR';
    margin-top: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      display: inline-block;
      width: 28px;
      height: 28px;
      margin-right: 6px;
      background-size: 28px 28px;
      background-image: url("../../../../assets/icon/ic_alert_line.svg");
    }
  }

  .guideImgContainer {
    margin: 32px auto 0;
    padding: 32.5px 0 40.5px;
    background: white;
    display: flex;

    .guide {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img {
        width: var(--imgWidth);
      }

      > div {
        .title {
          color: @COLOR_GRAY08;
          font-size: 27px;
          font-family: 'LGSmartUIB';
        }

        .desc {
          color: @COLOR_GRAY06;
          font-size: 24px;
          font-family: 'LGSmartUISB';
          margin-top: 8px;
        }
      }

      &:not(:last-child) {
        border-right: 1px solid #D9D9D9;
      }
    }
  }
}

.ratioSettingContainer {
  margin: 128px 0 177px;
  text-align: center;

  .title {
    color: @COLOR_GRAY01;
    font-size: 45px;
    font-family: 'LGSmartUIB';
    margin-bottom: 27px;
  }

  .text {
    color: @COLOR_GRAY03;
    font-size: 27px;
    font-family: 'LGSmartUIR';
    line-height: 38px;
    margin-bottom: 80px;
  }

  .ratioBtnWrapper {
    .ratioBtn {
      position: relative;
      background-color: rgba(255, 255, 255, 0.20);
      border-radius: 12px;
      color: @COLOR_GRAY01;
      font-size: 36px;
      font-family: 'LGSmartUIB';
      min-width: 239px;

      &:not(:last-child) {
        margin-right: 16px;
      }

      &.selected {
        box-shadow: 0 0 0 3px @COLOR_GRAY01 inset;
      }

      .tag {
        display: inline-block;
        color: #FEFEFE;
        font-size: 27px;
        font-family: 'LGSmartUISB';
        padding: 3px 16px;
        background-color: rgba(255, 255, 255, 0.10);
        margin-top: 14px;
        display: inline-block;
        line-height: 27px;
        border-radius: 100px;
      }

      &:focus {
        color: @COLOR_GRAY08;
        background-color: @COLOR_GRAY01;

        .tag {
          color: @COLOR_GRAY08;
          background-color: rgba(33, 33, 32, 0.10);
        }
      }

      &::before {
        content: '';
        position: absolute;
        width: 36px;
        height: 36px;
        background-image: url('../../../../assets/inHomeOutdoorActivity/ic_radio_nor_36.svg');
        top: 20px;
        left: 20px;
      }

      &.selected {
        &::before {
          background-image: url('../../../../assets/inHomeOutdoorActivity/ic_radio_sel_36.svg');
        }
      }

      &:focus {
        &::before {
          background-image: url('../../../../assets/inHomeOutdoorActivity/ic_radio_foc_36.svg');
        }
      }
    }
  }
}

.btnWrapper {
  .btn {
    font-size: 33px;
    height: 87px;
    line-height: 87px;
    background-color: #FEFEFE33;
    color: @COLOR_GRAY01;

    &:hover,
    &:focus {
      background-color: @COLOR_GRAY01;
      color: @COLOR_GRAY08;
    }
  }

  .btn:first-child {
    margin-right: 24px;
  }
}
@__DEV__: true;