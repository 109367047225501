@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@CIRCLE_SIZE: 658px;

.vrPlayer {
  position: relative;
}

.spotBtnContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  clip-path: content-box;
  z-index: 1;

  .controlBtn {
    position: absolute;
    margin: 0 52px;
    display: inline-block;

    &.left {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: inline-flex;
    }
    &.right {
      top: 50%;
      right: 0;
      transform: translateY(-50%) rotate(180deg);
      display: inline-flex;

      img {
        transform: rotate(180deg);
      }
    }
    &.top {
      margin: 52px 0;
      top: 0;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      display: flex;

      img {
        transform: rotate(-90deg);
      }
    }
    &.bottom {
      margin: 52px 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%) rotate(-90deg);
      display: flex;

      img {
        transform: rotate(90deg);
      }
    }

    > img {
      margin-left: 10px;
    }
  }

  .controlBox {
    position: absolute;
    top: 0;
    width: @CIRCLE_SIZE;
    height: @CIRCLE_SIZE;
    line-height: @CIRCLE_SIZE;
    color: @COLOR_GRAY01;
    font-size: 24px;
    font-family: 'LGSmartUIB';

    > div {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.20);
    }

    .controlLabel {
      .labelFlex {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrow {
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url('../../../assets/inHomeOutdoorActivity/ic_athome_scenery_left.svg');
      }
    }

    &.left {
      left: 0;
      top: 50%;
      transform: translate(-55%, -50%);

      .controlLabel {
        .labelFlex {
          margin-left: 55%;
        }

        .text {
          padding-left: 10px;
        }
      }
    }
    &.right {
      right: 0;
      top: 50%;
      transform: translate(55%, -50%);

      .controlLabel {
        .arrow {
          transform: scale(-1);
        }

        .text {
          padding-right: 10px;
        }
      }
    }
    &.top {
      top: calc(-@CIRCLE_SIZE / 2);
      left: 50%;
      transform: translateX(-50%);

      .controlLabel {
        padding-top: calc(27.41667rem / 1.5);
        line-height: normal;

        .labelFlex {
          width: 100%;
          flex-direction: column;

          .arrow {
            transform: rotate(90deg)
          }

          .text {
            padding-top: 10px;
          }
        }
      }
    }
    &.bottom {
      top: unset;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: calc(@CIRCLE_SIZE / 2);
      overflow: hidden;

      > div {
        height: @CIRCLE_SIZE;
      }

      .controlLabel {
        padding-top: calc(658px / 4);
        line-height: normal;

        .labelFlex {
          width: 100%;
          flex-direction: column;

          .arrow {
            transform: rotate(-90deg);
          }

          .text {
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.50);

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &.gestureGuide {
    .guideImg {
      margin-bottom: 24px;
    }

    .text {
      color: @COLOR_GRAY01;
      font-size: 30px;
      font-family: 'LGSmartUISB';
    }
  }
}
@__DEV__: true;