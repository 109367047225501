@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

@base-color: #63534b;
@stroke-width: 4;

.lineChart {
  overflow: visible;
}

.line {
  fill: none;
  stroke-width: @stroke-width;
  stroke: @base-color;
}

.area {
  fill: url(#areaGradient);
}

.circle {
  fill: #fff;
  stroke-width: @stroke-width;
  stroke: @base-color;
}

@__DEV__: true;