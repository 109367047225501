@import '../../../style/CommonStyle.module.less';
@import "../../../style/utils.module.less";



.question {

  //type
  &.mainQuestion {
    .title {
      color: @COLOR_GRAY01;
      font-size: 30px;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      height: 35px;
      line-height: 35px;
    }
  }
  &.subQuestion {
    .title {
      height: 32px;
      line-height: 32px;
      font-size: 27px;
      color: #FEFEFE;
      font-family: 'LGSmartUIR'; //font-weight: 400;
    }
  }

  // direction
  &.vertical {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: flex-start, @direction: column);
    .title {
      margin-bottom: 40px;
    }
  }
  &.horizontal {
    .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
    .title {
      margin-bottom: 0px;
    }
  }

  // id
  &.age,
  &.sex,
  &.height,
  &.weight{
    .title {
      width: 160px;
      height: 39px;
      line-height: 39px;
      font-size: 33px;
      color: #FEFEFE;
      font-family: 'LGSmartUIB';
    }
  }

  &.disease,
  &.disability,
  &.pain,
  &.purpose,
  &.interest,
  &.preference,
  &.desire,
  &.frequency,
  &.time,
  &.intensity,
  &.level,
  &.smoking,
  &.drinking {
    width: 100%;
    height: auto;
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.03);
    padding: 43px 60px;
    box-sizing: border-box;
  }

  &.intensity_low, 
  &.intensity_mid, 
  &.intensity_high {
    gap: 20px;
    .title {
      color: #DBD7D4;
      font-size: 27px;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      height: 32px;
      line-height: 32px;
      margin-bottom: 0 
    }
  }

  &.slider {
    .title {
      margin-bottom: 0 
    }
  }
}

.article {
  margin-top: 60px;
  .title {
    display: flex;
    align-items: center;
    color: #FEFEFE;
    font-size: 30px;
    line-height: 35px;
    height: 35px;
    font-family: 'LGSmartUISB';
    
  }
  
  .questionContainer {
    width: 1650px;
    margin-top: 20px;
    border-top: 1px solid #494847;
    padding: 40px 0 0 40px;
    box-sizing: border-box;
    >div:nth-child(1) {
      margin-bottom: 40px;
    }
    >div:nth-child(2) {
      margin-bottom: 61px;
    }
    >div:last-child {
      margin-bottom: 0;
    }
  }
  
  .icon {
    margin-right: 12px;
  }
  
}







@__DEV__: true;