// Times.module.less
//
@import "~@enact/sandstone/styles/variables.less";
@import "~@enact/sandstone/styles/mixins.less";

.times {
	font-family: 'LGSmartUISB';
	font-size: 24px;
	white-space: nowrap;
    margin-left: 1.1667rem;
    margin-right: 1.1667rem;
	> * {
		display: inline-block;
		vertical-align: super;
	}

	.separator {
		padding: 0 1ex;
	}

	.enact-locale-rtl({
		direction: ltr;
	});
}

@__DEV__: true;