@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tabs {
  width: auto;
  display: inline-block;
  &.large {
    height: 80px;
  }
  &.medium {
    // height: 70px;
  }
  &.small {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
  }
  &.header {
    height: 70px;
    width: 100%;
  }
  &.report {
    height: 86px;
    width: 100%;
  }
  &.smallReport {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
  }
}

@__DEV__: true;