@import '../../style/CommonStyle.module.less';


.body {
  padding: 0;
}
.contentContainer {
  padding: @fitTv-tBody-padding-topbottom @fitTv-tBody-padding-leftright;
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}
.mediaListTitle {
  font-size: 36px;
  height: 42px;
  line-height: 42px;
  font-family: "LGSmartUIB"; //font-weight: 700;
  color: #000;
  margin: 30px 0 0 115px;
}

@__DEV__: true;