@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.barItem {
  margin-bottom: 8px;
}

.title {
  .size(@w: auto, @h: 16px);
  line-height: 16px;
  font-size: 14px;
  color: #212120;
  font-family: "LGSmartUISB"; //font-weight: 600;
  margin-bottom: 10px;
}

.bar {
  .size(@w: 138px, @h: 10px); // default value
  background-color: @COLOR_GRAY02;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 4px;
  .separator {
    position: absolute;
    left: 50%;
    width: 0px;
    height: 100%;
    border-right: 1px dashed @COLOR_GRAY03;

    &.left {
      left: calc(25% + 2.5px);
    }
    &.right {
      left: auto;
      right: calc(25% + 2.5px);
    }
  }

  @ITEM_SIZE: 10px;
  .indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: @COLOR_GRAY04;
    position: absolute;
    left: calc(50% - (@ITEM_SIZE / 2));
  }
}

.textWrapper {
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
  .text {
    .size(@w: auto, @h: 14px);
    line-height: 14px;
    font-size: 12px;
    color: @COLOR_GRAY06;
    font-family: "LGSmartUIR"; //font-weight: 400;
  }
}

.OVERALL {
  &.barItem {
    margin-bottom: 0;
  }
  .bar {
    margin-bottom: 6px;
    .size(@w: 207px, @h: 15px);
  }
  .indicator {
    width: 15px;
    height: 15px;
  }
  .text {
    font-size: 18px;
    line-height: 21px;
  }
}

@__DEV__: true;