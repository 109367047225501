@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";


.title {
  .size(@w: 100%, @h: 19px);
  font-size: 16px;
  line-height: 19px;
  color: #212120;
  font-family: 'LGSmartUIB'; //font-weight: 700;
  margin-bottom: 12px;
  padding: 0 16px;
  box-sizing: border-box;
}

.wrapper {
  position: relative;
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  .size(@w: 328px, @h: auto);
  background-color: #FFF;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
}

.arrowIcon {
  .size(@w: 12px, @h: 12px);
  margin: 8px 0;
  background-image: url("../../../../../assets/preview/icon/ic_arrow_result_m.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
@__DEV__: true;