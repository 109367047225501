@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.tBody {
  height: 100%;
  padding: 0;

  .tScroller {
    font-family: 'LGSmartUIR';
    color: #212120;
    height: calc(100% - @fitTv-tHeader-height);

    .programSituation {
      // margin-top: 16px;
      padding: 60px @fitTv-tBody-padding-leftright 0;

      .contentContainer {}

      .programSituationTitle {
        font-family: 'LGSmartUIB';
        font-size: 36px;
        margin-bottom: 28px;
        line-height: 36px;
      }

      .flexBox {
        display: flex;
        align-items: center;
        margin-bottom: 60px;

        img {
          border-radius: 16px;
          width: 559px;
          height: 316px;
          margin-right: 80px;
        }

        .programSituationInfo {

          .customContTitSect {
            display: flex;

            .tag {
              display: inline-block;
              padding: 4px 13px;
              border-radius: 8px;
              background: @PRIMARY_COLOR_GREEN02;
              line-height: 40px;
              font-family: 'LGSmartUIB';
              color: @COLOR_GRAY01;
              font-size: 27px;
            }

            .customContTit {
              font-family: 'LGSmartUISB';
              font-size: 36px;
              margin-bottom: 12px;
            }
          }


          .courseStr {
            color: #313131;
            font-size: 27px;
            font-family: 'LGSmartUI';
            line-height: 35px;
            margin-bottom: 19px;
          }

          .summaryFlexBox {
            display: flex;
            justify-content: flex-start;
            font-size: 27px;
            font-family: 'LGSmartUISB';
            color: @COLOR_GRAY08;
            margin-bottom: 24px;

            >div>span {
              display: inline-block;
              width: 36px;
              height: 36px;
              background-size: cover;
              background-repeat: no-repeat;
              margin-right: 12px;
            }

            .align {
              display: flex;
              align-items: center;
              margin-right: 30px;
            }
          }
        }
      }

      .WeeklyStatusBox {
        position: relative;
        background-color: #FEFEFE;
        padding: 50px 50px 50px 0;
        border-radius: 20px;
        margin-bottom: 40px;
        transition: transform 0.3s;
        transform: scale(1);
        transform-origin: center center;

        &:focus {
          transform: scale(1.02);
          box-shadow: @MAIN_BOX_SHADOW;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .arrow {
          position: absolute;
          top: 50px;
          right: 50px;
        }

        .statusCollapsed {
          display: flex;
          align-items: center;

          &.marginTop {
            margin-bottom: 60px;
          }

          .weekly {
            min-width: 225px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .weeklyTitle {
              font-family: 'LGSmartUIB';
              font-size: 36px;
              width: fit-content;
              margin-bottom: 20px;
            }

            .exerciseStatus {
              font-family: 'LGSmartUISB';
              font-size: 27px;
              width: fit-content;
              border: 1px solid #B2B0AE;
              border-radius: 7px;
              padding: 6px 16px;
            }
          }

          .weeklyInfo {
            position: relative;
            padding-left: 46px;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 100%;
              border: 2px solid #F2EFED;
            }

            .weeklyInfoTitle {
              font-family: 'LGSmartUIB';
              font-size: 36px;
              margin-bottom: 24px;
            }

            .weeklyInfoCont {
              font-size: 27px;
              margin-bottom: 32px;
            }

            .completedExercise {

              .completedExerciseCont {
                font-family: 'LGSmartUISB';
                font-size: 27px;
                margin-bottom: 16px;

                span {
                  font-family: 'LGSmartUIB';
                  color: #44A280;
                }
              }

              .bar {}
            }
          }
        }
      }
    }

    .topBtn {
      display: block;
      margin: 60px auto 75px;
    }
  }
}



@__DEV__: true;