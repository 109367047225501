@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.ttoast{
	width: 1000px !important;
	margin: 0 460px 100px 460px !important;
	background-color: rgba(33, 33, 32, 0.90) !important;
  border-radius: 12px !important;
	>div:first-child{
		padding-top: 32px !important;
    padding-bottom: 32px !important;
	}
	>div>div>p:first-child{
    margin: 0 !important;
    width: 881px !important;
    height: auto !important;
		font-size: 30px !important;
		color: #FEFEFE !important;
		font-family: "LGSmartUISB" !important;
		text-align: center !important;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space:pre-wrap;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
}

@__DEV__: true;