@import '../../../../style/utils.module.less';

.withMargin {
  margin: 10px;
}

.alignVertical {
  display: flex;
  flex-direction: column;
  > div {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.buttonLayout,
.popupLayout,
.pageLayout {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}

.stepperLayout {
  position: relative;
  height: 80%;
  width: 100%;

  .stepper2 {
    width: 900px !important;
  }
}

.stepperTestLayout {
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.toolTipLayout {
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;
  .positionBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 500px;
    background-color: skyblue;
  }
  .button {
    transform: none;
  }
}

/* TVirtualSpotContainerLayout */
.container {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 1fr 1000px 1fr;
  grid-template-rows: 1fr 562px 1fr;

  > .button {
    margin: 2rem 0;
  }

  > .upbutton {
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;
  }

  > .leftbutton,
  .rightbutton {
    align-self: center;
  }

  > .downbutton {
    grid-column: 1 / -1;
    justify-self: center;
    align-self: center;
  }

  > .TVirtualSpotContainerLayout {
    .size(@w: 1000px, @h: 562px);
    .flex(@justifyCenter: flex-start, @alignCenter: flex-start);
    .imgElement (@backgroundW: 100%, @backgroundH: 100%, @positionX: center, @positionY: center);

    &:focus {
      .border-solid(@size: 1px, @color: #000);
    }

    > .child {
      .flex(@direction: column);
      .imgElement (@backgroundW: 100%, @backgroundH: 100%, @positionX: center, @positionY: center);
      .size(@w: 240px, @h: 240px);
      margin: 104px 0 0 36px;

      > p {
        margin: 0;
        color: #ffffff;
        text-align: center;
      }
    }

    > .focused {
      transform: scale(1.3);
    }

    // rectangle
    > .child:first-child {
      margin-left: 104px;
    }

    // star
    > .child:nth-child(2) {
      .size(@w: 240px, @h: 228px);
    }

    // diamond
    > .child:last-child {
    }
  }
}
/* TVirtualSpotContainerLayout */

/* TProgressLayout */
.progressLayout {
  .flex(@justifyCenter: space-evenly);

  > div {
    .flex(@direction: column);

    > p {
      margin: 0;
      text-align: center;
    }
  }
}
/* TProgressLayout */

/* TRadarChartLayout */
.radarChartLayout {
  .flex();
}
/* TRadarChartLayout */

/* TLineChartLayout */
.lineChartLayout {
  .flex(@direction: column);

  p {
    font-size: 20px;
  }
}
/* TLineChartLayout */

@__DEV__: true;