@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";



.section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FEFEFE;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
  height: 173px;
  padding: 0 32px;
  border-radius: 20px;
  // size
  // &.medium {

  // }

  // &.small {
  //   height: 170px;
  //   padding: 0 60px;
  // }

  //border
  // &.round {
  //   border-radius: 20px;
  // }

  .textContainer {
    .flex(@display: flex, @justifyCenter: center, @alignCenter: flex-start, @direction: column);
    gap: 10px;
    .text {
      font-size: 33px;
      height: 39px;
      line-height: 39px;
      font-family: 'LGSmartUISB';
      color: #212120;
    }
    .subText {
      font-size: 28px;
      height: 32px;
      line-height: 32px;
      font-family: 'LGSmartUIR';
      color: #212120;
    }
  }




  .rightContainer {
    display: flex;
    flex-flow: row wrap;
    gap: 20px;
    .value{
      line-height: 60px;
    }
    .button{
      margin-left:0px;
      margin-right: 0px;
    }
  }
}

// .underLine{
//   background: none;
//   border-bottom: 1px solid black;
// }



.section.isDark {
  background-color: transparent;
  box-shadow: none;
  .text {
    color: #FEFEFE;
  }
  .subText {
    color: #DBD7D4;
  }
}
@__DEV__: true;