@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.tbody {
  padding-top: 9px;
}
.top {
  padding-top: 0;
}
.tabLayout {
  position: relative;
  width: 1664px;
  height: 695px;
  background-color: white;
  border-radius: 0 0 12px 12px;

  .testBtnWrap {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0);

    .firstBtn {
      margin-right: 20px;
    }
  }

  .none {
    display: none;
  }
}

.notification {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50px;
  left: 75px;
  bottom: 13px;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 18px, @color: @COLOR_GRAY05, @fontWeight: 400);
  line-height: 25px;
  &.ces {
    font-size: 21px;
  }

  .guideText::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
  }
}
.medicalCenter {
  position: absolute;
  width: 181px;
  height: 38px;
  right: 92px;
  bottom: 18px;
  background-image: url("../../../../../assets/images/medicalCenterMark.svg");
}

@__DEV__: true;