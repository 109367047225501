@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";
@CONTENT_PADDING:  75px;

.scroller {
  height: inherit;
  width: 1664px;
  color: @COLOR_GRAY08;
  padding: 0;
  padding: 0;
  .content {
    padding: 60px 0 @CONTENT_PADDING @CONTENT_PADDING;
  }
}

.descLayer {
  width: calc(1514px - 60px - 50px);
  padding: 40px 60px 40px 50px;
  border: 1px solid @COLOR_GRAY03;
  border-radius: 16px;
  background: @COLOR_GRAY02;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .desc {
    color: @COLOR_GRAY08;
    font-family: 'LGSmartUISB';
    font-size: 33px;
    line-height: 48px;
    width: 797px;
  }
  .button {
    align-self: center;
  }
}

.topLayer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 60px;
  padding-right: @CONTENT_PADDING;

  .order {
    width: auto;
    > div:nth-child(1) {
      width: auto;
    }
    > div:nth-child(3) {
      width: auto;
    }
  }
}

.title {
  font-size: 34px;
  color: #212120;
  font-family: 'LGSmartUIB';
  height: 40px;
  line-height: 40px;
  margin-top: 48px;
}
.mediaList {
  margin-top: 20px;
  transform: translateX(-75px);
  width: calc(100% + @CONTENT_PADDING);
}
@__DEV__: true;