@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.noticeItem {
  height: 198px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // todo : focus, pressed
  &:focus {
    background-color: #FFF;
    .title {
      color: #212120;
    }
    .text {
      color: #212120;
    }
    .date {
      color: #212120;
    }
  }
  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      border-radius: inherit;
      opacity: 0.3;
    }
  }
}

.content {
  width: 1297px;
}

.wrap {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center);
  margin-bottom: 24px;
  .tag {
    background-color: #B59D85;
    color: #212120;
    font-size: 20px;
    font-family: 'LGSmartUIB';
    height: 40px;
    line-height: 40px;
    padding: 0 14px;
    border-radius: 10px;
    margin-right: 20px

  }
  .title {
    .elip(1);
    color: #FEFEFE;
    font-size: 33px;
    font-family: 'LGSmartUIB';
    height: 39px;
    line-height: 39px;
  }
}

.text {
  .elip(1);
  color: #DBD7D4;
  font-size: 27px;
  font-family: 'LGSmartUIR';
  height: 38px;
  line-height: 38px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.date {
  color: #DBD7D4;
  font-size: 27px;
  font-family: 'LGSmartUIR';
  height: 38px;
  line-height: 38px;
}
@__DEV__: true;