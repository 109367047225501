@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.summaryItem {
  display: flex;
  align-items: center;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  img {
    display: block;
    margin-right: 8px;
    width: 23px;
    height: 23px;
  }

  .label {
    color: #787776;
    font-size: 26px;
    font-family: 'LGSmartUISB';
    margin-bottom: 2px;
  }

  .value {
    font-size: 30px;
    font-family: 'LGSmartUISB';
  }
}

&.large {
  height: 181px;

  .summaryItem {
    &:not(:last-child)::after {
      height: 60px;
    }

    .title {
      font-size: 28px;
      margin-bottom: 4px;
    }

    .text {
      font-size: 38px;
    }
  }
}

&.lite {
  .summaryItem {
    width: auto;
    padding-left: 40px;

    &:first-child {
      padding-left: 0;
    }

    &:not(:last-child)::after {
      content: unset;
    }

    img {
      width: 36px;
      height: 36px;
      margin-right: 12px;
    }

    .text {
      font-size: 30px;
      font-family: 'LGSmartUISB';
    }
  }
}

&.center {
  .summaryItem {
    padding-left: 0;
    justify-content: center;
  }
}
@__DEV__: true;