@import "../../style/CommonStyle.module.less";
@import "../../style/CommonStyle.module.less";

.hide{
	visibility: hidden;
}

.count {
	color: @colorffffff;
	text-align: center;
	font-family: "LGSmartUIB";
	font-size: 260px;
	line-height: normal;
}

.start {
	color: @colorffffff;
	text-align: center;
	font-family: "LGSmartUIB";
	font-size: 160px;
	line-height: 304px;
}
@__DEV__: true;