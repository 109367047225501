@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.dot {
    width: 100%;
    display: block;
    height: 3px;
    position:absolute;
    left: 0px;
    bottom: 0px;
}
@__DEV__: true;