@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

//하단부 버튼 hover및 focus 컬러 지정


.HomecareWorkoutBtn {
  position: relative;
  border-radius: 20px;
  box-sizing: border-box;
  color: @COLOR_GRAY01;
  background-color: #5C7982;
  position: relative;
  width: 835px;
  background-repeat: no-repeat;

  &:focus {
    transition: all 200ms ease;
    transform: scale(1.02);
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .detail {
    font-size: 23px;
    color: #e0ded5;
    // width: 515px;
  }
  .color {
    position: absolute;
    width: 5px;
    height: 100px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0 5px 5px 0;
  }
}

@__DEV__: true;