@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@DIEMETER: 36px;
@BAR_WIDTH: 248px;
@BAR_HEIGHT: 3px;

.stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: @BAR_WIDTH;
  height: @BAR_HEIGHT;
  
  .fixedBar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #363533; 
    width: inherit;
    height: inherit;
  }

  .stepBar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #CFBF8C;
    height: inherit;
    width: 30%;
    transition: 0.3s ease;
  }

  .step {
    position: relative;
    .circle {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      background-color: #363533;
      width: @DIEMETER;
      height: @DIEMETER;
      border-radius: 50%;
      transition: 0.3s ease;
    }
    .text {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      font-size: 20px;
      font-family: 'LGSmartUISB';
			color: #DBD7D4;
    }

    &.active{
			// background-position: center;
			// background-repeat: no-repeat;
			// background-size: contain;
			.circle {
				background-color: #CFBF8C;
			}
			.text {
				color: #363533;
			}

    }
  }
}
@__DEV__: true;