@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.twoVerticalBarGraph {
  .size(@w: 536px, @h: 397px);
  border-radius: 20px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.10) inset;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 30px;
  box-sizing: border-box;
  position: relative;
}

.titleContainer {
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
  margin-bottom: 60px;
  .title {
    .size(@w: auto, @h: 32px);
    line-height: 32px;
    font-size: 27px;
    color: #F2EFED;
    font-family: 'LGSmartUIB'; //font-weight: 700;
  }
  .textWrapper {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    .text {
      .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
      .size(@w: auto, @h: 25px);
      line-height: 25px;
      font-size: 21px;
      color: #F2EFED;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      &:nth-child(1) {
        margin-right: 18px;
      }
    }
    .text:nth-child(1)::before {
      content: "";
      display: block;
      .size(@w: 12px, @h: 12px);
      border-radius: 50%;
      margin-right: 9px;
      background-color: #8A6F5E;
    }
    .text:nth-child(2)::before {
      content: "";
      display: block;
      .size(@w: 12px, @h: 12px);
      border-radius: 50%;
      margin-right: 9px;
      background-color: #B59D85;
    }
  }
}
.lineContainer {
  .size(@w: 476px, @h: 193px);
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: unset, @direction: column);
  margin-bottom: 16px;
  position: relative;
  .line {
    .size(@w: 476px, @h: 1px);
    background-image: url("../../../../../assets/FitnessManager/dot_horizontal_line.png");
    background-position: center;
    background-repeat: repeat;
    background-size: contain;
    &.last {
      background-image: none;
      background-position: unset;
      background-repeat: unset;
      background-size: unset;
      background-color: #DBD7D4;
      z-index: 1;
    }
  }
  


  .graphContainer {
    .size(@w: 100%, @h: 100%);
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .verticalBarContainer {
      .size(@w: auto, @h: 100%);
      position: absolute;
      bottom: 0;
      &:nth-child(1) {
        left: 44px;
      }
      &:nth-child(2) {
        left: 214px;
      }
      &:nth-child(3) {
        left: 383px;
      }
      .verticalBarWrapper {
        .size(@w: 48px, @h: 100%);
        .flex(@display: flex, @justifyCenter: unset, @alignCenter: flex-end, @direction: row);
        gap: 8px;

        .verticalBar {
          .size(@w: 20px, @h: 0);
          border-radius: 10px 10px 0 0;
          transition: height 0.3s ease-in-out;
          &:nth-child(1) {
            background-color: #8A6F5E;
          }
          &:nth-child(2) {
            background-color: #b59d85;
          }
        }
      }
      .label {
        .size(@w: auto, @h: 25px);
        line-height: 25px;
        font-size: 21px;
        color: #DBD7D4;
        font-family: 'LGSmartUISB'; //font-weight: 600;
        position: absolute;
        top: ~"calc(100% + 17px)";
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .emptyContainer {
    .size(@w: 100%, @h: auto);
    .position(@position: absolute, @top: 29px, @right: unset, @bottom: unset, @left: 50%);
    transform: translate(-50%);
    text-align: center;
    .title {
      .size(@w: auto, @h: 32px);
      line-height: 32px;
      font-size: 27px;
      color: #DBD7D4;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      margin-bottom: 7px;
    }
    .text {
      .size(@w: auto, @h: auto);
      line-height: 28pxpx;
      font-size: 24px;
      color: #B2B0AE;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
  }
}



@__DEV__: true;