@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.cl_modalDimmer {
  background-color: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.modal{
  .flex(@alignCenter: flex-end);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;

  &.full {
    .progressBar {
      width: 1720px;
    }
  }
  &.half {
    width: 50%;
    .progressBar {
      width: 760px;
    }
  }
  &.half73 {
    width: 70%;
    .progressBar {
      width: 1144px;
    }
  }
  &.landscape{
    width: 50%;
    .progressBar {
      width: 760px;
    }
  }

  .container {
    height: 100%;
    width: 100%;
    padding: 0 100px 75px;
    .flex(@direction: column, @justifyCenter: flex-end);

    .progressBarContainer {

      .progressBar {
        display: block;
        height: 12px;
        margin: 0;
        padding: 0;
        z-index: 2;
        > div > div:nth-child(1) {
          background-color: rgba(254, 254, 254, 0.5) !important;
          border-radius: 10px;
          > div:nth-child(2) {
            background-color: @PRIMARY_COLOR_GREEN03;
            // background-color: @COLOR_GRAY01;
            opacity: 1;
          }
        }
        // knob
        >div>div:nth-child(2)::before {
          height: 30px;
          width: 30px;
          // background-color: @COLOR_GRAY02 !important;
          // box-shadow: unset;
          // border: unset;
          border-radius: 50%;
          transform: translate(-50%, -50%) !important;

          background-color: @PRIMARY_COLOR_GREEN03 !important;
          box-shadow: inset 0 0 0 5px @COLOR_GRAY02;
          border: unset;
        }

        &:focus {
          > div > div:nth-child(1) {
            background-color: rgba(254, 254, 254, 0.5) !important;
            border-radius: 10px;
            > div:nth-child(2) {
              background-color: @PRIMARY_COLOR_GREEN03;
              opacity: 1;
            }
          }
          // knob
          >div>div:nth-child(2)::before {
          // background-color: @PRIMARY_COLOR_GREEN03 !important;
          // box-shadow: inset 0 0 0 5px @COLOR_GRAY02;
          // border: unset;
          background-color: @COLOR_GRAY02 !important;
          box-shadow: unset;
          border: unset;
          }
        }
      }
      .timeContainer{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 16px;
        color: @COLOR_GRAY02;
        div {
          margin: 0;
        }
      }
      .bottomButton {
        display: flex;
        flex-direction: row;
        column-gap: 30px;
        justify-content: center;
        margin-top: 46px;
        .bButton {
          min-width: unset;
          width: auto;
          background-color: unset;
          div:last-child {
            width: unset !important;
            max-width: unset !important;
          }
          &:focus {
            background-color: #F2EFED;
          }
        }
      }
    }
  }



  .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .size(@w: 140px, @h: 140px);
    .flex();
    z-index: 2;

    .pause, .play {
      display: inline-block;
      width: 140px;
      height: 140px;
      background-repeat: no-repeat;
      background-size: contain;
      position: relative;
    }

    .pause {
      background-image: url(../../../../../assets/icon/ic_pause_nor.svg);
    }
    .play {
      background-image: url(../../../../../assets/icon/ic_play_nor.svg);
    }

    &:focus, &:hover {
      .pause {
        background-image: url(../../../../../assets/icon/ic_pause_foc.svg);
      }
      .play {
        background-image: url(../../../../../assets/icon/ic_play_foc.svg);
      }
    }
    .pressed {
      &::after{
        content: '';
        width: 140px;
        height: 140px;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #212120;
        border-radius: 50%;
        opacity: 0.2;
      }
    }
  }
}

@__DEV__: true;