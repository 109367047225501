@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.flexEnd {
  display: flex;
  justify-content: flex-end;
}
.workingStatusArea {
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
  margin-bottom: 3.75rem;
}

//만보기 영역
.stepsCount {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: column);
  width: 50%;
  .userStepsStatus {
    display: flex;
    justify-content: center;
    .font(@fontFamily: 'LGSmartUIR', @fontSize: 1.54rem, @color: #565353, @fontWeight: 400);
    margin-top: 1.1666rem;
  }
}

//그래프 영역
.stepsGraphArea {
  .size(@w: 32.5rem, @h: 20rem);
}

.walkingCountry {
  display: flex;
  justify-content: space-between;

  .gridInner {
    .position(@position: absolute, @top: 0, @bottom: null, @right: null, @left: 0);
    .size(@w: 100%, @h: 100%);
    color: #fff;
    padding: 1.25rem;
    box-sizing: border-box;

    .workingStory {
      opacity: 0.9;
    }
    .target {
      z-index: 5;
      .position(@position: absolute, @top: 50%, @bottom: null, @right: null, @left: 50%);
      transform: translate(-50%, -50%);
      .font(@fontFamily: 'fluxNumberB', @fontSize: 3.3333rem, @color: #fff, @fontWeight: 700);

      &:before {
        content: '';
        display: inline-block;
        .size(@w: 1.625rem, @h: 2.4167rem);
        .imgElement (@backgroundW: 1.625rem, @backgroundH: 2.4167rem, @positionX: center, @positionY: center);
        background-image: url("data:image/svg+xml,%0A%3Csvg width='39' height='58' fill='none' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cpath opacity='.8' fill='url(%23a)' d='M0 0h39v58H0z'/%3E%3Cdefs%3E%3Cpattern id='a' patternContentUnits='objectBoundingBox' width='1' height='1'%3E%3Cuse xlink:href='%23b' transform='scale(.02564 .01724)'/%3E%3C/pattern%3E%3Cimage id='b' width='39' height='58' xlink:href='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAA6CAYAAAAz60BwAAAACXBIWXMAAAsTAAALEwEAmpwYAAAECElEQVRogc2ZTWgeRRjHf3mT9CNRW6FCSVDRxgoWVGqL1mChhfoV0FMv6sWDF9GL0FJQBLH00IvSY1G8KXpSClURxYpooNSi1bQGP0ppbU2tlJC0r2nM38O8m2xmZze7z+yCfxjIzOz8+e1Mdp6Z50USOWWZpOcljUpqS5qVNCbpNUmrC8bVVvI6BiUdV77OStrUNFyXJDwNAV8AN/sdni4D9wPjSzxnlg/XDxwD7iw5fhQYBuZq5gKg5dX3Uh4M4AFgZ304i5WeuXXASaC3ose3wIN1QiVKz9wuqoMBbAFurQdnsRK4PuCpCJ+Ha2DJKIEbAa6P8NlWA0tGCVzsm2+IBQkpgdsS6TMEdEV6ZNQlaTkwDXRHeg0A5+ORFtQD3E48GMCNFMOtAnbgtqx/gKPAN0AmRM1L0vaCGFpFebG2W9IrkqYDY36StC0vtrZwIasOhWa/G3gPeB23Xfm6C/gMeCFk2AKuqwnuYqDtVZYOb93AAeCZTI+kkRqWdFJSj7cs6yXNVPCYlnSHv6zt6DmDz4FZr+1lqoXDPtwMzqsF/B3HBcDbXn0VttPKo8DWpNICzkVAAfwIHPbaRoCVRr8Xkz9auH/kGaMRwD6yh83tEX5P4PZMWrhN8Bej0a/AB4H2zUY/gGXAY7AQW38zGh0E/g20rzP6JXoIFuB+Npp8FGgbJH5jvwcW4MYMBpcIv9RaK1FKdwOtBO6kweBCTnsoTFVVP7C2p1M5BmyqaDCV036DGWmx5uFmcID/J/X1pCr9uM+4iibJfq1/RiGllQq0bxgC/k5lz2ErJF01ePm6L31vPWV4t5FAW5vwFlNVV9Jwlu1kB+GLzZsmnMWaSMNZtpMB3J7ka7RTrGoDl9JwfwETBqPhnPb9Bq9EZyCbZfreYJQXRz/EnrsbhyzccYNR3mVawDsGP+iERR/uK4PR5YK+jw1+kDNzR4ArFY1+L+g7ge2OcgqycFPAoYpG3xX0zWH7yMYhmxMGeAT4pKTJWeAWilIKEfJnDuBTyh8C3qIhMAjPHLhE9NcUJ3jOA+vJPzqBuyIOVWS6QHIjDATupOwuCMpTkoYLxiZljyHgv5SMX8r8WUkTqYGzkg5J2lACDElfGuAeT8bnLWtavbj4uRK3OYYSNnlLepHqGfrbgNPgkodL6Rq2U/JWqoNdpRNXIfy11iXLDydjpLIHTcINGsacSFeahPNTYmV0NF1pEm7SMOZIutIkXNXk0Dm8q0KTcD9UfP59vFBYZp+zqhf4A1hT8vl78U7iTc7cNeDdks8eJnBFaHLmAG7Cnc1WFzzTBjYSuP01OXPgwtfTuJ+TQhLwHHnX0pIBPLZs7hwC5lIB/oykJ4vGNb2svgZw57tJXBa+cKP+D5U32Z7HsOYjAAAAAElFTkSuQmCC'/%3E%3C/defs%3E%3C/svg%3E");
      }
    }
  }

  .dimmed {
    .position(@position: absolute, @top: 0, @bottom: null, @right: null, @left: 0);
    .size(@w: 100%, @h: 100%);
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
    border-radius: 1.25rem;
  }
}
.trButton{
  position: absolute;
  left: 344px;
  top: 362px;
  transition: .5s;
  &.outdoor{
    left: initial;
    right: 346px;
  }
  &:focus{
    transform: scale(1.228);
  }
}
@__DEV__: true;