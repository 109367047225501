@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.header {
  text-align: center;
  margin-top: 120px;
  .title {
    color: @COLOR_GRAY01;
    font-family: 'LGSmartUIB';
    font-size: 45px;
    line-height: normal;
  }
  .desc {
    color: @COLOR_GRAY04;
    font-family: 'LGSmartUIR';
    font-size: 30px;
    line-height: normal;
    margin-top: 21px;
  }
}

.container{
  margin-top: 46px;
  text-align: center;
  font-family: 'LGSmartUISB' !important;
  .termsList {
    display: inline-block;
    width: 510px;
    text-align: right;
    margin-right: 110px;
    .innerContainer {
      display: inline-block;
      height: 78px;
      &:nth-child(1) {
        width: 510px;
        > div > div:nth-child(2) {
          //title
          font-size: 30px;
          font-family: 'LGSmartUISB' !important;
        }
      }
      &:nth-child(2), &:nth-child(3) {
        width: 486px;
        > div > div:nth-child(2) {
          //title
          font-size: 27px;
          font-family: 'LGSmartUISB' !important;
        }
      }
      > div {
        width: calc(100% - 48px);
        height: 100%;
        padding: 0 24px;
        border-radius: 6px;
        > div:nth-child(1) {
          //check icon
          width: 30px;
          height: 30px;
          background-size: contain;
        }
      }
    }
    // .detail {
    //   text-decoration: underline;
    //   padding: 10px 24px;
    //   &:focus {
    //     color: @COLOR_GRAY01;
    //     font-family: 'LGSmartUIB';
    //     border-radius: 30px;
    //     background: rgba(254, 254, 254, 0.10);
    //   }
    //   &.pressed {
    //     color: @COLOR_GRAY01;
    //     font-family: 'LGSmartUIB';
    //     background: rgba(254, 254, 254, 0.15);
    //   }
    // }
  }
  .termsLayer {
    display: inline-block;
    vertical-align: top;
  }
}
.btnBox{
  display: flex !important;
  justify-content: center;
  column-gap: 20px;
  margin-top: 95px;
}
@__DEV__: true;