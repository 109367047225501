@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

@ITEM_WIDTH: 300px;
@ITEM_HEIGHT: 160px;
@ITEM_GAP: 20px;
@ITEM_TOP_BOTTOM_PADDING: 28px;
@ITEM_LEFT_RIGHT_PADDING: 115px;

.bodyAlignmentList {
    margin-bottom: 0;
    height: 290px;
    box-sizing: border-box;
}

.scroller {
    width: 100%;
}
.container {
    width: ~"calc(@{ITEM_WIDTH} * 9 + @{ITEM_GAP} * 8 )";
    gap: @ITEM_GAP;
    display: flex;
    padding: @ITEM_TOP_BOTTOM_PADDING @ITEM_LEFT_RIGHT_PADDING;
}

.title {    
    padding: 0 @ITEM_LEFT_RIGHT_PADDING;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    color: #000;
    font-size: 36px;
    height: 42px;
    line-height: 42px;
}
@__DEV__: true;