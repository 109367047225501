@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.viewer{
    position: absolute;
    right: 0;
    top:0;
    width: 100%;
    height: 100%;
}
@__DEV__: true;