@import '../../style/CommonStyle.module.less';

.tpanelmain{
	width: 100%;
	// padding: @PANEL_PADDING_TB @PANEL_PADDING_LR;
	height: 100%;
	background-size: cover;
	background: linear-gradient(to bottom, @BG_COLOR_03 0%, @BG_COLOR_03 43.5%, @MAIN_COLOR 43.5%,  @MAIN_COLOR 100%);
	box-sizing: border-box;
	section{
		padding: 0 !important;
		word-break: keep-all;
	}
	&.isDark {
		background: @COLOR_GRAY08;
  }
  &.isTransparent {
    background: none;
	}
}

.tHeader {
	width: 100%;
	height: 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25px;
	font-family: "LGSmartUIR";
	font-size: 18px;
	color: #706F69;
	font-weight: 400;

	//header 좌측 목차 & 우측 날짜 공통
	.mainTit {
		display: flex;
		align-items: center;
		flex-direction: row;
		height: inherit;

		span {
			display: flex;
			align-items: flex-end;
			margin-right: 1.125rem;

			&:first-child {
				font-size: 56px;
                line-height: 100%;
				letter-spacing: -0.02rem;
			}
			&:last-child {
				height: inherit;
				font-size: 1rem;
				margin-right: 0;

				time {
					font-size: 1rem;
					opacity: 0.7;
				}
			}
		}
	}
	//navigation버튼 없는 header
	&.noButtonHeader {
		.mainTit {
			width: 100%;
			justify-content: space-between;
		}
		&:last-child {
			font-size: 1.375rem;
		}
	}
}
@__DEV__: true;