@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.jMediaItem {
  height: auto;
  background-color: transparent;
  padding: 10px 0;
  height: 328px;

  // background-color: red;

  .commonAbsolute {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    .iconLeft {
      position: absolute;
      top: 20px;
      left: 20px;
      padding: 4px 13px;
      border-radius: 8px;
      background-color: @PRIMARY_COLOR_GREEN02;
      font-family: "LGSmartUI";
      font-size: 26px;
      font-weight: 700;
      height: 30px;
      &.iconCheck {
        padding: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 2apx solid rgba(255, 255, 255, 0.4);
        background-color: rgba(255, 255, 255, 0.2);
        &.selected {
          z-index: 2;
          background-image: url("../../../assets/ic_checked_white.png");
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          border: none;
        }
      }
    }

    .iconRight {
      position: absolute;
      top: 20px;
      right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      z-index: 5;
    }
  }

  .imageArea {
    position: relative;
    width: calc(427px);
    height: 240px;
    // background-color: blue;
    padding: 0 24px;
    &:focus {
      z-index: 99;
    }
  }
  .imageBox {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 427px;
    height: 240px;
    border-radius: 12px;
    overflow: hidden;
    transition: transform 0.3s;
    transform: scale(1);
    transform-origin: center center;

    .focusCover {
      opacity: 1;
      z-index: 1;
      &.selected {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .textArea {
    // position: absolute;
    // top: 262px;
    width: calc(427px);
    padding: 0 18px;
    margin-top: 12px;
  }

  .image {
    // display: block;
    width: 427px;
    object-fit: cover;
  }

  &.large .image {
    height: 240px;
  }

  .title {
    margin-bottom: 8px;
    font-family: "LGSmartUISB";
    font-size: 30px;
    color: @COLOR_GRAY07;
    &.centerTitle {
      font-family: "LGSmartUIR";
      font-size: 27px;
      line-height: normal;
      > div {
        text-align: center;
      }
    }
  }
  .subTitle {
    position: relative;
    display: flex;
    align-items: center;

    .subText {
      font-family: "LGSmartUI";
      font-size: 28px;
      color: @COLOR_GRAY05;

      &::after {
        content: "";
        margin: 0 12px;
        display: inline-block;
        width: 1px;
        height: 17px;
        background-color: @COLOR_GRAY04;
      }
    }

    .subText:last-child::after {
      display: none;
    }
  }
  .playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
    height: 100px;
    z-index: 2;
    background-image: url("../../../assets/ic_play_thumb.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .playTime {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: @COLOR_GRAY09;
    opacity: 0.7;
    border-radius: 12px;
    padding: 3px 14px;
    height: 28px;

    // text type
    color: @COLOR_GRAY01;
    font-size: 24px;
    font-family: "LGSmartUI";
    font-weight: 600;
  }

  .totalGauge {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8px;
    background-color: #fefefe80;
  }
  .playGauge {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 8px;
    background-color: @PRIMARY_COLOR_GREEN02;
  }
  &:focus {
    z-index: 10;

    .playIcon {
      background-image: url("../../../assets/ic_play_foc.png");
    }

    // outline: 10px solid orange; /* 포커스된 요소 주위에 주황색 아웃라인 추가 */

    .imageBox {
      transform: scale(1.08);
      // box-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
      box-shadow: @MAIN_BOX_SHADOW;
    }

    .focusCover {
      box-shadow: inset 0 0 0 4px @PRIMARY_COLOR_BROWN03;
      border-radius: 12px;
      &.selected {
        background-color: transparent;
      }
      &.pressed {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.15);
          border-radius: inherit;
        }
      }
    }
  }

  &.medium {
    width: 296px;
    height: 257px;
    .imageArea {
      position: relative;
      width: 296px;
      height: 166px;
      padding: 0 18px;
      &:focus {
        z-index: 99;
      }
      .imageBox {
        height: 100%;
        width: calc(100% - 36px);
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
    .textArea {
      font-family: "LGSmartUISB";
      width: 100%;
      .title {
        color: @COLOR_GRAY07;
        font-size: 27px;
      }
      .subTitle {
        color: @COLOR_GRAY05;
        font-size: 27px;
      }
    }
  }

  .countContainer {
    display: flex;
    justify-content: center;
    column-gap: 46px;
    margin-top: 24px;
    .count {
      color: @COLOR_GRAY07;
      text-align: center;
      font-family: "LGSmartUISB";
      font-size: 30px;
      height: 35px;
    }
  }
}

@__DEV__: true;