@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.dataLayer {
	position: absolute;
	.size(@w: 548px, @h: 190px);
	right: 116px;
	top: 253px;
	// background-color: rgba(240, 248, 255, 100);
	text-align: center;
	display: flex;
	justify-content: space-between;

	.graphWrapper {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 190px;
		height: 190px;

		.textWrapper {
			position: absolute;
			height: 53px;

			.graphNum {
				color: @PRIMARY_COLOR_BROWN03;
				font-size: 45px;
				font-family: "LGSmartUI";
				font-weight: 700;
				height: 53px;
				line-height: 1.2;
			}
			.graphPer {
				color: @PRIMARY_COLOR_BROWN03;
				font-size: 26px;
				font-family: "LGSmartUI";
				font-weight: 600;
			}
		}
	}
	.infoWrapper {
		position: relative;
		width: 100%;
		height: 120px;

		margin: 35px 0;
		margin-left: 42px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		.goalComponent {
			position: relative;
			display: flex;
			align-items: center;
			color: @COLOR_GRAY05;
			font-size: 24px;
			font-family: "LGSmartUI";
			font-weight: 600;
			transition-duration: 0.3s;
			transition-property: color, font-size, font-weight;
			// width: 100%;
			&:focus::after {
				content: "";
				position: absolute;
				display: inline-block;
				width: 100%;
				height: 100%;
				border: 5px solid white;
				border-radius: 10px;
				left: -6px;
				padding: 4px;
			}

			&.active {
				color: @PRIMARY_COLOR_BROWN03;
				font-size: 32px;
				font-weight: 700;

				.goalInfo {
					opacity: 0.5;
				}
			}
		}
	}
	&.isDark {
		.graphNum, .graphPer {
			color: @COLOR_GRAY03 !important;
		}

		.goalComponent {
			&.active {
				color: @COLOR_GRAY03;
				.goalInfo {
					opacity: unset;
				}
			}
		}
	}
}
@__DEV__: true;