@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

.datePicker {
	display: flex;
	height: 37px;
	justify-content: space-between;
	align-items: center;
	margin: 0 auto 42px;

	.textLayer {
		width: 300px;
		height: 100%;
		position: relative;
		overflow: hidden;

		.text {
			position: absolute;
			width: 100%;
			font-family: "LGSmartUIB";
			font-size: 33px;
			color: @COLOR_GRAY07;
		}

		.text:first-child {
			transform: translateX(-100%);
		}

		.text:last-child {
			transform: translateX(100%);
		}

		.text.inc {
			transition: all 300ms ease;
			transform: translateX(-100%);

			&:first-child {
				transform: translateX(-200%);
			}

			&:last-child {
				transform: translateX(0%);
			}
		}

		.text.dec {
			transition: all 300ms ease;
			transform: translateX(100%);

			&:first-child {
				transform: translateX(0%);
			}

			&:last-child {
				transform: translateX(200%);
			}
		}
  }

	.arrowContainer{
		display: flex;
		gap: 16px;
		.arrow {
			width: 36px;
			height: 36px;
			background-size: 36px 36px;
		}
	}

}



&.isDark {
	.textLayer .text {
		color: #FEFEFE;
	}
}
@__DEV__: true;