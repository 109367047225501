.container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.aniFrame{
  position: relative;
  z-index:  1;
  top: 30%;
  left: 50%;
  transform: translate(-6.5%);
}
.aniItemAttr () {
  position: absolute;
  margin-left: 3px;
  opacity: 0;
  transform: scale(2.0);
  animation-fill-mode: forwards;
 }
.good12 {
  animation: goodSignShow 0.06s linear 0.6s;
  .aniItemAttr();
  margin-left: 0px;
}

.good11 {
  margin-top: 15px;
  animation: goodSignShow 0.06s linear 0.55s;
  .aniItemAttr();
}
.good10 {
  margin-top: 30px;
  animation: goodSignShow 0.06s linear 0.5s;
  .aniItemAttr();
}
.good9 {
  margin-top: 45px;
  animation: goodSignShow 0.06s linear 0.45s;
  .aniItemAttr();
}
.good8 {
  margin-top: 60px;
  animation: goodSignShow 0.06s linear 0.4s;
  .aniItemAttr();
}
.good7 {
  margin-top: 75px;
  animation: goodSignShow 0.06s linear 0.35s;
  .aniItemAttr();
}
.good6 {
  margin-top: 90px;
  animation: goodSignShow 0.06s linear 0.3s;
  .aniItemAttr();
}
.good5 {
  margin-top: 105px;
  animation: goodSignShow 0.06s linear 0.25s;
  .aniItemAttr();
}
.good4 {
  margin-top: 120px;
  animation: goodSignShow 0.06s linear 0.2s;
  .aniItemAttr();
}
.good3 {
  margin-top: 135px;
  animation: goodSignShow 0.06s linear 0.15s;
  .aniItemAttr();
}
.good2 {
  margin-top: 150px;
  animation: goodSignShow 0.06s linear 0.1s;
  .aniItemAttr();
}
.good1 {
  margin-top: 165px;
  animation: goodSignShow 0.06s linear 0.05s;
  .aniItemAttr();
}

@keyframes goodSignShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@__DEV__: true;