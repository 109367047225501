@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

/* DeviceStatus.module.less */
.container {
  display: flex;
  // width: 925px;
  justify-content: space-between;
  align-items: center;
  padding: 40px 6px 40px 0px;
  border-bottom: 1px solid #464646;
  font-family: "LGSmartUISB";
  color: #464646;
}

.noBorder {
  border-bottom: none;
}

.deviceNameCode {
  font-size: 27px;
  margin-right: 10px;
}

.status {
  display: inline !important;
  position: relative;
  width: 90px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  margin-right: 13px;
  &::after{
    content: "";
    position: absolute;
    width: 1px;
    height: 21px;
    right: -13px;
    top: 3.5px;
    background-color: @COLOR_GRAY03;
  }
}

.batteryInfo {
  display: inline;
  position: relative;
  margin-top: 5px;
  margin-left: 27px;
  font-size: 24px;
  &::before{
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/TSettingSection/ic_battery.png");
    background-size: contain;
    top: 3.5px;
    left: -20px;
  }
}

.buttonWrapper {
  .button{
    font-size: 24px;
    margin: 0 !important;
  }
}
@__DEV__: true;