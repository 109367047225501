@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@DIEMETER: 25px;

.horizontal {
  &.stepper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;

    .fixedBar {
      position: absolute;
      background-color: #d1d1d1;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 3px;
      width: 100%;
    }

    .stepBarMy {
      position: absolute;
      background-color: black;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 3px;
      width: 50%;
      transition: 0.4s ease;
      z-index: 10;

      .progressMy {
        position: absolute;
        bottom: 12px;
        right: 0;
        transform: translateX(50%);
        width: 70px;
        height: 50px;
        border-radius: 46%;
        background-color: rgb(60, 60, 60);
        color: white;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;
        z-index: 10;

        &::before {
          position: absolute;
          display: block;
          content: "";
          bottom: -8px;
          width: 0;
          height: 0;
          border-top: 10px solid rgb(60, 60, 60);
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          z-index: 10;
        }
      }
    }

    .stepBarProgram {
      position: absolute;
      background-color: #8c8c8c;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      height: 3px;
      width: 50%;
      transition: 0.4s ease;
      z-index: 1;

      .progressProgram {
        position: absolute;
        bottom: 12px;
        right: 0;
        transform: translateX(50%);
        width: 70px;
        height: 50px;
        border-radius: 46%;
        background-color: #b3b3b3;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: 1;
        z-index: 1;

        &::before {
          position: absolute;
          display: block;
          content: "";
          bottom: -8px;
          width: 0;
          height: 0;
          border-top: 10px solid #b3b3b3;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          z-index: 1;
        }
      }
    }
    .step {
      position: relative;
      z-index: 5;
      &.activeMy {
        .circle {
          background-color: black;
        }
        .text {
          color: black;
        }
      }
      &.activeProgram {
        .circle {
          background-color: #8c8c8c;
        }
        .text {
          color: #8c8c8c;
        }
      }
      .circle {
        background-color: #d1d1d1;
        width: @DIEMETER;
        height: @DIEMETER;
        border-radius: 50%;
        transition: 0.4s ease;
      }
      .text {
        color: #d1d1d1;
        font-size: 23px;
        position: absolute;
        white-space: nowrap;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        transition: 0.4s ease;
      }
    }
  }
}

@__DEV__: true;