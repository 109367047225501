@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.contentContainer {
  // padding: 0 75px 75px 75px;
  .contentTitle {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;
    .title {
      font-size: 30px;
      // margin-bottom: 28px;
      color: @COLOR_GRAY08;
      font-family: 'LGSmartUISB';
      // height: 40px;
      line-height: 60px;
      .img {
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-right: 12px;
        vertical-align: text-top;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../../../../../assets/HomecareProgram/ic_ai_tit.png);
      }
      > div {
        display: inline-block;
      }
    }
    .edit {
      width: auto;
      max-width: unset;
      min-width: unset;
      background-color: unset;
      > div:nth-child(2) {
        width: auto;
      }
      &:focus {
        background-color: @PRIMARY_COLOR_BROWN03;
        transform: scale(1);
      }
    }
  }

  .doPlan {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
    .description {
      color: @COLOR_GRAY07;
      text-align: center;
      font-size: 30px;
      line-height: 40px;
      height: 80px;
      margin-bottom: 36px;
      font-family: 'LGSmartUISB';
    }
    .playButton{
      margin-bottom: 78px;
    }
  }

  .weeklyList {
    display: flex;
    column-gap: 10px;
    // margin-bottom: 40px;
    .weekly {
      border: 1px solid @COLOR_GRAY03;
      border-radius: 16px;
      line-height: normal;
      position: relative;
      // background-color: transparent;
      // background-color: #b59d854d; // temp: @PRIMARY_COLOR_BROWN01, opacity 30%
      padding: 17px 30px;
      height: 116px;
      .day {
        color: @COLOR_GRAY05;
        font-family: "LGSmartUISB";
        font-size: 27px;
        margin-bottom: 10px;
        text-align: left;
      }
      .dateLayer{
        display: flex;
        justify-content: space-between;
        .date {
          color: @COLOR_GRAY06;
          font-family: "LGSmartUIB";
          font-size: 39px;
        }
        .routine {
          width: 28px;
          height: 28px;
          background: url(../../../../../assets/icon/ic_homecare_dumbbell_nor.png) no-repeat 0 0;
          align-self: self-end;
        }
      }
      &:focus, &.select {
        // background-color: @PRIMARY_COLOR_BROWN03;
        background-color: @PRIMARY_COLOR_BROWN01;
        .day {
          color: @COLOR_GRAY01;
        }
        .date {
          color: @COLOR_GRAY01;
        }
        .routine {
          background: url(../../../../../assets/icon/ic_homecare_dumbbell_foc.png) no-repeat 0 0;
        }
      }
      &.disabled {
        opacity: 0.3;
      }
    }
  }

  .mediaContainer {
    .titleLayer {
      color: @COLOR_GRAY06;
      font-size: 27px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      .playCount {
        font-size: 30px;
        .stick {
          display: inline-block;
          width: 6px;
          height: 27px;
          background-color: @PRIMARY_COLOR_BROWN02;
          margin-right: 12px;
          vertical-align: middle;
        }
        .countTitle {
          font-family: "LGSmartUISB";
          margin-right: 6px;
          vertical-align: middle;
        }
        .count {
          font-family: "LGSmartUIB";
          vertical-align: middle;
        }
      }
      .playInfo {
        display: flex;
        align-items: center;
        .process {
          font-family: "LGSmartUISB";
        }
        .stick {
          display: inline-block;
          width: 2px;
          height: 23px;
          background-color: @COLOR_GRAY03;
          margin: 0 14px;
        }
        .curProcess {
          font-family: "LGSmartUIB";
          margin-left: 8px;
        }
      }
      .playTime {
        .curTime {
          font-family: "LGSmartUIB";
          margin-left: 8px;
        }
        .totalTime {
          font-family: "LGSmartUISB";
        }
      }
    }
    .noneContent {
      text-align: center;
      line-height: 60px;
      .playButton{
        margin-top: 30px;
      }
    }
  }
  .buttonLayer {
    display: flex;
    margin-top: 32px;
    justify-content: center;
  }
}
@__DEV__: true;