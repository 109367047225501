@import "../../../style//CommonStyle.module.less";
@import "../../../style//utils.module.less";
@WEEKLY_HEIGHT :160px;
@WEEKLY_MARGIN_TOP :46px;
@WEEKLY_MARGIN_BOTTOM :60px;
@CONTENT_PADDING: 75px;

.panel {
  background: @BG_COLOR_02;
}

// .tbody {
//   height: calc(100% - @fitTv-tHeader-height - @CONTENT_MARGIN_BOTTOM);
// }
.scroller {
  height: calc(100% - @fitTv-tHeader-height - @WEEKLY_HEIGHT - @WEEKLY_MARGIN_TOP - @WEEKLY_MARGIN_BOTTOM);
  .container {
    row-gap: 40px;
    margin-bottom: 50px;
  }
}

.weeklyList {
  display: flex;
  column-gap: 10px;
  margin: @WEEKLY_MARGIN_TOP 0 @WEEKLY_MARGIN_BOTTOM 75px;
  .weekly {
    width: 245px;
    // height: @WEEKLY_HEIGHT;
    border: 1px solid @COLOR_GRAY03;
    border-radius: 16px;
    line-height: normal;
    padding: 20px 30px;
    position: relative;
    background-color: @COLOR_GRAY01;
    > div {
      text-align: left;
    }
    .day {
      color: @COLOR_GRAY05;
      font-family: "LGSmartUISB";
      margin-bottom: 12px;
      font-size: 27px;
    }
    .vr {
      width: 197px;
      height: 1px;
      background: @COLOR_GRAY03;
      margin: 0 auto 12px;
    }
    .count {
      color: @COLOR_GRAY06;
      font-family: "LGSmartUISB";
      font-size: 27px;
    }
    .time {
      color: @COLOR_GRAY06;
      font-family: "LGSmartUISB";
      font-size: 24px;
      margin-top: 6px;
    }

    &:focus, &.select {
      // background-color: @PRIMARY_COLOR_BROWN03;
      background-color: @PRIMARY_COLOR_BROWN01;
      transform: scale(1);
      .day, .count, .time {
        color: @COLOR_GRAY01;
      }
    }
  }
}

.filterSection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  padding-left: @CONTENT_PADDING;
  padding-right: @CONTENT_PADDING;
  > div:nth-child(1) {
    //dropdown wrap
    display: flex;
    column-gap: 20px;
  }
}

.category {
  width: auto;
  color: @COLOR_GRAY06;
  margin-bottom: 48px;

  // .total {
  //   display: block;
  //   height: 124px;
  //   width: 192px;
  //   border-radius: 74px;
  //   color: @COLOR_GRAY05 !important;
  //   text-align: center;
  //   font-family: "LGSmartUISB";
  //   font-size: 33px;
  //   line-height: normal;
  //   background-color: unset;
  //   > div:nth-child(1) {
  //     display: block;
  //     margin: 0 auto 10px;
  //   }
  //   > div:nth-child(2) {
  //     width: auto;
  //     > div {
  //       text-align: center;
  //     }
  //     display: block;
  //     margin: 0 auto 10px;
  //   }

  //   &.selected{
  //     .title{
  //       color: @COLOR_GRAY05 !important;
  //     }
  //   }
  //   &:focus {
  //     transform: scale(1);
  //     .title{
  //       color: @COLOR_GRAY05 !important;
  //     }
  //   }
  // }

  .categoryTitle {
    color: @COLOR_GRAY06;
    font-family: "LGSmartUISB";
    font-size: 30px;
    margin-bottom: 28px;
    padding-left: @CONTENT_PADDING;
  }
  .mediaList {
    // width: 1869px;
    // transform: translateX(-24px);
    width: calc(100% + 75px);
    transform: translateX(-75px);
    > div > div {
      height: 420px;
    }
  }
}

// .desc {
//   color: @COLOR_GRAY05;
//   text-align: center;
//   font-family: "LGSmartUISB";
//   font-size: 27px;
//   line-height: normal;
//   margin-top: 80px;
// }

.btnArea {
  display: flex;
  column-gap: 24px;
  justify-content: center;
  margin: 75px 0;
  height: 160px;
}

@__DEV__: true;