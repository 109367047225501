@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";
@CONTENT_PADDING:  75px;

.panel {
  font-family: "LGSmartUIR";
  color: black;
  width: 100%;
  height: 100%;

}
.workoutContainer {
  background-color: @COLOR_GRAY01;
  height: 738px;
  border-radius: 0 0 12px 12px;
}

.scroller{
  height: inherit;
  width: 1664px;
  color: @COLOR_GRAY08;
  padding: 0;
  border-radius: 0 0 12px 0 ;
  > div {
    padding-top: 50px;
  }
  .content {
    height: 100%;
    padding-bottom: @CONTENT_PADDING;
  }
}

.noScroll {
  padding: 60px 0 50px 0;
  > div:nth-child(1) {
    // weekly
    padding: 0 @CONTENT_PADDING 50px @CONTENT_PADDING;
  }
  .scroller{
    height: 418px;
    width: 100%;
    color: @COLOR_GRAY08;
    .content {
      height: 100%;
      padding-bottom: @CONTENT_PADDING;
    }
    > div {
      padding-top: 0;
    }
  }
}

.aiLayer {
  .mediaLists {
    padding-bottom: 60px;
    .titleLayer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - @CONTENT_PADDING);
      padding-left: @CONTENT_PADDING;
      .playButton {
        min-width: unset;
        max-width: unset;
        margin-right: 75px;
      }
      .day {
        padding: 6px 16px;
        text-align: center;
        border-radius: 7px;
        display: inline-block;
        color: @COLOR_GRAY01;
        font-family: "LGSmartUIB";
        font-size: 27px;
        margin-right: 14px;
        min-width: 76px;
        box-sizing: border-box;
        &.today {
          background: @PRIMARY_COLOR_BROWN03;
          border: unset;
        }
        &.nextday {
          border: 1px solid @COLOR_GRAY04;
          background: unset;
          color: @COLOR_GRAY06;
          > span:last-child {
            color: @COLOR_GRAY05;
          }
        }
      }
      .title {
        color: @COLOR_GRAY07;
        font-family: "LGSmartUIB";
        font-size: 36px;
        line-height: normal;
        display: inline-block;
      }
      .progressItem {
        color: @COLOR_GRAY06;
        font-family: "LGSmartUISB";
        font-size: 27px;
        display: flex;
        column-gap: 8px;
        margin-top: 8px;
        .percent {
          font-family: "LGSmartUIB";
        }
        .vr {
          margin: 0 6px;
          width: 2px;
          height: 23px;
          background: @COLOR_GRAY03;
          vertical-align: middle;
        }
        .playTime {
          font-family: "LGSmartUIB";
        }
        .grayTime {
          font-family: "LGSmartUISB";
          opacity: 0.7;
        }
      }
    }
  }
}

.topLayer {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 1514px;
  .partScroller{
    width: 1514px;
    margin-bottom: 49px;
    .partLayer {
      display: flex;
      flex-direction: row;
      column-gap: 8px;
      //button
      > div {
        min-width: unset;
        max-width: unset;
        font-size: 21px;
        &:hover,
        &:focus {
          background-color: @PRIMARY_COLOR_BROWN03;
          color: @COLOR_GRAY01;
          transform: scale(1);
        }
        &.pressed {
          opacity: 1;
        }
        &.selected {
          transform: scale(1);
        }
      }
    }

  }
  .mediaItem > img {
    object-fit: contain;
  }
}


.barContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 46px 40px;
  .info {
    display: flex;
    align-items: center;

    .title {
      width: 204px;
      padding: 25px 20px;
      box-sizing: border-box;
      font-family: 'LGSmartUIB';
      font-size: 21px;
      border-radius: 12px;
      text-align: center;
      border: 2px solid @COLOR_GRAY03;
      color: @PRIMARY_COLOR_BROWN03;
      margin-right: 20px;
      line-height: normal;
      .lastDate {
        color: @COLOR_GRAY05;
        font-size: 21px;
        font-family: 'LGSmartUIL';
      }
    }
    .bars {
      display: flex;
      flex-direction: column;
      gap: 16px;
      height: 54px; // bar+gap+text
      &.notScore {
        height: unset;
      }
    }
  }
}

.mediaLayer {
  margin-top: 62px;
  .levelTitle {
    display: flex;
    column-gap: 12px;
    color: @COLOR_GRAY08;
    font-family: 'LGSmartUIB';
    font-size: 36px;
    line-height: normal;
    .level {
      padding: 4px 13px;
      border-radius: 8px;
      background: @PRIMARY_COLOR_BROWN01;
      color: @COLOR_GRAY01;
      font-size: 30px;
      // height: 43px;
    }
  }
}

.contentLayer {
  // height: 359px;
  // margin-top: 28px;
  width: calc(100% + @CONTENT_PADDING);
  transform: translateX(-75px);
}

@__DEV__: true;