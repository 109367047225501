@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.item {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: middle;
  background-color: @COLOR_GRAY02;
  color: @COLOR_GRAY08;
  transition: background-color 0.3s ease;

  // size
  &.medium {
    width: auto;
    height: 52px;
    line-height: 52px;
    padding: 0 36px;
    font-size: 27px;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    min-width: 113px;
    max-width: 400px;
  }

  // shape
  &.square {
    border-radius: 12px;
  }
  &.round {
    border-radius: 35px;
  }

  &.selected {
    background-color: @COLOR_GRAY08;
    color: @COLOR_GRAY01;
  }
  &:hover,
  &:focus {
    background-color: @COLOR_GRAY08;
    color: @COLOR_GRAY01;
  }
  &.disabled {
    opacity: 0.3;
  }
  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      border-radius: inherit;
      opacity: 0.3;
    }
  }
}

// darkMode
.isDark {
  background-color: transparent;
  color: #DBD7D4;

  &.selected {
    background-color: rgba(254, 254, 254, 0.10);
    color: #FEFEFE;
  }
  &:focus {
    background-color: #FEFEFE;
    color: #212120;
  }
}
@__DEV__: true;