@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

.stepper {
  position: absolute !important;
  left: 50%;
  top: 169px;
  transform: translateX(-50%);
}


.description {
  position: absolute;
  top: 212px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: max-content;
  >h1 {
    color: #FEFEFE;
    font-family: 'LGSmartUISB';
    height: 42px;
    line-height: 42px;
    margin: 0;
    margin-bottom: 16px;

  }
  >p {
    color: #B2B0AE;
    font-family: 'LGSmartUISB';
    height: 33px;
    line-height: 33px;
  }
}

.scroller {
  position: absolute;
  top: 362px;
  left: 50%;
  transform: translateX(-50%);
  height: 717px;
}

.innerContainer {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  padding:0 75px 70px 75px;
  height: auto;

}

.basic {
  width: 1520px;
  height: 425px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.03);
  position: relative;
  >div {
    position: absolute;
    &:nth-child(1){
      top: 130px;
      left: 263px;
    }
    &:nth-child(2){
      top: 130px;
      left: 838px;
    }
    &:nth-child(3){
      top: 241px;
      left: 263px;
    }
    &:nth-child(4){
      top: 241px;
      left: 838px;
    }
  }
  &::after {  // border
    content: "";
    width: 2px;
    height: 219px;
    position: absolute;
    top: 95px;
    left: 724px;
    background-color: #B2B0AE;
    opacity: 0.3;
  }
}

.body, 
.exercise, 
.life {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;

}

.buttonContainer {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .instruction {
    font-family: 'LGSmartUISB';
    font-size: 26px;
    margin-bottom: 10px;
    text-align: center;
    color: #B2B0AE;
    height: 30px;
    line-height: 30px;
    margin-bottom: 40px;
  }
  .buttonArea{
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    gap: 40px;
  }

}



.lastContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 171px;
  .mark {
    margin-bottom: 68px;

  }
  .message {
    margin-bottom: 78px;
    >span:nth-child(1) {
      display: block;
      text-align: center;
      font-family: 'LGSmartUIB';
      height: 37px;
      line-height: 37px;
      font-size: 32px;
      color: #FEFEFE;
      margin-bottom: 9px;
    }
    >span:nth-child(2) {
      display: block;
      text-align: center;
      font-family: 'LGSmartUIR';
      height: 72px;
      line-height: 36px;
      font-size: 28px;
      color: #FEFEFE;
    }
  }
}
@__DEV__: true;