@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.tabContent {
  position: relative;
  padding: 54px 0 0 0;
  height: 785px;
  font-family: "LGSmartUIR";
  color: black;
  background-image: url("../../../../../assets/physicalTest/bodycheck_gate_physical.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  .dateAgo {
    display: inline-flex;
    padding: 8px 20px;
    align-items: center;
    height: 32px;
    line-height: 1.2;
    border: 2apx solid @COLOR_GRAY05;
    border-radius: 7px;
    .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 600);
  }
  .title {
    font-family: "LGSmartUIB";
    font-size: 62px;
    letter-spacing: -1.24px;
    margin-top: 14px;
    line-height: 85px;
    color: @COLOR_GRAY08;
  }
  .detail {
    font-size: 30px;
    font-weight: 400;
    padding: 24px 0 32px 0;
    color: @COLOR_GRAY07;
    line-height: 42px;
  }
  .flexBox {
    display: flex;
    justify-content: flex-start;
    font-size: 28px;
    font-weight: 400;
    color: @COLOR_GRAY08;
    > div > span {
      display: inline-block;
      width: 36px;
      height: 36px;
      background-size: cover;
      background-repeat: no-repeat;
      margin-right: 12px;
    }
    .align {
      display: flex;
      align-items: center;
      margin-right: 30px;
    }
  }
  .testBtn {
    padding: 63px 0;
    > div {
      margin-left: 0;
      margin-right: 24px;
    }
  }
  .tip {
    position: absolute;
    bottom: 75px;
    font-size: 27px;
    font-weight: 400;
    color: @COLOR_GRAY06;
    line-height: normal;
    > div {
      display: flex;
      align-items: center;

      &:first-child {
        padding-bottom: 12px;
      }

      > span {
        display: inline-block;
        width: 22px;
        height: 22px;
        background-image: url("../../../../../assets/icon/alert_circle.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 8px;
        vertical-align: middle;
      }
    }
  }
}
.button {
  font-size: 33px !important;
  padding: 24px 50px !important;
  min-width: 328px !important;
  height: 100% !important;
}

// @BUTTON_WIDTH: 220px;
// @BUTTON_HEIGHT: 35px;
// @H_SPACING: 30px;
// @V_SPACING: 24px;
// .button {
//   width: calc(@BUTTON_WIDTH + @H_SPACING * 2);
//   height: calc(@BUTTON_HEIGHT + @V_SPACING * 2);
//   padding: @V_SPACING @H_SPACING;
//   line-height: 1.1;
//   > div {
//     font-size: 30px;
//     height: @BUTTON_HEIGHT;
//   }
// }

@__DEV__: true;