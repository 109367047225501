@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.container {
  position: absolute;
  bottom: 52px;
  left: 52px;
  border-radius: 12px;
  background: #000;
  opacity: 0.95;

  .imgLayer {
    width: 100%;
    height: 285px;
    background-image: url("../../../assets/images/workout/img_workout_autoplay.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: relative;
    border-radius: 12px 12px 0px 0px;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: @COLOR_GRAY09;
      opacity: 0.4;
      border-radius: 12px 12px 0px 0px;
    }
    .countdown {
      position: absolute;
      top: 24px;
      left: 24px;
      color: @COLOR_GRAY01;
      font-family: "LGSmartUIB";
      > span {
        color: @PRIMARY_COLOR_GREEN03;
      }
    }
    .autoPlayStop {
      position:absolute;
      top:50%;
      left:50%;
      transform: translate(-50%,-50%);
      background-color: rgba(255, 255, 255, 0.20);
      border: 2px solid @COLOR_GRAY01;
      color: #fff;
      > div > span {
        display: inline-block;
        width: 24px;
        height: 25px;
        background-image: url("../../../assets/icon/autoplay_stop.png");
        background-repeat: no-repeat;
        margin-right: 4px;
        vertical-align: middle;
      }
    }
  }

  .textContainer{
    width: 506px;
    // height: 227px;
    .textLayer{
      padding: 24px;
      .nextTitle {
        font-family: "LGSmartUIB";
        font-size: 28px;
        color: @COLOR_GRAY01;
        margin-bottom: 12px;
      }
      .timeLayer {
        display: flex;
        flex-direction: row;
        column-gap: 9px;
        margin-bottom: 37px;
        .nextDetail {
          font-family: "LGSmartUIL";
          font-size: 24px;
        }
        span {
          font-size: 15px;
        }
        .nextDuration {
          font-family: "LGSmartUIL";
          font-size: 24px;
          margin: 0;
        }
      }
      .flexBox {
        // display: flex;
        // flex-direction: row;
        // column-gap: 10px;
        .testBtn {
          width: 458px;
          max-width: unset;
          height: 54px;
          font-family: "LGSmartUIB";
          font-size: 22px;
          padding: 14px 16px;
          line-height: 27px;
          margin-bottom: 16px;
        }
        .retryBtn {
          width: 458px;
          max-width: unset;
          height: 54px;
          font-family: "LGSmartUIB";
          font-size: 22px;
          padding: 14px 16px;
          line-height: 27px;
        }
      }
    }
  }
}

@__DEV__: true;