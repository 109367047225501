@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.scroller {
  height: 820px;
}

.activityCotainer {
  margin-top: 60px;
  padding: 0 75px 0 75px;
  .title {
    font-size: 36px;
    height: 42px;
    line-height: 42px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    margin-bottom: 28px;
  }
  .list {
    display: flex;
    gap: 28px;
  }
}

.subscriptionCotainer {
  margin-top: 60px;
  padding: 0 75px 0 75px;
  .titleLayer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    .title {
      font-size: 35px;
      height: 41px;
      line-height: 41px;
      color: #fefefe;
      font-family: "LGSmartUIB"; //font-weight: 700;
    }
    .totalRecordBtn {
      .font(@fontFamily: "LGSmartUISB" , @fontSize: 27px, @color: @COLOR_GRAY02, @fontWeight: 600);
      height: 32px;
      padding: 14px 24px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      overflow: hidden;
      &::after {
        content: "";
        display: inline-block;
        margin: 1px 0 3px 4px;
        width: 28px;
        height: 28px;
        background-image: url("../../../../assets/inHomeOutdoorActivity/ic_right_28_gray02.svg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }
      &:focus {
        background-color: @COLOR_GRAY01;
        color: @COLOR_GRAY08;
        &::after {
          background-image: url("../../../../assets/inHomeOutdoorActivity/ic_right_28_gray08.svg");
        }
      }
      &.pressed {
        position: relative;
        &::before {
          content: "";
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          right: 0;
          background-color: @COLOR_GRAY08;
          opacity: 0.3;
        }
      }
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
}
@__DEV__: true;