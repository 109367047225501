@import '../../../../style/CommonStyle.module.less';
@import "../../../../style/utils.module.less";


.none {
	.flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
	gap: 40px;
	margin-bottom: 36px;
}


.description {
	color: #DBD7D4;
	font-size: 24px;
	font-family: 'LGSmartUIR'; //font-weight: 400;
	height: 28px;
	line-height: 28px
}
@__DEV__: true;