ol,
ul,
li {
  list-style: none;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
header {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
/*
	cl_LRPaddingOutWrap : 좌우 패딩
*/
.cl_LRPaddingOutWrap {
  width: 100%;
  padding: 0 2.917rem;
  box-sizing: border-box;
}
/*
	레이아웃 세로 정렬
*/
.cl_layoutColumn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}
/*
	공통 header
	적용: BodyAlignment
*/
.cl_btnArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
  column-gap: 20px;
}
.cl_btnArea .firstArea {
  margin-right: 1.25rem;
}
.cl_btnArea .borderButton {
  border: 0.16666rem solid #e0ded5;
  background-color: #fff;
  box-sizing: border-box;
}
.cl_btnArea .infoButton > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  box-sizing: border-box;
  white-space: nowrap;
}
/*
	버튼 외곽 border 지정
	적용page: BodyAlignment
*/
.borderShapeButton {
  width: 12.92rem;
  height: 3.458rem;
  border: 0.16666rem solid #e0ded5;
  background-color: #fff;
  box-sizing: border-box;
  color: #b4b4b4;
}
/*
	modal 배경
*/
.cl_modalDimmer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
}
.cl_innerModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
/*
	MainPage animation
	적용page: BodyAlignment > PreviewReport.jsx,
*/
/*
	MainPage animation
	적용page: MainPage bodyAlignment 클릭시 keyframes
*/
.bodyAlignmentContainer:focus .test {
  animation: moveLeft 1s;
}
@keyframes moveLeft {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translate3d(5%, 0, 0);
  }
}
/*
	공통 :: Baseline
	적용page: BodyAlignment_002, BodyAlignment_003
*/
.cl_baseLine {
  position: relative;
  font-family: 'LGSmartUIR';
  font-size: 0.916rem;
  color: #83827D;
  font-weight: 400;
  margin-left: auto;
  margin-bottom: 0.666rem;
}
.cl_baseLine::before {
  content: '';
  width: 2.3333rem;
  height: 0.16666rem;
  background-size: 2.3333rem 0.16666rem;
  background-image: url("data:image/svg+xml,%0A%3Csvg width='56' height='4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2383827D' stroke-width='4' stroke-linecap='round' stroke-dasharray='0.05 6' d='M2 2h52'/%3E%3C/svg%3E");
  position: absolute;
  top: 5%;
  right: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
/*
	공통 :: 좌, 우측 화살표 공통
	적용page: LGFIT_bodyalignment_005_1, LGFIT_bodyalignment_003_1​
*/
.cl_chevronCommon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 1.25rem;
  background-color: #e0ded5;
  background-size: 0.75rem 1.1667rem;
  background-position: center center;
  background-repeat: no-repeat;
}
.cl_arrowWhite {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 4 4 14l10 10' stroke='%23fff' stroke-width='7' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.cl_arrowGray {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='18' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m4 4 10 10L4 24' stroke='%236C6C6C' stroke-width='7' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.disabledIcon {
  opacity: 0.5;
}
/*
	공통 :: tab 하단부 영역
*/
.commonContentsLayout {
  position: relative;
  z-index: 10;
  background-color: #FEFDFA;
  width: 100%;
  height: 695px;
  padding: 1.3333rem 1.5833rem;
  box-sizing: border-box;
}
/*
	적용page: LGFIT_bodyalignment_005
*/
.cl_contentsLayoutType00 {
  position: relative;
  z-index: 10;
  background-color: #FEFDFA;
  width: 100%;
  height: 695px;
  padding: 1.3333rem 1.5833rem;
  box-sizing: border-box;
  border-radius: 0 0 0.833rem 0.833rem;
}
/*
	적용page: LGFIT_pysicaltest_006​
*/
.cl_contentsLayoutType01 {
  position: relative;
  z-index: 10;
  background-color: #FEFDFA;
  width: 100%;
  height: 695px;
  padding: 1.3333rem 1.5833rem;
  box-sizing: border-box;
  border-radius: 0 0 0.833rem 0.833rem;
}
@font-face {
  font-family: 'fluxNumberL';
  src: url('../../../assets/fonts/LGFluxNumberLight.woff') format("woff");
}
@font-face {
  font-family: 'fluxNumberR';
  src: url('../../../assets/fonts/LGFluxNumberRegular.woff') format("woff");
}
@font-face {
  font-family: 'fluxNumberB';
  src: url('../../../assets/fonts/LGFluxNumberBold.woff') format("woff");
}
@font-face {
  font-family: 'LGSmartUIL';
  src: url('../../../assets/fonts/LGSmartUILight.woff') format("woff");
}
@font-face {
  font-family: 'LGSmartUIR';
  src: url('../../../assets/fonts/LGSmartUIRegular.woff') format("woff");
}
@font-face {
  font-family: 'LGSmartUISB';
  src: url('../../../assets/fonts/LGSmartUISemiBold.woff') format("woff");
}
@font-face {
  font-family: 'LGSmartUIB';
  src: url('../../../assets/fonts/LGSmartUIBold.woff') format("woff");
}
