@import '../../../style/CommonStyle.module.less';

.scroller {
  height: 593px;
  color: black;
}

.container {
  padding: 52px 75px 60px;
  .dateWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .filterSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 36px;
  }

  .contentContainer {
    color: @COLOR_GRAY02;
    background-color: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.10);
    font-family: "LGSmartUIR";
    margin-bottom: 28px;
    border-radius: 20px;
    padding: 66px 40px 50px 40px;
    .painHistoryChart {
      margin-bottom: 30px;
    }
    &:focus {
      // box-shadow: inset 0 0 0 5px @COLOR_GRAY04;
    }
  }
}
@__DEV__: true;