@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.archBarItemSect {
  line-height: 1;
  padding-bottom: 90px;
  &:first-child {
    border-bottom: 1px solid #D9D9D9;
  }

  &:last-child {
    margin-top: 32px;
    padding-bottom: 125px;
  }
}

.archInfoWrap {
  display: flex;
  gap: 8px;
  justify-content: center;
  font-family: 'LGSmartUIB';
  color: #363533;
  font-size: 30px;
  text-align: center;
  margin-bottom: 34px;
  align-items: center;

  .archInfoWrapText {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    // justify-content: center;
  }

  .archIndex {
    margin-right: 8px;
  }

  .type {
  }

  .level {
    font-family: 'LGSmartUISB';
    color: #787776;
    // margin-left: 8px;
  }
}

.fixedBar {
  display: flex;
  align-items: center;
  height: 14px;
  background-color: #DBD7D4;
}

.stepFlex {
  line-height: 1.1;
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  position: absolute;
  top: 22px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  font-size: 21px;
  align-items: center;
  // font-size: 20px;
  text-align: center;
  color: @COLOR_GRAY06;
  // margin-top: 9px;

  >span {
    display: block;

    &.step {
      color: @COLOR_GRAY05;
      // margin-top: 2px;
    }
  }
  >img {
    margin-top: 12px;
    width: 50px;
  }
}

.fixedBarSection {
  position: relative;
  height: 100%;

  .marker {
    position: absolute;
    bottom: 0;
    transform: translate(-50%, 0%);
    width: 14px;
    height: 38px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 2;

  }
  .none {
    display: none;
  }

  &.mainBar {
  position: relative;
    width: 25%;
  }

  &.sideBar {
    position: relative;
    width: 12.5%;
    background: #F2EFED;

    &::before {
      content: "";
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translate(50%, -50%);
      height: 100%;
      border: 1px solid #FEFEFE;
      box-sizing: border-box;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(50%, -50%);
      height: 100%;
      border: 1px solid #FEFEFE;
      box-sizing: border-box;
      z-index: 1;
    }
  }

  &.located {
    background-color: @PRIMARY_COLOR_BROWN01;
  &.sideBar {
    background-color: @PRIMARY_COLOR_BROWN01;
    }
  }
}
@__DEV__: true;