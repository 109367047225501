@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.instructionSign {
  .flex();
  position: fixed;
  font-family: "LGSmartUIB";
  font-size: 36px;
  color: @COLOR_GRAY01;
  padding: 26px 86px;
  height: 42px !important;
  white-space: pre;
  left: 50%;
  top: 173px;
  transform: translateX(-50%) translateY(0) !important;
  box-shadow:  0 -5px 5px -5px @PRIMARY_COLOR_GREEN03, 0 5px 5px -5px @PRIMARY_COLOR_GREEN03;
  background: linear-gradient(270deg, rgba(33, 33, 32, 0.00) 0%, #212120 11.91%, #212120 90.09%, rgba(33, 33, 32, 0.00) 99.41%);
  >b{
    color: @PRIMARY_COLOR_GREEN03;
  }
}
@__DEV__: true;