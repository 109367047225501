@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.panelLicenseDetail {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #000000B3 !important;
  z-index: 30;

  &::before {
    content: "";
    position: absolute;
    // top: 630px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 120px;
    background: linear-gradient(to bottom, transparent, #000 43%);
    z-index: 31;
  }

  >div {
    width: 100%;
    height: 100%;

    >div {
      display: block;
      width: 100%;
      height: 100%;
      padding: 60px 100px 0 100px !important;

      >div {
        height: 100%;
        max-width: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  .title {
    font-size: 35px;
    margin-bottom: 20px;
  }

  .date {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .tScroller {
    height: 75%;
    margin: 0;
    padding: 0 50px 0 0;

  }

  .paragraph {
    margin-bottom: 24px;

    .paraTitle {
      font-family: 'LGSmartUISB';
      font-size: 26px;
    }

    .desc {
      font-size: 26px;
      line-height: 1.5;
    }
  }

  .btnBox {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    bottom: 64px;
    z-index: 32;
  }
}
@__DEV__: true;