@import "../../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../../style/utils.module.less";

@LINE_WIDTH: 400px;

.verticalBarGraph {
  // .size(@w: 481px, @h: 422px);
  .size(@w: 650px, @h: 422px);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  border-radius: 20px;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.10) inset;
  background-color: rgba(255, 255, 255, 0.03);
  padding: 30px;
  box-sizing: border-box;
  position: relative;
  .legend {
    position: absolute;
    top: 30px;
    right: 150px;
    font-family: 'LGSmartUIR';
    font-size: 20px;
    color: @COLOR_GRAY03;
  }
}
.title {
  .size(@w: 100%, @h: 32px);
  line-height: 32px;
  font-size: 27px;
  color: #F2EFED;
  font-family: 'LGSmartUIB'; //font-weight: 700;
  margin-bottom: 50px;
}

// @LINE_WIDTH: 335px;

.lineContainer {
  .size(@w: @LINE_WIDTH, @h: 193px);
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: unset, @direction: column);
  margin-bottom: 16px;
  position: relative;
  .lineWrapper {
    position: relative;
    .number {
      .position(@position: absolute, @top: 0, @right: ~"calc(@{LINE_WIDTH} + 12px)", @bottom: unset, @left: unset);
      transform: translateY(-50%);
      .size(@w: auto, @h: 23px);
      line-height: 23px;
      font-size: 20px;
      color: #DBD7D4;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
    .line {
      .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
      transform: translateY(-50%);
      .size(@w: @LINE_WIDTH, @h: 1px);
      background-image: url("../../../../../../assets/FitnessManager/dot_horizontal_line.png");
      background-position: center;
      background-repeat: repeat;
      background-size: contain;
      &.last {
        background-image: none;
        background-position: unset;
        background-repeat: unset;
        background-size: unset;
        background-color: #DBD7D4;
        z-index: 1;
      }
    }
    .text {
      .size(@w: auto, @h: 23px);
      .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: ~"calc(@{LINE_WIDTH} - 12px)");

      // .size(@w: 120px, @h: 23px);
      // .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 370px);
      transform: translateY(-50%);
      line-height: 23px;
      font-size: 20px;
      color: #DBD7D4;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      // text-align: right;
    }
  }
  .graphContainer {
    .size(@w: 100%, @h: 100%);
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .flex(@display: flex, @justifyCenter: space-around, @alignCenter: end, @direction: row);
  }
  .emptyContainer {
    .size(@w: 100%, @h: auto);
    .position(@position: absolute, @top: 29px, @right: unset, @bottom: unset, @left: 50%);
    transform: translate(-50%);
    text-align: center;
    .title {
      .size(@w: auto, @h: 32px);
      line-height: 32px;
      // font-size: 27px;
      font-size: 24px;
      color: #DBD7D4;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      margin-bottom: 7px;
    }
    .text {
      .size(@w: auto, @h: auto);
      line-height: 28pxpx;
      font-size: 24px;
      color: #B2B0AE;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
  }
}

.labelContainer {
  .flex(@display: flex, @justifyCenter: space-around, @alignCenter: center, @direction: row);
  .size(@w: @LINE_WIDTH, @h: auto);
  margin-bottom: 28px;
  .label {
    .size(@w: auto, @h: 25px);
    line-height: 25px;
    font-size: 21px;
    color: #DBD7D4;
    font-family: 'LGSmartUISB'; //font-weight: 600;
  }
}

.buttonContainer {
  display: flex;
  // gap: 6px;
  gap: 18px;
}
@__DEV__: true;