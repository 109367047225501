@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@SLIDER_HEIGHT: 19px;
@SLIDER_PAIN_GAP: 14px;

.container {
  position: relative;
  width: 100%;
}

.pains {
  position: absolute;
  bottom: ~"calc(@{SLIDER_HEIGHT} + @{SLIDER_PAIN_GAP})";
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: inherit;
  height: auto;
  > div {
    font-family: 'LGSmartUISB';
    font-size: 22px;
    color: #B2B0AE;
    height: 26px;
    line-height: 26px;
  }
}

.slider {
  padding: 0 !important;
  margin: 0 !important;
  height: 100% !important;
  >div {
    height: @SLIDER_HEIGHT !important;
  }
  >div>div:nth-child(1)  { // bar
    border-radius: 40px !important;
    background: linear-gradient(90deg,
              #44A280 0, #44A280 20%,
              #65A257 0, #65A257 40%,
              #D2B220 0, #D2B220 60%,
              #E88625 0, #E88625 80%,
              #E35F37 0, #E35F37 100%,
    ) !important;
  }
  >div>div:nth-child(1)>div:nth-child(2) { //progressed bar
    background-color: transparent !important;
  }

  >div>div:nth-child(2)::before { // knob
    height: 30px !important;
    width: 30px !important;
    border-radius: 50% !important;
    transform: translate(-50%, -50%) !important;
    box-shadow: 0 0 0 4px #F2EFED inset !important;
    background-color: #44A280 !important;
    border: unset !important;
  }
  &:focus, &:hover {
    >div>div:nth-child(2)::before { //knob
      background-color: #FEFEFE !important;
      background-color: none !important;
      box-shadow: none !important;
    }
    + .numbers {
      .dot {
        visibility: visible;
        width: 22px;
        height: 22px;
      }
    }
  }
}

.selected {
  >div>div:nth-child(2)::before {
    background-color: #44A280 !important;
    box-shadow: 0 0 0 4px #FEFEFE inset !important;
  }
}

.numbers {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: inherit;
  height: auto;
  > div {
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: 'LGSmartUISB';
    font-size: 24px;
    color: #DBD7D4;
    height: 99px;
  }
  .dot {
    visibility: hidden;
    position: absolute;
    width: @SLIDER_HEIGHT;
    height: @SLIDER_HEIGHT;
    background-color: @COLOR_GRAY01;
    border-radius: 50%;
    align-self: center;
    &.selected {
      background-color: #44A280;
      width: 22px;
      height: 22px;
    }
  }
  &:focus, &:hover{
    .dot {
      visibility: visible;
      width: 22px;
      height: 22px;
    }
  }
}
@__DEV__: true;