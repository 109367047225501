.canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  canvas {
    position: absolute;
    bottom: 0;
  }
}
@__DEV__: true;