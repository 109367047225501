@import "../../../../../../style/CommonStyle.module.less";
@import "../../../../../../style/utils.module.less";

.overallDetail {
  > div {
    display: flex;
    justify-content: space-between;
    width: 1154px;

    .mainTitle {
      padding-left: 44px;
      display: flex;
      align-items: center;
      color: @COLOR_GRAY08;
      font-size: 33px;
      font-family: "LGSmartUIB";
      color: @COLOR_GRAY08;
      line-height: 46px;
      > span {
        display: inline-block;
        width: 48px;
        height: 48px;
        margin-right: 16px;
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
      > p {
        margin: 0;
      }
    }
    .tIconBtn {
      margin-right: 40px;
      div:nth-child(1) {
        margin-right: 12px;
      }
      .withMargin {
        width: 36px;
        height: 36px;
        border-radius: 6px;
      }
    }
    .section {
      margin-bottom: 32px;
      margin-right: 24px;
      &.isSingleItem {
        margin-bottom: 0;
        &:last-child {
          .title,
          .bodyImage {
            padding-left: 0;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
      .title {
        padding-left: 44px;
        font-size: 27px;
        font-family: "LGSmartUISB";
        color: @COLOR_GRAY06;
        height: 32px;
      }
      .bodyImage {
        padding-left: 44px;
        display: flex;
        margin-top: 20px;
        // padding-bottom: 12px;

        .eachImgBox {
          position: relative;
          border-radius: 8px;
          border: 1px solid @COLOR_GRAY03;
          width: 404px;
          height: 280px;
          box-sizing: border-box;
          border-radius: 16px;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          // transition: transform 0.3s ease-in-out;
          // &:focus {
          //   transform: scale(1.08);
          //   border: 3px solid @COLOR_GRAY08;
          // }

          &.noData {
            &::after {
              content: "";
              position: absolute;
              left: 0;
              top: 0;
              display: block;
              width: 100%;
              height: 100%;
              background-color: @COLOR_GRAY08;
              opacity: 0.1;
              z-index: 1;
            }
          }

          .labelLR {
            position: absolute;
            color: @COLOR_GRAY01;
            font-size: 24px;
            background-color: @COLOR_GRAY03;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            left: 20px;
            top: 161px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: "LGSmartUISB";
            &.right {
              left: auto;
              right: 20px;
            }
          }

          .imgBox {
            position: relative;
            display: block;
            width: 357px;
            height: 215px;

            .eachImg {
              position: absolute;
              top: 0;
              left: 0;
              width: 357px;
              height: 215px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: bottom;
              canvas > {
                width: 357px;
                height: 251px;
              }
            }
            &.narrow {
              width: 306px;
              .eachImg {
                width: 306px;
                canvas > {
                  width: 306px;
                  height: 215px;
                }
              }
            }
          }
          .divider {
            width: 100%;
            height: 1px;
            background-color: #d9d9d9;
          }
          .infoBox {
            margin: 13px auto 14px;
            display: flex;
            justify-content: center;
            align-items: center;
            .value {
              height: 32px;
              font-size: 24px;
              font-family: "LGSmartUIB";
              color: @COLOR_GRAY07;
              word-wrap: break-word;
            }
            .infoDivider {
              width: 1px;
              height: 22px;
              background-color: @COLOR_GRAY03;
              margin: 0 14px;
            }
            .gradeBox {
              border-radius: 30px;
              min-height: 25px;
              padding: 5px 16px;
              font-size: 21px;
              color: @COLOR_GRAY01;
              background-color: @COLOR_GRAY04;
              font-family: "LGSmartUISB";
              word-wrap: break-word;
              line-height: 1.2;

              &.fine {
                color: #44a280;
                background-color: #44a28026;
              }
              &.manage {
                color: #f9b811;
                background-color: #f9b81126;
              }
              &.danger {
                color: #f44b4a;
                background-color: #f44b4a26;
              }
            }
          }
        }
      }
    }
  }
}

.marginRight {
  margin-right: 24px;
}

.flexBox {
  display: flex;
  flex-wrap: wrap;
  // margin-top: 27px;
}

.feedbackLayer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-left: 44px;
  width: 100%;
  box-sizing: border-box;
  .feedback {
    .font(@fontFamily: "LGSmartUISB" , @fontSize: 36px, @color: @COLOR_GRAY08, @fontWeight: 600);
    line-height: 44px;
    word-wrap: break-word;
    > span {
      .font(@fontFamily: "LGSmartUIB" , @fontSize: 36px, @color: @COLOR_GRAY08, @fontWeight: 700);
    }
  }
  .feedbackDesc {
    margin-top: 16px;
    .font(@fontFamily: "LGSmartUIR" , @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
    line-height: 38px;
  }
  .notification {
    margin-top: 24px;
    padding-left: 34px;
    position: relative;
    .font(@fontFamily: "LGSmartUIR", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 400);
    line-height: normal;
    &::before {
      content: "";
      width: 26px;
      height: 26px;
      position: absolute;
      left: 0%;
      top: 0%;
      background-image: url("../../../../../../../assets/bodyAlignment/ic_workoutmanager_alret.png");
    }
  }
}

@__DEV__: true;