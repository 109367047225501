@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";


@ITEM_WIDTH: 528px;
@ITEM_HEIGHT: 246px;
@SPACING: 30px;

.healthInfo {
  position: relative;
}

.myInfoContainer {
  .position(@position: absolute, @top: 106px, @right: unset, @bottom: unset, @left: 0);
  .title {
    font-size: 33px;
    height: 39px;
    line-height: 39px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    margin-bottom: 31px;
  }
  .infoArea {
    .size(@w: 518px, @h: 525px);
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: flex-start, @direction: column);
    gap:40px;
    border-radius: 16px;
    padding: 60px;
    background: rgba(255, 255, 255, 0.03);
    box-sizing: border-box;
    .infoBox {
      .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
      .qBox {
        font-size: 30px;
        height: 35px;
        line-height: 35px;
        color: #B59D85;
        font-family: 'LGSmartUISB'; //font-weight: 600;
        width: 140px;
      }
      .aBox {
        font-size: 30px;
        height: 35px;
        line-height: 35px;
        color: #F2EFED;
        font-family: 'LGSmartUIR'; //font-weight: 400;
      }
    }
  }

}

.healthInfoContainer {
  .position(@position: absolute, @top: 106px, @right: unset, @bottom: unset, @left: 578px);
  .title {
    font-size: 33px;
    height: 39px;
    line-height: 39px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    margin-bottom: 31px;
  }
  .infoArea {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    flex-wrap: wrap;
    gap: @SPACING;
    width: ~"calc(@{ITEM_WIDTH} * 2 + @{SPACING})";
  }

  .tListItemButton {
    position: relative;
    .size(@w: 528px, @h: 246px);
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.03);
  
    .text {
      .position(@position: absolute, @top: 40px, @right: unset, @bottom: unset, @left: 40px);
      font-size: 33px;
      height: 47px; 
      line-height: 47px;
      color: #FEFEFE;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
  
    .date {
      .position(@position: absolute, @top: 164px, @right: unset, @bottom: unset, @left: 40px);
      font-size: 27px;
      height: 32px; 
      line-height: 32px;
      color: #DBD7D4;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
  
    &::after { // background image
      content: "";
      .position(@position: absolute, @top: 0, @right: 0, @bottom: unset, @left: unset);
      .size(@w: 180px, @h: 177px);
      background-image: url("../../../../assets/myPanel/img_report_gate_category_bg.png");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:focus {
      background: #FEFEFE;
      .text {
        color: #212120;
      }
      .date {
        color: #363533;
      }
      &::after {
        background-image: url("../../../../assets/myPanel/img_report_gate_category_bg_foc.png");
      }
    }
  }
}



@__DEV__: true;