@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

[id="floatLayer"] {
  //common layout
  > div:not([id]) > div:nth-child(2) {
    bottom: 50%;
    transform: translateY(50%);
  }
}

.container {
  width: 100%;
  height: 100%;

  //enact popup reset
  margin-bottom: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  > div {
    padding: 0 !important;
    > div > div {
      width: unset !important;
      margin: 0 !important;
    }
  }
}

.popup {
  width: 952px;
  height: 538px;
  padding: 48px 48px 32px 48px;
  box-sizing: border-box;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  // todo: Tscoller common margin
  .tScroller {
    position: absolute;
    top: 48px;
    left: 48px;
    color: @COLOR_GRAY08;
    font-size: 27px;
    font-family: "LGSmartUIR";
    line-height: 38px;
    height: 363px;
    width: 884px;
  }

  .text {
    height: auto;
    width: 856px;
    margin-right: 20px;
    > p {
      margin: 0;
      margin-bottom: 27px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .button {
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translateX(-50%) scale(1);
    min-width: 206px;
    background-color: @BG_COLOR_04;

    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
      transform: translateX(-50%) scale(1.08);
    }
    &.pressed {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: @COLOR_GRAY09;
        border-radius: inherit;
        opacity: 0.3;
      }
    }
  }
}

@__DEV__: true;