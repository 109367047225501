@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.container {
  display: flex;

  .switch {
    color: @COLOR_GRAY08;
  }

  .videoContainer {
    width: 70%;

    .box {
      display: flex;
      align-items: center;
    }
  }

  .optionContainer {
    width: calc(30% - 20px - 32px);
    margin-left: 20px;
    height: 910px;
  }

  .box {
    background: #fff;
    border-radius: 16px;
    padding: 16px;
    color: @COLOR_GRAY08;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    .title {
      font-family: 'LGSmartUIB';
    }
  }
}
@__DEV__: true;