@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@scrollBar : 0.625rem; //scrollbar width (15px)
@scrollCircle : 1.6667rem; //scrollbar circle (40 * 40)
@barColor: transparent;
@focusedBarColor: #transparent;
@verTrackColor: #f1efec;

.tScroller {
	> div:nth-child(2) > div:nth-child(1) { // track
		background-color: #DBD7D4;
		width: 8px;
		border-radius: 40px;
	}
	> div:nth-child(2) > div:nth-child(1) > div:nth-child(1) { //thumb
		background: #B2B0AE;
		&:focus {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			background-color: @COLOR_GRAY02 !important;
			border-radius: 188px !important;
			box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.20) !important;
			position: relative;
			left: 50% !important;
			transform: translateX(-50%);
			width: 16px !important;
			padding: 2px 0;
			box-sizing: border-box;

			> div {
				display: none;
			}
			&::before {
				content: "";
				display: block;
				background-image: url('../../../assets/TScoller/ic_scrollwheel_up.svg');
				width: 16px;
				height: 16px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
			&::after {
				content: "";
				display: block;
				background-image: url('../../../assets/TScoller/ic_scrollwheel_down.svg');
				width: 16px;
				height: 16px;
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			}
		}
	}
}

.onMove{
	> div:nth-child(2) {
		//scroller
		background-color: none;

		> div {
			//scrollbar container
			background-color: @COLOR_GRAY03;
			position: relative;

			> div {
				//scrollbar
				position: relative;

				background-color: @COLOR_GRAY04 !important;
				position: relative;
				left: 0 !important;
				width: 6px !important;
				border: 50%;
				> div {
						//span role="alert"
						opacity: 0;
				}
			}
		}
	}
}

.isDark.tScroller {
	> div:nth-child(2) > div:nth-child(1) { // track
		background-color: rgba(255, 255, 255, 0.05);
	}
	> div:nth-child(2) > div:nth-child(1) > div:nth-child(1) { //thumb
		background: rgba(255, 255, 255, 0.3);
		&:focus {
			background-color: @COLOR_GRAY05;
			&::before {
				background-image: url('../../../assets/TScoller/ic_scrollwheel_up_dark.svg');
			}
			&::after {
				background-image: url('../../../assets/TScoller/ic_scrollwheel_down_dark.svg');
			}
		}
	}
}


.isDark.onMove {
	> div:nth-child(2) {
		> div {
			//scrollbar container
			background: rgba(255, 255, 255, 0.05) !important;
			> div {
				//scrollbar
				background: rgba(255, 255, 255, 0.3) !important;
				> div {
						//span role="alert"
						opacity: 0;
				}
			}
		}
	}
}
@__DEV__: true;