@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

@ITEM_WIDTH: 264px;
@ITEM_HEIGHT: 137px;
.tItem{
  .size(@w: @ITEM_WIDTH, @h: @ITEM_HEIGHT);
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center);
  border-radius: 12px;
  background: rgba(254, 254, 254, 0.10);
  color: #DBD7D4;
  transition: background 0.3s, transform 0.3s;
  .title {
    font-size: 33px;
    font-family: 'LGSmartUISB';
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.selected {
    background: #FEFEFE;
    color: #212120;
  }

  &:focus {
    background: #FEFEFE;
    color: #212120;
    transform: scale(1.08);
  }

  &.disabled {
    opacity: 0.3;
  }

  &.pressed::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: inherit;
    opacity: 0.3;
  }
}

@__DEV__: true;