@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

@ITEM_HEIGHT: 316px;

.recommendedCourseContainer {
  margin-bottom: 60px;
  padding: 0;

  .recommendedCourseTitle {
    font-family: 'LGSmartUIB';
    font-size: 36px;
    padding: 0 @fitTv-tBody-padding-leftright;
    margin-bottom: 33px;
    color: @COLOR_GRAY08;
  }

  .swiperContainer {
    // padding: 0 @fitTv-tBody-padding-leftright;
    padding: 0 300px 10px @fitTv-tBody-padding-leftright;

    .recommendedCourse {
      display: flex;
      align-items: center;

      .recommendedCourseInfo {
        margin-left: 80px;
        padding-right: 78px;
        height: @ITEM_HEIGHT;
        // display: flex;
        // flex-direction: column;
        // justify-content: space-between;

        // > div:first-child {
        //   height: calc(100% - 73px);
        // }

        .customContTit {
          color: @COLOR_GRAY08;
          font-family: 'LGSmartUISB';
          font-size: 36px;
          margin-bottom: 12px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .courseStr {
          color: #313131;
          font-size: 27px;
          font-family: 'LGSmartUIR';
          line-height: 35px;
          margin-bottom: 24px;

          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .progressInfo {
          font-size: 27px;
          font-family: 'LGSmartUIB';
          color: @COLOR_GRAY08;
          margin-bottom: 29px;
          display: flex;
          align-items: center;

          .stepImg {
            width: 32px;
            margin-right: 8px;
          }
          .progressDistance > span {
            color: @COLOR_GRAY05;
            font-family: 'LGSmartUISB';
          }
        }

        .walkBtn {
          min-width: 252px;
          padding: 0 32px;
          border-radius: 56px;
          height: 73px;
          line-height: 73px;
          font-size: 27px;

          &:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }
    .dimmed{
      opacity: 0.5;
    }
  }

  .paginationContainer {
    text-align: center;

    .pagination {
      display: inline-block;
      margin-top: 40px;
      padding: 10px 18px;
      border-radius: 30px;
      line-height: 0;

      .bullet {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: @COLOR_GRAY04;
        &:not(:last-child) {
          margin-right: 10px;
        }

        &.active {
          width: 30px;
          border-radius: 20px;
          background-color: @PRIMARY_COLOR_BROWN03;
        }
      }

      &.focus {
        background-color: @PRIMARY_COLOR_BROWN03;

        .bullet {

          &.active {
            background-color: @COLOR_GRAY01;
          }

          &:focus {
            background-color: @COLOR_GRAY01;
          }
        }
      }
    }
  }
}

.courseImage {
  position: relative;
  border-radius: 16px;
  width: 559px;
  height: @ITEM_HEIGHT;
  overflow: hidden;
  flex-shrink: 0;

  .img {
    width: 559px;
    height: 316px;
    background-color: #D9D9D9;
  }

  .new {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: @PRIMARY_COLOR_GREEN02;
    padding: 4px 13px;
    border-radius: 8px;
    color: @COLOR_GRAY01;
    font-size: 26px;
    font-family: 'LGSmartUIB';
  }

  .course {
    position: absolute;
    width: 127px;
    height: 85px;
    bottom: 32px;
    right: 20px;
  }

  .progressBar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12px;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0.50;
      background: @COLOR_GRAY01;
    }
    &::after {
      content: '';
      position: absolute;
      width: var(--progress);
      height: 100%;
      background: @PRIMARY_COLOR_GREEN02;
    }
  }
}

.lButtonIcon, .rButtonIcon {
  .size(@w: 44px, @h: 100px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 2;
  transform: translateY(-50%);
}
.lButtonIcon {
  .position(@position: absolute, @top: 50%, @left: 0, @bottom: unset, @right: unset);
  background-image: url("../../../../../assets/landingPage/icon/ic_mrcu_left_nor.png");
}
.rButtonIcon {
  .position(@position: absolute, @top: 50%, @right: 0, @bottom: unset, @left: unset);
  background-image: url("../../../../../assets/landingPage/icon/ic_mrcu_right_nor.png");
}
@__DEV__: true;