@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.matList{
  font-family: "LGSmartUIB";
  font-size: 33px;
  display: flex;
  align-items: center;
  margin-top: 48px;
  .matTitle{
    position: relative;
    &::after{
      content:"";
      position: absolute;
      width: 1px;
      height: 25px;
      background: #D9D9D9;
      top: 7px;
      right: -12px;
    }
  }
  .searchDevices{
    height: 54px;
    line-height: 54px;
    margin-left: 22px;
    font-size: 24px;
    &.selected, &:focus{
      margin-left: 22px; 
    }
  }
}

.searchFailed{
  width: 924px;
  height: 187px;
  margin-top: 36px;
  text-align: center;
  color: @COLOR_GRAY06;
  border-radius: 12px;
  border: 1px solid @COLOR_GRAY03;
  .notSearch{
    font-size: 27px;
    margin-top: 40px;
    font-family: "LGSmartUISB";
    color: @COLOR_GRAY07
  }
  .retrySearch{
    font-family: "LGSmartUIR";
    font-size: 24px;
    margin-top: 13px;
    color: @COLOR_GRAY06;
  }
}
.tscroller{
  width: 924px;
}

@__DEV__: true;