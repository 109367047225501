@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.instruction {
  position: absolute;
  top: 171px;
  left: 50%;
  transform: translateX(-50%);
  > span:first-child {
    display: block;
    text-align: center;
    font-family: 'LGSmartUIB';
    font-size: 34px;
    line-height: 48px;
    height: 48px;
    color: #FEFEFE;
  }
  > span:last-child {
    display: block;
    text-align: center;
    font-family: 'LGSmartUIR';
    font-size: 28px;
    line-height: 48px;
    height: 96px;
    color: #DBD7D4;
  }
}

.inputSection {
  position: absolute;
  left: 75px;
  top: 359px;
  width: 1100px;
  height: 488px;
  border-radius: 12px;
  border: 2px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.03);
  padding: 60px;
  box-sizing: border-box;
}

.inputConainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;

  .mobile {
    display: inline-flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    box-shadow: 0 0 0 2px #494847 inset;
    .input {
      width: 616px;
    }
    .vr {
      width: 1px;
      height: 25px;
      opacity: 0.3 ;
      background-color: #FEFEFE ;
    }
  }
  

}

.recentContainer {
  .title {
    color:#DBD7D4;
    font-size: 28px;
    font-family: 'LGSmartUISB';
    height: 33px;
    line-height: 33px;
    margin-bottom: 24px;

  }
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .mobile {
    .size(@w: auto, @h: 52px);
    line-height: 52px;
    font-size: 26px;
    padding: 0 22px;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.20);
    background: rgba(255, 255, 255, 0.00);
    color: #B2B0AE;
    font-family: 'LGSmartUIR'; //font-weight: 400;
    &:focus {
      color: #212120;
    }
  }

}

.recipientContainer {
  .size(@w: 642px, @h: 488px);
  .position(@position: absolute, @top: 359px, @right: unset, @bottom: unset, @left: 1203px);
  border-radius: 12px;
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.03);

  .title {
    .position(@position: absolute, @top: 30px, @right: unset, @bottom: unset, @left: 30px);
    color:#F2EFED;
    font-size: 28px;
    font-family: 'LGSmartUISB';
    height: 33px;
    line-height: 33px;
  }
  .hr {
    .size(@w: 582px, @h: 1px);
    .position(@position: absolute, @top: 91px, @right: unset, @bottom: unset, @left: 30px);
    background-color: #fff;
    opacity: 0.2;

  }
  .scroller {
    .size(@w: auto, @h: 345px);
    .position(@position: absolute, @top: 123px, @right: unset, @bottom: unset, @left: 18px);
    position: absolute;
  }
  
  .list {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
    padding-right: 26px;
    gap: 10px;
  }

  .recipient {
    .size(@w: 582px, @h: 63px);
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    border-radius: 8px;
    padding: 0 12px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.00);

    .mobile {
      .size(@w: 522px, @h: 35px);
      .elip(1);
      font-size: 28px;
      line-height: 35px;
      color:#DBD7D4;
      font-family: 'LGSmartUIR'; //font-weight: 400;
      margin-right: 12px;
    }
    .deleteIcon {
      .size(@w: 24px, @h: 24px);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("../../../../assets/report/icon/ic_report_delete_nor.png");
    }

    &:focus {
      .mobile {
        color:#212120;
      }
      .deleteIcon {
        background-image: url("../../../../assets/report/icon/ic_report_delete_foc.png");
      }
    }
  }
}










.buttonArea {
  position: absolute;
  top: 905px;
  left: 668px;
  display: flex;
  gap: 24px;
}
@__DEV__: true;