@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.goalContainer {
  .size(@w: 536px, @h: 200px);
  .flex(@display: flex, @justifyCenter:flex-start , @alignCenter: center, @direction: row);
  padding: 40px;
  box-sizing: border-box;
  background: #242424;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.10) inset;
  border-radius: 20px;
}

.dataContainer {
  margin-left: 24px;

  .titleWrapper {
    margin-bottom: 8px;
    .flex(@display: flex, @justifyCenter:flex-start , @alignCenter: center, @direction: row);
    .icon {
      .size(@w: 36px, @h: 36px);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 6px;
    }
    .title {
      font-size: 27px;
      // height: 32px;
      line-height: 32px;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      color: #DBD7D4;
      max-width: 270px;
    }
  }

  .dataWrapper {
    .flex(@display: flex, @justifyCenter:flex-start , @alignCenter: center, @direction: row);
    .user {
      .size(@w: auto, @h: 42px);
      font-size: 36px;
      line-height: 42px;
      font-family: 'LGSmartUIB'; //font-weight: 700;
      color: #F2EFED;
    }
    .plan {
      .size(@w: auto, @h: 42px);
      font-size: 36px;
      line-height: 42px;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      color: #B2B0AE;
    }
    .vr {
      .size(@w: 2px, @h: 24px);
      background-color: #787776;
      margin: 0 15px 0 15px;
      border-radius: 2px;
    }
    .progress {
      .size(@w: auto, @h: 42px);
      font-size: 36px;
      line-height: 42px;
      font-family: 'LGSmartUIB'; //font-weight: 700;
      color: #F2EFED;
    }
  }

  .text {
    .size(@w: auto, @h: 32px);
    font-size: 27px;
    line-height: 32px;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    color: #DBD7D4;
  }
}
@__DEV__: true;