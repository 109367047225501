@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

@base-color: #555;
@average-color: rgba(176, 176, 176, 0.5);
@personal-color: rgba(245, 178, 194, 0.6);

.container {
  position: relative;
  font-family: 'LGSmartUIR';
}

.radar {
  // add some css
}

.levels {
  fill: none;
  stroke: @base-color;
}

.lines {
  stroke: @base-color;
}

.shape {
  &[data-type='average'] {
    fill: @average-color;
  }

  &[data-type='personal'] {
    fill: @personal-color;
  }
}

.label {
  &[label-position='up'] {
    text-anchor: middle;
  }
  &[label-position='right'] {
    text-anchor: start;
  }
  &[label-position='down'] {
    text-anchor: middle;
  }
  &[label-position='left'] {
    text-anchor: end;
  }
}

.legends {
  text-anchor: end;

  > tspan:nth-child(even) {
    fill: @base-color;
  }
}

@__DEV__: true;