.checkbox {
	display: flex;
	flex-flow: row wrap;
	gap: 26px 20px;

	.item {
		transform: none;
		max-width: fit-content;
	}
}
@__DEV__: true;