@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.graphBox {
  position: relative;
  .size(@w: 100%, @h: 2.9167rem);
  display: flex;
  align-items: center;
}

.overallGraphBox {
  position: relative;
  .size(@w: 100%, @h: 2.9167rem);
  display: flex;
  align-items: center;
  background-color: @COLOR_GRAY02;
  border: 1apx solid #fff;
  border-left-style: none;
  border-radius: 0 2.7917rem 2.7917rem 0;

  >div {
    border: 1apx solid #F7F5F2;
  }
}


//그래프 영역 공통
.BarFullSize {
  width: 100%;

  .commonGraphBg {
    // .position(@position: relative, @top: 0, @bottom: null, @right: null, @left: 0);
    border-radius: 1.6667rem;
    margin-right: 0.8333rem;
    height: 0.75rem;
  }
}

.physicalWord {
  width: 20rem;
  font-weight: 600;
  font-size: 1.5rem;
  color: #494947;
  font-family: 'LGSmartUIR';
  white-space: nowrap;
}

//ani 붙일 경우 그래프 좌->우로 채워짐
.ani {
  animation-name: graph;
  display: block;
  animation-duration: 3s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  // animation-timing-function: ease-in-out;
  animation-timing-function: cubic-bezier(0.25, 0.25, -0.2, 0);
}

@keyframes graph {
  0% {
    width: 0;
    opacity: 0;
  }

  100% {
    width: 100%;
    opacity: 1;
  }
}

.barType {
  display: flex;
  margin-top: 1.54167rem;
  margin-left: 1.75rem;

  >div {
    position: relative;
    font-weight: 400;
    font-size: 1.5rem;
    color: #494947;
    margin-right: 4.375rem;
    font-family: 'LGSmartUIR';
    white-space: nowrap;

    &::before {
      content: "";
      width: 0.875rem;
      height: 0.875rem;
      border-radius: 50%;
      position: absolute;
      left: -30px; top: 50%;
      transform: translate(-50%, -50%);
    }

    &:nth-child(1) {
      &::before {
        background-color: @fitTv-spotlight-color;
      }
    }

    &:nth-child(2) {
      &::before {
        background-color: #FF8616;
      }
    }

    &:nth-child(3) {
      &::before {
        background-color: #34A29B;
      }
    }
  }
}
@__DEV__: true;