.chartContainer {
  display: flex;
  justify-content: flex-end;
  border-bottom: 10px solid rgba(0, 0, 0, 0.2);
  padding-top: 1%;

  .graphContainer {
    position: relative;
    margin: 0 5px;
    width: 40px;
    height: 300px;
    .average {
      width: 100%;
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.2);
      border-radius: 25px 25px 0 0;
    }
    .mySelf {
      width: 100%;
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 25px 25px 0 0;
      z-index: 1;
    }
  }
}

@__DEV__: true;