@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

//우상단 가이드 Tit CountdownTimer=====================================================
.guideTitWrap {
  position: relative;
  z-index: 500;
  .flex(@display: flex, @justifyCenter: flex-end, @alignCenter: center);
  .size(@w: null, @h: 50px);
  line-height: 60px;
  padding: 30px 15px;
  .guideTit {
    .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
    .size(@w: 90%, @h: null);
    span {
      padding: 0 20px;
      box-sizing: border-box;
      color: #36383b;
      background-color: #cadcdd;
      border-radius: 25px;
    }
  }
}
//End :: guideTitWrap=================================================

.container {
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  .stepper {
    // position: absolute;
    // top: 67px;
    // margin-left: 50%;
    // transform: translateX(-50%);
    // width: 1454px;
    z-index: 10;
  }
  .workoutTitleContainer {
    .position(@position: absolute, @top:36%, @right: null, @bottom: null, @left: 50%);
    z-index: 5;
    transform: translateX(-50%);
    .flex(@justifyCenter:space-between, @direction: column);
    min-height: 215px;
    padding: auto;
    text-align: center;
    .testNo{
      font-family: 'LGSmartUIB';
      display: inline-flex;
      padding: 12px 28px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 60px;
      background: @COLOR_GRAY01;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
      color: @COLOR_GRAY08;
      text-align: center;
      font-size: 36px;
      margin-bottom: 30px;
    }
    .guideText{
      font-family: 'LGSmartUIB';
      width: 1509px;
      line-height: 168px;
      text-shadow: 0px 0px 14px rgba(0, 0, 0, 0.25);
      font-size: 144px;
    }
  }

  .workoutTitle {
    .font(@fontFamily: "LGSmartUIB", @fontSize: 1.46rem, @color: #fff, @fontWeight: 700);
    background-color: #fff;
    color: #2068a1;
    border-radius: 2rem;
    text-align: center;
    width: 8.0833rem;
    height: 2.5416rem;
    line-height: 2.5416rem;
  }

  .excerciseName {
    .font(@fontFamily: "LGSmartUIB", @fontSize: 4.25rem, @color: #fff, @fontWeight: 700);
    height: 75px;
    text-align: center;
    text-shadow: 0 0.416rem 0.416rem rgba(0, 0, 0, 0.25);
  }

  .excerciseName2 {
    margin-top: 6.4rem;
  }

  .countSecondContainer {
    .position(@position: absolute, @top:46%, @right: null, @bottom: null, @left: 50%);
    z-index: 5;
    transform: translateX(-50%);
    .flex(@justifyCenter:space-between, @direction: column);
    height: 215px;
    padding: auto;
  }

  .countSecond {
    .font(@fontFamily: "fluxNumberB", @fontSize: 17.5rem, @color: #fff, @fontWeight: 700);
    height: 75px;
    text-align: center;
    text-shadow: 0 0.208rem 0.208rem rgba(0, 0, 0, 0.25);
  }

  .frontTit {
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .size(@w: 100%, @h: 100%);
    z-index: 5;
    & div {
      .size(@w: 100%, @h: 100%);
      .flex(@display: flex, @justifyCenter: center, @alignCenter: center);
      .font (@fontFamily: "NotoSans", @fontSize: 175px, @color: #fff, @fontWeight: 900);;
    }
  }

  .cl_bgVideoType {
    z-index: 0;
    .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
    .size(@w: 100%, @h: 100%);

    section {
      display: flex;
      z-index: -1;
      .position(@position: absolute, @top: 0, @right: null, @bottom: null, @left: 0);
      .size(100%, 100%);

      & div {
        position: absolute;
        .size (@w : null, @h: 100%);
        // top: 0;
        transform: translateY(0);
      }
      .avatar {
        position: absolute;
        .size(@w:50%, @h: null);
        .flex(@display: inline-flex, @justifyCenter: center, @alignCenter: center);
        background-color: none;

        .shaka-player {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .skipPart{
          position: absolute;
          .size(@w: 100%, @h: 100%);
          opacity: 0.4;
          background: rgba(0, 0, 0, 0.70);
          display: flex;
          flex-direction: column;
          align-items: center;
          .skipIcon{
            margin-top: 466px;
            width: 96px;
            height: 96px;
            background-image: url("../../../../../assets/icon/guide_skip.png");
            background-repeat: no-repeat;
            background-size: cover;
          }
          .skipText{
            transform: translateY(570px);
          }
        }
        .labelContainer {
          position: absolute;
          top: 200px;
          left: 0;
          width: 100%;
          height: 60px;

          .label {
            position: absolute;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.08);
            .font(@fontFamily: "LGSmartUIB", @fontSize: 30px, @color: @COLOR_GRAY06, @fontWeight: 700);
            left: 52px;
            &.isRight {
              left: auto;
              right: 52px;
            }
          }
        }
      }
      .user {
        position: absolute;
        overflow: hidden;
        right: 0;
        .size(@w:50%, @h: 100%);
      }
    }
		.timeCounter{
			width: 263px !important;
			height: 80px !important;
			.detailTime{
				font-size: 36px;
				color: @COLOR_GRAY02;
				line-height: 80px;
				&::before{
					content: "";
					display: block;
					position: absolute;
					left: 45px;
					bottom: 20px;
					width: 35px;
					height: 35px;
					background-image: url('../../../../../assets/icon/ic_guide_count.png');
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
		}
    .exerciseCounter, .timeCounter {
      position: absolute;
      bottom: 176px;
      right: 52px;
      width: 263px;
      height: 151px;
      font-size: 45px;
      font-family: "LGSmartUIB";
      border-radius: 16px;
      background: rgba(33, 33, 32, 0.80);
      text-align: center;
      .detailCount{
        font-size: 45px;
        color: @COLOR_GRAY02;
        line-height: 80px;
        >span{
          font-size: 27px;
        }
        &::after{
          content:"";
          width: 215px;
          height: 1px;
          opacity: 0.3;
          background: #D9D9D9;
          display: block;
          position: absolute;
          top: 50%;
          left: 10%;
        }
      }
      .testTimeSec{
        font-size: 24px;
        color: @COLOR_GRAY02;
        line-height: 70px;
        &::before{
          content: "";
          display: inline-block;
          position: absolute;
          left: 70px;
          bottom: 23px;
          width: 24px;
          height: 24px;
          background-image: url('../../../../../assets/icon/ic_guide_count.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
		.timeResult{
			width: 263px !important;
			height: 128px !important;
			.timeValue{
				font-size: 36px;
				color: @COLOR_GRAY02;
				&::before{
					content: "";
					display: block;
					position: absolute;
					left: 45px;
					bottom: 20px;
					width: 35px;
					height: 35px;
					background-image: url('../../../../../assets/icon/ic_guide_count.png');
					background-repeat: no-repeat;
					background-size: contain;
				}
			}
		}
    .sftScoreResult, .timeResult{
      position: absolute;
      bottom: 176px;
      right: 52px;
      width: 263px;
      height: 197px;
      font-family: "LGSmartUIB";
      border-radius: 16px;
      background: rgba(33, 33, 32, 0.80);
      text-align: center;
      color: @COLOR_GRAY01;
      .grade, .prevent, .manage, .danger, .sGrade{
		    font-family: "LGSmartUIB";
		    width: 40px;
		    height: 28px;
		    line-height: 28px;
		    border-radius: 1.66667rem;
		    margin-left: 50%;
		    transform: translateX(-50%);
		    margin-top: 18px;
		    margin-bottom: 18px;
		    padding: 4px 14px;
		    font-size: 24px;
      }
      .sGrade{
        width: 80px !important;
        height: 32px !important;
		    line-height: 32px !important;
      }
      .prevent{
        background-color: #44A280;
      }
      .manage{
        background-color: #F9B811;
      }
      .danger{
        background-color: #F44B4A;
      }
      .countResult{
        font-size: 45px;
        color: @COLOR_GRAY02;
        >span{
          font-size: 27px;
					margin-left: 10px;
        }
        &::after{
          content:"";
          width: 215px;
          height: 1px;
          opacity: 0.3;
          background: #D9D9D9;
          display: block;
          position: absolute;
          top: 63%;
          left: 10%;
        }
      }
      .time, .bodyFlexTime{
        font-size: 24px;
        color: @COLOR_GRAY02;
        line-height: 95px;
        &::before{
          content: "";
          display: block;
          position: absolute;
          left: 70px;
					bottom: 23px;
          width: 24px;
          height: 24px;
          background-image: url('../../../../../assets/icon/ic_guide_count.png');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
      .bodyFlexTime{
        line-height: 40px;
      }
    }
  }

  .instructionSign {
    position: absolute;
    color: #222222;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    border-radius: 55px;
    padding: 13px 25px;
    font-family: LGSmartUI;
    margin-top: 25px;
    left: 50%;
    transform: translate(-50%);
  }

  .front {
    // opacity: 1;
    position: absolute;
    font-size: 100px;
    color: #fff;
    font-family: LGSmartUI;
    font-weight: bold;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    // animation: hideFrontSign 0.5s linear 2s;
    // animation-fill-mode: forwards;
  }

  .pauseBtn {
    position: absolute;
    right: 52px;
    bottom: 75px;
    z-index: 100;
  }
  .movePosition{
    position: absolute;
    right: 335px;
    bottom: 75px;
  }
}

@keyframes hideFrontSign {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.boundingBox {
  position: absolute;
  box-shadow: 0 0 0 2px #fff inset;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  cursor: pointer;
}

.takePicture {
  scale: 1;
  background-color: white;
  &:focus {
    scale: 1.3;
    background-color: orange;
  }
}

.exerciseTitle {
  padding-top: 40px;
  text-align: center;
  width: 100%;
  font-size: 50px;
  color: #000;
  font-family: "LGSmartUISB";
}

.counterContainer {
  position: absolute;
  bottom: 176px;
  left: 52px;
  z-index: 100;
  .flex(@direction: column, @justifyCenter: space-between);
}

// .buttonContainer {
//   display: flex;
//   position: absolute;
//   bottom: 75px;
//   left: 70px;
//   z-index: 100;
// }

.retryTestContainer{
  position: absolute;
  left: 52px;
  bottom: 75px;
  z-index: 100;
  .retryBtn{
    width: 314px;
    height: 81px;
    line-height: 81px;
    font-size: 28px;
    color: @COLOR_GRAY01;
    background: rgba(33, 33, 32, 0.70) !important;
  }
}

.videoSkip, .changePosition{
  position: fixed;
  left: 386px;
  bottom: 75px;
  z-index: 100;
  .skipBtn {
    position: relative;
    width: 263px;
    height: 81px;
    line-height: 81px;
    background-color: rgba(33, 33, 32, 0.70) !important;
    color: @COLOR_GRAY01;
    // > div > span {
    //   display: inline-block;
    //   width: 29px;
    //   height: 29px;
    //   margin: 0 10px;
    //   vertical-align: middle;
    //   background-image: url("../../../../../assets/icon/skip_btn.png");
    //   background-repeat: no-repeat;
    // }
    // &:hover,
    // &:focus {
    //   > div > span {
    //     background-image: url("../../../../../assets/icon/skip_btn_revert.png");
    //   }
    // }
  }
}
.changePosition{
  left: 52px;
}

.instruction {
  >div>div>div>div{
    width: 828px !important;
  }
  > div > div > div > div > div:nth-child(1) > div:nth-child(3) {
    // dumbbell info
    color: @PRIMARY_COLOR_BROWN02;
    font-size: 27px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 28px;
      height: 28px;
      background: url("../../../../../assets/icon/ic_alert_line.svg") no-repeat 0 0;
      background-size: contain;
      top: 2px;
      left: 50px;
    }
  }
}
.imgBtnGroup {
  display: flex;
  justify-content: space-evenly;
  column-gap: 24px;
  .imgBox {
    width: 402px;
    height: 263px;
    background-color: @COLOR_GRAY02;
    border-radius: 12px;
    text-align: center;
    position: relative;
    box-sizing: border-box;
    &:before {
      // content: "";
      position: absolute;
      display: inline-block;
      width: 36px;
      height: 36px;
      background: url(../../../../../assets/icon/unChked_icon.svg) no-repeat 0 0;
      background-size: 36px;
      top: 24px;
      left: 24px;
    }
    &.selected {
      outline: 4px solid @PRIMARY_COLOR_BROWN03;
      background-color: @COLOR_GRAY01;
      &:before {
        background: url(../../../../../assets/icon/checked_icon.svg) no-repeat 0 0;
      }
    }
    &:focus {
      outline: 4px solid @PRIMARY_COLOR_BROWN03;
      background-color: @COLOR_GRAY01;
    }
    .img {
      width: 148px;
      height: 148px;
      display: inline-block;
      margin-top: 32px;
    }
    .chair {
      background: url(../.././../../../assets/icon/chair_icon.svg) no-repeat 0 0;
    }
    .dumbbell {
      background: url(../.././../../../assets/icon/dumbbell_icon.svg) no-repeat
        0 0;
    }
    .target {
      background: url(../../../../../assets/icon/ic_target.svg) no-repeat
        0 0;
    }
    .text {
      font-size: 30px;
      color: @COLOR_GRAY07;
      font-weight: 600;
      margin-top: 20px;
    }
  }
}
.upAndGoGuide{
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 828px;
  height: 180px;
  border-radius: 14px;
  border: 2px solid rgba(17, 162, 126, 0.10);
  background: rgba(17, 162, 126, 0.06);
  text-align: center;
  font-size: 27px;
  margin-top: 32px;
  >div>span{
    display: inline-block;
    width: 28px;
    height: 28px;
    margin-right: 6px;
    vertical-align: bottom;
    background: url(../.././../../../assets/icon/ic_alert_line.png) no-repeat center;
    background-size: cover;
  }
}

@keyframes bellyPoint {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.bellyPointContainer {
	position: relative;
	width: 960px;
	height: 1080px;
}

.bellyPoint {
	width: 1.875rem;
	height: 1.875rem;
	margin-left: -0.9375rem;
	margin-top: -0.9375rem;
	position: absolute;
	z-index: 100;
	transform: scale(1);
	animation: bellyPoint linear infinite 0.8s;
	-webkit-animation: bellyPoint linear infinite 0.8s;
	transition-property: left, top;
	transition-duration: 100ms;
	transition-timing-function: linear;
	&.hide {
		opacity: 0;
	}
}

.testSkip{
  position: fixed;
  right: 52px;
  bottom: 75px;
  z-index: 100;
  .skipBtn{
    position: relative;
    width: 263px;
    height: 81px;
    line-height: 81px;
    background-color: rgba(33, 33, 32, 0.70) !important;
    color: @COLOR_GRAY01;
    // >div>span{
    //   display: inline-block;
    //   width: 29px;
    //   height: 29px;
    //   margin: 0 10px;
    //   vertical-align: middle;
    //   background-image: url('../../../../../assets/icon/skip_btn_revert.png');
    //   background-repeat: no-repeat;
    // }
    // &:hover, &:focus{
    //   >div>span{
    //     background-image: url('../../../../../assets/icon/skip_btn_revert.png');
    //   }
    // }
  }
}
.hide {
  opacity: 0;
}
@__DEV__: true;