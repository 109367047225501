@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@GRAPH_SIZE: 270px;

.popup {
  margin-bottom: 0 !important;
  width: 100%;
  height: 100%;
  > div > div {
    height: 100% !important;
    > div {
      max-width: unset !important;
      height: 100% !important;
      max-height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .topContainer {
    display: flex;
    column-gap: 80px;
    align-items: center;
    font-family: "LGSmartUIR";
    margin-bottom: 40px;
    .goalHistory {
      position: relative;
      height: @GRAPH_SIZE;
      padding: 40px;

      .goalPercentage {
        position: absolute;
        top: 50%;
        height: 53px;
        line-height: 53px;
        left: 50%;
        transform: translate(-50%,-50%);
        .font(@fontFamily: "LGSmartUIB" , @fontSize: 45px, @color: @COLOR_GRAY01, @fontWeight: 700);
        > span {
          .font(@fontFamily: "LGSmartUISB" , @fontSize: 27px, @color: @COLOR_GRAY01, @fontWeight: 600);
        }
      }
    }
    .aiContainer {
      width: 460px;
      .aiTitle {
        font-family: "LGSmartUIB";
        font-size: 39px;
        margin-bottom: 14px;
        color: @COLOR_GRAY01;
      }
      .aiFeedback {
        font-size: 27px;
        line-height: 36px;
        color: @COLOR_GRAY02;
        b {
          font-size: 'LGSmartUIB';
        }
      }
      hr {
        margin: 26px 0;
        border-color: @COLOR_GRAY06;
      }
      .detail {
        color: @COLOR_GRAY03;
        display: flex;
        column-gap: 20px;
        font-size: 27px;
        justify-content: space-between;
        line-height: normal;
        margin-bottom: 12px;
        > div {
          font-family: "LGSmartUIB";
          > span {
            font-family: "LGSmartUIR";
          }
        }
      }
    }
  }

  .noMarginBottom {
    margin-bottom: 0 !important;
  }

  .textContainer {
    width: 925px;
    height: 156px;
    line-height: 38px;
    font-size: 30px;
    padding: 40px;
    margin-bottom: 60px;
    word-break: keep-all;
    background-color: @COLOR_GRAY08;
    border-radius: 20px;
    font-family: "LGSmartUIR";
    box-sizing: border-box;
    color: @COLOR_GRAY01;
  }
  .btnContainer {
    display: flex;
    justify-content: center;
  }

  // goto aiManager at home popup
  .gotoAiManager {
    font-family: "LGSmartUIB";
    font-size: 45px;
    text-align: center;
    color: @COLOR_GRAY01;
    .text {
      width: 674px;
      margin-bottom: 96px;
    }
  }
}
@__DEV__: true;