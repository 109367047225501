@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.archImg {
  width: 138px;
  margin-right: 42px;
}

.archBarItemSectWrap {

  &:first-child {
    .archBarItemSect {
      padding-bottom: 50px;
      border-bottom: 1px solid #D9D9D9;
    }
  }

  &:last-child {
    .archBarItemSect {
      margin-top: 50px;
    }
  }
}


.archInfoWrap {
  font-family: 'LGSmartUIB';
  color: #363533;
  font-size: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 34px;

  .archIndex {
  }

  .type {
    margin-left: 8px;
  }

  .level {
    font-family: 'LGSmartUISB';
    color: #787776;
    margin-left: 8px;
  }
}

  .fixedBar {
    display: flex;
    align-items: center;
    height: 14px;
    background-color: #DBD7D4;
  }

  .stepFlex {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 20px;
    text-align: center;
    color: #363533;
    margin-top: 9px;

    &.LeftAlign {
      text-align: left;
    }

    &.rightAlign {
      text-align: right;
    }

    >div>span {
      display: block;

      &.step {
        color: #787776;
        margin-top: -6px;
      }
    }
   }

  .fixedBarSection {
    position: relative;
    width: 33%;
    height: 100%;

    .marker {
      position: absolute;
      bottom: 0;
      transform: translate(50%, 0%);
      width: 14px;
      height: 38px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      z-index: 2;
    }

    .none {
      display: none;
    }

    &.centerBar {
      position: relative;
      width: 34%;

      &::before {
        content: "";
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translate(50%, -50%);
        height: 100%;
        border: 1px solid #FEFEFE;
        box-sizing: border-box;
        z-index: 1;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        height: 100%;
        border: 1px solid #FEFEFE;
        box-sizing: border-box;
        z-index: 1;
      }
    }

    &.located {
      background-color: @PRIMARY_COLOR_BROWN01;
    }
  }
@__DEV__: true;