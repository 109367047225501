@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@bgImageFocus: linear-gradient(
  50deg,
  rgba(90, 111, 146, 0.6),
  rgba(90, 111, 146, 0.77),
  rgba(112, 77, 116, 0.77),
  rgba(112, 77, 116, 0.6)
);
@fillPerImage: linear-gradient(
  46deg,
  rgba(91, 113, 200, 40%),
  rgba(171, 130, 238, 40%)
);

.container {
  position: relative;
  border-radius: 60px;
  padding: 6px;
  height: 53px;
  width: 127px;
  background-color: @COLOR_GRAY02;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  &:focus {
    background-color: @PRIMARY_COLOR_BROWN03;
    .tButton.selected {
      background-color: @COLOR_GRAY01;
      color: @COLOR_GRAY07;
    }
  }

  .tButton {
    position: relative;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;

    width: 58px;
    height: 41px;
    .font(@fontFamily: "LGSmartUIB", @fontSize: 18px, @color: @COLOR_GRAY04, @fontWeight: 700);

    &:focus {
      background-color: @PRIMARY_COLOR_BROWN03;
      color: @COLOR_GRAY01;
      transition: background-color 200ms ease;
    }
  }

  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      border-radius: inherit;
      opacity: 0.3;
    }
  }

  &.disabled {
    opacity: 0.3;
  }
}

//하단부 버튼 hover및 focus 컬러 지정
//colorE0DED6

.tButton {
  //common style
  display: inline-block;
  box-sizing: border-box;
  background-size: contain;
  vertical-align: middle;
  font-family: "LGSmartUISB";
  background-color: transparent;
  color: @COLOR_GRAY08;
  border-radius: 60px;

  &.selected {
    background-color: @COLOR_GRAY06;
  }

  &:focus {
    background-color: @PRIMARY_COLOR_BROWN03;
    color: @COLOR_GRAY01;
    transition: all 200ms ease;
  }
  &.disabled {
    opacity: 0.3;
  }
}

@__DEV__: true;