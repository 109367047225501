@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.datePicker {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;

  .calendar {
    align-self: center;
  }

  .textLayer {
    min-width: 298px;
    height: 50px;
    line-height: 1.2;
    position: relative;
    overflow: hidden;

    .text {
      width: 100%;
      .font(@fontFamily: "LGSmartUIR", @fontSize: 40px, @color: #212120, @fontWeight: 600);
    }

    .text:first-child {
      position: absolute;
      transform: translateX(-100%);
    }

    .text:last-child {
      position: absolute;
      transform: translateX(100%);
    }

    .text.inc {
      transition: all 300ms ease;
      // transform: translateX(-100%);

      &:first-child {
        // transform: translateX(-200%);
      }

      &:last-child {
        // transform: translateX(0%);
      }
    }

    .text.dec {
      transition: all 300ms ease;
      // transform: translateX(100%);

      &:first-child {
        // transform: translateX(0%);
      }

      &:last-child {
        // transform: translateX(200%);
      }
    }
    &.quarterly {
      min-width: 460px;
    }
    &.monthly {
      min-width: 250px;
    }
    &.weekly {
      min-width: 500px;
    }
    &.total {
      min-width: 298px;
      display: flex;
      align-items: center;
    }
    &.disabled {
      opacity: 0.2;
    }
  }

  .TIconLayer {
    display: flex;
    align-items: center;

    .chevron {
      &:first-child {
        margin-right: 18px;
      }
    }
  }

  &.isDark {
    .text {
      color: @COLOR_GRAY01;
    }
  }
}

.blurCss {
  pointer-events: none;
  filter: opacity(0.2);
}

.dropdown {
  position: absolute;
  opacity: 0;
  top: -24px;
  pointer-events: none;
}

@__DEV__: true;