@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";

// location
.myProfile {
  position: relative;
  .account {
    position: absolute;
    top: 60px;
    left: 0;
  }
  .subscription {
    position: absolute;
    top: 198px;
    left: 0;
  }
  .rewardContainer {
    position: absolute;
    top: 346px;
    left: 0;
  }
  .healthBall {
    position: absolute;
    top: 60px;
    left: 903px;
  }
  .advertisment {
    position: absolute;
    top: 631px;
    left: 903px;
  }
}

.account {
  display: flex;
  align-items: center;

  .image{
    .size(@w: 100px, @h: 100px);
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 26px;
  }

  .info {
    .flex(@display: flex, @justifyCenter: center, @alignCenter: normal, @direction: column);
    width: 387px;
    margin-right: 12px;
    .name {
      font-family: 'LGSmartUIB';
      font-size: 33px;
      color: #FEFEFE;
      height: 39px;
      line-height: 39px;
    }
    .email {
      font-family: 'LGSmartUIR';
      font-size: 27px;
      color: #B2B0AE;
      height: 32px;
      line-height: 32px;
    }
  }
  .button {
    min-width: 160px;
  }
}

.subscription {
  .size(@w: auto, @h: 100px);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
  padding: 34px 32px;
  box-sizing: border-box;
  border-radius: 20px;

  .subscribeIcon {
    .size(@w: 26px, @h: 26px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../assets/myPanel/icon/ic_my_subscribe_nor.png");
    margin-right: 10px;
  }

  .arrowIcon {
    .size(@w: 28px, @h: 28px);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../../assets/myPanel/icon/ic_right_dark_nor.png");
  }

  .title {
    .size(@w: 113px, @h: 32px);
    font-size: 27px;
    line-height: 32px;
    color: #DBD7D4;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    margin-right: 12px;
  }
  .text {
    .size(@w: 573px, @h: 32px);
    font-size: 27px;
    line-height: 32px;
    color: #DBD7D4;
    font-family: 'LGSmartUIR'; //font-weight: 400;
    margin-right: 12px;
  }

  &:focus {
    .subscribeIcon {
      background-image: url("../../../../assets/myPanel/icon/ic_my_subscribe_foc.png");
    }
    .arrowIcon {
      background-image: url("../../../../assets/myPanel/icon/ic_right_dark_foc.png");
    }
    .title {
      color: #212120;
    }
    .text {
      color: #212120;
    }
  }
}

.advertisment {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  width: 760px;
  height: 133px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.04);
  color: #787776;
  font-size: 28px;
  font-family: 'LGSmartUISB';
}


.rewardContainer {
  .title {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center);
    margin-bottom: 24px;
    font-size: 33px;
    color: #FEFEFE;
    height: 39px;
    line-height: 39px;
    font-family: 'LGSmartUIB';

    &::before {
      content: "";
      display: block;
      .size(@w: 36px, @h: 36px);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("../../../../assets/myPanel/icon/ic_my_reward.png");
      margin-right: 8px;
    }

  }
  .list {

  }

  .reward {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center);
    padding: 24px 0;
    box-sizing: border-box;
    .image {
      .size(@w: 40px, @h: 40px);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 20px;
    }
    .text {
      .size(@w: 460px, @h: 32px);
      font-size: 27px;
      color: #F2EFED;
      line-height: 32px;
      font-family: 'LGSmartUISB';
      margin-right: 38px;
    }
    .point {
      .size(@w: 266px, @h: 32px);
      font-size: 27px;
      color: #F2EFED;
      line-height: 32px;
      font-family: 'LGSmartUISB';
      text-align: end;
    }
  }
}

@__DEV__: true;