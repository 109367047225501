@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.summarySect {
  position: relative;
  height: 100%;

  .summaryFlex {
    display: flex;
    justify-content: space-between;
    height: 100%;

    .innerBox {

      .title {
        font-family: 'LGSmartUIB';
        font-size: 36px;
        text-align: center;
        margin-bottom: 24px;
      }
    }


    .statusWrap {
      width: 508px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .statusBox {
        .tScroller {
          font-family: 'LGSmartUIR';
          color: @COLOR_GRAY07;
        }

        &:first-child {
          .tScroller {
            height: 405px;
            padding: 18px;
            font-size: 27px;
            border: 1px solid #DBD7D4;
            border-radius: 10px;

            .info {
              line-height: 1.4;

              .infoText:last-child {
                margin-top: 16px;
              }
            }
          }
        }

        &:last-child {
          .title {
            font-size: 27px;
            margin-bottom: 10px;
          }

          .info {
            line-height: 1.4;
            padding: 18px;
            font-size: 21px;
            border-radius: 10px;
            background-color: @COLOR_GRAY02;
          }
        }
      }
    }

    .muscleWrap {
      width: 456px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .mirrorMode {
        position: absolute;
        .font(@fontFamily: "LGSmartUIR", @fontSize: 20px, @color: @COLOR_GRAY05, @fontWeight: 600);
        top: 590px;
        right: 0;
      }
      .title {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
      }

      .muscleImgWrap {
        display: flex;
        justify-content: space-between;
        margin-top: 90px;
        margin-bottom: 24px;

        > img {
          width: 217px;
          height: 496px;
        }
      }

      .muscleInfoWrap {
        display: flex;
        width: fit-content;
        font-size: 24px;
        margin: 0 auto;
        border-radius: 12px;
        padding: 12px 30px 12px 44px;

        >div {
          position: relative;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            left: -14px;
            transform: translate(-50%, -50%);
            width: 9px;
            height: 9px;
            border-radius: 50%;
            background-color: #3D90F5;
          }

          &:first-child {
            margin-right: 70px;

            &:before {
              background-color: #F44B4A;
            }
            &.markerGreen {
              margin-right: 0;
              &:before {
                background-color: #44a280;
              }
            }
          }
        }
      }
    }

    .doubleRightArrow {
      width: 23px;
    }

    .recommendWrap {
      width: 545px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .recommendInner {
        .title {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          width: max-content;
        }

        .imgBox {
          margin-top: 57px;
          position: relative;
          width: 100%;
          height: 312px;
          overflow: hidden;
          border-radius: 10px;
          margin-bottom: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            border-radius: 10px;
          }
        }

        .infoBox {
          margin-bottom: 10px;

          .info {
            font-size: 26px;
            line-height: 32px;
            display: flex;
            margin-bottom: 14px;
            &:last-child {
              margin-bottom: 0;
            }

            .dotPoint {
              height: 100%;
              display: inline-block;
              vertical-align: middle;
              min-width: 6px;
              height: 6px;
              background-color: black;
              border-radius: 50%;
              margin-top: 14px;
              margin-left: 4px;
              margin-right: 12px;
            }
          }
          &.smallMargin {
            .info {
              margin-bottom: 8px;
            }
          }
        }
      }

      .testBtnWrap {
        text-align: center;
      }
    }
  }
}

.labelContainerL {
  position: absolute;
  top: 511px;
  left: -52px;
  width: 70%;
  height: 2.5rem;
  z-index: 10;
}

.labelContainerR {
  position: absolute;
  top: 511px;
  left: 190px;
  width: 70%;
  height: 2.5rem;
  z-index: 10;
}

.label {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #DBD7D4;
  font-family: "LGSmartUISB";
  .font(@fontFamily: "LGSmartUIB", @fontSize: 20px, @color: @COLOR_GRAY01, @fontWeight: 600);
  left: 52px;
  &.isRight {
    left: auto;
    right: 52px;
  }
}

.positionFB{
  display: flex;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 2.5rem;
  justify-content: space-between;
  .text{
    font-size: 25px;
    width: 217px;
    text-align: center;
  }
}
@__DEV__: true;