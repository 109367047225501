@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

.canvas {
  height: 100%;
  display: block;
  margin: 0 auto;
}

.hide {
  display: none;
}

.canvasWrap {
  height: 100%;
  display: flex;

  .canvasBox {
    position: relative;
    display: inline-block;
    width: 490px;
    height: 306px;
    border-radius: 16px;
    border: 1px solid #dbd7d4;
    background-color: #000080;

    &:first-child {
      margin-right: 40px;
    }

    .footPosition {
      position: absolute;
      bottom: 24px;
      font-family: 'LGSmartUISB';
      color: #FEFEFE;
      font-size: 21px;
      text-align: center;
      line-height: 36px;
      width: 36px;
      height: 36px;
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 50%;

      &.left {
        right: 24px;
      }

      &.right {
        left: 24px;
      }
    }

    .contourLineSect {
      position: absolute;
      top: 50%;
      transform: translate(0%, -50%);
      background-color: rgba(255, 255, 255, 0.20);
      border-radius: 10px;
      padding: 10px;

      &.left {
        left: 24px;

        .flexBox {

          .line {
            margin-right: 10px;
          }
        }
      }

      &.right {
        right: 24px;

        .flexBox {
          flex-direction: row-reverse;

          .line {
            margin-left: 10px;
          }
        }
      }

      .flexBox {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .line {
          width: 12px;
          height: 226px;
          background: linear-gradient(180deg, #FF1200 0%, #FC9410 12.48%, #FFFA0E 24.9%, #43F50C 37.4%, #18C3B6 49.9%, #0D76D7 61.88%, #0739BC 74.38%, #16009F 86.88%, #000 100%);
          border-radius: 6px;
        }

        .figure {
          height: 226px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          >span {
            font-family: 'LGSmartUISB';
            font-size: 15px;
            color: #DBD7D4;
            line-height: 1;
          }
        }
      }
    }
  }
}
@__DEV__: true;