@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";
.inline{
  color: black;
  position:relative;
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  >div{
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 20px;
  }
  .responseBox{
    display: inline-block;
    text-align: center;
    margin-top: 10px;
    margin-right: 20px;
    padding: 20px;
    width: 32%;
    height: 500px;
    border-radius: 20px;
    border-color: #000;
    color: black;
    border: 1px solid black;
    .row{
      text-align: left;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px;
    }
  }
}
@__DEV__: true;