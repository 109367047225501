@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tIconButton {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: 54px 54px;
  // background-color: #E0DFD6;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  box-sizing: border-box;

  //size
  &.small {
    width: 50px;
    height: 50px;
    background-size: 50px 50px;
  }
  &.tiny {
    width: 30px;
    height: 30px;
    background-size: 14px 14px;
  }

  &:focus {
    background-color: #4F423C;
  }

  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      border-radius: inherit;
      opacity: 0.3;
    }
  }
  &.disabled {
    // background-color: @BG_COLOR_05;
    opacity: 0.2;
    &:focus {
      opacity: 0.5;
    }
  }
  &:last-child {
    margin-right: 0;
  }

  // color
  &.black {
    background-color: #494847;
    &:focus {
      background-color: #F2EFED;
    }
    &.pressed {
      &::before {
        background-color: #212120;
        opacity: 0.3;
      }

    }
  }
}

.tIconButton.isDark {
  &.pressed {
    &::before {
      background-color: #212120;
      opacity: 0.3;
    }
  }
  &:focus {
    background-color: #FEFEFE;
  }
}




//back btn
.back {
  background-image: url("../../../assets/Theader/ic_back.png");
  &:focus {
    background-image: url("../../../assets/Theader/ic_back_w.png");
  }
}
.back.isDark {
  background-image: url("../../../assets/Theader/ic_back_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_back_dark_focused.svg");
  }
}

.home {
  .imgElement(@backgroundW:36px, @backgroundH: 36px, @positionX: center, @positionY: center);
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none'%3E%3Cpath stroke='%23706F69' stroke-linejoin='round' stroke-width='3' d='M17.875 2 2 14v20h11V23.5h10V34h11V14L17.875 2Z'/%3E%3C/svg%3E");
}

.pause {
  background-image: url("../../../assets/icon/pauseIcon.png");
}
.testPause{
  width: 81px !important;
  height: 81px !important;
  background-size: cover;
  background-image: url("../../../assets/icon/ic_pause_s_nor.png");
}
.play {
  background-image: url("../../../assets/icon/playIcon.png");
}

.underArrow {
  background-image: url("../../../assets/icon/ic_calendar_arrow_down_nor.svg");
  opacity: 1;
  border-radius: unset;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  &:focus {
    background-image: url("../../../assets/icon/ic_calendar_arrow_down_foc.svg");
    opacity: 1;
    background-color: unset;
  }
  &.pressed {
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #000000;
      border-radius: inherit;
      opacity: 0.3;
    }
  }
}

.rightArrow {
  background-image: url("../../../assets/icon/nomal_right_arrow.svg");
  opacity: 1;
  border-radius: unset;
  &:focus {
    background-image: url("../../../assets/icon/focus_right_arrow.svg");
    opacity: 1;
    background-color: unset;
  }
  &.pressed {
    &::before {
      background-color: unset;
    }
  }
}

.leftArrow {
  background-image: url("../../../assets/icon/nomal_left_arrow.svg");
  opacity: 1;
  border-radius: unset;
  &:focus {
    background-image: url("../../../assets/icon/focus_left_arrow.svg");
    opacity: 1;
    background-color: unset;
  }
  &.pressed {
    &::before {
      background-color: unset;
    }
  }
}

.rightArrow.isDark{
  background-image: url("../../../assets/icon/ic_calendar_arrow_right_nor_dark.svg");
  &:focus {
    background-image: url("../../../assets/icon/ic_calendar_arrow_right_foc_dark.svg");
    border-radius: 7px;
  }
}
.leftArrow.isDark{
  background-image: url("../../../assets/icon/ic_calendar_arrow_left_nor_dark.svg");
  &:focus {
    background-image: url("../../../assets/icon/ic_calendar_arrow_left_foc_dark.svg");
    border-radius: 7px;
  }
}


.leftArrowS {
  background-image: url("../../../assets/icon/ic_calendar_arrow_left_s_nor.svg");
  opacity: 1;
  border-radius: unset;
  &.small {
    background-size: cover;
  }
  &:focus {
    background-image: url("../../../assets/icon/ic_calendar_arrow_left_s_foc.svg");
    opacity: 1;
    background-color: unset;
  }
  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: inherit;
      opacity: 0.15;
    }
  }
}
.rightArrowS {
  background-image: url("../../../assets/icon/ic_calendar_arrow_right_s_nor.svg");
  opacity: 1;
  border-radius: unset;
  &.small {
    background-size: cover;
  }
  &:focus {
    background-image: url("../../../assets/icon/ic_calendar_arrow_right_s_foc.svg");
    opacity: 1;
    background-color: unset;
  }
  &.pressed {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      border-radius: inherit;
      opacity: 0.15;
    }
  }
}

.calendar {
  .imgElement(@backgroundW: 46px, @backgroundH: 46px, @positionX: center, @positionY: center);
  border-radius: unset;
  background-image: url("../../../assets/icon/nomal_calendar_icon.svg");
  width: 46px !important;
  height: 46px !important;
  background-size: unset;
  align-self: baseline;
  &:focus {
    background-image: url(../../../assets/icon/focus_calendar_icon.svg);
    border-radius: unset;
    background-color: unset;
    background-size: unset;
  }
  &.pressed {
    &::before {
      background-color: unset;
    }
  }

  &.isDark{
    background-image: url("../../../assets/icon/ic_workoutmanager_calendar.svg");
    &:focus {
      background-image: url("../../../assets/icon/nomal_calendar_icon.svg");
      border-radius: 7px;
    }
  }
}

.heart {
  .imgElement(@backgroundW: 36px, @backgroundH: 36px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/heart.png");
}

.heartHollow {
  .imgElement(@backgroundW: 36px, @backgroundH: 36px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/heart_hollow.png");
}

.heartWhite {
  .imgElement(@backgroundW: 36px, @backgroundH: 36px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/heart.svg");
}

.heartHollowWhite {
  .imgElement(@backgroundW: 36px, @backgroundH: 36px, @positionX: center, @positionY: center);
  background-image: url("../../../assets/icon/heart_hollow.svg");
}

// survey
.plus {
  background-image: url("../../../assets/survey/plus_white.svg");
  &:focus {
    background-image: url("../../../assets/survey/plus_black.svg");
  }
}

.minus {
  background-image: url("../../../assets/survey/minus_white.svg");
  &:focus {
    background-image: url("../../../assets/survey/minus_black.svg");
  }
}

// header
.mat {
  background-image: url("../../../assets/Theader/ic_mat.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_mat_w.svg");
  }
}
.matNo {
  background-image: url("../../../assets/Theader/ic_mat_no.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_mat_no_w.svg");
  }
}
.matNo.isDark {
  background-image: url("../../../assets/Theader/ic_mat_no_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_mat_no_dark_focused.svg");
  }
}
.camera {
  background-image: url("../../../assets/Theader/ic_camera.png");
  &:focus {
    background-image: url("../../../assets/Theader/ic_camera_w.png");
  }
}
.cameraNo {
  background-image: url("../../../assets/Theader/ic_camera_no.png");
  &:focus {
    background-image: url("../../../assets/Theader/ic_camera_no_w.png");
  }
}
.camera.isDark {
  background-image: url("../../../assets/Theader/ic_camera_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_camera_dark_focused.svg");
  }
}
.cameraNo.isDark {
  background-image: url("../../../assets/Theader/ic_camera_no_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_camera_no_dark_focused.svg");
  }
}

.close {
  background-image: url("../../../assets/Theader/ic_close.png");
  &:focus {
    background-image: url("../../../assets/Theader/ic_close_w.png");
  }
}
.close.isDark {
  background-image: url("../../../assets/Theader/ic_close_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_close_dark_focused.svg");
  }
}
.alert {
  background-image: url("../../../assets/Theader/ic_alert.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_alert_w.svg");
  }
}
.alertNoti {
  background-image: url("../../../assets/Theader/ic_alert_noti.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_alert_noti_w.svg");
  }
}
.alert.isDark {
  background-image: url("../../../assets/Theader/ic_alert_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_alert_dark_focused.svg");
  }
}
.alertNoti.isDark  {
  background-image: url("../../../assets/Theader/ic_alert_noti_dark.svg");
  &:focus {
    background-image: url("../../../assets/Theader/ic_alert_noti_dark_focused.svg");
  }
}

.speaker {
  .size(@w: 52px, @h: 52px);
  .imgElement(@backgroundW: 1.2917rem, @backgroundH: 1.2917rem, @positionX: center, @positionY:center);
  border-radius: 100%;
  background-color: #e0ded5;
  min-width: 52px;
  background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%23706F69' stroke-width='1.857' d='M8.044 12.1H3.37a.371.371 0 0 0-.371.371v7.057c0 .205.166.372.371.372h4.687c.091 0 .18.034.248.095l5.774 5.197c.24.215.62.045.62-.276V6.496c0-.33-.4-.496-.634-.262L8.307 11.99a.371.371 0 0 1-.263.109Z'/%3E%3Cpath stroke='%23706F69' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.86' d='M27.5 12 20 19.5m0-7.5 7.5 7.5'/%3E%3C/svg%3E");
  &:focus,
  &:hover {
    background-color: #403e3d;
    box-shadow: 0 6px 8px 0 rgba(64, 62, 61, 0.2),
      0 5px 23px 0 rgba(64, 62, 61, 0.12), 0 12px 19px 0 rgba(64, 62, 61, 0.14);
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.86' d='M27.5 12 20 19.5m0-7.5 7.5 7.5'/%3E%3Cpath stroke='%23fff' stroke-width='1.857' d='M8.044 12.1H3.37a.371.371 0 0 0-.371.371v7.057c0 .205.166.372.371.372h4.687c.091 0 .18.034.248.095l5.774 5.197c.24.215.62.045.62-.276V6.496c0-.33-.4-.496-.634-.262L8.307 11.99a.371.371 0 0 1-.263.109Z'/%3E%3C/svg%3E");
    //focus, hover -> selected on speaker
    &.selected {
      background-color: #403e3d;
      background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.857' d='M26.07 6.9a15.53 15.53 0 0 1 2.928 9.1 15.53 15.53 0 0 1-2.927 9.1m-5.204-14.3a9.059 9.059 0 0 1 1.631 5.2 9.059 9.059 0 0 1-1.63 5.2'/%3E%3Cpath stroke='%23fff' stroke-width='1.857' d='M8.044 12.1H3.37a.371.371 0 0 0-.371.371v7.057c0 .205.166.372.371.372h4.687c.091 0 .18.034.248.095l5.774 5.197c.24.215.62.045.62-.276V6.496c0-.33-.4-.496-.634-.262L8.307 11.99a.371.371 0 0 1-.263.109Z'/%3E%3C/svg%3E");
    }
  }
  //selected on speaker(gray)
  &.selected {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' fill='none'%3E%3Cpath stroke='%23706F69' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.857' d='M26.07 6.9a15.53 15.53 0 0 1 2.928 9.1 15.53 15.53 0 0 1-2.927 9.1m-5.204-14.3a9.059 9.059 0 0 1 1.631 5.2 9.059 9.059 0 0 1-1.63 5.2'/%3E%3Cpath stroke='%23706F69' stroke-width='1.857' d='M8.044 12.1H3.37a.371.371 0 0 0-.371.371v7.057c0 .205.166.372.371.372h4.687c.091 0 .18.034.248.095l5.774 5.197c.24.215.62.045.62-.276V6.496c0-.33-.4-.496-.634-.262L8.307 11.99a.371.371 0 0 1-.263.109Z'/%3E%3C/svg%3E");
  }
}

.top {
  width: 120px;
  height: 120px;
  background-size: 120px 120px;
  background-image: url("../../../assets/icon/ic_inhome_activity_top_nor.svg");
  &:focus {
    background-image: url("../../../assets/icon/ic_inhome_activity_top_foc.svg");
  }
  &.pressed {
    background-image: url("../../../assets/icon/ic_inhome_activity_top_pre.svg");

    &::before {
      content: unset;
    }
  }
}

.like {
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
  background-image: url("../../../assets/icon/ic_inhome_like_sel.svg");
  background-color: unset !important;
  &:focus {
    background-image: url("../../../assets/icon/ic_inhome_like_sel_foc.svg");
  }
}

.likeHollow {
  width: 36px;
  height: 36px;
  background-size: 36px 36px;
  background-image: url("../../../assets/icon/ic_inhome_like_nor.svg");
  background-color: unset !important;
  &:focus {
    background-image: url("../../../assets/icon/ic_inhome_like_foc.svg");
  }

  &.pressed {
    background-image: url("../../../assets/icon/ic_inhome_like_pre.svg");

    &::before {
      content: unset;
    }
  }
}

.controlArrow {
  background-image: url('../../../assets/icon/ic_inhome_scenery_arrow_left_nor.svg');
  background-size: 80px 80px;

  &:focus {
    background-image: url('../../../assets/icon/ic_inhome_scenery_arrow_left_foc.svg');
  }
}

.countPlus {
  background-image: url(../../../assets/HomecareProgram/ic_routine_plus_45_nor.svg);
  background-position: center;
  background-size: contain;
  width: 46px;
  height: 46px;
  border-radius: 7px;
  &:focus {
    background-image: url(../../../assets/HomecareProgram/ic_routine_plus_45_foc.svg);
  }
  &.pressed {
    filter: brightness(70%);
  }
}

.countMinus {
  background-image: url(../../../assets/HomecareProgram/ic_routine_minus_45_nor.svg);
  background-position: center;
  background-size: contain;
  width: 46px;
  height: 46px;
  border-radius: 7px;
  &:focus {
    background-image: url(../../../assets/HomecareProgram/ic_routine_minus_45_foc.svg);
  }
  &.pressed {
    filter: brightness(70%);
  }
}

.question{
  width: 70px;
  height: 70px;
  background-size: 70px 70px;
  background-image: url("../../../assets/icon/ic_tutorial_nor.svg");
  background-color: unset !important;
  &:focus {
    background-image: url("../../../assets/icon/ic_tutorial_foc.svg");
  }

  &.pressed {
    background-image: url("../../../assets/icon/ic_tutorial_pre.svg");

    &::before {
      content: unset;
    }
  }
}
@keyframes iconHome {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}








@__DEV__: true;