@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

@ITEM_WIDTH: 805px;

.panel {
  font-family: "LGSmartUIR";
  background: @BG_COLOR_02;
  width: 100%;
  height: 100%;

  .tBody {
    padding: 46px 39px 75px 75px;

    .dropLayer {
      display: flex;
      column-gap: 20px;
      margin-bottom: 30px;
    }

    .topLayer {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-right: 40px;

      .collection {
        color: @color222222;
        font-family: "LGSmartUIB";
        font-size: 36px;
      }

      .orderLayer {
        .order {

        }
      }
    }
    .container {
      display: flex;
      column-gap: 24px;

      .workout_lists {
        height: 730px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .single {
          height: 680px;
          padding: 40px 40px 0 46px;
          background-size: contain;
          background-position: bottom;
        }

        .multiple {
          height: 230px;
          padding: 50px;
          background-size: contain;
          background-position: right;
        }
      }
    }

    .tScroller{
      width: ~'calc(@{ITEM_WIDTH} + 40px)' !important;
      height: 720px;
      padding: 0px;
      flex-direction: column;
      justify-content: space-between;
      > div:nth-child(2) {
        padding: 0;
        > div:nth-child(1) {
          // scorllbar
          height: 680px;
        }
      }

      .centerInfo {
        width: 805px;
      }
    }
  }
}






@__DEV__: true;