@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.buttoncontainer{
    display: flex;
    flex-direction: column;
}
.titleArea {
    position: relative;
    display: block;
    color: black;
    font-size: 1.1667rem;
    .titleBox {
        position: relative;
        display: inline-block;
        width: 17.5rem;
        height: auto;
        left: 3.125rem;
        top: 0rem;
        .text {
          position: relative;
          top: 0rem;
          text-align: start;
          margin-top: 0.5417rem;
          font-weight: bold;
        }
    }
    .textArea {
        position: relative;
        display: inline-block;
        width: 41.6667rem;
        height: auto;
        left: 2.5rem;
        background-color: rgba(174, 0, 255, 0.0);
        .text {
          position: relative;
          color: rgb(252, 252, 252);
          text-align: start;
          margin-top: 0.5417rem;
          color: black;
        }
    }
    .title {
        position: relative;
        width:100%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 'bold';
    }
    .switchs {
      position: relative;
      display: inline-flex;
      width: 100%;
      margin-top: 20px;
      .switchTitle {
        position: relative;
        width: 20.8333rem;
        height: 3rem;
        line-height: 3rem;
        margin-left: 30px;
        font-size: 1.25rem;
        font-weight: bold;
        color: black;
        background-color: bisque;
      }
      .switch{
        width: 10.4167rem;
        color: black;
      }
    }
  }
.bellyCameraBg{
    position:fixed;
    width: 50vw;
    height: 100vh;
    top:0;
    right:0;
    z-index: -1;
    background-color:black;
    > div {
        width: 100%;
        height: 100%;
    }
}
@keyframes bellyPoint {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
}

.bellyPoint {
    width: 45px;
    height: 45px;
    margin-left: -0.9375rem;
    margin-top: -0.9375rem;
    position: absolute;
    z-index: 100;
    transform: scale(1);
    animation: bellyPoint linear infinite 0.8s;
    -webkit-animation: bellyPoint linear infinite 0.8s;
    transition-property: left, top;
    transition-duration: 100ms;
    transition-timing-function: linear;
    &.hide{
        opacity: 0;
    }
}
@__DEV__: true;