@TAB_HEADER_GAP: 23px;

.body {
  padding: @TAB_HEADER_GAP 0 0 0;
}

.tab {
  padding: 0 75px 0 75px;
  box-sizing: border-box;
}

.scroller {
  height: 820px;
}
@__DEV__: true;