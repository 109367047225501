@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

.container {
  position: relative;
}

.graph {
  position: absolute;
  top: 0;
  left: 0;
}

@__DEV__: true;