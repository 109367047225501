@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.helpcontainer {
  position: relative;
  margin: 40px;
  background-color: #fff;

  .swiperSlide {

    &:nth-child(1) {
      .helpSliderInner {
        .helpCont {
          .helpFlex {
            .helpType {
              margin: 0;

              .type {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .helpSliderInner {
      width: max-content;
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);

      .helpTitle {
        font-family: 'LGSmartUISB';
        margin-bottom: 30px;
      }

      .helpCont {

        .helpFlex {
          display: flex;
          margin-bottom: 10px;
          font-size: 24px;

          .helpType {
            color: #fff;
            margin-right: 20px;

            .type {
              text-align: center;
              background-color: #000;
              display: block;
              margin-bottom: 10px;
              padding: 0 14px;
              border-radius: 20px;
            }
          }

          .helpDesc {

            .desc {
              display: block;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  .swiper {
    width: 100%;
    height: 650px;
    background-color: #fff;
    margin-bottom: 60px;
  }

  .paginationWrap {
    position: absolute;
    left: 50%;
    top: 12%;
    transform: translate(-50%, 0);
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 20px;

    .helpSwiperBtn {
      min-width: 18px;
      height: 18px;
      border-radius: 50%;
      padding: 0;

      &.activePage {
        background-color: @fitTv-selected-color;
      }
    }
  }
}
@__DEV__: true;