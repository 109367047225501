@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.body{
  display: flex;
  height: 940px;
  .bookList {
    width: 300px;
    height: 100%;
    color: black;
    background-color: ghostwhite;
    .item {
      height: 52px;
      > div {
        height: 52px;
      }
    }
  }
  .detailLayout{
    width: ~"calc(100% - 300px)";
    height: 100%;
    display: inline-block;
    margin: 20px;
    color: black;
  }
  .logcontainer{
    width: 50%;
    color: black;
  }
  .workerscontainer{
    width: 50%;
    color: black;
  }
}
@__DEV__: true;