@import "../../../../../../style/CommonStyle.module.less";
@import "../../../../../../style/utils.module.less";

.report {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .leftSect {
    height: 510px;

    .responseBox {
      margin-bottom: 24px;
    }

    .resultInfoArea {
      max-width: 1020px;
      color: #363533;
      font-family: "LGSmartUIR";
      font-size: 27px;

      .infoDesc {
        // line-height: 38px;
        // margin-bottom: 28px;
      }

      .footWrap {
        display: flex;

        .FootInner {
          width: 490px;

          &:first-child {
            margin-right: 40px;
          }
        }

        .footTit {
          font-family: "LGSmartUISB";
          color: #212120;
          margin-bottom: 8px;
        }

        .footInfo {
          line-height: 38px;
          word-break: normal;
        }
      }

      .PrescriptionTitArea {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .PrescriptionTit {
          font-family: "LGSmartUISB";
          color: #212120;
          margin-left: 8px;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }

      .PrescriptionDesc {
        line-height: 38px;
      }
    }
  }

  .coordinatesWrap {
    position: relative;
    width: 447px;
    height: 414px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 48px 34px;
    border: 1px solid #DBD7D4;
    border-radius: 16px;

    .coordinates {
      width: inherit;
      height: auto;

      >div:first-child>div:last-child>div>div:last-child>img {
        display: none;
      }
    }

    .wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 500px;

      &::after {
        content: "";
        position: absolute;
        bottom: 50%;
        transform: translate(0, -50%);
        width: 100%;
        border: 1px solid #b2b0ae;
      }
    }
  }
}
@__DEV__: true;