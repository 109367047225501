@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

@ITEM_LENGTH: 66px;
@ITEM_HIGHT: 57px;
@ITEM_GAP_COL: 10px;
@ITEM_GAP_ROW: 0px;
@ITME_FONT_SIZE: 27px;

.calendar {
  width: ~'calc(( @{ITEM_LENGTH} + @{ITEM_GAP_ROW} ) * 7)';
  background-color: @colorffffff;

  .weekdays {
    margin-bottom: 20px;
    display: flex;
    gap: @ITEM_GAP_COL @ITEM_GAP_ROW;

    .weekdayItem {
      width: @ITEM_LENGTH;
      height: 36px;
      line-height: 36px;
      font-size: @ITME_FONT_SIZE;
      color: #787776;
      text-align: center;
      font-family: 'LGSmartUISB';
    }
    .weekdayItem:first-child { //sunday
      color: #F44B4A;
    }
  }

  .datelist {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    gap: @ITEM_GAP_COL @ITEM_GAP_ROW;

    .calendarItem {
      position: relative;
      width: @ITEM_LENGTH;
      height: @ITEM_HIGHT;
      display: flex;
      align-items: center;
      justify-content: center;
      color: @COLOR_GRAY08;
      border-radius: 8px;

      .title {
        font-size: @ITME_FONT_SIZE;
        text-align: center;
        color: #212120;
        font-family: 'LGSmartUIR';
        .timeProgressBar {
          width: 43px;
          height: 6px;
          > div {
            //total
            border-radius: 3px;
            div:nth-child(1) {
              border-radius: inherit;
              background-color: @COLOR_GRAY02 !important;
            }
            //progress
            div:nth-child(2) {
              border-radius: inherit;
              background-color: @PRIMARY_COLOR_BROWN03 !important;
            }
          }
        }

        &.dark {
          color: #c6c6c6;
          .timeProgressBar {
            opacity: 0.3;
          }
        }

        .timeCheck {
          width: 29px;
          height: 29px;
          background-position: center;
          background-repeat: no-repeat;
          background-image: url(../../../assets/HomecareProgram/ic_calander_workout_clear.svg);
        }
      }
      &.selected {
        box-sizing: border-box;
        box-shadow: 0 0 0 3px inset @PRIMARY_COLOR_BROWN03;
        margin-top: 0px;
        // .historylayer {
        //   margin-top: 1px;
        // }
      }
      &:focus {
        color: @COLOR_GRAY01;
        box-sizing: border-box;
        background-color: @PRIMARY_COLOR_BROWN03;

        .title {
          color: white;
          .timeProgressBar {
            > div {
              //total
              div:nth-child(1) {
                background-color: @COLOR_GRAY03 !important;
              }
              //progress
              div:nth-child(2) {
                background-color: @COLOR_GRAY01 !important;
              }
            }
          }
          .timeCheck {
            background-image: url(../../../assets/icon/checkbox_selected.svg);
            border-radius: 50%;
          }
        }
      }
      &.disabled {
        color: #B2B0AE;
        .title {
          color: inherit;
        }
        .timeProgressBar {
          opacity: 0.3;
        }
      }
    }
  }

}

.isDark {
  &.calendar {
    background: rgba(255, 255, 255, 0.03);
  }
  .weekdayItem {
    color: #DBD7D4;
  }
  .datelist .calendarItem {
    .title {
      color: #FEFEFE;
    }
    .title.dark {
      color: #FEFEFE;
      opacity: 0.3;
    }
    &.selected {
      box-shadow: 0 0 0 3px inset #FEFEFE;
    }
    &:focus {
      background-color: #FEFEFE;
    }
    &:focus .title {
      color: #212120;
    }
    &.disabled {
      color: #FEFEFE;
      opacity: 0.3;
    }
    &.pressed {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        border-radius: inherit;
        opacity: 0.3;
      }
    }

  }

}



@__DEV__: true;