@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.title {
  height: 42px;
  line-height: 42px;
  font-size: 36px;
  color: #FEFEFE;
  font-family: 'LGSmartUIB'; //font-weight: 700;
  margin-bottom: 28px;
}

.questionContainer {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  gap: 40px;
}
@__DEV__: true;