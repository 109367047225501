@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.detailsReportSect {
  position: relative;
  background-color: #fff;
  height: 874px;
  border-radius: 30px;

  .detailsbtnSect {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    .testBtn {
      margin-right: 20px;
    }
  }
}

.tBody {
  height: 874px;
  padding-top: 0;
  padding-bottom: 75px;
}

.medicalCenter {
  position: absolute;
  width: 181px;
  height: 38px;
  right: 92px;
  bottom: 18px;
  background-image: url("../../../../../assets/images/medicalCenterMark.svg");
}

@__DEV__: true;