@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.body {
  padding-top: 0px;
  .scroller {
    width: 1770px;
    height: 874px;
    background-color: @COLOR_GRAY01;
    border-radius: 12px;
    padding-right: 0;

    .summary {
      padding: 60px;
      padding-bottom: 30px;
      width: 100%;
      box-sizing: border-box;
      // height: 580px; // previous value
      height: auto;
      display: flex;
      .summaryImageWapper {
        min-width: 393px;
        height: 579px;
        border-radius: 15px;
        border-width: 1px;
        border-style: solid;
        border-color: @COLOR_GRAY03;
        margin-right: 38px;
        overflow: hidden;
        .innerImg {
          position: relative;
          width: 393px;
          height: 473px;
          border-color: @COLOR_GRAY03;
          border-bottom-style: solid;
          border-width: 1px;
          border-top-left-radius: 15px;
          border-top-right-radius: 15px;
          .canvas,
          > div {
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          > div > img {
            width: 100%;
            height: auto;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .content {
          width: 100%;
          height: 105px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .userTitle {
            .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @COLOR_GRAY07, @fontWeight: 600);
            text-align: center;
            height: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          .muscleTitle {
            .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @COLOR_GRAY07, @fontWeight: 600);
            text-align: center;
            line-height: 1.2;
            margin-bottom: 4px;
          }
          .muscleSubText {
            .font(@fontFamily: "LGSmartUIR", @fontSize: 20px, @color: @COLOR_GRAY06, @fontWeight: 400);
            text-align: center;
            .withCircle::before {
              content: "";
              display: inline-block;
              vertical-align: middle;
              width: 9px;
              height: 9px;
              background-color: #f44b4a;
              border-radius: 50%;
              margin-right: 6px;
            }
            .blueCicle:before {
              background-color: #3d90f5;
            }
            .withCircle {
              margin-left: 10px;
              margin-right: 10px;
            }
          }
        }
      }
      .summaryTextLayer {
        margin-left: 32px;
        width: 800px;
        //   height: 580px; // previous value
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .impPoint {
          &.diseasTitle {
            margin-bottom: 16px;
          }
        }
        .dotTextLayer {
          margin-top: 16px;
        }
        .dotTextArea {
          display: flex;
          margin-bottom: 6px;
          &:last-child {
            margin-bottom: 0;
          }
          .dotPoint {
            margin-top: 14px;
            margin-right: 9px;
          }
        }
        .dotText {
          .font(@fontFamily: "LGSmartUIR", @fontSize: 28px, @color: @COLOR_GRAY07, @fontWeight: 400);
        }
        .impPoint {
          .font(@fontFamily: "LGSmartUIB", @fontSize: 33px, @color: @COLOR_GRAY08, @fontWeight: 700);
          height: 37px;
        }
        .diseaseWrapper {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          width: 100%;
          height: auto;
          .disease {
            .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY06, @fontWeight: 600);
            height: 32px;
            background-color: @COLOR_GRAY02;
            padding: 12px 24px;
            border-radius: 10px;
            border: 1px solid @COLOR_GRAY03;
            color: @COLOR_GRAY06;
            white-space: nowrap;
            display: inline-block;
          }
        }
      }
    }
    .musclePicBox {
      padding: 30px 60px;
    }

    .buttonLayer {
      position: absolute;
      bottom: 16px;
      width: 100%;
      display: flex;
      justify-content: center;
      > div {
        width: 160px;
        height: 60px;
        margin-left: 7px;
        margin-right: 7px;
        padding: 13px 24px;
        background-color: @BG_COLOR_04;
        color: @COLOR_GRAY06;
        > div {
          height: 100%;
          line-height: 1;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.selected {
          background-color: @COLOR_GRAY01;
          color: @COLOR_GRAY08;
          border: 2apx solid @PRIMARY_COLOR_BROWN03;
          box-shadow: none;
        }
        &:focus {
          background-color: @PRIMARY_COLOR_BROWN03;
          color: @COLOR_GRAY01;
        }
      }
    }
    .bottomButtonLayer {
      display: flex;
      justify-content: center;
      // margin-top: 46px;
      // padding-bottom: 60px; // origin GUI
      margin-top: 10px; // temp
      padding-bottom: 49px; // temp
      .button {
        margin-left: 10px;
        margin-right: 10px;
        height: 71px;
        min-width: 304px;
        font-size: 33px;
      }
    }
  }
}
.dotPoint {
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  min-width: 6px;
  height: 6px;
  background-color: black;
  border-radius: 50%;
  margin-top: 14px;
  margin-right: 9px;
}

.wellnessLayer {
  display: flex;
  justify-content: space-between;

  .moreInfo {
    display: flex;
    flex-direction: column;
    border: 1px solid @COLOR_GRAY03;
    border-radius: 16px;
    padding-bottom: 32px;
    overflow: hidden;
    box-sizing: border-box;

    width: 525px;
    // height: 783px;
    height: auto;
    .innerImg {
      width: 100%;
      height: auto;
      position: relative;
      > img {
        width: 100%;
        height: 100%;
      }
      .buttonLayer {
        bottom: 0;
      }
    }
    .moreText {
      width: 100%;
      height: auto;
      padding: 32px;
      padding-bottom: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        .font(@fontFamily: "LGSmartUISB", @fontSize: 33px, @color: @COLOR_GRAY08, @fontWeight: 600);
        height: 39px;
      }
      .detail {
        margin-top: 12px;
        .font(@fontFamily: "LGSmartUIR", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
        line-height: 38px;
      }
    }
  }
}

.exerciseLayer {
  // padding: 0 60px;
  padding-top: 30px;

  .pictogramLayer {
    margin-top: 24px;
    margin-bottom: 16px;
    padding: 0 60px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .pictogram {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY06, @fontWeight: 600);
      padding: 12px 24px;
      border-radius: 10px;
      border: 1px solid @COLOR_GRAY03;
      display: flex;
      align-items: center;
      height: 32px;

      .picIcon {
        height: 32px;
        margin-right: 6px;
      }
    }
  }

  .exerciseFeedbackLayer {
    .font(@fontFamily: "LGSmartUIR", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
    list-style: 38px;

    .exerciseFeedbackBox {
      padding: 0 60px 16px 60px;
    }

    .exerciseBox {
      min-height: 38px;
      margin-bottom: 6px;
      display: flex;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
      .dotPoint {
        margin-top: 13px;
        margin-right: 8px;
      }
    }
  }
}
.mainTitle {
  display: flex;
  align-items: center;
  height: 46px;
  .icon {
    background-image: url("../../../../../assets/icon/alert_circle_subheading.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 26px;
    height: 26px;
    margin-right: 12px;
  }
  .font(@fontFamily: "LGSmartUIB", @fontSize: 36px, @color: @COLOR_GRAY07, @fontWeight: 700);
  line-height: 46px;
  word-wrap: break-word;
  margin-bottom: 24px;
}

.mediaList {
  width: auto;
  height: 360px;
  > div {
    width: 1770px;
    height: 360px;
    position: absolute;
    left: 0;
    & > div {
      height: 360px;
    }
    & > div > div {
      padding-top: 28px;
    }
  }
}

.notification {
  position: absolute;
  left: 75px;
  bottom: 26px;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 400);
  line-height: 25px;
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
  }
}

.medicalCenter {
  position: absolute;
  width: 181px;
  height: 38px;
  right: 92px;
  bottom: 18px;
  background-image: url("../../../../../assets/images/medicalCenterMark.svg");
}

.switchBtn {
  font-size: 27px !important;
}

@__DEV__: true;