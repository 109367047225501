@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";

.wrapper {
  border: 1px solid @COLOR_GRAY03;
  width: 656px;
  height: 518px;
  border-radius: 12px;
  padding: 31px 60px 32px;
  box-sizing: border-box;

  .nudeTabArea {
    margin-bottom: 20px;
  }
  .tabArea {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;
  }

  .graphWrapper {
    display: flex;
    // width: 526px; // previous value
    width: 100%;
    height: 310px;
    // margin-left: 30px; // previous value
    justify-content: flex-end;
    align-items: flex-end;

    .graph {
      position: relative;
      // width: 460px; // previous value
      width: 100%;
      height: 310px;

      .graphLine {
        position: absolute;
        width: 100%;
        height: 0;
        border-bottom: 1px dotted @COLOR_GRAY04;
        > div {
          position: relative;
          .yLabel {
            position: absolute;
            width: 72px;
            height: 19px;
            text-align: right;
            left: -82px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 20px;
            font-family: "LGSmartUISB";
            font-weight: 600;
            word-wrap: break-word;
            color: @COLOR_GRAY05;
            line-height: 1.2;
          }
        }
      }
    }
  }
}
@GRAPH_PADDING: 0 34px;
.container {
  width: 100%;
  height: 100%;

  .graphArea {
    position: relative;
    width: 100%;
    height: 271px;
    display: flex;
    align-items: flex-end;

    .graphLine {
      position: absolute;
      width: 100%;
      height: 0;
      border-bottom: 1px dotted @COLOR_GRAY03;
    }
    .scoreContainer {
      width: 100%;
      padding: @GRAPH_PADDING;
      max-height: 220px;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 32px;
      .scoreBox {
        padding: 0 33px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .score {
          position: relative;
          width: 16px;
          height: 0%;
          background-color: @PRIMARY_COLOR_BROWN03;
          border-radius: 8px 8px 0 0;
          transition: height 1s ease-in-out;

          .scoreTextBox {
            position: absolute;
            min-width: 44px;
            height: 26px;
            padding: 4px 12px;
            border-radius: 8px;
            background-color: @PRIMARY_COLOR_BROWN01;
            top: -51px;
            left: 50%;
            transform: translateX(-50%);

            color: #ffffff;
            font-size: 22px;
            font-family: "LGSmartUIR";
            font-weight: 600;
            word-wrap: break-word;
            text-align: center;
            line-height: 1.1;

            > img {
              position: absolute;
              top: 34px;
              left: 50%;
              transform: translateX(-50%);
              width: 11px;
              height: 5px;
            }
          }
        }
      }
    }
  }
  .labelArea {
    margin-top: 16px;
    padding: @GRAPH_PADDING;
    display: flex;
    justify-content: space-between;
    .label {
      width: 82px;
      height: 25px;
      .font(@fontFamily: "LGSmartUISB", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 600 );
      word-wrap: break-word;
      text-align: center;
      line-height: 1;
    }
  }
}

.graphContainer {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  bottom: 0;

  .lineGraphBox {
    position: relative;

    .ordinator {
      position: absolute;
      top: 0;
      left: 0;
      > div {
        position: relative;

        .timeTextBox {
          position: absolute;
          min-width: 44px;
          height: 26px;
          padding: 4px 12px;
          border-radius: 8px;
          background-color: @PRIMARY_COLOR_BROWN01;
          top: -59px;
          left: 50%;
          transform: translateX(-50%);

          color: #ffffff;
          font-size: 22px;
          font-family: "LGSmartUIR";
          font-weight: 600;
          word-wrap: break-word;
          text-align: center;
          line-height: 1.1;
          white-space: nowrap;

          > img {
            position: absolute;
            top: 34px;
            left: 50%;
            transform: translateX(-50%);
            width: 11px;
            height: 5px;
          }
        }
      }
    }
  }
}

@__DEV__: true;