@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.mediaItem {
  &.pressed .imageArea::before {
    content: "";
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .size(@w: 100%, @h: 100%);
    background-color: #000;
    border-radius: inherit;
    opacity: 0.3;
  }
  &:focus {
    .imageArea {
      transform: scale(1.08);
    }
  }
}

.mediaItem.normal, 
.mediaItem.withCheck, 
.mediaItem.withCheckIndex {
  .size(@w: 427px, @h: auto);
  background-color: transparent;

  .imageArea {
    .size(@w: 427px, @h: 240px);
    position: relative;
    transition: transform 0.3s;
    overflow: hidden;
    border-radius: 12px;
    margin-bottom: 12px;

    .image {
      .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
      .size(@w: 100%, @h: 100%);
      z-index: -1;
    }
    .playIcon {
      .position(@position: absolute, @top: 50%, @right: unset, @bottom: unset, @left: 50%);
      .size(@w: 80px, @h: 80px);
      transform: translate(-50%, -50%);
      background-image: url("../../../assets/ic_play_thumb.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .playTime {
      .position(@position: absolute, @top: unset, @right: 20px, @bottom: 20px, @left: unset);
      background-color: @COLOR_GRAY09;
      opacity: 0.7;
      border-radius: 12px;
      padding: 0 14px;
      height: 34px;
      line-height: 34px;
      font-size: 24px;
      color: @COLOR_GRAY01;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
    .newIcon {
      .position(@position: absolute, @top: 20px, @right: unset, @bottom: unset, @left: 20px);
      .size(@w: 87px, @h: 40px);
      background-image: url("../../../assets/media/ic_badge_new.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .checkIcon {
      .position(@position: absolute, @top: 20px, @right: unset, @bottom: unset, @left: 20px);
      .size(@w: 36px, @h: 36px);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .checkIndex {
      .position(@position: absolute, @top: 20px, @right: unset, @bottom: unset, @left: 20px);
      .size(@w: 36px, @h: 36px);
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      border: 2px solid rgba(255, 255, 255, 0.4);
      &.selected {
        background-color: @colorffffff;
        border: 2px solid @colorffffff;
        color: var(--gray-gray-08212120, #212120);
        text-align: center;
        font-family: 'LGSmartUIB';
        font-size: 24px;
        line-height: 36px;
      }
    }
    .likeIcon {
      .position(@position: absolute, @top: 20px, @right: 20px, @bottom: unset, @left: unset);
      .size(@w: 36px, @h: 36px);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
    .subTypeIcon {
      .position(@position: absolute, @top: 20px, @right: 20px, @bottom: unset, @left: unset);
      padding: 4px 13px;
      color: @COLOR_GRAY01;
      font-family: 'LGSmartUISB';
      font-size: 24px;
      line-height: 28px;
      border-radius: 8px;
      background-color: @PRIMARY_COLOR_BROWN03;
    }
    .totalGauge {
      .position(@position: absolute, @top: unset, @right: unset, @bottom: 0, @left: 0);
      .size(@w: 100%, @h: 8px);
      background-color: #fefefe80;
    }
    .playGauge {
      .position(@position: absolute, @top: unset, @right: unset, @bottom: 0, @left: 0);
      .size(@w: 100%, @h: 8px);
      background-color: @PRIMARY_COLOR_GREEN02;
    }
  }

  .title {
    .size(@w: 100%, @h: 35px);
    line-height: 35px;
    font-size: 30px;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    color: @COLOR_GRAY07;
    margin-bottom: 8px;
  }

  .subTextArea {
    width: 100%;
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    .text {
      height: 32px;
      line-height: 32px;
      font-size: 27px;
      font-family: 'LGSmartUIR';
      color: @COLOR_GRAY05;
    }

    .text:first-child::after { // border
      content: "";
      .size(@w: 1px, @h: 17px);
      margin: 0 12px;
      display: inline-block;
      background-color: @COLOR_GRAY04;
    }
  }

  .secondSubTextArea {
    width: 100%;
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    .text {
      height: 32px;
      line-height: 32px;
      font-size: 27px;
      font-family: 'LGSmartUIR';
      color: @COLOR_GRAY05;
      > span {
        font-family: 'LGSmartUIB';
      }
    }
  }

  &:focus {
    .playIcon {
      background-image: url("../../../assets/ic_play_foc.png");
    }
  }

  &.small {
    .size(@w: 296px, @h: auto);
    .imageArea {
      .size(@w: 296px, @h: 166px);
    }
  }
}

.mediaItem.mobile {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(2, 40px); 
  gap: 4px 10px; 
  .imageArea {
    .size(@w: 144px, @h: 80px);
    position: relative;
    transition: transform 0.3s;
    overflow: hidden;
    border-radius: 5px;
    grid-row: 1 / 3;
    .image {
      .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
      .size(@w: 100%, @h: 100%);
    }
  }
  .title {
    .size(@w: 130px, @h: 17px);
    line-height: 17px;
    font-size: 15px;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    color: #363533;
    align-self: end;
  }
  .subTextArea {
    .size(@w: 130px, @h: 17px);
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    align-self: start;
    .text {
      .flex(@display: inline-flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
      height: 14px;
      line-height: 14px;
      font-size: 12px;
      font-family: 'LGSmartUIR'; //font-weight: 400;
      color: #363533;
    }
    .text:first-child::after { // border
      content: "";
      .size(@w: 1px, @h: 10px);
      margin: 0 5px;
      display: inline-block;
      background-color: #363533;
    }
  }
}



.countContainer {
  display: flex;
  justify-content: center;
  column-gap: 46px;
  margin-top: 24px;
  .count {
    color: @COLOR_GRAY07;
    text-align: center;
    font-family: "LGSmartUISB";
    font-size: 30px;
    height: 35px;
  }
}





@__DEV__: true;