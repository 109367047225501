@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";
.buttonLayer {
  position: absolute;
  width: auto;
  display:flex;
  bottom: 46px;
  left: 50%;
  transform: translateX(-50%);
  .button{
    margin-left: 10px;
    margin-right: 10px;
    height: 71px;
    min-width: 304px;
    font-size: 33px;
  }
}


@__DEV__: true;