@import "../../../style/CommonStyle.module.less";
@import "../../../style/utils.module.less";

.recommendedStrideContainer {
  padding: 0 32px;
  display: flex;

  .infoBox {
    background-color: rgba(255, 255, 255, 0.03);
    border: 2px rgba(255, 255, 255, 0.10) solid;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:not(:last-child) {
      margin-right: 16px;
    }

    .infoTitle {
      color: @COLOR_GRAY03;
      font-size: 27px;
      font-family: 'LGSmartUIR';
    }

    &.height {
      min-width: 580px;
      width: 580px;
      box-sizing: border-box;
    }

    .button {
      min-width: 124px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}
@__DEV__: true;