@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

.container {
  .topTap {
    margin: 40px 40px 36px 40px;
    > div {
      margin-right: 12px;
    }
  }
  .flexBox {
    display: flex;
    height: 420px;
    padding: 0 40px;
    .imageContainer {
      position: relative;
      overflow: hidden;
      min-width: 595px;
      height: 418px;
      border-radius: 16px;
      border: 1px solid @COLOR_GRAY03;

      display: flex;
      flex-direction: column;

      .positionTab {
        position: absolute;
        width: 100%;
        height: 60px;
        display: flex;
        gap: 1px;
        justify-content: space-between;
        align-items: center;
        overflow: hidden;
        z-index: 1;
        border-bottom: 1px solid @COLOR_GRAY03;

        & > div {
          max-width: 297px;
          height: 60px;
          box-sizing: border-box;
          padding: 10px;
          border: none;
          div > div > div {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        > div:first-child {
          // margin-right: -1px;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: -1px;
            margin: 16px 0;
            width: 1px;
            height: 29px;
            background: @COLOR_GRAY03;
            z-index: 100;
          }
        }
      }
      .testImg {
        position: absolute;
        top: 60px;
        left: 0;
        width: 595px;
        height: 358px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-size: cover;

        &.backgroundS {
          height: 358px;
          background-image: url("../../../../../../assets/rom/img_bg_s.png");
        }
        &.backgroundL {
          height: 420px;
          top: 0;
          background-image: url("../../../../../../assets/rom/img_bg_l.png");
        }
        .isError {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-color: #2121201a;
          z-index: 1;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          .font(@fontFamily: "LGSmartUISB", @fontSize: 50px, @color: #ffffffcc, @fontWeight: 600);
        }

        .resultImg {
          position: absolute;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position-y: bottom;
        }
      }

      .labelContainer {
        position: absolute;
        bottom: 20px;
        left: 24px;
        width: 545px;
        height: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .positionLabel {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: @COLOR_GRAY03;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          font-family: "LGSmartUISB";
          color: @COLOR_GRAY01;
          line-height: 1;
        }
      }
    }
    .contents {
      margin-left: 80px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .textBox {
        min-height: 192px;
        .title {
          font-family: "LGSmartUISB";
          color: @COLOR_GRAY08;
          font-size: 36px;
          height: 46px;
          line-height: 46px;
          word-wrap: break-word;
        }
        .detail {
          margin-top: 16px;
          font-family: "LGSmartUIR";
          font-size: 27px;
          line-height: 38px;
          color: @COLOR_GRAY07;
          word-wrap: break-word;
        }
      }
      .ratingBox {
        margin-bottom: 96px;
        .rating {
          width: 576px;
          margin-bottom: 65px;
          display: flex;
          justify-content: space-between;
          .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY09, @fontWeight: 600);
          &:last-child {
            .font(@fontFamily: "LGSmartUIB", @fontSize: 27px, @color: @COLOR_GRAY09, @fontWeight: 700);
          }
          > div > span {
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            background-image: url("../../../../../../assets/rom/ic_bodycheck_rom_measure.png");
            background-repeat: no-repeat;
            background-size: contain;
            margin-right: 8px;
          }
        }
      }
    }
    .tBar {
      width: 576px; // 192 * 3
    }
  }
}

.testBtnWrap {
  display: flex;
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translate(-50%, 0);
  > div {
    margin: 0 10px;
  }
}

.valueLabel {
  position: absolute;
  left: 24px;
  bottom: 156px;
  height: 47px;
  z-index: 1;
  color: @COLOR_GRAY08;
  font-size: 40px;
  font-family: "LGSmartUIB";

  &.valueLabelS {
    bottom: 156px;
  }
  &.valueLabelL {
    bottom: 185px;
  }
  &.valueLabelRight {
    left: auto;
    right: 24px;
  }
}

@__DEV__: true;