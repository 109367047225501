.panel {
  font-family: "LGSmartUIR";
  width: 100%;
  height: 100%;
  color: black;
}

.titleSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  .title{
    >span {
      font-weight: bold;
    }
  }
}

.table {
  width: 100%;
  border-collapse: collapse;

  .th {
    background-color: #ddd;
    padding: 16px;
    text-align: center;

  };

  .td {
    height: 150px;
    text-align: center;
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    .product {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 35px;
      line-height: 1.5;
      .title {
        font-weight: bold;
      }
    }
  };

}
@__DEV__: true;