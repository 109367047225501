@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tHeader {
  width: 100%;
  height: @fitTv-tHeader-height;
  color: @COLOR_GRAY08;
  font-family: "LGSmartUIB";
  position: relative;
  z-index: 2;

  .backBtn {
    display: flex;
    align-items: center;
    position: absolute;
    top: 45px;
    left: 75px;
    height: 54px;
    .icon {
      margin-right: 15px;
    }
    .title {
      font-size: 45px;
    }
  }

  .device {
    display: flex;
    align-items: center;
    gap: 22px;
    position: absolute;
    top: 32px;
    right: 53px;
  }
}

.matBtn {
  position: relative;
  width: unset !important;
  height: unset !important;
  background-size: unset !important;
  border-radius: 40px !important;
  padding: 13px 19px 13px 22px;

  .icBattery {
    width: 24px;
    height: 24px;
    background-image: url("../../../assets/Theader/ic_battery.png");
  }
  .percentage {
    color: #787776;
    font-family: "LGSmartUIB";
    font-size: 24px;
    height: 28px;
    line-height: 28px;
    width: 49px;
    margin-right: 6px;
  }
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 54px;
    height: 54px;
    background-image: url("../../../assets/Theader/ic_mat.svg");
  }

  &:focus {
    .icBattery {
      background-image: url("../../../assets/Theader/ic_battery_w.png");
    }
    .percentage {
      color: #F2EFED;
    }
    .icon {
      background-image: url("../../../assets/Theader/ic_mat_w.svg");
    }
  }
}

.iconBtn {
  position: relative;
}
.iconBox {
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    width: 54px;
    height: 54px;
  }
}

.loginBtn {
  position: relative;
  margin: 5px 12px 6px 12px;
  &:focus {
    transform: none !important;
  }
  .loginTooltip {
    top: 80px !important;
  }
}

.fontSize{
  font-size: 24px !important;
}

.userBtn {
  position: relative;
  width: 42px !important;
  height: 42px !important;
  background-size: unset !important;
  border-radius: 21px !important;
  box-shadow: 0 0 0 1px @COLOR_GRAY03 inset;
  margin: 19px;
  &:focus {
    background-color: transparent !important;
    box-shadow: 0 0 0 2px #4F423C inset;
  }
  .userToolTip {
    top: 72px !important;
  }
}



.tHeader.isDark {
  color: @COLOR_GRAY01;
  .matBtn {
    .icBattery {
      background-image: url("../../../assets/Theader/ic_battery_dark.svg");
    }
    .percentage {
      color: @COLOR_GRAY03;
    }
    .icon {
      background-image: url("../../../assets/Theader/ic_mat_dark.svg");
    }
    &:focus  {
      .icBattery {
        background-image: url("../../../assets/Theader/ic_battery_dark_focused.svg");
      }
      .percentage {
        color: @COLOR_GRAY06;
      }
      .icon {
        background-image: url("../../../assets/Theader/ic_mat_dark_focused.svg");
      }
    }
  }
  .userBtn {
    &:focus {
      box-shadow: 0 0 0 2px #cfbf8c inset;
    }
  }
}

@__DEV__: true;