.listHeader{
    padding-bottom: 0px;
}

.setting:not(:last-child) {
    border-bottom: 1px solid #494847;
}

.subText {
    display: flex;
    align-items: center;
    > span:nth-child(1) {
      color: #CFBF8C;
    }
}
@__DEV__: true;