@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tagImg{
  font-size: 27px;
  font-family:'LGSmartUIB';
  color: #000;
  >span{
    margin-left: 30px;
    background-color: @COLOR_GRAY01;
    display: inline-block;
    white-space: nowrap;
    border-radius: 0 9px 9px 0;
    padding-right: 13px;

    &.matched {
      box-shadow: inset -2px 0px 0 0 #21FFCD, // right
                  inset 0 2px 0 0 #21FFCD, // top
                  inset 0 -2px 0 0 #21FFCD; // bottom
    }
  }

  &.small {
    font-size: 21px;

    > span {
      margin-left: 21px;
      border-radius: 0 8px 8px 0;
      padding-right: 12px;
    }
  }
}
@__DEV__: true;