@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.body {
  position: relative;
  padding: 0;
  height: ~"calc(100% - @{fitTv-tHeader-height})";
  // .flex(@display: flex, @justifyCenter: center, @alignCenter: unset, @direction: row);
}

.scroller {
  .size(@w: 100%, @h: 100%);
  padding-left: 360px;
  box-sizing: border-box;
}

.innerContainer {
  .size(@w: 360px, @h: auto);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  background-color: #F0F0F0;
  padding: 0 0 30px 0;
}

.jimmyImage {
  .size(@w: 360px, @h: 221px);
  background-image: url("../../../assets/preview/img_gate_m.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.gateContainer {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  padding: 38px 0 44px 0;
}
.infoContainer {
  // .size(@w: 328px, @h: 129px);
  position: relative;
  box-sizing: border-box;
  background-color: #F3F1EE;
  border-radius: 10px;
  box-shadow: inset 0 0 0 1px #EBEBEB;
  margin-bottom: 16px;
  width: 328px;

  padding: 24px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 16px;

  .infoBox {
    // position: absolute;
    // .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    // gap: 11px;
    display: flex;
    justify-content: space-between;
    width: 140px;
    .qBox {
      .size(@w: 46px, @h: 16px);
      font-size: 14px;
      line-height: 16px;
      color: #787776;
      font-family: 'LGSmartUISB'; //font-weight: 600;
    }
    .aBox {
      .size(@w: 71px, @h: 16px);
      font-size: 14px;
      line-height: 16px;
      color: #000;
      font-family: 'LGSmartUISB'; //font-weight: 600;
      .elip(1);
    }

    // &:nth-child(1) {
    //   .position(@position: absolute, @top: 24px, @right: unset, @bottom: unset, @left: 20px);
    // }
    // &:nth-child(2) {
    //   .position(@position: absolute, @top: 24px, @right: unset, @bottom: unset, @left: 183px);
    // }
    // &:nth-child(3) {
    //   .position(@position: absolute, @top: 56px, @right: unset, @bottom: unset, @left: 20px);
    // }
    // &:nth-child(4) {
    //   .position(@position: absolute, @top: 56px, @right: unset, @bottom: unset, @left: 183px);
    // }
    // &:nth-child(5) {
    //   .position(@position: absolute, @top: 89px, @right: unset, @bottom: unset, @left: 20px);
    // }
    // &:nth-child(6) {
    //   .position(@position: absolute, @top: 89px, @right: unset, @bottom: unset, @left: 183px);
    // }
  }
}

@ITEM_WIDTH: 160px;
@ITEM_HEIGHT: 160px;
@SPACING: 8px;

.buttonWrapper {
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
  flex-wrap: wrap;
  gap: @SPACING;
  width: ~"calc(@{ITEM_WIDTH} * 2 + @{SPACING})";
}

.captureButtonWrapper {
  .position(@position: absolute, @top: 50%, @right: 300px, @bottom: unset, @left: unset);
  transform: translateY(-50%);
}
.qRCodeWrapper {
  .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: column);
  .position(@position: absolute, @top: 50%, @right: unset, @bottom: unset, @left: 45%);
  transform: translateY(-50%);
  z-index: -1;
  .qrcode {
    margin-bottom: 40px;
  }
  .guideText {
    // .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
    .size(@w: auto, @h: auto);
    font-size: 24px;
    line-height: 28px;
    color: #DBD7D4;
    font-family: 'LGSmartUISB'; //font-weight: 600;
    text-align: center;
    // &::before {
    //   content: "";
    //   display: block;
    //   margin-right: 9px;
    //   width: 20px;
    //   height: 20px;
    //   background-image: url("../../../assets/preview/icon/ic_exclamation.png");
    //   background-size: cover;
    //   background-repeat: no-repeat;
    //   background-position: center;
    // }
  }
}

.footer {
  margin-top: 20px;
  .font(@fontFamily: "LGSmartUIR" , @fontSize: 14px, @color: @COLOR_GRAY05, @fontWeight: 400);
  line-height: 1.2;
  padding: 0 20px;
  text-align: center;
}

@__DEV__: true;