@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

@ITEM_WIDTH: 382px;
@ITEM_HEIGHT: 653px;
@ITEM_GAP: 24px;
@PADDING: 52px;
.panel{
  font-family: "LGSmartUIR";
  width: 100%;
  color: @COLOR_GRAY08;
  .tBody{
    padding: 0px;
    .title{
      margin-left: 79px;
      font-size: 33px;
      margin-top: 20px;
    }

    .imageContainer {
      position: absolute;
      transition: transform 0.5s ease-in-out;
      width: 356px;

      bottom: 98px;
      z-index: 1;
    }
    .mainBtnInnerContainer{
      position: absolute;
      transition: left 0.5s ease-in-out;
      // width: ~"calc(100% - 230px)";
      width: 100%;
      height: ~"calc(@{ITEM_HEIGHT} + @{PADDING} * 2)";
      bottom: 98px;
      // left: 230px;
      z-index: 0;
      .sectionContainer{
        width: ~"calc(@{ITEM_WIDTH} * 4 + @{ITEM_GAP} * 3)";
        height: @ITEM_HEIGHT;
        white-space: nowrap;
        padding: @PADDING;
      }
    }
  }
}

.popupPanel {
  width: 100%;
  height: 100%;
  background: @COLOR_GRAY09;
  opacity: 0.95;
  z-index: 300;
  font-family: 'LGSmartUIR';

  > div {
    width: 100%;
    height: 100%;
    padding: 0 !important;

    > div {
      width: 100%;
      height: 100%;

      > div {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
      }
    }
  }

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    line-height: normal;
    padding: 0;
  }
}
@__DEV__: true;