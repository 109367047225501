@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.donutChartItem {
  .size(@w: 395px, @h: 411px);
  background-color: rgba(255, 255, 255, 0.03);
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.10);
  border-radius: 19px;
  padding: 30px;
  box-sizing: border-box;

  .title {
    height: 32px;
    line-height: 32px;
    font-size: 27px;
    color: #F2EFED;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    margin-bottom: 24px;
  }

  .donutContainer {
    .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
    position: relative;
    margin-bottom: 10px;
    .graphText {
      .position(@position: absolute, @top: 50%, @right: unset, @bottom: unset, @left: 50%);
      transform: translate(-50%, -50%);
      .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: column);
      gap: 2px;
      .top {
        .size(@w: auto, @h: 25px);
        line-height: 25px;
        font-size: 21px;
        color: #F2EFED;
        font-family: 'LGSmartUISB'; //font-weight: 600;
      }
      .rank {
        .size(@w: auto, @h: 39px);
        line-height: 39px;
        font-size: 33px;
        color: #B59D85;
        font-family: 'LGSmartUIB'; //font-weight: 700;
      }
    }
  }

  .dataContainer {
    .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
    gap: 12px;
    flex-wrap: wrap;
    .dataWrapper {
      .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
      gap: 5px;
      .dot {
        .size(@w: 12px, @h: 12px);
        border-radius: 50%;
      }
      .label {
        height: 25px;
        line-height: 25px;
        font-size: 21px;
        color: #F2EFED;
        font-family: 'LGSmartUIR'; //font-weight: 400;
      }
    
      .data {
        height: 25px;
        line-height: 25px;
        font-size: 21px;
        color: #FEFEFE;
        font-family: 'LGSmartUIB'; //font-weight: 700;
      }
    }
  }
  .text {
    .size(@w: auto, @h: auto);
    line-height: 35px;
    font-size: 24px;
    color: #DBD7D4;
    font-family: 'LGSmartUIR'; //font-weight: 400;
    text-align: center;
    >span {
      color: #FEFEFE;
      font-family: 'LGSmartUIB'; //font-weight: 700;
    }
  }
}


@__DEV__: true;