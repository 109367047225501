@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.bodyBalanceReport {
  .size(@w: 100%, @h: auto);
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: column);
  .coordinatesWrapper {
    .size(@w: 328px, @h: 380px);
    margin-bottom: 36px;

    .divider {
      position: absolute;
      width: calc(100% - 40px);
      margin: 0 auto;
      bottom: 82px;
      border-bottom: 1px solid @COLOR_GRAY02;
    }
  }
  .footShapeWrapper {
    .flex(@display: flex, @justifyCenter: flex-end, @alignCenter: center, @direction: column);
    .size(@w: 328px, @h: 250px);
    .tCanvasMatRaw {
      .position(@position: absolute, @top: 20px, @right: unset, @bottom: 136px, @left: unset);
      transform: scale(0.28);
      transform-origin: top;
    }
    .footTypesWrap {
      width: 100%;
      .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center);
      > div:first-child {
        margin-right: 11px !important;
      }
    }
    .footInfo {
      font-family: "LGSmartUIR";
      font-size: 14px;
      color: @COLOR_GRAY07;
      line-height: 1.3;

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }
}

@__DEV__: true;