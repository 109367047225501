@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";
html {
  background-color: @BG_COLOR_02;
  background-image: url("../../../webos-meta/splash.png");
  width: 100%;
  height: 100%;
}

.mainlayout{
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  background-color: @MAIN_COLOR;
  opacity: 1;

  &.transparent {
    opacity: 0;
  }
}
@__DEV__: true;