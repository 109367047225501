@import "../../../../style/CommonStyle.module.less";
// common less file
@import "../../../../style/utils.module.less";


.barItem {
  display: flex;
  align-items: center;
}

.bars {
  .size(@w: 1140px, @h: 22px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  .barContainer {
    height: 100%;

    .one {
      .size(@w: 377px, @h: inherit);
      border-radius: 37px 0px 0px 37px;
      background-color: @COLOR_GRAY02;

      &.active {
        background-color: #44A280;
      }
    }

    .two {
      .size(@w: 377px, @h: inherit);
      background-color: @COLOR_GRAY02;

      &.active {
        background-color: #44A280;
      }
    }

    .three {
      .size(@w: 377px, @h: inherit);
      border-radius: 0px 37px 37px 0px;
      background-color: @COLOR_GRAY02;

      &.active {
        background-color: #44A280;
      }
    }
  }
}
@__DEV__: true;