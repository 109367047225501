@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.emptySect{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  .emptyMainImg {
    width: 533px;
    height: 405px;
    margin-right: 60px;
  }
  .emptyTestImg {
    width: 390px;
    height: 405px;
    margin-right: 60px;
  }
  .totalTextBox{
    width: 894px;
    font-family: 'LGSmartUIR';
    color: @COLOR_GRAY07;
  }
  .partTextBox{
    width: 894px;
    font-family: 'LGSmartUIR';
    color: @COLOR_GRAY07;
  }
}

.title{
  font-size: 36px;
  font-weight: bold;
  margin : 0 0 21px 0;
  line-height: 45px;
  color: @COLOR_GRAY07;
}
.subheading {
  font-size: 26px;
  font-weight: bold;
  margin: 44px 0 12px 0;
  >span {
    background-image: url("../../../assets/icon/alert_circle_subheading.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 26px;
    height: 26px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
}
.detail{
  font-size: 28px;
  line-height: 38px;
}
.testStart{
  font-size: 28px;
  color: @PRIMARY_COLOR_BROWN02;
  margin: 44px 0 20px 0;
  >span{
    background-image: url("../../../assets/icon/bodycheck_test.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 21px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 12px;
  }
}
@__DEV__: true;