@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.reset_body {
  height: 100%;
  padding: 0;
}

.body {
  .reset_body();
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  .size(@w: 150px, @h: 150px);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../../assets/errorPanel/icon.png");
  margin-bottom: 43px;
}

.title {
  height: 42px;
  line-height: 42px;
  font-size: 36px;
  color: #fefefe;
  font-family: "LGSmartUIB"; //font-weight: 700;
  margin-bottom: 38px;
}

.inputField {
  height: 32px;
  line-height: 32px;
  font-size: 20px;
  color: #fefefe;
  font-family: "LGSmartUIR"; //font-weight: 400;
  margin-bottom: 60px;
}

.btnLayer {
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: row);
  gap: 24px;
}

@__DEV__: true;