@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";

//physical Test tab 레이아웃 별도
.cl_contentsLayoutType01 {
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-top: 34px;
}

.cl_btnArea {
  margin-bottom: 46px;
}

//tab레이아웃 추가===============================================

//tab 버튼 상단부 layOut wrapper
.tabLayOut {
  width: 100%;
  height: 86px;
  .flex(@display: flex, @justifyCenter: flex-Start, @alignCenter: flex-end, @direction: row);
  padding-left: 0;
  margin-bottom: 0;

  .tab {
    > div > div > div {
      font-size: 23px;
    }
  }
}

// tabBtn
.tabBtn {
  position: relative;
  .size(@w: 100%, @h: 2.9166rem);
  line-height: 30px;
  background-color: @fitTv-tab-color;
  border-radius: 0.833rem 0.833rem 0 0;
  margin-right: 0.3333rem;
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: null);
  .font(@fontFamily:"LGSmartUIR", @fontSize: 1rem, @color: @color706F69, @fontWeight:400);

  //tabBtn 첫번째 단독 색상 지정
  &:first-child {
    background-color: rgba(112, 111, 105, 0.4);
    > span {
      color: #fefdfa;
    }
  }

  //두줄처리된 텍스트
  & .whiteSpaceText {
    display: block;
    white-space: pre-line;
    text-align: center;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  //한줄 텍스트
  & .normalText {
    width: 100%;
    display: block;
    text-align: center;
  }

  &:last-child {
    margin-right: 0;
  }

  &.on {
    background-color: #fefdfa;

    //tabBtn 첫번째 단독 색상 지정
    &:first-child {
      > span {
        color: #222;
      }
    }

    span {
      //width: 6.3333rem;
      position: absolute;
      font-size: 1rem;
      font-weight: 600;
      // left: 50%;
      // transform: translateX(-50%);
      color: #222;
      font-family: "LGSmartUISB";
    }
  }
  .dot {
    content: "";
    position: absolute;
    top: 0.4167rem;
    right: 0.625rem;
    display: block;
    width: 0.625rem;
    height: 0.2083rem;
    border-radius: 0.2083rem;
    background-color: red;
  }

  &:focus {
    .size(@w: 100%, @h: 3.333rem);
    background-color: #fefdfa;
    // animation: tabSelection 0.3s normal forwards ease-in-out;
    text-align: center;

    span {
      display: block;
      // .font(@fontFamily: "LGSmartUIR", @fontSize: 1rem, @color: #222, @fontWeight: 400);//previous
      .font(@fontFamily: "LGSmartUISB", @fontSize: 1.25rem, @color: #222, @fontWeight: 600);
    }
    &:last-child {
      margin-right: 0;
    }
  }
} //End :: tabBtn

//tab 내부 layOut(PhysicalTestTabContentsLayout.jsx)
.physicalTestTabContentsWrapper {
  height: 100%;
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: column);
}
.physicalTestTabContents {
  overflow: hidden;
  width: 100%;
  height: 590px;
  margin-bottom: 0;
}

//그래프  전체
.physicalOverAll {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-right: 3.7083rem;

  //좌측 overall 그래프 영역

  .overAllGraphArea {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .overAllBarGraph {
    .size(@w: 100%, @h: 100%);
  }

  .bodyAverage {
    position: absolute;
    right: 0;
    top: -35%;
    height: 100%;
    width: 28%;
    text-align: center;
  }

  //physicalTest previous별도 class지정
  &.basic {
    .bodyAverage {
      left: 0;
      img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        transition: opacity 1s ease-in-out;
      }
      .fade-in {
        opacity: 1;
      }
      .fade-out {
        opacity: 0.3;
      }
    }
    .userBodyScore {
      right: 2.5rem;
      left: inherit;
    }
    .bodyScoreText {
      font-family: "LGSmartUISB";
      font-size: 1.5rem;
      font-weight: 600;
      color: #8b8b8b;
    }
    .num {
      font-family: "fluxNumberR";
      font-size: 9.4583rem;
      font-weight: 400;
      line-height: 0.8;
      margin-top: -1.6667rem;
      color: #8b8b8b;
    }
  }
}

.bodyAverageGraph {
  padding: 3.5rem 1.6667rem 0 1.6667rem;

  .ExGraph {
    .size(@w: 8.5833rem, @h: 3.1667rem);
    text-align: center;
    white-space: normal;
    margin: 0 auto;
    .font(@fontFamily: "LGSmartUIR", @fontSize: 1.25rem, @color: #706f69, @fontWeight: 400);
  }
  .bodyAverageText {
    text-align: center;
    letter-spacing: -0.02em;
    .font(@fontFamily: "LGSmartUIR", @fontSize: 1rem, @color: #A8A8A8, @fontWeight: 400);
  }
}

.averageLine {
  .position(@position: absolute, @top: 15rem, @bottom: null, @right: null, @left: 55%);
  transform: translateX(-50%);
  .font(@fontFamily: "LGSmartUIR", @fontSize: 1rem, @color: #FF902F, @fontWeight: 600);
  &:before {
    content: "";
    display: inline-block;
    .position(@position: absolute, @top: null, @bottom: 95%, @right: null, @left: 50%);
    .size(@w: 0.1667rem, @h: 13rem);
    .imgElement(0.1667rem, 13rem, center, center);
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='360' fill='none'%3E%3Cpath stroke='url(%23a)' stroke-dasharray='0.5 6' stroke-linecap='round' stroke-width='4' d='M2 2v356'/%3E%3Cdefs%3E%3ClinearGradient id='a' x1='0' x2='0' y1='360' y2='0' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23FF902F'/%3E%3Cstop offset='.439' stop-color='%23FF902F' stop-opacity='.32'/%3E%3Cstop offset='1' stop-color='%23FF902F' stop-opacity='0'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  }
}

.bodyEachReport {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;

  .centerChart {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.top {
  padding-top: 0;
}

.medicalCenter {
  position: absolute;
  width: 181px;
  height: 38px;
  right: 92px;
  bottom: 18px;
  background-image: url("../../../../assets/images/medicalCenterMark.svg");
}

.scoreContainer {
  position: absolute;
  right: 0;
  width: 488px;
  height: 100%;
  background: linear-gradient(
    270deg,
    @COLOR_GRAY02 50%,
    rgba(242, 239.4, 237, 0) 100%
  );

  .userBodyScore {
    position: absolute;
    top: 48px;
    right: 12px;
    width: 251px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: @COLOR_GRAY08;
    .bodyScoreText {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 36px, @color: @COLOR_GRAY06, @fontWeight: 600);
      height: 42px;
      line-height: 42px;
    }
    .num {
      line-height: 6.4583rem;
      margin-top: -10px;
      // margin-top: -1.6667rem;
      .font(@fontFamily: "LGSmartUISB", @fontSize: 150px, @color: @COLOR_GRAY08, @fontWeight: 400);
    }
  }
  .scoreMonthContainer {
    position: absolute;
    right: 19px;
    bottom: 64px;
    width: 439px;
    height: 101px;

    display: flex;
    justify-content: space-between;
    gap: 10px;
    .scoreMonthItem {
      width: 100px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      align-items: center;

      .month {
        .font(@fontFamily:"LGSmartUISB", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 600);
        height: 25px;
        line-height: 1.1;
      }
      .score {
        .font(@fontFamily:"LGSmartUIB", @fontSize: 30px, @color: @COLOR_GRAY05, @fontWeight: 700);
        height: 35px;
        line-height: 1.1;
      }
      .time {
        .font(@fontFamily:"LGSmartUISB", @fontSize: 18px, @color: @COLOR_GRAY05, @fontWeight: 600);
        height: 21px;
        opacity: 0.5;
        line-height: 1.1;
      }
    }
  }
}

@__DEV__: true;