@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

@FONT_SIZE: 28px;
.input {
	margin-right: 0px;
	margin-left: 0px;
	height: 81px !important;
	padding: 0 30px !important;
	border-radius: 8px;
	box-shadow: 0 0 0 2px #494847 inset;
	background: rgba(255, 255, 255, 0.08) !important;

	// border
	&.none {
		box-shadow: none;
	}

	//color 
	&.transparent {
		background: transparent !important;
	}
	
	&:focus-within,
	&:hover {
		box-shadow: 0 0 0 2px #FEFEFE inset;
	}
	> div {
		display: none;
	}

	> input {
		font-family: 'LGSmartUISB';
		color: #FEFEFE !important;
		font-size: 32px !important;
		height: inherit !important;;
    line-height: inherit !important;
	}
	> input::placeholder {
		font-family: 'LGSmartUIR';
		color: #787776;
		font-size: 32px;
	}



}

.withIcon {
	> div { // icon border
		display: block;
    width: 1px;
    height: 25px;
    margin: 0 20px;
		position: unset !important;
    opacity: 0.3 !important;
    background-color: #FEFEFE !important;
    border-radius: unset !important;
	}

	&::before { //icon
		content: "";
		display: block;
		width: 32px;
		height: 32px;
		background-size: 24px 19px;
		background-position: center;
		background-repeat: no-repeat;
	}

}

.email {
	&::before  {
		background-image: url('../../../assets/report/email.svg');
	}
	
}
@__DEV__: true;