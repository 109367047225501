@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";
@import "../../components/TabLayout/TabLayout.module.less";

.imageContainer {
  position: absolute;
  width: 400px;
  height: 500px;
  right: 250px;
  &.hasData {
    right: auto;
    left: 900px;
  }
}

.header {
  background-color: @BG_COLOR_04;
}

.panel {
  background: initial !important;
  background-color: @MAIN_COLOR;
  width: ~"calc(100% - @{MAIN_COLLAPSED_WIDTH})" !important;
  top: 0px;
  left: @MAIN_COLLAPSED_WIDTH;
  height: 100%;
  color: black;
  position: absolute;
}

.hide {
  visibility: hidden;
}

.body {
  padding: 0px !important;
  .firstPage{
    width: 100%;
    height: ~"calc(100vh - @{fitTv-tHeader-height})";
    position: relative;
    .scrollGuide {
      color: #4F423C;
      font-size: 24px;
      height: 28px;
      line-height: 28px;
      font-family: 'LGSmartUIB'; //font-weight: 700;
      display: flex;
      justify-content: left;
      align-items: center;
      position: absolute;
      left: 50%;
      bottom: 26px;
      transform: translateX(-50%);
    }
    .scrollGuide::before { // arrow bottom
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background-image: url("../../../assets/landingPage/icon/ic_arrow_down_light.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 10px;
    }
  }

  .nthPage{
    width: 100%;
    height: auto;
    // height: ~"calc(100vh - @{fitTv-tHeader-height} - 110px)";
    // position: relative;
    &:last-child {
      height: 100vh;
    }
  }


  .mediaListTitle{
    font-size: 36px;
    height: 42px;
    line-height: 42px;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    color: #000;
    margin:0 0 0 115px;
  }
  .contentList {
    margin-bottom: 30px;
  }
  .physicalTestList {
    margin-bottom: 32px;
  }
  .banner {
    .size(@w: 1584px, @h: 256px);
    border-radius: 20px;
    margin: 0 115px 0 115px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-bottom: 60px;
  }
  @MOVE_RIGHT: 268px;
  .heroImage {
    position: absolute;
    .size(@w: auto, @h: 695px);
    top: -90px;
    right: 0px;
    &.hasData {
      right: @MOVE_RIGHT;
    }
  }
  .heroBGImage {
    position: absolute;
    .size(@w: 643px, @h: 617px);
    top: -62px;
    right: 116px;
    &.hasData {
      right: calc(116px + @MOVE_RIGHT);
    }
  }

  .graphContainer {
    @W_GAP: 41px;
    @GRAPH_WIDTH: 196px;
    width: calc(@GRAPH_WIDTH + 82px);
    // padding: 0 @W_GAP;
    position: absolute;
    top: 50px;
    right: 96px;
    padding: 41px 41px 36px 41px;
    box-sizing: border-box;
    > div {
      > canvas {
        width: 196px;
      }
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .goalPercentage {
        position: absolute;
        top: 71px;
        height: 53px;
        line-height: 53px;
        left: 50%;
        transform: translateX(-50%);
        .font(@fontFamily: "LGSmartUIB" , @fontSize: 45px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 700);
        > span {
          .font(@fontFamily: "LGSmartUISB" , @fontSize: 27px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 600);
        }
      }
    }

    .textWrapper {
      margin-top: 16px;
      text-align: center;
      .font(@fontFamily: "LGSmartUISB" , @fontSize: 27px, @color: @PRIMARY_COLOR_BROWN03, @fontWeight: 600);
      .typeName {
        margin: 0;
        height: 32px;
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        &::after {
          content: "";
          display: inline-block;
          width: 26px;
          height: 26px;
          margin-left: 8px;
          background-image: url("../../../assets/landingPage/icon/ic_arrow_round_26.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          transform: scale(1);
          transition: transform 0.3s ease;
        }
      }
      .goalContainer {
        height: 32px;
        text-align: center;
        > span:last-child {
          margin-left: 4px;
          color: @COLOR_GRAY05;
        }
      }
    }

    &:focus {
      .typeName::after {
        transform: scale(1.08);
      }
    }
    &.pressed {
      &::before {
        content: "";
        display: inline-block;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 16px;
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
  }

  .logoLayer {
    position: absolute;
    top: 44px;
    left: 116px;
    .logoImage {
      width: 319px;
      height: 84px;
    }
    .guideText {
      margin-top: 24px;
      width: 650px;
      height: 90px;
      .font (@fontFamily: 'LGSmartUIR' , @fontSize: 32px, @color: @COLOR_GRAY06, @fontWeight: 500);;
      word-break: keep-all;
      line-height: 45px;
    }
    .btn {
      margin-top: 30px;
    }
  }
  .dataLayer {
    position: absolute;
    .size(@w: 548px, @h: 190px);
    right: 116px;
    top: 253px;
    // background-color: rgba(240, 248, 255, 100);
    text-align: center;
    display: flex;
    justify-content: space-between;

    .graphWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 190px;
      height: 190px;

      .textWrapper {
        position: absolute;
        width: 69px;
        height: 53px;

        .graphNum {
          color: @PRIMARY_COLOR_BROWN03;
          font-size: 45px;
          font-family: "LGSmartUI";
          font-weight: 700;
          height: 53px;
          line-height: 1.2;
        }
        .graphPer {
          color: @PRIMARY_COLOR_BROWN03;
          font-size: 26px;
          font-family: "LGSmartUI";
          font-weight: 600;
        }
      }
    }
    .infoWrapper {
      position: relative;
      width: 100%;
      height: 120px;

      margin: 35px 0;
      margin-left: 42px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .goalComponent {
        position: relative;
        display: flex;
        align-items: center;
        color: @COLOR_GRAY05;
        font-size: 24px;
        font-family: "LGSmartUI";
        font-weight: 600;
        transition: 0.3s all;
        // width: 100%;
        &:focus::after {
          content: "";
          position: absolute;
          display: inline-block;
          width: 100%;
          height: 100%;
          border: 5px solid white;
          border-radius: 10px;
          left: -6px;
          padding: 4px;
        }

        &.active {
          color: @PRIMARY_COLOR_BROWN03;
          font-size: 32px;
          font-weight: 700;

          .goalInfo {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .mainSectionContainer {
    position: absolute;
    width: 1582px;
    right: 86px;
    bottom: 50px;
    padding: 30px 30px 60px 30px;
    display: flex;
    justify-content: space-between;
  }
}

.upperContainer {
  height: 400px;
}

@__DEV__: true;