@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";


.popup {
  //enact popup reset
  margin-bottom: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  width: 100%;
  height: 100%;
  // z-index: 300;
  > div {
    padding: 0 !important;
    width: 100%;
    height: 100%;
    > div {
      width: 100%;
      height: 100%;

      > div {
        max-width: 100% !important;
        max-height: 100% !important;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.painContainer{
  .cl_modalDimmer {
    background: @COLOR_GRAY09;
    opacity: 0.9;
    // z-index: 3;
  }
  .container {
    position: fixed;
    top: @fitTv-tHeader-height;
    left: 0px;
    z-index: 3;
    width: 100%;
    height: 100%;
    color: @COLOR_GRAY01;
    text-align: center;
    line-height: normal;
    .titleContainer{
      margin-top: 34px;
      .title {
        font-family: "LGSmartUIB";
        font-size: 48px;
      }
      .desc {
        font-family: "LGSmartUIL";
        font-size: 28px;
        line-height: 36px;
        margin-top: 16px;
      }
    }

    .contentsContainer {
      margin: 44px auto 60px;
      img {
        vertical-align: middle;
      }
      .painLayer {
        width: 815px;
        height: 523px;
        padding: 40px;
        display: inline-block;
        border-radius: 24px;
        background-color: rgba(33, 33, 32, 0.8);;
        // backdrop-filter: blur(39px);
        text-align: start;
        vertical-align: middle;
        .paintitle {
          font-family: "LGSmartUISB";
          font-size: 28px;
        }
        .painDesc {
          position: relative;
          color: @COLOR_GRAY04;
          font-family: "LGSmartUIL";
          font-size: 24px;
          vertical-align: middle;
          margin-top: 16px;
          img {
            width: 22px;
            height: 22px;
            margin-right: 10px;
          }
          // &::before {
          //   content: '';
          //   position: absolute;
          //   background: url("../../../../../assets/icon/alert_circle.png") no-repeat 0 0;
          //   background-size: contain;
          //   top: 0;
          //   left: -10px;
          // }
        }
        .painList {
          margin-top: 32px;
          width: 815px;
          display: flex;
          column-gap: 12px;
          flex-wrap: wrap;
          > div {
            // items
            margin-bottom: 16px;
            padding: 10px 12px;
            height: 48px;
            min-width: 105px;
            > div {
              // text
              font-size: 24px;
              height: 28px;
              line-height: normal;
            }
          }
        }
        .hr {
          width: 815px;
          height: 1px;
          background-color: @COLOR_GRAY06;
          margin-top: 16px;
        }
        .painLevel {
          margin-top: 32px;
          .levelTitle {
            font-family: "LGSmartUISB";
            font-size: 28px;
          }
          > div:nth-child(2) {
            margin-top: 68px;
            width: 822px;
            transform: translateX(7px);
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      flex-direction: row;
      column-gap: 24px;
      justify-content: center;
    }
  }
}


@__DEV__: true;