@import '../../../style/CommonStyle.module.less';
@import "../../../style/utils.module.less";

.center {
  // height: 576px;
  border-radius: 20px;
  background: @COLOR_GRAY01;
  padding: 40px 40px 46px 40px;
  color: #222;
  font-size: 26px;
  position: relative;
  margin-bottom: 20px;
  opacity: 0.5;

  .titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    column-gap: 28px;
    .logo {
      width: 134px;
      height: 134px;
      // border-radius: 20px;
      // border: 1px solid @COLOR_GRAY03;
    }

    .titleBox {
      align-self: center;
      width: 565px;
      .titleTags {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 36px;
          font-family: 'LGSmartUIB';
        }
        .tags {
          display: flex;
          flex-direction: row;
          column-gap: 6px;
          .tag {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 6px 10px;
            text-align: center;
            color:  @COLOR_GRAY07;
            font-family: 'LGSmartUISB';
            border-radius: 7px;
            border: 1px solid @COLOR_GRAY04;
          }
        }
      }
      .features {
        font-family: 'LGSmartUIR';
        margin-top: 8px;
      }
      .fav {
        color: @PRIMARY_COLOR_BROWN02;
        font-size: 21px;
        font-family: 'LGSmartUISB';
        .like {
          display: inline-block;
          width: 24px;
          height: 24px;
          background: url(../../../../assets/icon/ic_inhome_like_sel_b.svg);
          margin-right: 6px;
          vertical-align: text-top;
        }
      }
      .rating {
        margin-top: 14px;
        .star {
          width: 26px;
          height: 26px;
        }
      }
    }
  }

  .subTitle {
    height: 30px;
    font-family: 'LGSmartUIR';
    margin-top: 24px;
  }

  .hr {
    width: 725px;
    height: 1px;
    background-color: @COLOR_GRAY03;
    margin-top: 36px;
  }

  .program {
    color: @COLOR_GRAY07;
    font-family: 'LGSmartUIB';
    margin-top: 36px;
  }
  .introduction {
    color: @COLOR_GRAY07;
    font-family: 'LGSmartUIR';
    margin-top: 12px;
  }
  .button {
    margin-top: 44px;
    font-size: 27px;
  }

  &:focus {
    // box-shadow: 3px 3px 10px 0px #787776;
    opacity: 1;
  }
}
@__DEV__: true;