@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.topLayout{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  .btnBox{
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 1;

    .divider {
      width: 2px;
      height: 39px;
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}
.feedbackBox {
  display: flex;
  align-items: center;
  gap: 16px;
  > span {
    .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 600);
  }
  &.onOff {
    > span {
      min-width: 67px;
      text-align: right;
    }
  }
}
@__DEV__: true;