@import "../../../style/CommonStyle.module.less";
// common less file
@import "../../../style/utils.module.less";


.dateContainer {
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
  padding: 60px 75px 10px 75px;
  .dateWrapper {
    .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
    .tSimpleDatePicker {
      margin-right: 20px;
    }
    .alert {
      .flex(@display: flex, @justifyCenter: unset, @alignCenter: center, @direction: row);
      .size(@w: auto, @h: 28px);
      line-height: 28px;
      font-size: 24px;
      color: #DBD7D4;
      font-family: 'LGSmartUISB'; //font-weight: 600;

      &::before { // alert icon
        content: "";
        .size(@w: 20px, @h: 20px);
        background-image: url("../../../../assets//FitnessManager/icon/ic_workoutmanager_alret.png");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 9px;
      }
    }
  }
}

// todo : change height
.scroller{
  height: 745px;
}

.scrollInnerContainer {
  padding: 60px 0 78px 0;

}

.twoVerticalBarGraphContainer {
  padding: 0 60px 0 75px;
  margin-bottom: 60px;
  .titleWrapper {
    margin-bottom: 33px;
    .title {
      .size(@w: auto, @h: 42px);
      line-height: 42px;
      font-size: 36px;
      color: #FEFEFE;
      font-family: 'LGSmartUIB'; //font-weight: 700;
    }
  }
  .twoVerticalBarGraphWrapper {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    gap: 28px;
  }

}

.achievementContainer {
  padding: 0 60px 0 75px;
  .title {
    .size(@w: auto, @h: 42px);
    line-height: 42px;
    font-size: 36px;
    color: #FEFEFE;
    font-family: 'LGSmartUIB'; //font-weight: 700;
    margin-bottom: 28px;
  }

  .gGraphWrapper {
    .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
    gap: 28px;
    margin-bottom: 28px;
  }
}

.bodyCheckUpContainer {
  .titleWrapper {
    padding: 0 60px 0 75px;
    margin-bottom: 34px;
    .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: row);
    .title {
      .size(@w: auto, @h: 42px);
      font-size: 36px;
      line-height: 42px;
      color: #FEFEFE;
      font-family: 'LGSmartUIB'; //font-weight: 700;
    }
  }
}

.donutChartContainer {
  padding: 0 60px 0 75px;
  .flex(@display: flex, @justifyCenter: flex-start, @alignCenter: center, @direction: row);
  gap: 28px;
  margin-bottom: 66px;
}

.titleContainer {
  padding: 0 60px 0 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @COLOR_GRAY01;
  font-family: "LGSmartUIB";
  font-size: 36px;
  margin-top: 60px;
}

.lineChartContaienr {
  padding: 0 60px 0 75px;
  .lineChartWrapper {
    color: @COLOR_GRAY02;
    background-color: rgba(255, 255, 255, 0.03);
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.10);
    font-family: "LGSmartUIR";
    margin-top: 28px;
    border-radius: 20px;
    padding: 60px 40px 50px;
    
  }
}




@__DEV__: true;