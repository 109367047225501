@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.body{
  padding-top: 0px;
  .top{
    padding: 0px;
    padding-top: 9px;
  }
  .tab{
    padding-top: 20px;
  }
  .notification {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 50px;
    left: 75px;
    bottom: 13px;
    .font(@fontFamily: "LGSmartUIR", @fontSize: 21px, @color: @COLOR_GRAY05, @fontWeight: 400);
    line-height: 25px;
    .guideText::before {
      content: "*";
      display: inline-block;
      margin-right: 4px;
    }
  }
  .medicalCenter {
    position: absolute;
    width: 181px;
    height: 38px;
    right: 92px;
    bottom: 18px;
    background-image: url('../../../../../assets/images/medicalCenterMark.svg');
  }
}
.tabLayout{
  position: relative;
  width: 1664px;
  height: 695px;
  background-color: white;
  border-radius: 0 0 12px 12px;

  .testBtnWrap {
    display: flex;
    position: absolute;
    left: 50%;
    bottom: 40px;
    transform: translate(-50%, 0);
  }

  .testBtn {
    margin-right: 20px;
  }
}

//todo 하단 삭제....

@padding: 1.66rem;
// @borderRadius: 20px;
//tab 버튼 상단부 layOut wrapper

// tabBtn
.tabBtn {
  position: relative;
  .size(@w: 7.125rem, @h: 2.9167rem);
  line-height: 30px;
  background-color: @fitTv-tab-color;
  border-radius: 0.833rem 0.833rem 0 0;
  margin-right: 0.4166rem;
  .flex(@display: flex, @justifyCenter: center, @alignCenter: center, @direction: null);
  .font(@fontFamily:"LGSmartUIR", @fontSize: 1rem, @color: @color706F69, @fontWeight:400);
  //tabBtn 첫번째 단독 색상 지정
  &:first-child {
    background-color: rgba(112,111,105,0.4);
    > span {
      color: #FEFDFA;
    }
  }

  //두줄처리된 텍스트
  & .whiteSpaceText {
    display: block;
    white-space: pre-line;
    text-align: center;
    padding: 0 1.7rem;
    box-sizing: border-box;
  }

  //한줄 텍스트
  & .normalText {
    width: 100%;
    display: block;
    text-align: center;
  }


  &:last-child {
    margin-right: 0;
  }
  &.on {
    background-color: #FEFDFA;
    // height: 3.3333rem;
    height: 100%;

    //tabBtn 첫번째 단독 색상 지정
    &:first-child {
      > span {
        color: #222;
      }
    }

    span {
      position: absolute;
      // font-size: 1rem;
      font-size: 1rem;
      font-weight: 600;
      // left: 50%;
      // transform: translateX(-50%);
      //width: 3.3333rem;
      color: #222;
      font-family: 'LGSmartUISB';
    }
  }

  &:focus {
    .size(@w: 7.125rem, @h: 3.3333rem);
    background-color: #FEFDFA;
    // animation: tabSelection 0.3s normal forwards ease-in-out;

    span {
      display: block;
      // width: 3.3333rem;
      .font(@fontFamily: "LGSmartUISB", @fontSize: 1.25rem, @color: #222, @fontWeight: 600);
    }
  }
}


@keyframes tabSelection {
  0% {
    scale: 1;
  }
  100% {
    scale: 1.02;
  }
}

//tab 내부 layOut
.previousDetailContentsWrapper {
  height: 100%;
  .flex(@display: flex, @justifyCenter: space-between, @alignCenter: center, @direction: column);
}

.previousDetailContents {
  position: relative;
  width: 100%;

  .toggle {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

}

/*
  only FakeData (physicalRecordSwiper.jsx 42 Line)
*/



/*
  only realData (physicalRecordSwiper.jsx 42 Line)
*/
.realDataWrapping {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  width: 63.1667rem;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}



//적용page: OverallContainer.jsx
.overallContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .overallTool {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    // margin-bottom: 1.0833rem;

    //overall 상단 tool(datePicker)
    > div:first-child {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      height: 2.5833rem;
    }
    div {
      margin-bottom: 0;
    }
  }

  .overAllTabWrapper {
    display: flex;
    width: 100%;
    max-width: 52.7083rem;
    margin: 0 auto;
    height: 100%;
    justify-content: center;

    .userDataArea {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 15.25rem;
      height: 22.0833rem;
      canvas {
        border-radius: 0.8333rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .overallArea {
    position: relative;
    background-color: #f7f5f2;
    // width: 15.54rem;
    // height: 20rem;
    margin-right: 1.25rem;
    border-radius: 0.83rem;
    display: flex;
    justify-content: center;
    align-items: center;

    //마지막 overallGraph width 별도
    &.overallGraph {
      width: 19.3333rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.tempArrowImage {
  position: absolute;
}

.mark {
  position: absolute;
  bottom: -18%;
  left: 0;
  margin-top: 0.8333rem;
  display: inline;
  justify-content: flex-start;
  flex-direction: column;
  .font(@fontFamily: "LGSmartUIR", @fontSize: 0.5417rem, @color: #D8D8D7, @fontWeight: 400);
  img {
    display: block;
  }
}

@__DEV__: true;