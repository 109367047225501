@import '../../style/CommonStyle.module.less';
@import "../../style/utils.module.less";

.tBody {
  padding: 0 150px;
  height: calc(1080px - @fitTv-tHeader-height);
  text-align: center;
}

.title {
  font-size: 40px;
  margin-top: 50px;
  color: black;
  font-weight: bold;
}

.detail {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  font-size: 30px;
  color: black;
  margin-bottom: 20px;

  .rightBtn {
    position: absolute;
    top: 50%;
    right: 50px;
  }

  .leftBtn {
    position: absolute;
    top: 50%;
    left: 50px;
  }

  .image {
    position: absolute;
    top: 600px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: auto
  }

  .next {
    position: absolute;
    top: 920px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .startBox {
    display: flex;
    position: absolute;
    top: 920px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.pagination {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.paginationButton {
  margin:5px 10px;
  padding: 10px 10px;
  border-radius: 50%;
  color: #555;
  border: 1px solid #ccc;
  &:focus, &.selected {
    background-color: #555;
    color: #fff;
  }
}
@__DEV__: true;