@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.wrapper {
  position: absolute;
  bottom: 20px;
  right: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
  background-color: #333333;
  opacity: 0.7;
  width: 800px;
  height: auto;
  z-index: 5;
  color: white;
  overflow: hidden;
}
.loggerTestButton {
  padding: 10px 30px;
  border: none;
  background-color: burlywood;
  border-radius: 10px;
  font-size: 0.8rem;
  color: rgb(70, 70, 70);
  :hover {
    background-color: rgb(200, 200, 200);
    cursor: pointer;
  }
}
.loggerTestButton_disabled {
  padding: 10px 30px;
  border: none;
  background-color: rgb(163, 139, 107);
  border-radius: 10px;
  font-size: 0.8rem;
  color: rgb(70, 70, 70);
}

.loggerWindowBar {
  padding: 0 10px;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 10px;
  height: 30px;
  background-color: #000;
  box-sizing: border-box;
}

.loggerWindowButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  :hover {
    cursor: pointer;
  }
  svg {
    width: 14px;
    height: auto;
  }
}

.loggerModalWrapper {
  padding: 10px 20px;
}

.loggerTestScrollArea {
  color: white;
  overflow: auto;
  height: 260px;
  background-color: #222222;
  p {
    display: flex;
    gap: 10px;
    font-size: 10px;
    margin: 0 5px 5px 5px;
    line-height: 1.5;
  }
  p:last-child {
    margin: 0 5px 0 5px;
  }
}

.loggerButtonArea {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.logIndex {
  min-width: 20px;
}

.logItem {
}

@__DEV__: true;