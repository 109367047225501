@import "../../style/CommonStyle.module.less";

.panel{
  font-family: "LGSmartUIR";
  width: 100%;
  height: 100%;
  color: @COLOR_GRAY08;
  background: @BG_COLOR_02;
  .tBody {
    padding: 40px 75px 60px;
    position: relative;
    .overview {
      line-height: 1.5;
      font-size: 30px;
      > span {
        font-family: "LGSmartUIB";
      }
    }
    .section {
      margin-top: 54px;
      .title {
        margin-bottom: 28px;
        font-family: "LGSmartUIB";
        font-size: 34px;
      }
    }
    .weekdays {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 28px;
      column-gap: 28px;
      height: 144px;

      .timeSetting {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 13px;
        padding: 32px 16px 16px;
        justify-content: flex-start;
        width: 228px;
        // row-gap: 8px;
        background: #FFF;
        box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);

        .day {
          font-size: 34px;
          font-family: "LGSmartUISB";
          line-height: 40px;
        }
        .time {
          font-size: 28px;
          font-family: "LGSmartUISB";
          margin-top: 8px;
          color: @PRIMARY_COLOR_GREEN01;
        }

        .button {
          margin: 24px 0 0;
          font-family: "LGSmartUIB";
          width: 197px;
          height: 67px;
          border-radius: 13px;
        }
        &.disabled {
          opacity: 0.3;
        }
      }
    }

    .buttonArea {
      margin-top: 112px;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      column-gap: 20px;
      > div {
        max-width: unset;
      }
    }

  }

}

@__DEV__: true;