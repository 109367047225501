@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.tListItemButton {
  position: relative;
  &.pressed::before {
    content: "";
    .position(@position: absolute, @top: 0, @right: unset, @bottom: unset, @left: 0);
    .size(@w: 100%, @h: 100%);
    border-radius: inherit;
  }
}

.tListItemButton.dark {
  background-color: rgba(255, 255, 255, 0.03);
  &:focus {
    background-color: #FFF;
  }
  &.pressed::before  {
    background-color: #000;
    opacity: 0.3;
  }
}
@__DEV__: true;