@import "../../../../style/CommonStyle.module.less";
@import "../../../../style/utils.module.less";

.centerFlex {
    display: flex;
    justify-content: center;
    align-items: center;
}

//only real Data (physicalRecordSwiper.jsx - 43line)
.photoWrapper {
    width: 1160px;
    height: 100%;
    .centerFlex();
    .swiper_container{
        position: relative;
        width: 982px;
        .swiperSlide{
      padding-top: 12px;
      padding-bottom: 56px;
        }
        .workoutInfoLayer{
      z-index: 1;
            position: absolute;
            display: flex;
            justify-content: center;
      bottom: 0;
            width: 100%;
      height: 50px;
            opacity: 1;
            transition: opacity 300ms ease;
            .infoItem{
                display: flex;
        margin-right: 29px;
        &:last-child {
          margin-right: 0;
        }
                .leftline{
          width: 40px;
          height: 29px;
          background-image: url("../../../../../assets/bodyAlignment/ic_bodycheck_bodyAllignment_detail_arrow1.svg");
                }
                .textLayer{
          position: relative;
          width: 220px;
          height: 44px;
          margin-top: 6px;
          border-radius: 12px;
          box-sizing: border-box;
          padding: 8px 16px;
          background-color: @COLOR_GRAY07;
          text-align: center;
                    .contents{
            .font(@fontFamily: "LGSmartUISB", @fontSize: 24px, @color: @COLOR_GRAY01, @fontWeight: 600);
            height: 28px;
            display: flex;
            justify-content: center;
            align-items: center;
                        &::before {
                            content: "";
                            width: 28px;
                            height: 28px;
              margin-right: 6px;
                            position: relative;
                            display: inline-block;
              background-image: url("../../../../../assets/bodyAlignment/ic_bodycheck_bodyAllignment_detail_date_change.svg");
                        }
                    }
                }
                .rightline{
          width: 45px;
          height: 29px;
          background-image: url("../../../../../assets/bodyAlignment/ic_bodycheck_bodyAllignment_detail_arrow2.svg");
                }
            }
        }
    }

    //좌, 우 화살표 및 하단부 날짜 스타일 공통
    .chevron {
        //왼쪽 화살표
        &.swiperButtonPrev {
            // margin-right: 28px;
            transform: scale(1);
            display: block;
            background-color: @colore0ded5;
            border-radius: 100%;
      .size(@w: 60px, @h: 60px);
      .imgElement(@backgroundW: 60px, @backgroundH: 60px, @positionX: center, @positionY: center);
      background-image: url("../../../../../assets/icon/ic_arrow_round_left_nor.png");
            &:focus {
        background-image: url("../../../../../assets/icon/ic_arrow_round_left_foc.png");
            }
        }
        //오른쪽 화살표
        &.swiperButtonNext {
            display: block;
            background-color: @colore0ded5;
            border-radius: 100%;
      .size(@w: 60px, @h: 60px);
      .imgElement(@backgroundW: 60px, @backgroundH: 60px, @positionX: center, @positionY: center);
      background-image: url("../../../../../assets/icon/ic_arrow_round_right_nor.png");
            transform: scale(1);
            &:focus {
        background-image: url("../../../../../assets/icon/ic_arrow_round_right_foc.png");
            }
        }
        &:focus {
            transform: scale(1.1);
            box-shadow: 0 3px 5px 0 rgba(112, 111, 105, 0.5);
            background-color: #403E3D;
      .size(@w: 60px, @h: 60px);
      .imgElement(@backgroundW: 60px, @backgroundH: 60px, @positionX: center, @positionY: center);
        }
        &.disabled {
           opacity: 0.3;
        }
    }

    //슬라이드 하단부 날짜
    .recordDate {
    .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 600);
        text-align: center;
        width: 310px;
    height: 32px;
    line-height: 32px;
    padding-top: 13px;
    }
    .slideWrapper{
        width: 310px;
        // height: 457px;
        height: 375px;
        // border: 1px solid @COLOR_GRAY03;
        box-shadow: 0 0 0 1px @COLOR_GRAY03 inset;
        border-radius: 15px;
        border-width: 1px;
        border-style: solid;
    box-sizing: border-box;
        .swiperInnerImg {
            position: relative;
            width: 310px;
            height: 374px;
            .canvas{
                border-radius: 15px;
            }
        }
    }
    .hidden{
        opacity: 0 !important;
    }
}
@__DEV__: true;