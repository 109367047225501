@import "../../../../../style/CommonStyle.module.less";
@import "../../../../../style/utils.module.less";

.scroller {
  padding-right: 0;
  .infoBox {
    padding: 60px;
    padding-bottom: 30px;
    .info {
      display: flex;
      justify-content: space-between;

      > img {
        width: 858px;
        height: 352px;
        margin-right: 52px;
      }

      .footTypeInfo {
        width: 740px;
      }
    }
  }

  .diseasesListBox {
    padding: 30px 0;
    .diseasesList {
      margin: 0 60px;
      display: flex;
      flex-wrap: wrap;
      position: relative;

      .divider {
        position: absolute;
        left: 549px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: #d9d9d9;
        &.second {
          left: 1099px;
        }
      }
      .desc {
        margin-bottom: 16px;
        width: 490px;
        margin-left: 60px;
        &:first-child,
        &:nth-child(4),
        &:nth-child(7) {
          margin-left: 0;
          width: 550px;
        }
        &::before {
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          vertical-align: middle;
          background-color: #000000;
          margin-right: 8px;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .title {
    .font(@fontFamily: "LGSmartUIB", @fontSize: 36px, @color: @COLOR_GRAY07, @fontWeight: 700);
    line-height: 46px;
    word-wrap: break-word;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    .icon {
      background-image: url("../../../../../../assets/icon/alert_circle_subheading.svg");
      background-repeat: no-repeat;
      background-size: cover;
      width: 26px;
      height: 26px;
      margin-right: 12px;
    }
  }
  .desc {
    .font(@fontFamily: "LGSmartUIR", @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 400);
    line-height: 38px;
    word-wrap: break-word;
    > span {
      .font(@fontFamily: "LGSmartUIB",  @fontSize: 27px, @color: @COLOR_GRAY07, @fontWeight: 700);
    }
  }

  // &::before {
  //   content: "";
  //   width: 2px;
  //   height: 100%;
  //   border-radius: 50%;
  //   position: absolute;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  //   background-color: #f2efed;
  // }
}

.tab {
  position: absolute;
  top: -86px;
  left: 0;
}

.exerciseLayer {
  padding-top: 30px;
  .pictogramLayer {
    padding: 0 60px;
    margin-top: 24px;
    margin-bottom: 16px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .pictogram {
      .font(@fontFamily: "LGSmartUISB", @fontSize: 27px, @color: @COLOR_GRAY06, @fontWeight: 600);
      padding: 12px 24px;
      border-radius: 10px;
      border: 1px solid @COLOR_GRAY03;
      display: flex;
      align-items: center;
      height: 32px;

      .picIcon {
        height: 32px;
        margin-right: 6px;
      }
    }
  }
}

.mediaList {
  width: auto;
  height: 360px;
  > div {
    width: 1770px;
    height: 360px;
    position: absolute;
    left: 0;
    & > div {
      height: 360px;
    }
    & > div > div {
      padding-top: 28px;
    }
  }
}

.bottomButtonLayer {
  display: flex;
  justify-content: center;
  margin-top: 46px;
  padding-bottom: 60px;
  .button {
    margin-left: 10px;
    margin-right: 10px;
    height: 71px;
    min-width: 304px;
    font-size: 33px;
  }
}

@__DEV__: true;