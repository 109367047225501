@import "../../style/CommonStyle.module.less";
@import "../../style/utils.module.less";

.panel {
  position: fixed;
  bottom: 52px;
  left: 52px;
  width: 506px;
  height: 586px;
  background: #000;
  z-index: 30;
  font-family: "LGSmartUIR";
  border-radius: 12px;
  .shaka-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 506px;
    height: 285px;
  }
  .countdown{
    position: absolute;
    left: 24px;
    top: 30px;
    z-index: 30;
    color: @COLOR_GRAY01;
    >span{
      color: @PRIMARY_COLOR_GREEN03;
    }
  }
  .autoPlayStop{
    position: absolute;
    top: 115px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.20);
    border: 2px solid @COLOR_GRAY01;
    color: #fff;
    >div>span{
      display: inline-block;
      width: 24px;
      height: 25px;
      background-image: url("../../../assets/icon/autoplay_stop.png");
      background-repeat: no-repeat;
      margin-right: 4px;
      vertical-align: middle;
    }
  }
  .container{
    position: absolute;
    top: 285px;
    left: 24px;
    height: 227px;
    .testTitle{
      font-family: "LGSmartUIB";
      font-size: 27px;
      color: @COLOR_GRAY01;
      margin-top: 24px;
    }
    .testDetail{
      font-family: "LGSmartUIL";
      font-size: 24px;
      margin-top: 10px;
    }
    .flexBox{
      font-family: "LGSmartUIB";
      text-align: center;
      .testBtn, .retryBtn{
        display:block;
        width: 458px;
        height: 56px;
        line-height: 56px;
        font-size: 24px;
        color: @COLOR_GRAY01;
        border-radius: 58px;
        background: rgba(255, 255, 255, 0.10);
        max-width: 458px !important;
      }
      .testBtn{
        margin-top: 26px;
        margin-bottom: 14px;
      }
    }
  }
  }
@__DEV__: true;