@import "../../style/CommonStyle.module.less";
// common less file
@import "../../style/utils.module.less";

.jListTitle {
  font-size: 34px;
  font-family: "LGSmartUIB";
  margin-bottom: 28px;
}
.jMediaList {
  width: 100%;
  height: 348px;

  & > div {
  }
  & > div > div {
    // transition: 0.3s all;
    // margin-right: -75px;
    // margin: 0 -75px;
    // padding-right: 75px;
  }
  //do not set height
}

@__DEV__: true;