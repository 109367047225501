@import '../../style/CommonStyle.module.less';
@import '../../style/utils.module.less';

.trackContainer {
  position: relative;
  display: inline-block;

  .canvas {
    position: relative;
    z-index: 1;
  }
  .guideCanvas {
    position: absolute;
    top: 0;
    left: 0;
  }

  .startIcon, .goalIcon, .myIcon, .speedIcon {
    position: absolute;
    z-index: 2;
  }
}
@__DEV__: true;