.image{
  opacity: 0;
  position:absolute;
  width: 100%;
  height: 100%;
}
.canvas{
  position:absolute;
  width: 100%;
  height: 100%;
}
@__DEV__: true;